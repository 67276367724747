import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Address from "../components/Partner/Address/Address";
import BasicInfo from "../components/Partner/BasicInfo/BasicInfo";
import Contact from "../components/Partner/Contact/Contact";
import Documents from "../components/Partner/Documents/Documents";
import PersonalDetails from "../components/Partner/PersonalDetails/PersonalDetails";
import "./Tabcontent.css";

const Tabcontent: React.FC<any>= ({setlogo,partnerlogo}) => {
  const navigate = useNavigate();
  const [stepFlag, setStepFlag] = useState<any>(
    localStorage.getItem("StepFlag")
  );

  const [buttonLoadingSkip, setButtonLoadingSkip] = useState(false);
  const [buttonLoadingBack, setButtonLoadingBack] = useState(false);

  const [value, setValue] = useState(parseInt(stepFlag) + 1);

  const onSaveAndContinueClick = (flag: string) => {
    const number = 1;
    if (flag === "N") {
      setValue(value + number);
      setStepFlag(localStorage.getItem("StepFlag"));
    } else if (flag === "B") {
      setValue(value - number);
    } else {
      setStepFlag(localStorage.getItem("StepFlag"));
      setValue(value)
    }
  };
  const onNextClick = () => {
    setButtonLoadingSkip(true);
    setTimeout(() => { 
    const number = 1;
    setValue(value + number);
  }, 1000);
  };

  const onStepClick = (val: any) => {
    setValue(val);
  };

  if (value == 6) {
    navigate("/payment");
  }
  if (value == 12) {
    setValue(1);
  }
  return (
    <>
      <div className="tab-content" id="pills-tabContent">
        <ul className="tab-pane active" id="myTab" role="tablist">
          <div className="payment-steps">
            <ul className="steps">
              <li className="step">
                <span
                  className={
                    value === 1 ? "payment-count active" : "payment-count"
                  }
                  onClick={() => onStepClick(1)}
                >
                  1
                </span>
                <span className="pl-8 step-title text-blue">Basic Info</span>
                <span className="payment-line" />
              </li>
              <li className="step">
                <span
                  className={
                    value === 2 ? "payment-count active" : "payment-count"
                  }
                  onClick={() =>
                    parseInt(stepFlag) === 0 ? "" : onStepClick(2)
                  }
                >
                  2
                </span>
                <span className="pl-8 step-title text-blue">Address</span>
                <span className="payment-line" />
              </li>
              <li className="step">
                <span
                  className={
                    value === 3 ? "payment-count active" : "payment-count"
                  }
                  onClick={() =>
                    parseInt(stepFlag) === 1 || parseInt(stepFlag) === 0
                      ? ""
                      : onStepClick(3)
                  }
                >
                  3
                </span>
                <span className="pl-8 step-title text-blue">Contact Info</span>
                <span className="payment-line" />
              </li>
              <li className="step">
                <span
                  className={
                    value === 4 ? "payment-count active" : "payment-count"
                  }
                  onClick={() =>
                    parseInt(stepFlag) === 2 ||
                      parseInt(stepFlag) === 1 ||
                      parseInt(stepFlag) === 0
                      ? ""
                      : onStepClick(4)
                  }
                >
                  4
                </span>
                <span className="pl-8 step-title text-blue">Documents</span>
                <span className="payment-line" />
              </li>
              <li className="step">
                <span
                  className={
                    value === 5 ? "payment-count active" : "payment-count"
                  }
                  onClick={() =>
                    parseInt(stepFlag) === 3 ||
                      parseInt(stepFlag) === 2 ||
                      parseInt(stepFlag) === 1 ||
                      parseInt(stepFlag) === 0
                      ? ""
                      : onStepClick(5)
                  }
                >
                  5
                </span>
                <span className="pl-8 step-title text-blue">
                  Personal Details
                </span>
              </li>
            </ul>
          </div>
        </ul>
      </div>

      {value === 1 && (
        <BasicInfo
          onSaveAndContinueClick={onSaveAndContinueClick}
          onNextClick={onNextClick}
        
          buttonLoadingSkip={buttonLoadingSkip}

          setButtonLoadingSkip={setButtonLoadingSkip}
  
        />
      )}
      {value === 2 && (
        <Address
          onSaveAndContinueClick={onSaveAndContinueClick}
          onNextClick={onNextClick}
          buttonLoadingBack={buttonLoadingBack}
          buttonLoadingSkip={buttonLoadingSkip}
          setButtonLoadingBack={setButtonLoadingBack}
          setButtonLoadingSkip={setButtonLoadingSkip}
          
        />
      )}
      {value === 3 && (
        <Contact
          onSaveAndContinueClick={onSaveAndContinueClick}
          onNextClick={onNextClick}
          buttonLoadingBack={buttonLoadingBack}
          buttonLoadingSkip={buttonLoadingSkip}
          setButtonLoadingBack={setButtonLoadingBack}
          setButtonLoadingSkip={setButtonLoadingSkip}
        />
      )}
      {value === 4 && (
        <Documents
          onSaveAndContinueClick={onSaveAndContinueClick}
          onNextClick={onNextClick}
          buttonLoadingBack={buttonLoadingBack}
          buttonLoadingSkip={buttonLoadingSkip}
          setButtonLoadingBack={setButtonLoadingBack}
          setButtonLoadingSkip={setButtonLoadingSkip}
        />
      )}
      {value === 5 && (
        <PersonalDetails
          onSaveAndContinueClick={onSaveAndContinueClick}
          onNextClick={onNextClick}
          buttonLoadingBack={buttonLoadingBack}
          buttonLoadingSkip={buttonLoadingSkip}
          setButtonLoadingBack={setButtonLoadingBack}
          setButtonLoadingSkip={setButtonLoadingSkip}
        />
      )}
    </>
  );
};
export default Tabcontent;
