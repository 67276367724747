import React, { useState, useEffect } from "react";
import { IRegister } from "../../../models/IRegister";
import IRLOGO from "../../../assets/images/icon/logo-icon.png";
import emailImg from "../../../assets/images/Vector.svg";
import username from "../../../assets/images/username-img.svg";
import { Button } from "primereact/button";
import { RegisterService } from "../../../services/Account/RegisterService";
import { Link, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import RegisterSuccess from "../../../assets/images/tick.svg";
import { validEmail } from "../../../utils/utils";
import { Logout } from "../../../utils/AccountUtils";
interface IState {
  user: IRegister;
}
const Login: React.FC = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<IState>({
    user: {
      userName: "",
      firstName: "",
      lastName: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [onSuccessRegistration, setOnSuccessRegistration] = useState(false);
  const [userNameErrorMessage, setuserNameErrorMessage] = React.useState("");
  const [firstNameErrorMessage, setfirstNameErrorMessage] = React.useState("");
  const [lastNameErrorMessage, setlastNameErrorMessage] = React.useState("");
  //   handle change function

  const ErrorMessageEmptyModel = () => {
    setuserNameErrorMessage("");
    setfirstNameErrorMessage("");
    setlastNameErrorMessage("");
  };

  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null || value === 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  const isValidate = (values: any) => {

    let formIsValid = true;
    ErrorMessageEmptyModel();
    if (CheckNull(values.firstName)) {
      setfirstNameErrorMessage("Please enter firstname.");
      formIsValid = false;
    }


    if (values.firstName.trim().length === 0) {
      setfirstNameErrorMessage(
        "Please enter firstname."
      );
      formIsValid = false;

    }

    if (values.firstName.length > 0) {
      setState({
        user: {
          ...state.user,
          firstName: values.firstName.trim(),
        },
      });
    }

    if (CheckNull(values.lastName)) {
      setlastNameErrorMessage("Please enter lastname");
      formIsValid = false;
    }

    if (values.lastName.length > 0) {
      setState({
        user: {
          ...state.user,
          lastName: values.lastName.trim(),
        },
      });
    }
    if (values.lastName.trim().length === 0) {
      setlastNameErrorMessage(
        "Please enter lastname."
      );
      formIsValid = false;
    }


    if (CheckNull(values.userName)) {
      setuserNameErrorMessage("Please enter email");
      formIsValid = false;
    }

    if (values.userName.trim().length === 0) {
      setuserNameErrorMessage(
        "Please enter email."
      );
      formIsValid = false;
    }
    if (values.userName.length > 0) {
      setState({
        user: {
          ...state.user,
          userName: values.userName.trim(),
        },
      });
    }

    if (!CheckNull(values.userName)) {
      if (!validEmail.test(values.userName)) {
        setuserNameErrorMessage("Please enter valid email");
        formIsValid = false;
      }
    }
    return formIsValid;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({
      user: {
        ...state.user,
        [event.target.name]: event.target.value,
      },
    });
  };

  // form handling
  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoading(true);
    if (isValidate(state.user)) {
      RegisterService.register(state.user)
        .then((data) => {

          setLoading(false);
          setOnSuccessRegistration(true);
        })
        .catch((error) => {

          if (error.response.status === 500) {
            console.log(error);
          } else if (error.response.status === 409) {
            setuserNameErrorMessage(error.response.data);
          }

          setLoading(false);
          setOnSuccessRegistration(false);
        });
    } else {
      setLoading(false);
    }
  };

  const onHideClick = () => { };
  return (
    <>
      <div>
        <div className="Sigin-page bg-height">
          <div className="Register-form">
            <div className="login-page">
              <img src={IRLOGO} alt="img" className="logo-white" />
            </div>
            <div className="signin-form-display">
              <div className="login-form-display">
                <div className="text-center">
                  <h2>Welcome!</h2>

                  <h3>Access the instant payment network</h3>
                </div>

                <div className="username-form register-form">
                  <form>
                    <div className="form-group login-form-input ">
                      <p className="lh-40">
                        <img src={username} alt="img" />
                        First name
                      </p>
                      <input
                        id="exampleInputFirstname"
                        name="firstName"
                        autoComplete="nope"
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter firstname"
                        onChange={handleChange}
                        value={state.user.firstName}
                      />
                      {firstNameErrorMessage !== null &&
                        firstNameErrorMessage.length > 0 ? (
                        <span className="login-error-msg">
                          {firstNameErrorMessage}
                        </span>
                      ) : null}
                    </div>
                    <div className="form-group login-form-input ">
                      <p className="lh-40">
                        <img src={username} alt="img" />
                        Last name
                      </p>
                      <input
                        type="text"
                        name="lastName"
                        autoComplete="nope"
                        className="form-control"
                        id="exampleInputLastname"
                        placeholder="Enter lastname"
                        value={state.user.lastName}
                        onChange={handleChange}
                      />
                      {lastNameErrorMessage !== null &&
                        lastNameErrorMessage.length > 0 ? (
                        <span className="login-error-msg">
                          {lastNameErrorMessage}
                        </span>
                      ) : null}
                    </div>
                    <div className="form-group login-form-input password-input">
                      <p>
                        {" "}
                        <img src={emailImg} alt="img" />
                        Email
                      </p>
                      <input
                        type="email"
                        name="userName"
                        autoComplete="nope"
                        className="form-control"
                        id="exampleInputEmail"
                        placeholder="Enter email"
                        value={state.user.userName}
                        onChange={handleChange}
                      />
                      {userNameErrorMessage !== null &&
                        userNameErrorMessage.length > 0 ? (
                        <span className="login-error-msg">
                          {userNameErrorMessage}
                        </span>
                      ) : null}
                    </div>

                    <Button
                      iconPos="left"
                      label="Register"
                      className="btn btn-register"
                      loading={loading}
                      onClick={handleSubmit}
                    />
                  </form>
                </div>
                <div className="text-center  ">
                  <span className=" register-login-text">
                    <Link to="/">Already have login and password?</Link>
                  </span>
                  <div className="sign-in-text" >
                    {" "}
                    <Link to="/">Sign In</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
   
       
{onSuccessRegistration ? 
<div className="popup-body register-main">

         <div className="regiter-popup">

        <div className="text-center ">
                <img src={RegisterSuccess} alt="img" className="popup-img " />{" "}
                <div className="awesome-text">
                  <h2 style={{ }}> Awesome !<br />
                    Thank you for registering</h2>
                </div>
              </div>
    
              <p className="text-center">
                Please click on the link that has just been sent to your email
                account to verify your email and continue the onboarding process.
                <br />
                <button
                  className="btn btn-register-ok "
                  onClick={() => Logout(navigate)}
                >
                 
                  OK
                </button>
              </p>
  
    </div>
    </div>
     :null
}

  
        </div>
    
    </>
  );
};

export default Login;
