import { Button } from "primereact/button";
import React, { useState, useEffect, useContext } from "react";
import { ILogin } from "../../../models/ILogin";
import IRLOGO from "../../../assets/images/icon/logo-icon.png";
import passImg from "../../../assets/images/pass-img.svg";
import username from "../../../assets/images/username-img.svg";
import { useNavigate } from "react-router";
import "./Login.css";
import { LoginService } from "../../../services/Account/LoginService";
import { Link } from "react-router-dom";
import { validEmail } from "../../../utils/utils";
import { Logindata } from "../../../utils/AccountUtils"
import LocalStorageContext from "../../Context/LocalStorageContext";

interface IState {
  user: ILogin;
}
const Login: React.FC = () => {
  const navigate = useNavigate();
  const context = useContext(LocalStorageContext);
  const [state, setState] = useState<IState>({
    user: {
      userName: "",
      password: "",
    },
  });
  const [userErrorMessage, setuserErrorMessage] = React.useState("");
  const [passErrorMessage, setpassErrorMessage] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const ErrorMessageEmptyModel = () => {
    setuserErrorMessage("");
    setpassErrorMessage("");
  };


  useEffect(() => {
    localStorage.clear();
  }, []);


  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null) {
      return true;
    }
    return false;
  };

  const isValidate = (values: any) => {
    let formIsValid = true;
    ErrorMessageEmptyModel();
    if (!validEmail.test(values.userName)) {
      setuserErrorMessage("Please enter valid username");
      formIsValid = false;
    }

    if (CheckNull(values.userName)) {
      setuserErrorMessage("Please enter username");
      formIsValid = false;
    }

    if (CheckNull(values.password)) {
      setpassErrorMessage("Please enter password");
      formIsValid = false;
    }
    if (!CheckNull(values.password)) {
      if (values.password.trim().length === 0) {
        setpassErrorMessage("Please enter password");
        formIsValid = false;
      }
    }
    return formIsValid;
  };
  //   handle change function

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({
      user: {
        ...state.user,
        [event.target.name]: event.target.value,
      },
    });
  };

  // form handling
  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoading(true);
    if (isValidate(state.user)) {
      LoginService.login(state.user)
        .then((response: any) => {

          Logindata(response, navigate);
          context.updateLogoSrc(response.data.logoURL);
          context.updateStatus(response.data.onboardingStatus);
          context.updateStepFlag(response.data.stepFlag);
          context.updateFacilitator(response.data.isfacilitator);
          context.updateRole(response.data.partnerRole);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            setpassErrorMessage("Invalid user or credentials");
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.clear();    localStorage.removeItem("PartnerLogo");    localStorage.removeItem("StepFlag");    localStorage.removeItem("OnboardingStatus");   localStorage.removeItem("PartnerPaymentRole");    localStorage.removeItem("IsFacilitator");
  }, []);


  return (

      <div className="Sigin-page bg-height">
        <div className="Login-form">
          <div className="login-page">
            <img src={IRLOGO} alt="img" className="logo-white" />
          </div>
          <div className="signin-form-display">
            <div className="login-form-display">
              <div className="text-center">
                <h2>Hello Again!</h2>
                <h3>Access the instant payment network</h3>
              </div>
              <div className="username-form">
                <form>
                  <div className="form-group login-form-input ">
                    <p>
                      {" "}
                      <img src={username} alt="img" /> Username
                    </p>
                    <input
                      id="exampleInputEmail1"
                      name="userName"
                      type="email"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter username"
                      onChange={handleChange}
                      value={state.user.userName}
                    />
                    {userErrorMessage !== null &&
                      userErrorMessage.length > 0 ? (
                      <span className="login-error-msg">
                        {userErrorMessage}
                      </span>
                    ) : null}
                  </div>
                  <div className="form-group login-form-input password-input">
                    <p>
                      {" "}
                      <img src={passImg} alt="img" /> Password
                    </p>
                    <div className="password-input-icon">
                      <input
                        type={passwordType}
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Enter password"
                        name="password"
                        value={state.user.password}
                        onChange={handleChange}
                      />

                      <span className="eyeicon">
                        {passwordType === "password" ? <i className="pi pi-eye-slash" onClick={togglePassword}></i> : <i className="pi pi-eye" onClick={togglePassword}></i>}
                      </span>
                    </div>

                    {/* <PasswordStrengthBar password={state.user.password} /> */}
                    {passErrorMessage !== null &&
                      passErrorMessage.length > 0 ? (
                      <span className="login-error-msg">
                        {passErrorMessage}
                      </span>
                    ) : null}
                  </div>
        
                  <Button
                    loading={loading}
                    label="Login"
                    className="btn btn-login"
                    onClick={handleSubmit}
                  />
                </form>
              </div>
              <div className="text-center forgot-password">
                <Link to="/forgot-password">Forgot your password?</Link>
              </div>

              <div className="new-here text-center ">
                <p className="text-white"> New here?</p>
              </div>

              <div className="text-center forgot-password">
                <Link to="/register">
                  Sign up to discover a world of global instant payments!
                </Link>
              </div>

              <div className="educational-message">
<p><i>Public Wi-Fi networks risk your personal information being stolen.</i></p>
<p><i>Before sending a transaction, ensure that your customer has been verified and passed sanctions check.</i></p>
              </div>
            </div>
          </div>
        </div>
      </div>
 
  );
};

export default Login;
