import React, { useState, useEffect } from 'react';
import LocalStorageContext from '../Context/LocalStorageContext';

const LocalStorageProvider: React.FC<any> = ({ children }) => {

  const [logoSrc, setLogoSrc] = useState(() => {

    const item = localStorage.getItem('PartnerLogo');
    return item ? item : null;
    
  });

  const [status, setStatus] = useState(() => {
    const onboardingstatus = localStorage.getItem('OnboardingStatus');
    return onboardingstatus ? onboardingstatus : null;
  });

  const [stepflag, setStepFlag] = useState(() => {
    const step = localStorage.getItem('StepFlag');
    return step ? step : null;
  });

  const [facilitator, setFacilitator] = useState(() => {
    const isfacilitator = localStorage.getItem('IsFacilitator');
    return isfacilitator ? isfacilitator : null;
  });

  const [role, setRole] = useState(() => {
    const partnerrole = localStorage.getItem('PartnerPaymentRole');
    return partnerrole ? partnerrole : null;
  });

  useEffect(() => {
    localStorage.setItem('PartnerLogo', logoSrc);
   
  }, [logoSrc]);

  useEffect(() => {
    localStorage.setItem('OnboardingStatus', status);
  }, [status]);

  useEffect(() => {
    localStorage.setItem('StepFlag', stepflag);
  }, [stepflag]);

  useEffect(() => {
    localStorage.setItem('IsFacilitator', facilitator);
  }, [facilitator]);

  useEffect(() => {
    localStorage.setItem('PartnerPaymentRole', role);
  }, [role]);

  const updateLogoSrc = (newLogoSrc: any) => {
 
    setLogoSrc(newLogoSrc);
   
  };

  const updateStatus = (newStatus: any) => {
    setStatus(newStatus);
  };

  const updateStepFlag = (newStep: any) => {
    setStepFlag(newStep);
  };

  const updateFacilitator = (facilitator: any) => {
    setFacilitator(facilitator);
  };


  const updateRole = (role: any) => {
    setRole(role);
  };



  return (
    <LocalStorageContext.Provider value={{ logoSrc, updateLogoSrc, status, updateStatus, stepflag, updateStepFlag, facilitator, updateFacilitator, role, updateRole }}>
      {children}
    </LocalStorageContext.Provider>
  );
}

export default LocalStorageProvider;
