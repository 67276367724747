import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useNavigate } from "react-router-dom";
import BRLimg from "../../../../assets/images/BRLimg.svg";
import CANADAimg from "../../../../assets/images/canada-flag.svg";
import INDimg from "../../../../assets/images/india.svg";
import USDlogo from "../../../../assets/images/USDImg.svg";
import { CurrenciesService } from "../../../../services/Partner/Currencies/Currencies";
import { PartnerFeesDetailsService } from "../../../../services/Partner/Fees/Fees";
import { PartnershipDetailsService } from "../../../../services/Partner/PartnershipDetails/PartnershipDetailsService";
import { Logout } from "../../../../utils/AccountUtils";
import C2CSendFees from "./C2CSendFee";
import ReceiveFees from "./ReceiveFees";

const SendFees: React.FC<any> = ({
  onSaveAndContinueClick,
  partnerType,
  partnerPaymentRole,
  setActiveIndex,
  receiverBackButton,
  receiverBackButtonValue,
}) => {
  const id = localStorage.getItem("PartnerId");
  const [status, setstatus] = useState(
    localStorage.getItem("OnboardingStatus")
  );

  const [displaypopup, setDisplayPopup] = useState(false);
  const [dirtyfield, setDirtyField] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [showcurrency, setShowCurrency] = useState(null);

  const [readonly, setReadOnly] = useState(false);
  const [sendfixedfeeserrorMessage, setsendfixedfeeserrorMessage] =
    useState("");

  const [sendfirstfixedfeeserrorMessage, setsendfirstfixedfeeserrorMessage] =
    useState("");

  const [sendvariablefeeserrorMessage, setsendvariablefeeserrorMessage] =
    useState("");
  const [partnerid, setpartnerId] = useState(id);
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [backbuttonLoading, setBackButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const [showB2B, setShowB2B] = useState(false);

  const [showC2C, setShowC2C] = useState(false);

  const [feesAmount, setFeesAmount] = useState("");
  const [updated, setUpdated] = useState(feesAmount);

  const [variablefees, setVariblaeFees] = useState("");
  const [updatedVariableFee, setUpdatedVariableFee] = useState(variablefees);

  const [latestsendfeeNewModel, setlatestsendfeeNewModel]: any = useState([]);

  const [defaultsendfeeNewModel, setdefaultsendfeeNewModel]: any = useState([
    {
      id: 0,
      partnerId: id,
      partnerSendFeeType: 1,
      partnerSendFeeTier: null,
      sendFeeCurrency: "INR",
      sendFixedFees: null,
      sendVariableFees: null,
      yescheckedFees: false,
      nocheckedFees: false,
      flag: INDimg,
    },
    {
      id: 0,
      partnerId: id,
      partnerSendFeeType: 1,
      partnerSendFeeTier: null,
      sendFeeCurrency: "USD",
      sendFixedFees: null,
      sendVariableFees: null,
      yescheckedFees: false,
      nocheckedFees: false,
      flag: USDlogo,
    },
    {
      id: 0,
      partnerId: id,
      partnerSendFeeType: 1,
      partnerSendFeeTier: null,
      sendFeeCurrency: "CAD",
      sendFixedFees: null,
      sendVariableFees: null,
      yescheckedFees: false,
      nocheckedFees: false,
      flag: CANADAimg,
    },
    {
      id: 0,
      partnerId: id,
      partnerSendFeeType: 1,
      partnerSendFeeTier: null,
      sendFeeCurrency: "BRL",
      sendFixedFees: null,
      sendVariableFees: null,
      yescheckedFees: false,
      nocheckedFees: false,
      flag: BRLimg,
    },
  ]);

  const [sendfeeNewModel, setsendfeeNewModel]: any = useState([
    {
      id: 0,
      partnerId: id,
      partnerSendFeeType: 1,
      partnerSendFeeTier: null,
      sendFeeCurrency: "INR",
      sendFixedFees: null,
      sendVariableFees: null,
      yescheckedFees: false,
      nocheckedFees: false,
      flag: INDimg,
    },
    {
      id: 0,
      partnerId: id,
      partnerSendFeeType: 1,
      partnerSendFeeTier: null,
      sendFeeCurrency: "USD",
      sendFixedFees: null,
      sendVariableFees: null,
      yescheckedFees: false,
      nocheckedFees: false,
      flag: USDlogo,
    },
    {
      id: 0,
      partnerId: id,
      partnerSendFeeType: 1,
      partnerSendFeeTier: null,
      sendFeeCurrency: "CAD",
      sendFixedFees: null,
      sendVariableFees: null,
      yescheckedFees: false,
      nocheckedFees: false,
      flag: CANADAimg,
    },
    {
      id: 0,
      partnerId: id,
      partnerSendFeeType: 1,
      partnerSendFeeTier: null,
      sendFeeCurrency: "BRL",
      sendFixedFees: null,
      sendVariableFees: null,
      yescheckedFees: false,
      nocheckedFees: false,
      flag: BRLimg,
    },
  ]);

  const [c2cSendfeeNewModel, c2cSetsendfeeNewModel]: any = useState([
    {
      id: 0,
      partnerId: id,
      partnerSendFeeType: 3,
      partnerSendFeeTier: null,
      sendFeeCurrency: "INR",
      sendFixedFees: null,
      sendVariableFees: null,
      yescheckedFees: false,
      nocheckedFees: false,
      flag: INDimg,
    },
    {
      id: 0,
      partnerId: id,
      partnerSendFeeType: 3,
      partnerSendFeeTier: null,
      sendFeeCurrency: "USD",
      sendFixedFees: null,
      sendVariableFees: null,
      yescheckedFees: false,
      nocheckedFees: false,
      flag: USDlogo,
    },
    {
      id: 0,
      partnerId: id,
      partnerSendFeeType: 3,
      partnerSendFeeTier: null,
      sendFeeCurrency: "CAD",
      sendFixedFees: null,
      sendVariableFees: null,
      yescheckedFees: false,
      nocheckedFees: false,
      flag: CANADAimg,
    },
    {
      id: 0,
      partnerId: id,
      partnerSendFeeType: 3,
      partnerSendFeeTier: null,
      sendFeeCurrency: "BRL",
      sendFixedFees: null,
      sendVariableFees: null,
      yescheckedFees: false,
      nocheckedFees: false,
      flag: BRLimg,
    },
  ]);

  const onVariableFeeChange = (e: any, index: number) => {
    let newArr: any = [...latestsendfeeNewModel];
    newArr[index][e.target.name] = e.target.value;
    setlatestsendfeeNewModel(newArr);
    setVariblaeFees(e.target.value);
  };

  const onfixedFeeChange = (e: any, index: number) => {
    setFeesAmount(e.target.value);
    let newArr: any = [...latestsendfeeNewModel];
    newArr[index][e.target.name] = e.target.value;
    setlatestsendfeeNewModel(newArr);
  };

  const SendFeesClick = (event: any, index: any) => {
    let newArr: any = [...latestsendfeeNewModel];

    newArr[index]["sendFixedFees"] = latestsendfeeNewModel[0].sendFixedFees;
    newArr[index]["sendVariableFees"] =
      latestsendfeeNewModel[0].sendVariableFees;

    // newArr[index].nocheckedFees = false;
    // newArr[index].yescheckedFees = true;

    setlatestsendfeeNewModel(newArr);
  };
  const RemoveSendFeesClick = (e: any, index: number) => {
    let newArr: any = [...latestsendfeeNewModel];
    newArr[index]["sendFixedFees"] = "";
    newArr[index]["sendVariableFees"] = "";
    setlatestsendfeeNewModel(newArr);
  };

  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null) {
      return true;
    }
    return false;
  };

  const ErrorMessageEmptyModel = () => {
    setsendfixedfeeserrorMessage("");
    setsendvariablefeeserrorMessage("");
    setsendfirstfixedfeeserrorMessage("");
  };

  const isValidate = (sendfees: any) => {
    let formIsValid = true;

    if (sendfees.length === 0) {
      setsendfixedfeeserrorMessage("Please enter send fees");
      setsendfirstfixedfeeserrorMessage("Please enter send fees");
      formIsValid = false;
    } else {
      sendfees
        .slice()
        .reverse()
        .forEach((sendfee: any) => {
          // if (CheckNull(sendfee.sendFixedFees)) {
          //   setsendfixedfeeserrorMessage(
          //     `Please enter send fixed fees for ${sendfee.sendFeeCurrency}. `
          //   );
          //   formIsValid = false;
          // }
          // if (CheckNull(sendfee.sendVariableFees)) {
          //   setsendvariablefeeserrorMessage(
          //     `Please enter send variable fees for ${sendfee.sendFeeCurrency}. `
          //   );
          //   formIsValid = false;
          // }

          if (
            !(Number(sendfee.sendFixedFees) < 0) &&
            !CheckNull(sendfee.sendFixedFees)
          ) {
            if (
              !sendfee.sendFixedFees.toString().match(/^\d{1,16}(\.\d{0,2})?$/)
            ) {
              setsendfixedfeeserrorMessage(
                `Send fixed fees valid only upto length(16,2) for ${sendfee.sendFeeCurrency}.`
              );
              formIsValid = false;
            }
          }
          if (
            !(Number(sendfee.sendVariableFees) < 0) &&
            !CheckNull(sendfee.sendVariableFees)
          ) {
            if (
              !sendfee.sendVariableFees
                .toString()
                .match(/^\d{1,16}(\.\d{0,2})?$/)
            ) {
              setsendvariablefeeserrorMessage(
                `Send variable fees valid only upto length(16,2) for ${sendfee.sendFeeCurrency}.`
              );
              formIsValid = false;
            }
          }
          if (
            Number(sendfee.sendFixedFees) < 0 ||
            Object.is(Number(sendfee.sendFixedFees), -0)
          ) {
            setsendfixedfeeserrorMessage(
              `Send fixed fees cannot be negative number for ${sendfee.sendFeeCurrency}.`
            );
            formIsValid = false;
          }
          if (
            Number(sendfee.sendVariableFees) < 0 ||
            Object.is(Number(sendfee.sendVariableFees), -0)
          ) {
            setsendvariablefeeserrorMessage(
              `Send variable fees cannot be negative number for ${sendfee.sendFeeCurrency}.`
            );
            formIsValid = false;
          }
        });
    }

    return formIsValid;
  };

  const getPartnerSendFees = () => {
    setLoading(true);
    PartnerFeesDetailsService.getPartnerSendFeesByPartnerId(
      Number(partnerid),
      "B2B"
    )
      .then((data) => {
        const feesData = data.data;

        if (feesData.length === 0) {
          setsendfeeNewModel(sendfeeNewModel);
        } else {
          const sendFeedata = [...defaultsendfeeNewModel];

          feesData.forEach((feeRecord: any) => {
            if (feeRecord.sendFeeCurrency == "INR") {
              sendFeedata[0] = feeRecord;
              sendFeedata[0].flag = INDimg;
            }
            if (feeRecord.sendFeeCurrency == "USD") {
              sendFeedata[1] = feeRecord;
              sendFeedata[1].flag = USDlogo;
            }
            if (feeRecord.sendFeeCurrency == "CAD") {
              sendFeedata[2] = feeRecord;
              sendFeedata[2].flag = CANADAimg;
            }
            if (feeRecord.sendFeeCurrency == "BRL") {
              sendFeedata[3] = feeRecord;
              sendFeedata[3].flag = BRLimg;
            }
          });

          const sendFeesData = sendFeedata.filter(
            (send: any) =>
              send.sendFixedFees !== null && send.sendFixedFees !== null
          );

          setsendfeeNewModel(sendFeesData);
          setlatestsendfeeNewModel(sendFeesData);
        }

        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting partner send fees details.",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };

  const getCurrenciesByPartnerId = (partnerid: any) => {
    setLoading(true);
    CurrenciesService.getCurrenciesByPartnerId(partnerid)

      .then((data: any) => {
        const responsedata = data.data;

        const currencies = responsedata.filter(
          (currency: any) =>
            currency.partnerPaymentRole === 1 ||
            currency.partnerPaymentRole === 3
        );

        var feesCurrencyModel: any = [];
        sendfeeNewModel.map((feeModel: any, index: any) => {
          responsedata.map((currency: any, index: any) => {
            if (
              feeModel.sendFeeCurrency == currency.currency &&
              (currency.partnerPaymentRole === 1 ||
                currency.partnerPaymentRole === 3)
            ) {
              feesCurrencyModel.push(feeModel);
            }
          });
        });

        setlatestsendfeeNewModel(feesCurrencyModel);

        setCurrency(currencies);
        setLoading(false);
        setCurrency(currencies);

        getPartnerSendFees();
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting partnership details.",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };

  const onNextClick = () => {
    if (partnerPaymentRole === 1 || partnerPaymentRole === 2) {
      if (partnerType?.includes("C2C")) {
        setShowB2B(false);
        setShowC2C(true);
      } else {
        onSaveAndContinueClick("N");
      }
    } else if (partnerPaymentRole === 3) {
      if (partnerType?.includes("C2C")) {
        setShowB2B(false);
        setShowC2C(true);
      } else {
        setActiveIndex(1);
      }
    }
  };

  const onBackClick = () => {
    setBackButtonLoading(true);
    setTimeout(() => {
      onSaveAndContinueClick("B");
    }, 1000);
  };

  const onAddClick = () => {
    const data = sendfeeNewModel.filter((sendFee: any) =>
      latestsendfeeNewModel.some(
        (send: any) => send.sendFeeCurrency === sendFee.sendFeeCurrency
      )
    );

    latestsendfeeNewModel.forEach((element: any) => {
      if (element.sendFixedFees === null || element.sendFixedFees == "") {
        element.sendFixedFees = 0;
      }
      if (element.sendVariableFees === null || element.sendVariableFees == "") {
        element.sendVariableFees = 0;
      }
    });
    ErrorMessageEmptyModel();
    if (isValidate(data)) {
      setButtonLoading(true);
      PartnerFeesDetailsService.addPartnerSendFees(data)
        .then((data) => {
          toast.current?.show({
            severity: "success",
            summary: "Send fees added successfully",
            life: 3000,
          });

          getPartnerSendFees();
          setButtonLoading(false);
          if (partnerPaymentRole === 1 || partnerPaymentRole === 2) {
            if (partnerType?.includes("C2C")) {
              onNextClick();
            } else {
              onSaveAndContinueClick("N");
            }
          } else if (partnerPaymentRole === 3) {
            if (partnerType?.includes("C2C")) {
              onNextClick();
            } else {
              setActiveIndex(1);
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            toast.current?.show({
              severity: "error",
              summary: "Something went wrong",
              life: 3000,
            });
          } else if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 404) {
            setsendfixedfeeserrorMessage("Please select send fees");
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Error while adding partner send fees.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };

  const handleClose = () => {
    if (dirtyfield === true) {
      setDisplayPopup(true);
    }
  };

  const OnPopupClose = () => {
    setDisplayPopup(false);
  };
  const OnPopupOk = () => {
    latestsendfeeNewModel.map((model: any, index: any) => {
      model.sendFixedFees = null;
      model.sendVariableFees = null;
    });
    getCurrenciesByPartnerId(Number(partnerid));
    setDisplayPopup(false);
    setDirtyField(false);
    ErrorMessageEmptyModel();
  };

  const onPartnerDeliveryChange = () => {
    if (partnerType?.includes("C2C")) {
      setShowC2C(true);
      setShowB2B(false);
    }
    if (partnerType?.includes("B2B")) {
      setShowB2B(true);
      setShowC2C(false);
    }
    if (partnerType?.includes("B2C")) {
      setShowB2B(true);
      setShowC2C(false);
    }
    if (partnerType?.includes("B2B") && partnerType?.includes("C2C")) {
      setShowB2B(true);
      setShowC2C(false);
    }
    if (partnerType?.includes("B2B") && partnerType?.includes("B2B")) {
      setShowB2B(true);
      setShowC2C(false);
    }
    if (partnerType?.includes("B2C") && partnerType?.includes("C2C")) {
      setShowB2B(true);
      setShowC2C(false);
    }
  };

  useEffect(() => {
    if (receiverBackButtonValue) {
      setShowB2B(false);
      setShowC2C(true);
      Number(status) === 8 ? setReadOnly(true) : setReadOnly(false);
      getCurrenciesByPartnerId(Number(partnerid));
    } else {
      Number(status) === 8 ? setReadOnly(true) : setReadOnly(false);
      onPartnerDeliveryChange();
      getCurrenciesByPartnerId(Number(partnerid));
    }
  }, []);

  // useEffect(() => {
  // if(receiverBackButtonValue){

  // }
  // // if(partnerType?.includes("C2C") ){
  // //   setShowB2B(false);
  // //   setShowC2C(true);
  // // }
  // }, [partnerType]);

  return (
    <>
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <Scrollbars
          className="contain-scroll"
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={500}
          autoHeightMax={1000}
          thumbMinSize={30}
          universal={true}
        >
          <>
            {showB2B ? (
              <div className="Fees-container">
                <p className="fees-discription">
                  First lets configure any fees that you want to charge businesses for the send transactions
                </p>

                <div className="Fees-heading">
                  <h2>Business Send Fees </h2>
                </div>

                <div className="Fees-sending-payment">
                  <h4>
                    What do you want to charge your business customers for sending
                    payments?
                  </h4>
                  <div className="Fees-details-card  Fees-details-card-1 ">
                    {latestsendfeeNewModel.map((model: any, index: any) => {
                      if (index == 0) {
                        return (
                          <div className="inner-card-wrapper" key={index}>
                            <div className="fees-card country">
                              <p>Country</p>
                              <img
                                src={model.flag}
                                alt="img"
                                className="currency-img"
                              />
                            </div>
                            <div className="fees-card currency">
                              <p>Currency</p>
                              <h3>{model.sendFeeCurrency}</h3>
                            </div>

                            <div className=" fees-card  fixed-fee">
                              <p>Fixed Fees</p>
                              <div className="fees-card-inupt">
                                <input
                                  readOnly={readonly}
                                  type="number"
                                  onKeyDown={(evt) =>
                                    evt.key === "e" && evt.preventDefault()
                                  }
                                  name="sendFixedFees"
                                  id="feesAmount"
                                  className="form-control"
                                  aria-describedby="text"
                                  value={model.sendFixedFees}
                                  onChange={(e: any) => {
                                    onfixedFeeChange(e, index);
                                    setDirtyField(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className=" fees-card  fixed-fee">
                              <p>Variable Fees</p>
                              <div className="percent-wrapper">
                                <input
                                  readOnly={readonly}
                                  type="number"
                                  id="variablefees"
                                  onKeyDown={(evt) =>
                                    evt.key === "e" && evt.preventDefault()
                                  }
                                  className="form-control"
                                  name="sendVariableFees"
                                  aria-describedby="text"
                                  value={model.sendVariableFees}
                                  onChange={(e: any) => {
                                    onVariableFeeChange(e, index);
                                    setDirtyField(true);
                                  }}
                                />

                                <span className="feespercent">%</span>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                    {latestsendfeeNewModel.length >= 2 ? null : (
                      <div className="error-card-3 ">
                        <div className="error-message-col">
                          {sendfixedfeeserrorMessage !== null &&
                            sendfixedfeeserrorMessage.length > 0 && (
                              <span className="error-msg">
                                {sendfixedfeeserrorMessage}
                              </span>
                            )}
                        </div>

                        <div className="error-message-col">
                          {sendvariablefeeserrorMessage !== null &&
                            sendvariablefeeserrorMessage.length > 0 && (
                              <span className="error-msg">
                                {sendvariablefeeserrorMessage}
                              </span>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <hr />

                <>
                  {latestsendfeeNewModel.length >= 2 && (
                    <div className="Fees-sending-payment Country-select">
                      <h4>
                      Will the send fees for businesses be the same in the following countries?
                      </h4>
                      <div className="Fees-details-card  Fees-details-card-2">
                        {latestsendfeeNewModel.map((model: any, index: any) => {
                          if (index != 0) {
                            return (
                              <div className="inner-card-wrapper" key={index}>
                                <div className="fees-card country">
                                  <p>Country</p>
                                  <img
                                    src={model.flag}
                                    alt="img"
                                    className="currency-img"
                                  />
                                </div>
                                <div className="fees-card currency">
                                  <p>Currency</p>
                                  <h3>{model.sendFeeCurrency}</h3>
                                </div>
                                <div className="toggle-country ">
                                  <div className="Toggle-btn">
                                    <div className="switch-field">
                                      <input
                                        readOnly={readonly}
                                        disabled={readonly}
                                        type="radio"
                                        id={`radio-one-${index}`}
                                        name={`switch-one${index}`}
                                        onChange={
                                          latestsendfeeNewModel.yescheckedFees
                                        }
                                        onClick={(e: any) =>
                                          SendFeesClick(e, index)
                                        }
                                      />
                                      <label htmlFor={`radio-one-${index}`}>
                                        Yes
                                      </label>
                                      <input
                                        readOnly={readonly}
                                        disabled={readonly}
                                        type="radio"
                                        id={`radio-two-${index}`}
                                        name={`switch-one${index}`}
                                        defaultChecked={true}
                                        onChange={
                                          latestsendfeeNewModel.nocheckedFees
                                        }
                                        onClick={(e: any) =>
                                          RemoveSendFeesClick(e, index)
                                        }
                                      />
                                      <label htmlFor={`radio-two-${index}`}>
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div className="fees-card fixed-fee">
                                  <p>Fixed Fees</p>
                                  <div className="fees-card-inupt">
                                    <input
                                      readOnly={readonly}
                                      type="number"
                                      onKeyDown={(evt) =>
                                        evt.key === "e" && evt.preventDefault()
                                      }
                                      name="sendFixedFees"
                                      id="feesAmount"
                                      className="form-control"
                                      aria-describedby="text"
                                      value={model.sendFixedFees}
                                      onChange={(e: any) => {
                                        onfixedFeeChange(e, index);
                                        setDirtyField(true);
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="fees-card fixed-fee">
                                  <p>Variable Fees</p>
                                  <div className="percent-wrapper">
                                    <input
                                      readOnly={readonly}
                                      type="number"
                                      id="variablefees"
                                      onKeyDown={(evt) =>
                                        evt.key === "e" && evt.preventDefault()
                                      }
                                      className="form-control"
                                      name="sendVariableFees"
                                      aria-describedby="text"
                                      value={model.sendVariableFees}
                                      onChange={(e: any) => {
                                        onVariableFeeChange(e, index);
                                        setDirtyField(true);
                                      }}
                                    />

                                    <span className="feespercent">%</span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}

                        <div className="error-card-3 ">
                          <div className="error-message-col">
                            {sendfixedfeeserrorMessage !== null &&
                              sendfixedfeeserrorMessage.length > 0 && (
                                <span className="error-msg ">
                                  {sendfixedfeeserrorMessage}
                                </span>
                              )}
                          </div>

                          <div className="error-message-col">
                            {sendvariablefeeserrorMessage !== null &&
                              sendvariablefeeserrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {sendvariablefeeserrorMessage}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>

                <div className="button-section">
                  <div className="bottom-btns">
                    <Button
                      label="Back"
                      loading={backbuttonLoading}
                      className="btn btn-back second-btn"
                      onClick={onBackClick}
                    />

                    {Number(status) === 8 ? (
                      <>
                        <button
                          type="button"
                          onClick={onNextClick}
                          className="btn btn-next second-btn "
                        >
                          Next
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-back second-btn"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>

                        <Button
                          iconPos="left"
                          label=" Save and Continue"
                          loading={buttonLoading}
                          className="btn btn-continue second-btn"
                          onClick={() => onAddClick()}
                        />
                        {/* <button
                          disabled={partnerType?.includes("C2C") ? false : true}
                          type="button"
                          onClick={() => onNextClick()}
                          className="btn btn-next second-btn "
                        >
                          Next
                        </button> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {showC2C && (
              <C2CSendFees
                partnerPaymentRole={partnerPaymentRole}
                partnerType={partnerType}
                onBackClick={onBackClick}
                onSaveAndContinueClick={onSaveAndContinueClick}
                setActiveIndex={setActiveIndex}
                setShowC2C={setShowC2C}
                setShowB2B={setShowB2B}
              />
            )}
          </>
        </Scrollbars>
      )}
      {/* Popup of cancel button */}
      {displaypopup ? (
        <>
          <div className="popup-body">
            <div className="Cancel-popup">
              <div className="text-center ">
                <div className="awesome-text">
                  <h4>Are you sure you want to cancel?</h4>
                  <p> All unsaved changes will be lost.</p>
                </div>
              </div>
              <div className="payment-screen-btn">
                <button
                  className="btn btn-cancel second-btn "
                  onClick={OnPopupClose}
                >
                  {" "}
                  No
                </button>
                <button
                  className="btn btn-continue second-btn yes-btn-popup"
                  onClick={OnPopupOk}
                >
                  {" "}
                  Yes
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SendFees;
