const PersonalDetailToolTip= [
    {
        "id":0,
         "Info":`<h4>First name exactly as it appears on your government issued national ID</h4>`
    },
    {
        "id":1,
         "Info":`<h4>Middle name exactly as it appears on your government issued national ID</h4>`
    },
    {
        "id":2,
         "Info":`<h4>Last name exactly as it appears on your government issued national ID</h4>`
    }, 
    {
        "id":3,
         "Info":`<h4>Mother’s surname exactly as it appears on your government issued national ID</h4>`
    },
    {
        "id":4,
         "Info":`<h4>Father’s surname exactly as it appears on your government issued national ID</h4>`
    },
    {
        "id":5,
         "Info":`<h4>Phone number</h4>`
    },
    {
        "id":6,
         "Info":`<h4>Official email address associated with the organization. We do not accept personal emails</h4>`
    },
    {
        "id":7,
         "Info":`<h4>Date of birth exactly as it appears on your government issued national ID</h4>`
    },
    {
        "id":8,
         "Info":`<h4>Your street address and unit number</h4>`
    },
    {
        "id":9,
         "Info":`<h4>Optional building number for address</h4>`
    },
    {
        "id":10,
         "Info":`<h4>Optional address line</h4>`
    },
    {
        "id":11,
         "Info":`<h4>Town or city of address</h4>`
    },
    {
        "id":12,
         "Info":`<h4>Province or state of address</h4>`
    },
    {
        "id":13,
         "Info":`<h4>Country</h4>`
    },
    {
        "id":14,
         "Info":`<h4>Postal Code or zip code</h4>`
    },
]


export default PersonalDetailToolTip;