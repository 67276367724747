import axios from "axios";
import { environment } from "../../environments/environment";

export class PaymentService {
  static getDetailPaymentId(paymentId: any) {
    throw new Error("Method not implemented.");
  }
    
    private static PartnerURL: any = environment.partnerApiUrl;
    
  public static getPayment = (partnerid: any, paymentModel: any) => {
    let UserURL: string = `${this.PartnerURL}/partnersecurity/paymentapi?partnerId=${partnerid}`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    };
    return axios.post<any>(UserURL, paymentModel, config);
  };

  public static getPaymentLogs = (paymentid: number) => {
    let UserURL: string = `${this.PartnerURL}/partnersecurity/paymentlogs?paymentId=${paymentid}`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    };
    return axios.get<any>(UserURL, config);
  };

  public static validatePayment = (paymentModel: any,partnerid: any) => {
    let UserURL: string = `${this.PartnerURL}/payment/send?partnerId=${partnerid}`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    };
    return axios.post<any>(UserURL, paymentModel, config);
  };

  public static getPaymentStatus = (paymentid: number) => {
    let UserURL: string = `${this.PartnerURL}/partnersecurity/paymentstatus?paymentId=${paymentid}`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    };
    return axios.get<any>(UserURL, config);
  };

}


