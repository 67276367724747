import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IAddress } from "../../../models/IAddress";
import { AddressService } from "../../../services/Partner/Address/AddressService";
import { countryList, setHeaderImage, validpostalcode } from "../../../utils/utils";
import ToolTip from "../ToolTipsData/ToolTip";
import { Scrollbars } from "react-custom-scrollbars-2";
import AddressoolTipData from "../../../services/Partner/Address/AddressToolTips";
import { Logout } from "../../../utils/AccountUtils";

interface IState {
  address: IAddress;
}

const Address: React.FC<any> = ({ onSaveAndContinueClick, onNextClick, buttonLoadingSkip,
  buttonLoadingBack,
  setButtonLoadingBack,
  setButtonLoadingSkip }) => {
  const id = localStorage.getItem("PartnerId");

  const [partnerid] = useState(id);

  const [displaypopup, setDisplayPopup] = useState(false);

  const [dirtyfield, setDirtyField] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [postaladdresserrorMessage, setpostaladdressErrorMessage] = useState("");

  const [postaladdress2errorMessage, setpostaladdress2ErrorMessage] = useState("");

  const [
    physicalpostaladdresserrorMessage,
    setphysicalpostaladdressErrorMessage,
  ] = useState("");

  const [
    physicalpostaladdress2errorMessage,
    setphysicalpostaladdress2ErrorMessage,
  ] = useState("");

  const [postalcodeerrorMessage, setpostalcodeErrorMessage] = useState("");

  const [postalcode2errorMessage, setpostalcode2ErrorMessage] = useState("");

  const [provinceerrorMessage, setprovinceErrorMessage] = useState("");

  const [townnameerrorMessage, settownnameErrorMessage] = useState("");

  const [countryerrorMessage, setcountryErrorMessage] = useState("");

  const [physicalprovinceerrorMessage, setphysicalprovinceErrorMessage] = useState("");

  const [physicaltownnameerrorMessage, setphysicaltownnameErrorMessage] = useState("");

  const [physicalcountryerrorMessage, setphysicalcountryErrorMessage] = useState("");

  const [buildingerrorMessage, setbuildingErrorMessage] = useState("");

  const [physicalbuildingerrorMessage, setphysicalbuildingErrorMessage] = useState("");

  const [readonly, setReadOnly] = useState(false);
  const [checkreadonly, setCheckReadOnly] = useState(false);

  const [status] = useState(localStorage.getItem("OnboardingStatus"));

  const navigate = useNavigate();

  const toast = useRef<Toast>(null);

  const [checked, setChecked] = useState(false);

  const [filteredcountrieslist, setFilteredCountryList] = useState<any[]>([]);

  const [countryAutoComplete, setCountryAutoComplete] = useState("");

  const [physicalcountryAutoComplete, setPhysicalCountryAutoComplete] = useState("");

  const [addressToolTipdata] = useState<any>(AddressoolTipData);

  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null) {
      return true;
    }
    return false;
  };

  const [, setBeforeChangeRegisteredaddressModel] = useState({
    id: 0,
    partnerId: Number(partnerid),
    postalAddressLine1: "",
    postalAddressLine2: "",
    buildingNumber: "",
    postalCode: "",
    province: "",
    townName: "",
    country: "",
    addressType: false,
    createdBy: 0,
    updatedBy: 0,
    deletedBy: 0,
  });

  const [
    beforeChangePhysicaladdressModel,
    setBeforeChangePhysicaladdressModel,
  ] = useState({
    id: 0,
    partnerId: Number(partnerid),
    postalAddressLine1: "",
    postalAddressLine2: "",
    buildingNumber: "",
    postalCode: "",
    province: "",
    townName: "",
    country: "",
    addressType: true,
    createdBy: 0,
    updatedBy: 0,
    deletedBy: 0,
  });

  // register address model
  const [registeredaddressModel, setRegisteredAddressModel] = useState({
    id: 0,
    partnerId: Number(partnerid),
    postalAddressLine1: "",
    postalAddressLine2: "",
    buildingNumber: "",
    postalCode: "",
    province: "",
    townName: "",
    country: "",
    addressType: false,
    createdBy: 0,
    updatedBy: 0,
    deletedBy: 0,
  });

  //physical address model
  const [physicaladdressModel, setPhysicalAddressModel] = useState({
    id: 0,
    partnerId: Number(partnerid),
    postalAddressLine1: "",
    postalAddressLine2: "",
    buildingNumber: "",
    postalCode: "",
    province: "",
    townName: "",
    country: "",
    addressType: true,
    createdBy: 0,
    updatedBy: 0,
    deletedBy: 0,
  });

  const [, setAddressesModel] = useState([
    registeredaddressModel,
    physicaladdressModel,
  ]);

  const setModelEmpty = () => {
    setRegisteredAddressModel({
      id: 0,
      partnerId: Number(partnerid),
      postalAddressLine1: "",
      postalAddressLine2: "",
      buildingNumber: "",
      postalCode: "",
      province: "",
      townName: "",
      country: "",
      addressType: false,
      createdBy: 0,
      updatedBy: 0,
      deletedBy: 0,
    });
    setPhysicalAddressModel({
      id: 0,
      partnerId: Number(partnerid),
      postalAddressLine1: "",
      postalAddressLine2: "",
      buildingNumber: "",
      postalCode: "",
      province: "",
      townName: "",
      country: "",
      addressType: true,
      createdBy: 0,
      updatedBy: 0,
      deletedBy: 0,
    });
    setCountryAutoComplete("");
    setPhysicalCountryAutoComplete("");
  };

  const [loading, setLoading] = useState(true);

  const [, setError] = useState(false);

  // error message empty model handle
  const ErrorMessageEmptyModel = () => {
    setpostaladdressErrorMessage("");
    setpostaladdress2ErrorMessage("");
    setpostalcodeErrorMessage("");
    setprovinceErrorMessage("");
    settownnameErrorMessage("");
    setcountryErrorMessage("");
    setbuildingErrorMessage("");
    setpostalcode2ErrorMessage("");
    setphysicalprovinceErrorMessage("");
    setphysicalpostaladdressErrorMessage("");
    setphysicalpostaladdress2ErrorMessage("");
    setphysicaltownnameErrorMessage("");
    setphysicalcountryErrorMessage("");
    setphysicalbuildingErrorMessage("");
  };

  // validation handle
  const isValidate = (values: any, values1: any) => {
    ErrorMessageEmptyModel();
    let formIsValid = true;
    if (CheckNull(values.postalAddressLine1)) {
      setpostaladdressErrorMessage("Please enter address line 1.");
      formIsValid = false;
    }
    if (!CheckNull(values.postalAddressLine1)) {
      if (values.postalAddressLine1.trim().length === 0) {
        setpostaladdressErrorMessage("Please enter address line 1.");
        formIsValid = false;
      }
      if (values.postalAddressLine1.length > 70) {
        setpostaladdressErrorMessage(
          "Please enter valid address line 1."
        );
        formIsValid = false;
      }
    }
    if (!CheckNull(values.postalAddressLine2)) {
      if (values.postalAddressLine2.length > 70) {
        setpostaladdress2ErrorMessage(
          "Please enter valid address line 2."
        );
        formIsValid = false;
      }
    }
    if (!CheckNull(values.buildingNumber)) {
      if (values.buildingNumber.length > 16) {
        setbuildingErrorMessage(
          "Please enter valid building number."
        );
        formIsValid = false;
      }
    }
    if (CheckNull(values.postalCode)) {
      setpostalcodeErrorMessage("Please enter postal code.");
      formIsValid = false;
    }
    if (!CheckNull(values.postalCode)) {
      if (values.postalCode.trim().length === 0) {
        setpostalcodeErrorMessage("Please enter postal code.");
        formIsValid = false;
      }
      if (values.postalCode.length > 16) {
        setpostalcodeErrorMessage(
          "Please enter valid postal code."
        );
        formIsValid = false;
      }
      if (!validpostalcode.test(values.postalCode)) {
        setpostalcodeErrorMessage("Please enter correct postal code.");
        formIsValid = false;
      }
    }

    if (CheckNull(values.province)) {
      setprovinceErrorMessage("Please enter province.");
      formIsValid = false;
    }
    if (!CheckNull(values.province)) {
      if (values.province.trim().length === 0) {
        setprovinceErrorMessage("Please enter province.");
        formIsValid = false;
      }

      if (values.province.length > 50) {
        setprovinceErrorMessage(
          "Please enter valid province."
        );
        formIsValid = false;
      }
    }

    if (CheckNull(values.townName)) {
      settownnameErrorMessage("Please enter town.");
      formIsValid = false;
    }

    if (!CheckNull(values.townName)) {
      if (values.townName.trim().length === 0) {
        settownnameErrorMessage("Please enter town.");
        formIsValid = false;
      }
   
      if (values.townName.length > 50) {
        settownnameErrorMessage(
          "Please enter valid town."
        );
        formIsValid = false;
      }
    }
    if (CheckNull(values.country)) {
      setcountryErrorMessage("Please select country.");
      formIsValid = false;
    }
    if (CheckNull(values1.postalAddressLine1)) {
      setphysicalpostaladdressErrorMessage("Please enter address line 1.");
      formIsValid = false;
    }
    if (!CheckNull(values1.postalAddressLine1)) {
      if (values1.postalAddressLine1.trim().length === 0) {
        setphysicalpostaladdressErrorMessage("Please enter address line 1.");
        formIsValid = false;
      }
      if (values1.postalAddressLine1.length > 70) {
        setphysicalpostaladdressErrorMessage(
          "Please enter valid address line 1."
        );
        formIsValid = false;
      }
    }
    if (!CheckNull(values1.postalAddressLine2)) {
      if (values1.postalAddressLine2.length > 70) {
        setphysicalpostaladdress2ErrorMessage(
          "Please enter address line 2."
        );
        formIsValid = false;
      }
    }

    if (!CheckNull(values1.buildingNumber)) {
      if (values1.buildingNumber.length > 16) {
        setphysicalbuildingErrorMessage(
          "Please enter valid building number."
        );
        formIsValid = false;
      }
    }
    if (CheckNull(values1.postalCode)) {
      setpostalcode2ErrorMessage("Please enter postal code.");
      formIsValid = false;
    }

    if (!CheckNull(values1.postalCode)) {
      if (values.postalCode.trim().length === 0) {
        setpostalcode2ErrorMessage("Please enter postal code.");
        formIsValid = false;
      }
      if (values1.postalCode.length > 16) {
        setpostalcode2ErrorMessage(
          "Please enter valid postal code."
        );
        formIsValid = false;
      }
      if (!validpostalcode.test(values1.postalCode)) {
        setpostalcode2ErrorMessage("Please enter correct postal code.");
        formIsValid = false;
      }
    }
    if (CheckNull(values1.province)) {
      setphysicalprovinceErrorMessage("Please enter province.");
      formIsValid = false;
    }

    if (!CheckNull(values1.province)) {
      if (values.province.trim().length === 0) {
        setphysicalprovinceErrorMessage("Please enter province.");
        formIsValid = false;
      }
      if (values1.province.length > 50) {
        setphysicalprovinceErrorMessage(
          "Please enter valid province."
        );
        formIsValid = false;
      }
   
    }
    if (CheckNull(values1.townName)) {
      setphysicaltownnameErrorMessage("Please enter town.");
      formIsValid = false;
    }

    if (!CheckNull(values1.townName)) {
      if (values.townName.trim().length === 0) {
        setphysicaltownnameErrorMessage("Please enter town.");
        formIsValid = false;
      }
      if (values1.townName.length > 50) {
        setphysicaltownnameErrorMessage(
          "Please enter valid town."
        );
        formIsValid = false;
      }
   
    }
    if (CheckNull(values1.country)) {
      setphysicalcountryErrorMessage("Please select country.");
      formIsValid = false;
    }
    return formIsValid;
  };

  // get address by partner id
  const getAddressByPartnerId = (id: number) => {
    setLoading(true);
    AddressService.getAddressByPartnerId(id)
      .then((response: any) => {
        const data = response.data;

        setRegisteredAddressModel(
          data.filter((x: any) => x.addressType === false)[0]
        );
        if (
          countryList.find(
            (country) =>
              country.code ===
              data.filter((x: any) => x.addressType === false)[0].country
          ) !== undefined
        ) {
          setCountryAutoComplete(
            countryList.find(
              (country) =>
                country.code ===
                data.filter((x: any) => x.addressType === false)[0].country
            ).name
          );
        }
        setPhysicalAddressModel(
          data.filter((x: any) => x.addressType === true)[0]
        );
        if (
          countryList.find(
            (country) =>
              country.code ===
              data.filter((x: any) => x.addressType === true)[0].country
          ) !== undefined
        ) {
          setPhysicalCountryAutoComplete(
            countryList.find(
              (country) =>
                country.code ===
                data.filter((x: any) => x.addressType === true)[0].country
            ).name
          );
        }

        setBeforeChangeRegisteredaddressModel(
          data.filter((x: any) => x.addressType === false)[0]
        );
        setBeforeChangePhysicaladdressModel(
          data.filter((x: any) => x.addressType === true)[0]
        );
        setLoading(false);
        // setTimeout(() => { }, 100);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting the address details",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };

  // handle close 
  const handleClose = () => {
    if (dirtyfield === true) {
      setDisplayPopup(true);
    } else {
      ErrorMessageEmptyModel();
    }

  };

  // popup close
  const OnPopupClose = () => {
    setDisplayPopup(false);
  };

  //on popup ok
  const OnPopupOk = () => {
    physicaladdressModel.id === 0
      ? setModelEmpty()
      : getAddressByPartnerId(Number(partnerid));
    setDisplayPopup(false);
    setDirtyField(false);
    ErrorMessageEmptyModel();
  };

  // on back
  const onBackClick = () => {
    setButtonLoadingBack(true);
    setTimeout(() => {
      onSaveAndContinueClick("B");
    }, 1000);

  };

  // on save click
  const onAddClick = () => {
    ErrorMessageEmptyModel();
    setPhysicalAddressModel({ ...physicaladdressModel, addressType: true });

    setRegisteredAddressModel({
      ...registeredaddressModel,
      addressType: false,
    });

    setAddressesModel([registeredaddressModel, physicaladdressModel]);

    if (registeredaddressModel.id === 0 && physicaladdressModel.id === 0) {
      if (isValidate(registeredaddressModel, physicaladdressModel)) {
        setButtonLoading(true);

        AddressService.addAddress([
          registeredaddressModel,
          physicaladdressModel,
        ])
          .then((data: any) => {
            ErrorMessageEmptyModel();
            setButtonLoading(false);

            toast.current?.show({
              severity: "success",
              summary: "Address details saved successfully.",
              life: 3000,
            });
            localStorage.setItem("StepFlag", "2");
            onSaveAndContinueClick("N");
          })
          .catch((error: any) => {
            if (error.response.status === 500) {
              toast.current?.show({
                severity: "error",
                summary: "Something went wrong",
                life: 3000,
              });
            } else if (error.response.status === 401) {
              toast.current?.show({
                severity: "error",
                summary: "Unauthorized",
                life: 3000,
              });
              Logout(navigate);
            } else if (error.response.status === 400) {
              toast.current?.show({
                severity: "error",
                summary: error.response.data[0].errorMessage,
                life: 3000,
              });
            } else {
              toast.current?.show({
                severity: "error",
                summary: "Error while saving address details.",
                life: 3000,
              });
            }
            ErrorMessageEmptyModel();
            setButtonLoading(false);
            setError(false);
          });
      } else {
        setButtonLoading(false);
      }
    } else if (registeredaddressModel.id > 0 || physicaladdressModel.id > 0) {
      if (isValidate(registeredaddressModel, physicaladdressModel)) {
        setButtonLoading(true);
        AddressService.updateAddress([
          registeredaddressModel,
          physicaladdressModel,
        ])
          .then((data) => {
            toast.current?.show({
              severity: "success",
              summary: "Address details updated successfully!",
              life: 3000,
            });
            ErrorMessageEmptyModel();
            setButtonLoading(false);
            onSaveAndContinueClick("N");
          })
          .catch((error) => {
            if (error.response.status === 500) {
              toast.current?.show({
                severity: "error",
                summary: "Something went wrong",
                life: 3000,
              });
            } else if (error.response.status === 400) {
              toast.current?.show({
                severity: "error",
                summary: error.response.data[0].errorMessage,
                life: 3000,
              });
            } else if (error.response.status === 401) {
              toast.current?.show({
                severity: "error",
                summary: "Unauthorized",
                life: 3000,
              });
              Logout(navigate);
            } else {
              toast.current?.show({
                severity: "error",
                summary: "Error while updating address details.",
                life: 3000,
              });

              ErrorMessageEmptyModel();
            }
            setButtonLoading(false);
            setError(false);
          });
      } else {
        setButtonLoading(false);
      }
    }
  };

  // on check for input fiedls
  const onCheckClick = (e: any) => {
    setTimeout(() => {
      setChecked(!e.target.checked);
    }, 100);

    if (e.target.checked) {
      setPhysicalAddressModel({
        ...registeredaddressModel,
        id: physicaladdressModel.id,
        addressType: true,
      });
      setDirtyField(true);
      setCheckReadOnly(true);
      setPhysicalCountryAutoComplete(countryAutoComplete);
    } else {
      setCheckReadOnly(false);
      // setPhysicalAddressModel({
      //   ...beforeChangePhysicaladdressModel,
      //   id: physicaladdressModel.id,
      //   addressType: true,
      // });
      setDirtyField(true);
      // setPhysicalCountryAutoComplete(countryAutoComplete);
    }
  };

  // search country
  const searchCountry = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < countryList.length; i++) {
      let item = countryList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredCountryList(_filteredItems);
  };

  // on country change
  const onCountryChange = (e: any) => {
    if (e.value !== null) {
      setCountryAutoComplete(e.value);
      setRegisteredAddressModel({
        ...registeredaddressModel,
        country: e.value.code,
      });
      setDirtyField(true);


      if (checked) {
        setPhysicalCountryAutoComplete(e.value);
        setPhysicalAddressModel({
          ...physicaladdressModel,
          country: e.value.code,
          addressType: true,
        });
      }
    }
  };

  // on physical country change
  const onPhysicalCountryChange = (e: any) => {
    if (e.value !== null) {
      setPhysicalCountryAutoComplete(e.value);
      setPhysicalAddressModel({
        ...physicaladdressModel,
        country: e.value.code,
      });
      setDirtyField(true);
    }
  };


  useEffect(() => {
    setButtonLoadingSkip(false);
    setButtonLoadingBack(false)
    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    {
      Number(status) === 8 ? setReadOnly(true) : setReadOnly(false);
    }

    if (id !== "0") {
      getAddressByPartnerId(Number(partnerid));
    } else {
      setRegisteredAddressModel({
        id: 0,
        partnerId: Number(partnerid),
        postalAddressLine1: "",
        postalAddressLine2: "",
        buildingNumber: "",
        postalCode: "",
        province: "",
        townName: "",
        country: "",
        addressType: false,
        createdBy: 0,
        updatedBy: 0,
        deletedBy: 0,
      });
      setPhysicalAddressModel({
        id: 0,
        partnerId: Number(partnerid),
        postalAddressLine1: "",
        postalAddressLine2: "",
        buildingNumber: "",
        postalCode: "",
        province: "",
        townName: "",
        country: "",
        addressType: true,
        createdBy: 0,
        updatedBy: 0,
        deletedBy: 0,
      });
      setLoading(false);
    }

  }, []);

  return (
    <>
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <Scrollbars
            className="contain-scroll"
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={100}
            autoHeightMax={100}
            thumbMinSize={30}
            universal={true}
          >
            <div className="container-fluid acc-screen  register-main-div">
              <Toast ref={toast}></Toast>

              <div className="row ">
                <div className="col-md-6 info-section info-section-border">
                  <div className="col-md-12 address-heading">
                    <span className="text-blue-address">
                      Registered Address
                    </span>
                  </div>
                  <div className=" col-md-12 form-group ">
                    <span className="input-label">
                      Address Line 1 <span className="color-red">*</span>
                      <ToolTip props={addressToolTipdata[0]}></ToolTip>
                    </span>
                    <input
                      readOnly={readonly}
                      className="form-control "
                      type="text"
                      name="addressLine1"
                      autoComplete="nope"
                      placeholder="Enter address line1"
                      value={registeredaddressModel.postalAddressLine1}


                      onChange={(e) => {
                        setRegisteredAddressModel({
                          ...registeredaddressModel,
                          postalAddressLine1: e.target.value,
                        });

                        if (checked) {
                          setPhysicalAddressModel({
                            ...physicaladdressModel,
                            postalAddressLine1: e.target.value,
                            addressType: true,
                          });
                        }
                      }
                      }
                    />

                    {postaladdresserrorMessage !== null &&
                      postaladdresserrorMessage.length > 0 && (
                        <span className="error-msg">
                          {postaladdresserrorMessage}
                        </span>
                      )}
                  </div>
                  <div className=" col-md-12 form-group ">
                    <span className="input-label">Address Line 2 </span>
                    <input
                      readOnly={readonly}
                      className="form-control "
                      type="text"
                      name="addressLine2"
                      autoComplete="nope"
                      placeholder="Enter address line2"
                      value={registeredaddressModel.postalAddressLine2}
                      onChange={(e) => {
                        setRegisteredAddressModel({
                          ...registeredaddressModel,
                          postalAddressLine2: e.target.value,
                        });
                        setDirtyField(true);
                        if (checked) {
                          setPhysicalAddressModel({
                            ...physicaladdressModel,
                            postalAddressLine2: e.target.value,
                            addressType: true,
                          });
                        }

                      }}
                    />

                    {postaladdress2errorMessage !== null &&
                      postaladdress2errorMessage.length > 0 && (
                        <span className="error-msg">
                          {postaladdress2errorMessage}
                        </span>
                      )}
                  </div>
                  <div className="col-md-12 form-group ">
                    <span className="input-label">
                      Building Number/Street Name/Landmark{" "}
                      <ToolTip props={addressToolTipdata[2]}></ToolTip>
                    </span>
                    <input
                      readOnly={readonly}
                      className="form-control"
                      type="text"
                      name="buildingNO"
                      autoComplete="nope"
                      placeholder="Enter building number/street name/landmark"
                      value={registeredaddressModel.buildingNumber}
                      onChange={(e) => {
                        setRegisteredAddressModel({
                          ...registeredaddressModel,
                          buildingNumber: e.target.value,
                        });
                        setDirtyField(true);
                        if (checked) {
                          setPhysicalAddressModel({
                            ...physicaladdressModel,
                            buildingNumber: e.target.value,
                            addressType: true,
                          });
                        }
                      }}
                    />
                    {buildingerrorMessage !== null &&
                      buildingerrorMessage.length > 0 && (
                        <span className="error-msg">
                          {buildingerrorMessage}
                        </span>
                      )}
                  </div>
                  <div className="col-md-12 form-group ">
                    <span className="input-label">
                      Town <span className="color-red">*</span>{" "}
                      <ToolTip props={addressToolTipdata[3]}></ToolTip>
                    </span>
                    <input
                      readOnly={readonly}
                      className="form-control"
                      type="text"
                      name="town"
                      autoComplete="nope"
                      placeholder="Enter town"
                      value={registeredaddressModel.townName}
                      onChange={(e) => {
                        setRegisteredAddressModel({
                          ...registeredaddressModel,
                          townName: e.target.value,
                        });
                        setDirtyField(true);
                        if (checked) {
                          setPhysicalAddressModel({
                            ...physicaladdressModel,
                            townName: e.target.value,
                            addressType: true,
                          });
                        }
                      }}
                    />
                    {townnameerrorMessage !== null &&
                      townnameerrorMessage.length > 0 && (
                        <span className="error-msg">
                          {townnameerrorMessage}
                        </span>
                      )}
                  </div>
                  <div className="col-md-12 form-group ">
                    <span className="input-label">
                      Province <span className="color-red">*</span>{" "}
                      <ToolTip props={addressToolTipdata[4]}></ToolTip>
                    </span>
                    <input
                      readOnly={readonly}
                      className="form-control"
                      type="text"
                      name="province"
                      autoComplete="nope"
                      placeholder="Enter province"
                      value={registeredaddressModel.province}
                      onChange={(e) => {
                        setRegisteredAddressModel({
                          ...registeredaddressModel,
                          province: e.target.value,
                        });
                        setDirtyField(true);
                        if (checked) {
                          setPhysicalAddressModel({
                            ...physicaladdressModel,
                            province: e.target.value,
                            addressType: true,
                          });
                        }
                      }}
                    />
                    {provinceerrorMessage !== null &&
                      provinceerrorMessage.length > 0 && (
                        <span className="error-msg">
                          {provinceerrorMessage}
                        </span>
                      )}
                  </div>
                  <div className="col-md-12 form-group ">
                    <span className="input-label">
                      Country <span className="color-red">*</span>
                    </span>
                    <div className="dropdown-custome">

                      <AutoComplete
                        disabled={readonly}
                        field="name"
                        dropdown={true}
                        aria-label="Countries"
                        dropdownAriaLabel="Select Country"
                        scrollHeight="200"
                        className="dropdown-acc"
                        panelClassName="dropdown"
                        placeholder="Select country"
                        suggestions={filteredcountrieslist}
                        completeMethod={searchCountry}
                        onChange={(e) => onCountryChange(e)}
                        value={countryAutoComplete}
                        forceSelection
                      />
                    </div>

                    {countryerrorMessage !== null &&
                      countryerrorMessage.length > 0 && (
                        <span className="error-msg">{countryerrorMessage}</span>
                      )}
                  </div>
                  <div className="col-md-12 form-group ">
                    <span className="input-label">
                      Postal Code <span className="color-red">*</span>{" "}
                    </span>
                    <input
                      readOnly={readonly}
                      className="form-control"
                      type="text"
                      name="postalCode"
                      autoComplete="nope"
                      placeholder="Enter postal code"
                      value={registeredaddressModel.postalCode}
                      onChange={(e) => {
                        setRegisteredAddressModel({
                          ...registeredaddressModel,
                          postalCode: e.target.value,
                        });
                        setDirtyField(true);
                        if (checked) {
                          setPhysicalAddressModel({
                            ...physicaladdressModel,
                            postalCode: e.target.value,
                            addressType: true,
                          });
                        }
                      }}
                    />
                    {postalcodeerrorMessage !== null &&
                      postalcodeerrorMessage.length > 0 && (
                        <span className="error-msg">
                          {postalcodeerrorMessage}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-6 info-section-second">
                  <div className="col-md-12 physical-address-text   address-heading">
                    <span className="text-blue-address ">Physical Address</span>
                    <div className="register-address">
                      <input
                        readOnly={readonly}
                        disabled={readonly}
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                        checked={checked}
                        onChange={onCheckClick}
                      />
                      <span className="form-check-label">
                        Same as registered address
                      </span>
                    </div>
                  </div>

                  <div className=" col-md-12 form-group ">
                    <span className="input-label">
                      Address Line 1 <span className="color-red">*</span>{" "}
                      <ToolTip props={addressToolTipdata[0]}></ToolTip>
                    </span>
                    <input
                      readOnly={Number(status) === 8 ? readonly : checkreadonly}
                      className="form-control"
                      type="text"
                      name="physicalAddress1"
                      autoComplete="nope"
                      placeholder="Enter address line1"
                      value={physicaladdressModel.postalAddressLine1}
                      onChange={(e) => {
                        setPhysicalAddressModel({
                          ...physicaladdressModel,
                          postalAddressLine1: e.target.value,
                        });
                        setDirtyField(true);
                      }}
                    />
                    {physicalpostaladdresserrorMessage !== null &&
                      physicalpostaladdresserrorMessage.length > 0 && (
                        <span className="error-msg">
                          {physicalpostaladdresserrorMessage}
                        </span>
                      )}
                  </div>
                  <div className=" col-md-12 form-group ">
                    <span className="input-label">Address Line 2 </span>
                    <input
                      readOnly={Number(status) === 8 ? readonly : checkreadonly}
                      className="form-control"
                      type="text"
                      name="physicalAddress2"
                      autoComplete="nope"
                      placeholder="Enter address line2"
                      value={physicaladdressModel.postalAddressLine2}
                      onChange={(e) => {
                        setPhysicalAddressModel({
                          ...physicaladdressModel,
                          postalAddressLine2: e.target.value,
                        });
                        setDirtyField(true);
                      }}
                    />
                    {physicalpostaladdress2errorMessage !== null &&
                      physicalpostaladdress2errorMessage.length > 0 && (
                        <span className="error-msg">
                          {physicalpostaladdress2errorMessage}
                        </span>
                      )}
                  </div>
                  <div className="col-md-12 form-group ">
                    <span className="input-label">
                      Building Number/Street Name/Landmark{" "}
                      <ToolTip props={addressToolTipdata[2]}></ToolTip>
                    </span>
                    <input
                      readOnly={Number(status) === 8 ? readonly : checkreadonly}
                      className="form-control"
                      type="text"
                      name="physcialBuildingNo"
                      autoComplete="nope"
                      placeholder="Enter building number/street name/landmark"
                      value={physicaladdressModel.buildingNumber}
                      onChange={(e) => {
                        setPhysicalAddressModel({
                          ...physicaladdressModel,
                          buildingNumber: e.target.value,
                        });
                        setDirtyField(true);
                      }}
                    />
                    {physicalbuildingerrorMessage !== null &&
                      physicalbuildingerrorMessage.length > 0 && (
                        <span className="error-msg">
                          {physicalbuildingerrorMessage}
                        </span>
                      )}
                  </div>
                  <div className="col-md-12 form-group  ">
                    <span className="input-label">
                      Town <span className="color-red">*</span>{" "}
                      <ToolTip props={addressToolTipdata[3]}></ToolTip>
                    </span>
                    <input
                      readOnly={Number(status) === 8 ? readonly : checkreadonly}
                      className="form-control"
                      type="text"
                      name="physicalTown"
                      autoComplete="nope"
                      placeholder="Enter town"
                      value={physicaladdressModel.townName}
                      onChange={(e) => {
                        setPhysicalAddressModel({
                          ...physicaladdressModel,
                          townName: e.target.value,
                        });
                        setDirtyField(true);
                      }}
                    />
                    {physicaltownnameerrorMessage !== null &&
                      physicaltownnameerrorMessage.length > 0 && (
                        <span className="error-msg">
                          {physicaltownnameerrorMessage}
                        </span>
                      )}
                  </div>
                  <div className="col-md-12 form-group ">
                    <span className="input-label">
                      Province <span className="color-red">*</span>{" "}
                      <ToolTip props={addressToolTipdata[4]}></ToolTip>
                    </span>
                    <input
                      readOnly={Number(status) === 8 ? readonly : checkreadonly}
                      className="form-control"
                      type="text"
                      name="physicalProvnce"
                      autoComplete="nope"
                      placeholder="Enter province"
                      value={physicaladdressModel.province}
                      onChange={(e) => {
                        setPhysicalAddressModel({
                          ...physicaladdressModel,
                          province: e.target.value,
                        });
                        setDirtyField(true);
                      }}
                    />
                    {physicalprovinceerrorMessage !== null &&
                      physicalprovinceerrorMessage.length > 0 && (
                        <span className="error-msg">
                          {physicalprovinceerrorMessage}
                        </span>
                      )}
                  </div>
                  <div className=" col-md-12 form-group ">
                    <span>
                      Country <span className="color-red">*</span>
                    </span>
                    <AutoComplete
                      disabled={Number(status) === 8 ? readonly : checkreadonly}
                      field="name"
                      dropdown
                      forceSelection
                      aria-label="Countries"
                      dropdownAriaLabel="Select Country"
                      className="dropdown-acc"
                      placeholder="Select country"
                      suggestions={filteredcountrieslist}
                      completeMethod={searchCountry}
                      onChange={(e) => onPhysicalCountryChange(e)}
                      value={physicalcountryAutoComplete}
                    />

                    {physicalcountryerrorMessage !== null &&
                      physicalcountryerrorMessage.length > 0 && (
                        <span className="error-msg">
                          {physicalcountryerrorMessage}
                        </span>
                      )}
                  </div>
                  <div className="col-md-12 form-group ">
                    <span>
                      Postal Code <span className="color-red">*</span>
                    </span>
                    <input
                      readOnly={Number(status) === 8 ? readonly : checkreadonly}
                      className="form-control"
                      type="text"
                      name="physicalPostalCode"
                      autoComplete="nope"
                      placeholder="Enter postal code"
                      value={physicaladdressModel.postalCode}
                      onChange={(e) => {
                        setPhysicalAddressModel({
                          ...physicaladdressModel,
                          postalCode: e.target.value,
                        });
                        setDirtyField(true);
                      }}
                    />
                    {postalcode2errorMessage !== null &&
                      postalcode2errorMessage.length > 0 && (
                        <span className="error-msg">
                          {postalcode2errorMessage}
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className="button-section">
                <div className="bottom-btns">

                  <Button
                    iconPos="left"
                    label="Back"
                    loading={buttonLoadingBack}
                    onClick={onBackClick}
                    className="btn btn-back second-btn"
                  />

                  {Number(status) === 8 ? (
                    <>
                      <Button
                        iconPos="left"
                        label="Next"
                        loading={buttonLoadingSkip}
                        onClick={onNextClick}
                        className="btn btn-continue   btn-next second-btn"
                      />
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={handleClose}
                        className="btn btn-cancel second-btn"
                      >
                        Cancel
                      </button>
                      <Button
                        iconPos="left"
                        label=" Save and Continue"
                        className="btn btn-continue second-btn"
                        loading={buttonLoading}
                        onClick={onAddClick}
                      />{" "}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Scrollbars>
          {displaypopup ? (
            <>
              <div className="popup-body">
                <div className="Cancel-popup">
                  <div className="text-center ">
                    <div className="awesome-text">
                      <h4>Are you sure you want to cancel?</h4>
                      <p> All unsaved changes will be lost.</p>
                    </div>
                  </div>
                  <div className="payment-screen-btn">
                    <button
                      className="btn btn-cancel second-btn "
                      onClick={OnPopupClose}
                    >
                      {" "}
                      No
                    </button>
                    <button
                      className="btn btn-continue second-btn yes-btn-popup"
                      onClick={OnPopupOk}
                    >
                      {" "}
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default Address;
