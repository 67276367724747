import axios from "axios";
import { environment } from "../../../environments/environment";

export class TransactionDetailsService {
  private static URL: any = environment.partnerApiUrl;
  public static getTransactionDetailsByPartnerId() {
    
    let PartnerURL: string = `${this.URL}/paymenttransacation/transaction`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    };
    return axios.get<any>(PartnerURL, config);

}

public static getDetailPaymentId(paymentId: number) {
  let PartnerURL: string = `${this.URL}/paymenttransacation/transcation/${paymentId}`;
const config = {
headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
};
return axios.get<any>(PartnerURL, config);

}
}
