import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { validEmail } from "../../../utils/utils";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dialog } from "primereact/dialog";
import { AutoComplete } from "primereact/autocomplete";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CloseIcon from "../../../assets/images/icon/close-icon.png";
import { statusList } from "../../../utils/utils";
import { UserManagementService } from "../../../services/Partner/UserManagement/UserManagementService";
import { IUserManagement } from "../../../models/IUserManagement";
import { Logout } from "../../../utils/AccountUtils";
import { confirmDialog } from "primereact/confirmdialog";
import { PartnershipDetailsService } from "../../../services/Partner/PartnershipDetails/PartnershipDetailsService";
import { InputText } from "primereact/inputtext";
import { CommonService } from "../../../services/Common/CommonService";
import { InputSwitch } from "primereact/inputswitch";
import moment from "moment";

const UserManagement: React.FC<any> = ({
  onSaveAndContinueClick,
  source,
  setButtonLoadingBack,
  buttonLoadingBack,
}) => {
  const [displayBasic, setDisplayBasic] = useState(false);
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const [disabled, setDisabled] = useState(false);
  const [status, setstatus] = useState(
    localStorage.getItem("OnboardingStatus")
  );

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true)
  const [userloading, setUserLoading] = useState(false);
  const [userlist, setUserList] = useState<any[]>([]);
  const [userrolelist, setUserRoleList] = useState<any[]>([]);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [filteredItems, setFilteredItems] = useState<any>(null);
  const [statusfilteredItems, setStatusFilteredItems] = useState<any>(null);
  const [roleAutoComplete, setRoleAutoComplete] = useState("");
  const [statusAutoComplete, setStatusAutoComplete] = useState("");
  const id = localStorage.getItem("PartnerId");
  const [partnerId, setpartnerId] = useState(id);
  const [userNameErrorMessage, setuserNameErrorMessage] = useState("");
  const [firstNameErrorMessage, setfirstNameErrorMessage] = useState("");
  const [lastNameErrorMessage, setlastNameErrorMessage] = useState("");
  const [roleerrorMessage, setroleErrorMessage] = useState("");
  const [statuserrorMessage, setstatusErrorMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [Isfacilitator, setIsFacilitator] = useState(false);
  const [nextbuttonLoading, setNextButtonLoading] = useState(false);
  const [readonly, setReadOnly] = useState(false);
  const [readyPopup, setReadyPopup] = useState(false);
  const [checkFeesPopup, setCheckFeesPopup] = useState(false);
  const [activeStatus, setActiveStatus] = useState(null);
  const [changeStatus, setChangeStatus] = useState(false);
  const [displaydeletepopup, setDisplayDeletePopup] = useState(false);
  const [inactivePartner, setInactivePartner] = useState(null);

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };

  const [userModel, setUserModel] = useState<IUserManagement>({
    id: 0,
    partnerId: Number(partnerId),
    firstName: "",
    userName: "",
    lastName: "",
    roleId: 0,
    isActive: true,
  });
  const setModelEmpty = () => {
    setUserModel({
      id: 0,
      partnerId: Number(partnerId),
      firstName: "",
      userName: "",
      lastName: "",
      roleId: 0,
      isActive: true,
    });
    setRoleAutoComplete("");
  };

  useEffect(() => {
    if (source === "organization") {
      setButtonLoadingBack(false);
    }
    {
      Number(status) === 8 && source === "organization"
        ? setDisabled(true)
        : setDisabled(false);
    }
    // Update the document title using the browser API
    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    if (Number(partnerId) !== 0) {
      userByPartnerId();
    }
    getPartnershipDetailsByPartnerId();
  }, []);

  const reloadPage = () => {
    if (Number(partnerId) !== 0) {
      userByPartnerId();
    }
    getPartnershipDetailsByPartnerId();
  }

  const userByPartnerId = () => {
    setLoading(true);
    UserManagementService.getUserByPartnerId()
      .then((response) => {
        const data = response.data;
        setUserModel(data);
        setUserList(data);
        setRoleAutoComplete(data.roleName);
        setLoading(false);
        setPageLoading(false);

      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };

  const getUserRole = () => {
    UserManagementService.getUserRole()
      .then((response) => {
        const data = response.data;

        if (Isfacilitator === false) {
          setUserRoleList(data.filter((item: any) => item.id !== 5));
        } else if (Isfacilitator === true) {
          setUserRoleList(data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
      });
  };

  const ErrorMessageEmptyModel = () => {
    setuserNameErrorMessage("");
    setfirstNameErrorMessage("");
    setlastNameErrorMessage("");
    setroleErrorMessage("");
    setstatusErrorMessage("");
  };

  const actionBodyStatus = (rowData: any) => {
    if (Number(rowData.isActive) === 1) {
      return <>Active</>;
    } else {
      return <>Inactive</>;
    }
  };

  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null || value === 0) {
      return true;
    }
    return false;
  };

  const isValidate = (values: any) => {
    let formIsValid = true;
    ErrorMessageEmptyModel();
    if (CheckNull(values.firstName)) {
      setfirstNameErrorMessage("Please enter firstname");
      formIsValid = false;
    }

    if (CheckNull(values.lastName)) {
      setlastNameErrorMessage("Please enter lastname");
      formIsValid = false;
    }

    if (CheckNull(values.userName)) {
      setuserNameErrorMessage("Please enter email");
      formIsValid = false;
    }
    if (!CheckNull(values.userName)) {
      if (!validEmail.test(values.userName)) {
        setuserNameErrorMessage("Please enter valid email");
        formIsValid = false;
      }
    }
    if (CheckNull(values.roleId)) {
      setroleErrorMessage("Please select role.");

      formIsValid = false;
    }
    return formIsValid;
  };

  const searchItems = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < userrolelist.length; i++) {
      let item = userrolelist[i];

      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredItems(_filteredItems);
  };

  const searchStatusItems = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < statusList.length; i++) {
      let item = statusList[i];

      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setStatusFilteredItems(_filteredItems);
  };

  const onRoleChange = (e: any) => {
    if (e.value !== null) {
      setRoleAutoComplete(e.value);
      setUserModel({
        ...userModel,
        roleId: e.value.id,
      });
    }
  };

  const onStatusChange = (e: any) => {
    if (e.value !== null) {
      setStatusAutoComplete(e.value);

      setUserModel({
        ...userModel,
        isActive: e.value.value,
      });
    }
  };

  const ShowHideDialog = () => {
    setModelEmpty();
    ErrorMessageEmptyModel();
    setDisplayBasic(true);
    setReadOnly(false);
    getUserRole();
  };
  const getPartnershipDetailsByPartnerId = () => {
    PartnershipDetailsService.getPartnershipDetailsByPartnerId(
      Number(partnerId)
    )
      .then((response) => {
        const data = response.data;
        setIsFacilitator(response.data.isFacilitator);
      })
      .catch((error: any) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting partnership details.",
            life: 3000,
          });
        }
      });
  };

  const OnUpdateClick = () => {
    userModel.partnerId = Number(partnerId);
    ErrorMessageEmptyModel();
    setButtonLoading(true);
    if (isValidate(userModel)) {
      UserManagementService.updateUser(userModel)
        .then((data) => {
          setButtonLoading(false);
          userByPartnerId();
          onHideClick();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            setuserNameErrorMessage(error.response.data);
          } else if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data[0].errorMessage,
              life: 3000,
            });
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Oops! Something went wrong.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };

  const onNextClick = () => {
    navigate("/dashboard");
  };

  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    userModel.partnerId = Number(partnerId);
    ErrorMessageEmptyModel();
    setButtonLoading(true);
    if (isValidate(userModel)) {
      UserManagementService.addUser(userModel)
        .then((data) => {
          setButtonLoading(false);

          onHideClick();
          userByPartnerId();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            setuserNameErrorMessage(error.response.data);
          } else if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data[0].errorMessage,
              life: 3000,
            });
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Oops! Something went wrong.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };

  const onHideClick = () => {

    setDisplayBasic(false);
  };

  const actionBodyTemplateFullName = (rowData: any) => {
    return (
      <>
        {rowData.firstName} {rowData.lastName}
      </>
    );
  };

  const GetUserById = (id: any) => {
    setUserLoading(true);
    UserManagementService.getUserById(id)
      .then((response) => {
        const data = response.data;
        {
          data.id > 0 ? setReadOnly(true) : setReadOnly(false);
        }

        setUserModel(data);
        setRoleAutoComplete(data.roleName);
        if (data.isActive === true) {
          setStatusAutoComplete("Active");
        } else if (data.isActive === false) {
          setStatusAutoComplete("InActive");
        }
        setUserLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }

        setUserLoading(false);
      });
  };

  const EditUserDetails = (rowData: any) => {
    setUserLoading(true);

    setDisplayBasic(true);

    ErrorMessageEmptyModel();
    GetUserById(rowData.id);
    getUserRole();
  };

  const onDeleteClick = (rowData: any) => {
    userModel.partnerId = Number(partnerId);
    ErrorMessageEmptyModel();
    setButtonLoading(true);
    UserManagementService.updateUserProfileStatus(rowData.id, changeStatus)
      .then((data) => {
        setButtonLoading(false);
        userByPartnerId();
        onHideClick();
      })
      .catch((error) => {
        if (error.response.status === 409) {
          setuserNameErrorMessage(error.response.data);
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setButtonLoading(false);
      });
  };

  const formatDateField = (rowData: any) => {
    return (
      <React.Fragment>
        <span>{moment(rowData.createdDate).format("MM/DD/YYYY H:mm.ss")} </span>
      </React.Fragment>
    );
  };


  const actionBodyTemplate = (rowData: any) => {
    return (
      <>
        <div className="d-flex user-input " >
          <Button
            disabled={disabled}
            icon="pi pi-pencil"
            className="editbtn "
            onClick={() => EditUserDetails(rowData)}
            title="Edit"
          />

          <span className="switch-style ">
            <InputSwitch
              disabled={disabled}
              className="status-check activeinput"
              name="Send"
              checked={Boolean(rowData.isActive)}
              onChange={(e) => InactiveUser(rowData, e)}
            />
          </span>
        </div>
      </>
    );
  };


  const InactiveUser = (rowData: any, e: any) => {
    setActiveStatus(Boolean(rowData.isActive));
    setChangeStatus(e.target.value);
    setInactivePartner(rowData);
    setDisplayDeletePopup(true);

  };



  const DeleteUserdetails = (rowData: any) => {
    confirmDialog({
      message: "Are you sure you want to Delete?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => onDeleteClick(rowData),
      reject,
    });
  };

  const reject = () => {
    setButtonLoading(false);
  };

  const rejectClick = () => {
    setDisplayDeletePopup(false);
  };


  const cancelPopup = () => {

    setReadyPopup(false);
  };
  const cancelFeesPopup = () => {
    setCheckFeesPopup(false);
  }

  const accept = () => {
    setReadyPopup(false);
    setCheckFeesPopup(false);
    setNextButtonLoading(true);
    UserManagementService.nextUser()

      .then((response: any) => {
        localStorage.setItem("StepFlag", "11");
        localStorage.setItem("OnboardingStatus", "7");
        setNextButtonLoading(false);
        onSaveAndContinueClick("N");
        navigate("/onboarding");
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setNextButtonLoading(false);
      });
  };

  const checkFeesExistance = (partnerid: any) => {
    CommonService.checkFeesExistance(partnerid)

      .then((response: any) => {
        if (response.data === false) {
          setCheckFeesPopup(true);
          setNextButtonLoading(false);
        } else {
          setReadyPopup(true);
          setNextButtonLoading(false);
        }
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }

      });
  };

  const onAddClick = () => {
    setNextButtonLoading(true);
    checkFeesExistance(Number(partnerId));

  };

  const handleClose = () => {

    userModel.id === 0 ? setModelEmpty() : GetUserById(userModel.id);

    ErrorMessageEmptyModel();
    onHideClick();
  };

  const onBackClick = () => {

    setButtonLoadingBack(true);
    setTimeout(() => {
      onSaveAndContinueClick("B");
    }, 1000);
  };
  const onDeleteClickUser = () => {
    onDeleteClick(inactivePartner)
    //onDeleteClick(inactivePartner.id);
    setDisplayDeletePopup(false);
  };
  return (
    <>
      {pageLoading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <div className="usermanagement-search">
          <div className="container-fluid acc-screen usermanagement-screen">
            <div className="user-tab">
              <div className="user-heading user-manage">
                <div className="">
                  {source === "sidebar" ? (
                    <>
                      <div className="user-heading ">
                        <span className="p-input-icon-left">
                          <i className="pi pi-search" />
                          <InputText
                            type="search"
                            placeholder="Search..."
                            onInput={(e: any) =>
                              setGlobalFilterValue(e.target.value)
                            }
                          />
                        </span>

                      </div></>
                  ) : (
                    <>
                      <h2>
                        Invite your team member to help manage your account
                      </h2>
                    </>
                  )}
                </div>
                <div className="user-send-invite">
                  {source === "sidebar" ? (
                    <><Button
                      iconPos="left"
                      className="btn btn-continue  second-btn"
                      icon="pi pi-refresh"
                      label={"Refresh"}
                      onClick={() =>
                        reloadPage()
                      }
                    ></Button></>
                  ) : (
                    <>
                      <Button
                        iconPos="left"
                        label="Back"
                        loading={buttonLoadingBack}
                        onClick={onBackClick}
                        className="btn btn-back  second-btn"
                      />
                    </>
                  )}

                  <button
                    disabled={disabled}
                    type="button"
                    className="btn btn-continue second-btn send-invitebutton"
                    onClick={ShowHideDialog}
                  >
                    Send Invite
                  </button>

                  {Number(status) === 8 && source === "organization" ? (
                    <>
                      <button
                        type="button"
                        onClick={onNextClick}
                        className="btn btn-continue second-btn"
                      >
                        Next
                      </button>
                    </>
                  ) : (
                    <>
                      {Number(status) !== 8 ? (
                        <>
                          <Button
                            loading={nextbuttonLoading}
                            label="Ready"
                            className="btn btn-continue second-btn "
                            onClick={onAddClick}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>


              <div className="">
                {loading ? (
                  <div className="spinner-class">
                    <ProgressSpinner />
                  </div>
                ) : (
                  <div className="datatable-doc-demo">
                    <DataTable
                      value={userlist}
                      paginator
                      className="p-datatable-customers"
                      rows={10}
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      rowsPerPageOptions={[10, 25, 50]}
                      dataKey="id"
                      rowHover
                      filterDisplay="menu"
                      loading={loading}
                      globalFilter={globalFilterValue}
                      filters={globalFilterValue}
                      responsiveLayout="scroll"
                      globalFilterFields={[
                        "firstName",
                        "roleName",
                        "userName"
                      ]}
                      emptyMessage="No Users found."
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    >
                      <Column
                        field="firstName"
                        header="User"
                        sortable
                        body={actionBodyTemplateFullName}
                        style={{ width: "15%" }}
                      />
                      <Column field="roleName" header="Role" sortable  style={{ width: "15%" }}/>
                      <Column field="userName" header="Email" sortable  style={{ width: "15%" }}/>
                      <Column field="createdDate"  body={formatDateField} header="Invited Date" sortable  style={{ width: "15%" }}/>
                      <Column field="createdBy" header="Invited By" sortable  style={{ width: "15%" }}/>
                      <Column
                          field="isActive"
                          header="Status"
                          sortable
                          body={actionBodyStatus}
                          style={{ width: "10%" }}
                        />
                      <Column header="Action" body={actionBodyTemplate} style={{ width: "15%" }} />
                    </DataTable>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}


      {userloading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <Dialog
            header="Header"
            visible={displayBasic}
            onHide={() => onHideClick()}
            className="user-dialog"
          >
            <div className="">
              <button className="close-btn" onClick={onHideClick}>
                <img src={CloseIcon} />
              </button>

              <>
                {userModel.id > 0 ? (
                  <>
                    {" "}
                    {source === "sidebar" ? (
                      <></>
                    ) : (
                      <>
                        <h2>
                          Update your team member to help manage your account
                        </h2>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {source === "sidebar" ? (
                      <></>
                    ) : (
                      <>
                        <h2>
                          Invite your team member to help manage your account
                        </h2>
                      </>
                    )}
                  </>
                )}
                <div className="row user-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <span>
                        First Name <span className="color-red">*</span>
                      </span>
                      <input
                        readOnly={readonly}
                        type="email"
                        name="firstName"
                        autoComplete="nope"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Enter first name"
                        value={userModel.firstName}
                        onChange={(e) =>
                          setUserModel({
                            ...userModel,
                            firstName: e.target.value,
                          })
                        }
                      />
                      {firstNameErrorMessage !== null &&
                        firstNameErrorMessage.length > 0 ? (
                        <span className="login-error-msg">
                          {firstNameErrorMessage}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <span>
                        Last Name <span className="color-red">*</span>
                      </span>
                      <input
                        readOnly={readonly}
                        type="email"
                        name="lastName"
                        autoComplete="nope"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter last name"
                        value={userModel.lastName}
                        onChange={(e) =>
                          setUserModel({
                            ...userModel,
                            lastName: e.target.value,
                          })
                        }
                      />
                      {lastNameErrorMessage !== null &&
                        lastNameErrorMessage.length > 0 ? (
                        <span className="login-error-msg">
                          {lastNameErrorMessage}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row user-row last-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <span>
                        Business Email <span className="color-red">*</span>
                      </span>
                      <input
                        readOnly={readonly}
                        type="email"
                        name="businessEmail"
                        autoComplete="nope"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter business email"
                        value={userModel.userName}
                        onChange={(e) =>
                          setUserModel({
                            ...userModel,
                            userName: e.target.value,
                          })
                        }
                      />
                      {userNameErrorMessage !== null &&
                        userNameErrorMessage.length > 0 ? (
                        <span className="login-error-msg">
                          {userNameErrorMessage}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <span>
                        Role <span className="color-red">*</span>
                      </span>
                      <AutoComplete
                        field="name"
                        dropdown
                        aria-label="roles"
                        minLength={1}
                        dropdownAriaLabel="Select Role"
                        className="dropdown-acc"
                        placeholder="Select role"
                        virtualScrollerOptions={{ itemSize: 38 }}
                        suggestions={filteredItems}
                        completeMethod={searchItems}
                        onChange={(e) => onRoleChange(e)}
                        value={roleAutoComplete}
                        forceSelection
                      />
                      {roleerrorMessage !== null &&
                        roleerrorMessage.length > 0 ? (
                        <span className="login-error-msg">
                          {roleerrorMessage}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>

                {userModel.id > 0 ? (
                  <>
                    {" "}
                    <div className="row user-row last-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <span>
                            Select status <span className="color-red">*</span>
                          </span>
                          <AutoComplete
                            field="name"
                            dropdown
                            aria-label="status"
                            minLength={1}
                            dropdownAriaLabel="Select status"
                            className="dropdown-acc"
                            placeholder="Select status"
                            virtualScrollerOptions={{ itemSize: 38 }}
                            suggestions={statusfilteredItems}
                            completeMethod={searchStatusItems}
                            onChange={(e) => onStatusChange(e)}
                            value={statusAutoComplete}
                            forceSelection
                          />
                          {statuserrorMessage !== null &&
                            statuserrorMessage.length > 0 ? (
                            <span className="error-msg">
                              {statuserrorMessage}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="payment-btn-dialog">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="btn btn-cancel second-btn"
                  >
                    Cancel
                  </button>
                  <Button
                    iconPos="left"
                    label={userModel.id > 0 ? "Update User" : "Save"}
                    className="btn btn-continue second-btn update-btn"
                    loading={buttonLoading}
                    onClick={userModel.id > 0 ? OnUpdateClick : handleSubmit}
                  />
                </div>
              </>
            </div>
          </Dialog>
        </>
      )}


      {readyPopup ? (
        <div className="popup-body">
          <div className="regiter-popup confirm-popup " >
            <div className="popup-text">
              <i className="pi pi-info-circle"></i>
              <p>
                Are you sure you want to submit? Once you submit, you will not
                be able to undo your changes.
              </p>
            </div>

            <div className="popup-btn">
              <button
                type="button"
                onClick={cancelPopup}
                className="btn btn-back second-btn"
              >
                No
              </button>
              <button
                type="button"
                onClick={accept}
                className="btn btn-continue second-btn"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      ) : null}


      {checkFeesPopup ? (
        <div className="popup-body ">
           <div className="regiter-popup confirm-popup " id="confirm-popup">
           <div className="popup-text">
           <i className="pi pi-info-circle"></i>
                  <p>
                  Are you sure you want to submit without fees?
                  </p>
            </div>

            <div className="popup-btn">
              <button
                type="button"
                onClick={cancelFeesPopup}
                className="btn btn-back second-btn"
              >
                No
              </button>
              <button
                type="button"
                onClick={accept}
                className="btn btn-continue second-btn"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      ) : null}


{displaydeletepopup ? (
        <div className="popup-body usrmanagement">
          <div className="regiter-popup Payment-screen ">
            <div className="text-center ">
              <div className="awesome-text">
                {activeStatus ? (
                  <h4>
                    {" "}
                    <i className="pi pi-info-circle"></i> Are you sure you want
                    to inactivate user?
                  </h4>
                ) : (
                  <h4>
                    {" "}
                    <i className="pi pi-info-circle"></i> Are you sure you want
                    to activate user?
                  </h4>
                )}
              </div>
            </div>
            <div className="payment-screen-btn">
              <button className="btn btn-cancel second-btn " onClick={rejectClick}>
                {" "}
                No
              </button>
              <button
                className="btn btn-continue second-btn yes-btn-popup"
                onClick={onDeleteClickUser}
              >
                {" "}
                Yes
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UserManagement;