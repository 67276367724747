import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useCallback, useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import { IContact } from "../../../models/IContact";
import { ContactService } from "../../../services/Partner/Contact/ContactService";
import {
  checkPhoneno, validEmail
} from "../../../utils/utils";
import { formatPhoneNumber } from 'react-phone-number-input'
import ContactToolTips from "../../../services/Partner/Contact/ContactToolTips";
import ToolTip from "../ToolTipsData/ToolTip";

import { Logout } from "../../../utils/AccountUtils";


const Contact: React.FC<any> = ({ onSaveAndContinueClick, onNextClick, buttonLoadingSkip,
  buttonLoadingBack,
  setButtonLoadingBack,
  setButtonLoadingSkip }) => {

  const navigate = useNavigate();

  const toast = useRef<Toast>(null);

  const [readonly, setReadOnly] = useState(false);

  const [status] = useState(localStorage.getItem("OnboardingStatus"));

  const [displaypopup, setDisplayPopup] = useState(false);

  const [dirtyfield, setDirtyField] = useState(false);

  const [loading, setLoading] = useState(true);

  const id = localStorage.getItem("PartnerId");

  const [partnerId] = useState(id);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [contactModel, setContactModel] = useState<IContact>({
    id: 0,
    partnerId: Number(partnerId),
    phoneNumber: "",
    faxNumber: "",
    emailAddress: "",
  });

  const setModelEmpty = () => {
    setContactModel({
      id: 0,
      partnerId: Number(partnerId),
      phoneNumber: "",
      faxNumber: "",
      emailAddress: "",
    });
  };

  const [phonenoerrorMessage, setphonenoErrorMessage] = useState("");

  const [emailerrorMessage, setemailErrorMessage] = useState("")
    ;
  const [faxerrorMessage, setfaxErrorMessage] = useState("");

  const [contactToolTip] = useState<any>(ContactToolTips);

  // check null values
  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null) {
      return true;
    }
    return false;
  };

  // error model empty
  const ErrorMessageEmptyModel = () => {
    setphonenoErrorMessage("");
    setemailErrorMessage("");
    setfaxErrorMessage("");
  };

  //partner contact by partner id
  const partnerContactByPartnerId = (id: number) => {
   
    ContactService.getContactByPartnerId(id)
      .then((response) => {
        const data = response.data;
        setContactModel(data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting contact details.",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };

  // handle close
  const handleClose = () => {
    if (dirtyfield === true) {
      setDisplayPopup(true);
    } else {
      ErrorMessageEmptyModel();
    }

  };

  //popup close
  const OnPopupClose = () => {
    setDisplayPopup(false);
  };

  //popup ok
  const OnPopupOk = () => {
    contactModel.id === 0
      ? setModelEmpty()
      : partnerContactByPartnerId(Number(partnerId));

    setDisplayPopup(false);
    setDirtyField(false);
    ErrorMessageEmptyModel();
  };

  // handle validation
  const isValidate = (values: any) => {
    let formIsValid = true;
    ErrorMessageEmptyModel();
    if (CheckNull(values.phoneNumber)) {
      setphonenoErrorMessage("Please enter company phone number.");
      formIsValid = false;
    }


    if (!CheckNull(values.phoneNumber)) {
      if (values.phoneNumber.trim().length === 0) {
        setphonenoErrorMessage("Please enter company phone number.");
        formIsValid = false;
      }
      if (formatPhoneNumber(values.phoneNumber).trim().length > 15) {
        setphonenoErrorMessage(
          "Please enter valid company phone number."
        );
        formIsValid = false;
      }
      if (formatPhoneNumber(values.phoneNumber).trim().length < 6) {
        setphonenoErrorMessage(
          "Please enter valid company phone number."
        );
        formIsValid = false;
      }


      if (checkPhoneno(values.phoneNumber) === false) {
        setphonenoErrorMessage("Please enter valid phone number.");
        formIsValid = false;
      }
    }
    if (!CheckNull(values.faxNumber)) {
      if (formatPhoneNumber(values.faxNumber).trim().length > 15) {
        setfaxErrorMessage(
          "Please enter valid fax number."
        );
        formIsValid = false;
      }
      if (formatPhoneNumber(values.faxNumber).trim().length < 6) {
        setfaxErrorMessage(
          "Please enter valid fax number."
        );
        formIsValid = false;
      }
    }

    if (!CheckNull(values.emailAddress)) {
      if (!validEmail.test(values.emailAddress)) {
        setemailErrorMessage("Please enter correct company email address.");
        formIsValid = false;
      }
      if (values.emailAddress.length > 2048) {
        setemailErrorMessage(
          "Company email address length should not exceed the max length than 2048."
        );
        formIsValid = false;
      }
    }
    if (CheckNull(values.emailAddress)) {
      setemailErrorMessage("Please enter company email");
      formIsValid = false;
    }

    return formIsValid;
  };

 
  // on save click
  const onAddClick = () => {
    contactModel.partnerId = Number(partnerId);
    ErrorMessageEmptyModel();
    setButtonLoading(true);

    if (isValidate(contactModel)) {
      ContactService.addContact(contactModel)
        .then((response) => {

          localStorage.setItem("StepFlag", "3");
          ErrorMessageEmptyModel();
          setButtonLoading(false);
          onSaveAndContinueClick("N");
        })
        .catch((error) => {
          if (error.response.status === 500) {
            toast.current?.show({
              severity: "error",
              summary: "Something went wrong",
              life: 3000,
            });
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data[0].errorMessage,
              life: 3000,
            });
          } else if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Error while updating contact details.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };

  // on update click
  const onUpdateClick = () => {
    setButtonLoading(true);
    if (isValidate(contactModel)) {
      ContactService.updateContact(contactModel)
        .then((data: any) => {

          ErrorMessageEmptyModel();
          setButtonLoading(false);
          onSaveAndContinueClick("N");
        })
        .catch((error: any) => {
          if (error.response.status === 500) {
            toast.current?.show({
              severity: "error",
              summary: "Something went wrong",
              life: 3000,
            });
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data[0].errorMessage,
              life: 3000,
            });
          } else if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Error while updating contact details.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };

  // on back click
  const onBackClick = () => {
    setButtonLoadingBack(true);
    setTimeout(() => {
      onSaveAndContinueClick("B");
    }, 1000);

  };

  useEffect(() => {
    setButtonLoadingSkip(false);
    setButtonLoadingBack(false);
    {
      Number(status) === 8 ? setReadOnly(true) : setReadOnly(false);
    }

    // Update the document title using the browser API
    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    if (Number(partnerId) !== 0) {
      partnerContactByPartnerId(Number(partnerId));
    }
  }, []);

  return (
    <>
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (<>
        <div className="container-fluid acc-screen contact-info ">
          <Toast ref={toast}></Toast>
          <div className="row ">
            <div className="col-md-6 form-group info-section">
              <div className="col-md-12 form-group ">
                <span className="input-label">
                  Company phone number<span className="color-red">*</span>{" "}
                  <ToolTip props={contactToolTip[0]} />
                </span>


                <PhoneInput
                  disabled={readonly}
                  className="form-control Phone-input"
                  type="text"
                  initialValueFormat="national"
                  addInternationalOption={false}
                  countryCallingCodeEditable={true}
                  international={false}
                  placeholder="Enter phone number"
                  defaultCountry="US"
                  name="phoneNumber"               
                  autoComplete="nope"
                  value={contactModel.phoneNumber}
                  onChange={(e) => {
                    setContactModel({
                      ...contactModel,
                      phoneNumber: e!,
                    }); setDirtyField(true)
                  }
                  }
                />

                {phonenoerrorMessage !== null &&
                  phonenoerrorMessage.length > 0 && (
                    <span className="error-msg">{phonenoerrorMessage}</span>
                  )}
              </div>
              <div className="col-md-12 form-group ">
                <span className="input-label">Company fax number
                  <ToolTip props={contactToolTip[1]} />
                </span>
                <PhoneInput
                  disabled={readonly}
                  className="form-control Phone-input"
                  type="text"
                  initialValueFormat="national"
                  addInternationalOption={false}
                  countryCallingCodeEditable={true}
                  international={false}
                  placeholder="Enter fax number"
                  defaultCountry="US"
                  name="faxNumber"               
                 autoComplete="nope"
                  value={contactModel.faxNumber}
                  onChange={(e) => {
                    setContactModel({
                      ...contactModel,
                      faxNumber: e!,
                    }); setDirtyField(true)
                  }
                  }
                />
                {faxerrorMessage !== null && faxerrorMessage.length > 0 && (
                  <span className="error-msg">{faxerrorMessage}</span>
                )}
              </div>
              <div className="col-md-12 form-group mb-setShowHide2">
                <span className="input-label">
                  Company email address <span className="color-red">*</span>{" "}
                  <ToolTip props={contactToolTip[2]} />
                </span>
                <input
                  readOnly={readonly}
                  className="form-control "
                  type="email"
                  placeholder="Enter email address"
                  name="companyEmail"
                  autoComplete="nope"
                  value={contactModel.emailAddress}
                  onChange={(e) => {
                    setContactModel({
                      ...contactModel,
                      emailAddress: e.target.value,
                    }); setDirtyField(true)
                  }
                  }
                />
                {emailerrorMessage !== null && emailerrorMessage.length > 0 && (
                  <span className="error-msg">{emailerrorMessage}</span>
                )}
              </div>
            </div>
          </div>

          <div className="button-section">
            <div className="bottom-btns">
              <Button
                iconPos="left"
                label="Back"
                loading={buttonLoadingBack}
                onClick={onBackClick}
                className="btn btn-back second-btn"
              />


              {Number(status) === 8 ? (
                <>
                  <Button
                    iconPos="left"
                    label="Next"
                    loading={buttonLoadingSkip}
                    onClick={onNextClick}
                    className="btn btn-continue   btn-next second-btn"
                  />
                </>
              ) : (
                <>


                  <button
                    type="button"
                    onClick={handleClose}
                    className="btn btn-cancel second-btn"
                  >
                    Cancel
                  </button>

                  <Button
                    iconPos="left"
                    label=" Save and Continue"
                    className="btn btn-continue second-btn"
                    loading={buttonLoading}
                    onClick={contactModel.id === 0 ? onAddClick : onUpdateClick}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {displaypopup ? (
          <>
            <div className="popup-body">
              <div className="Cancel-popup">
                <div className="text-center ">
                  <div className="awesome-text">
                    <h4>Are you sure you want to cancel?</h4>
                    <p> All unsaved changes will be lost.</p>
                  </div>
                </div>
                <div className="payment-screen-btn">

                  <button
                    className="btn btn-cancel second-btn"
                    onClick={OnPopupClose}
                  >
                    {" "}
                    No
                  </button>
                  <button
                    className="btn btn-continue second-btn yes-btn-popup"
                    onClick={OnPopupOk}
                  >
                    {" "}
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
      )}
    </>
  );
};

export default Contact;
