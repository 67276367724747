import requiredBlue from "../../../assets/images/required-blue.svg";

function ToolTip(props: any) {
  return (
    <>
      <div className="tool-tip-info">
        <img src={requiredBlue} alt="img" className=" requiredBlue-img" />
        <div
          className="custom-target-icon"
          dangerouslySetInnerHTML={{ __html: props.props.Info }}
        ></div>
      </div>
    </>
  );
}

export default ToolTip;
