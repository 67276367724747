import React, { useEffect, useRef, useState } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import PhoneInput from "react-phone-number-input";
import { DataTable } from "primereact/datatable";
import {
  countryList,
  PaymentTransactionTypeList,
  PaymentRoutingPreferenceList,
  PaymentPreferredValueList,
  PaymentSourceOfFundsList,
  PaymentPurposeOfTransactionList,
  PaymentRelationshipList,
  currenciesList,
  RTPNameList,
  PaymentTypeList,
} from "../../../utils/utils";
import { Logout } from "../../../utils/AccountUtils";
import { useNavigate } from "react-router-dom";
import { PaymentService } from "../../../services/Payment/PaymentService";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import Scrollbars from "react-custom-scrollbars-2";
import moment from "moment";
import { PartnershipDetailsService } from "../../../services/Partner/PartnershipDetails/PartnershipDetailsService";
import { ProgressSpinner } from "primereact/progressspinner";
import { IsValidRoute } from "../../../utils/AccountUtils";

const CreatePayment: React.FC = () => {
  const id = localStorage.getItem("PartnerId");
  const [partnerId] = useState(id);
  const [paymentId, setPaymentId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageList, setErrorMessageList] = useState([]);
  const [paymentModel, setPaymentModel] = React.useState({
    partnerId: Number(partnerId),
    securityKey: null,
    idempotencyKey: null,
    messageInformation: {
      messageID: null,
      messageCreationDate: null,
      additionalMessageField1: null,
      additionalMessageField2: null,
    },
    paymentDataModel: [],
  });
  const [paymentDataModel, setPaymenDataModel] = React.useState({
    paymentInformation: {
      paymentID: null,
      sendAmount: null,
      receiveAmount:null,
      requestedDeliveryDate: null,
      sendCurrency: null,
      receiveCurrency: null,
      paymentType: null,
      transactionType: null,
      purposeOfTransaction: null,
      sourceOfFunds: null,
      relationship: null,
      callbackURL: null,
      routingPreferences: {
        preferredType: null,
        preferredValue: null,
      },
      paymentNotes: null,
      recurringFlag: false,
    },
    senderInformation: {
      bankAccountNumber: null,
      bankAccountType: null,
      bankBIC: null,
      bankBranchAddress: null,
      bankBranchName: null,
      bankBranchNumber: null,
      bankIBAN: null,
      bankRoutingNumber: null,
      bankSortCode: null,
      buildingNumber: null,
      businessID: null,
      businessName: null,
      businessType: null,
      cardNumber: null,
      country: null,
      countryOfBirth: null,
      dateOfBirth: null,
      emailAddress: null,
      firstName: null,
      lastName: null,
      maternalLastName: null,
      middleName: null,
      mobileWalletID: null,
      mobileWalletName: null,
      nationalID: null,
      nationalIDExpiryDate: null,
      nationalIDIssuingCountry: null,
      nationalIDType: null,
      paternalLastName: null,
      phoneNumber: null,
      postalAddressLine: null,
      postalCode: null,
      provinceOfBirth: null,
      provinceState: null,
      referenceNumber: null,
      rtpName: null,
      rtpid: null,
      townCity: null,
      townOfBirth: null,
      namePrefix: null,
    },
    receiverInformation: {
      firstName: null,
      lastName: null,
      dateOfBirth: null,
      townOfBirth: null,
      countryOfBirth: null,
      nationalID: null,
      nationalIDType: null,
      nationalIDExpiryDate: null,
      phoneNumber: null,
      emailAddress: null,
      postalAddressLine: null,
      rtpid: null,
      rtpName: null,
      businessName: null,
      businessID: null,
      namePrefix: null,
    },
  });
  const [statusCodeList, setStatusCodeList] = useState([]);
  const [dateofbirthvalue, setDateofbirthvalue] = useState(null);
  const [phonenovalue, setPhoneNovalue] = useState(null);
  const [recipientphonenovalue, setRecipientPhoneNovalue] = useState(null);
  const [paymentloglist, setPaymentLogList] = useState<any[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageloading, setPageLoading] = useState(true);
  const [buttonloading, setButtonLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const [filteredcountrieslist, setFilteredCountryList] = useState<any[]>([]);
  const [filteredrtpnamelist, setFilteredRTPNameList] = useState<any[]>([]);
  const [partnerlogo, setlogo] = useState(localStorage.getItem("PartnerLogo"));
  const [stringParts, setStringParts] = useState<string[]>([]);
  const [filteredcurrencieslist, setFilteredCurrencyList] = useState<any[]>([]);
  const [filteredtransactiontypelist, setFilteredTransactionTypeList] =
    useState<any[]>([]);
  const [filteredroutingpreferencelist, setFilteredRoutingPreferenceList] =
    useState<any[]>([]);
  const [filteredpreferredvaluelist, setFilteredPreferedValueList] = useState<
    any[]
  >([]);
  const [filteredpaymenttypelist, setFilteredPaymentTypeList] = useState<any[]>(
    []
  );
  const [defaultList, setDefaultList] = useState<any[]>([]);
  const [filteredsourceoffundlist, setFilteredSourceOfFundList] = useState<
    any[]
  >([]);
  const [
    filteredpurposeoftransactionlist,
    setFilteredPurposeOfTransactionList,
  ] = useState<any[]>([]);
  const [filteredrelationshiplist, setFilteredRelationshipList] = useState<
    any[]
  >([]);
  const [fromcurrencyAutoComplete, setFromCurrencyAutoComplete] = useState("");
  const [tocurrencyAutoComplete, setToCurrencyAutoComplete] = useState("");
  const [transactiontypeAutoComplete, setTransactionTypeAutoComplete] =
    useState("");
  const [routingpreferenceAutoComplete, setRoutingPreferenceAutoComplete] =
    useState("");
  const [preferredvalueAutoComplete, setPreferredValueAutoComplete] =
    useState("");
  const [paymenttypeAutoComplete, setPaymentTypeAutoComplete] = useState("");
  const [sourceoffundsAutoComplete, setSourceOfFundsAutoComplete] =
    useState("");
  const [
    purposeoftransactionAutoComplete,
    setPurposeOfTransactionAutoComplete,
  ] = useState("");
  const [relationshipAutoComplete, setRelationshipAutoComplete] = useState("");
  const [rtpnameAutoComplete, setRTPNameAutoComplete] = useState("");
  const [countryAutoComplete, setCountryAutoComplete] = useState("");
  const [transactiontypeerrorMessage, setTransactionTypeErrorMessage] =
    useState("");
  const [paymenttypeerrorMessage, setPaymentTypeErrorMessage] = useState("");
  const [preferredtypeerrorMessage, setPreferredTypeErrorMessage] =
    useState("");
  const [preferredvalueerrorMessage, setPreferredValueErrorMessage] =
    useState("");
  const [sendingfromerrorMessage, setSendingFromErrorMessage] = useState("");
  const [sendingtoerrorMessage, setSendingToErrorMessage] = useState("");
  const [sendamounterrorMessage, setSendAmountErrorMessage] = useState("");
  const [receiveamounterrorMessage, setReceiveAmountErrorMessage] = useState("");
  const [sourceoffunderrorMessage, setSourceOfFundErrorMessage] = useState("");
  const [
    purposeoftransactionerrorMessage,
    setPurposeOfTransactionErrorMessage,
  ] = useState("");
  const [relationshiperrorMessage, setRelationshipErrorMessage] = useState("");
  const [paymentnoteserrorMessage, setPaymentNotesErrorMessage] = useState("");
  const [senderfirstnameerrorMessage, setSenderFirstNameErrorMessage] =
    useState("");
  const [senderlastnameerrorMessage, setSenderLastNameErrorMessage] =
    useState("");
  const [sendercountryerrorMessage, setSenderCountryErrorMessage] =
    useState("");
  const [senderphonenoerrorMessage, setSenderPhoneNoErrorMessage] =
    useState("");
  const [senderemailerrorMessage, setSenderEmailErrorMessage] = useState("");
  const [senderbusinessnameerrorMessage, setSenderBusinessNameErrorMessage] =
    useState("");
  const [recipientfirstnameerrorMessage, setRecipientFirstNameErrorMessage] =
    useState("");
  const [recipientlastnameerrorMessage, setRecipientLastNameErrorMessage] =
    useState("");
  const [recipientphonenoerrorMessage, setRecipientPhoneNoErrorMessage] =
    useState("");
  const [recipientemailerrorMessage, setRecipientEmailErrorMessage] =
    useState("");
  const [recipientrtpiderrorMessage, setRecipientRTPIdErrorMessage] =
    useState("");
  const [
    recipientdateofbirtherrorMessage,
    setRecipientDateOfBirthErrorMessage,
  ] = useState("");
  const [recipientrtpnameerrorMessage, setRecipientRTPNameErrorMessage] =
    useState("");
  const [
    recipientbusinessnameerrorMessage,
    setRecipientBusinessNameErrorMessage,
  ] = useState("");
  const [recipientbusinessiderrorMessage, setRecipientBusinessIdErrorMessage] =
    useState("");
  const Quote = localStorage.getItem("Quotedata");

  const type = localStorage.getItem("Type");
  const roleType=localStorage.getItem("RoleType");

  useState("");
  const [Quotedata, SetQuotedata] = useState(JSON.parse(Quote));

  useEffect(() => {
    IsValidRoute(navigate);

    const isFacilitator=JSON.parse(localStorage.getItem("IsFacilitator"));
 
    if(isFacilitator){
      Logout(navigate);
    }else{
      if (JSON.parse(localStorage.getItem("PaymentModel")) !== null) {
        setPaymentModelEmpty();
        setPaymenDataModel(
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
        );
        setFromCurrencyAutoComplete(
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .paymentInformation.sendCurrency
        );
        setToCurrencyAutoComplete(
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .paymentInformation.receiveCurrency
        );
        setTransactionTypeAutoComplete(
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .paymentInformation.transactionType
        );
        setPaymentTypeAutoComplete(
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .paymentInformation.paymentType
        );
        if (
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .paymentInformation.routingPreferences.preferredType === "SmartSpeed"
        ) {
          setRoutingPreferenceAutoComplete("Smart Speed");
        } else if (
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .paymentInformation.routingPreferences.preferredType ===
          "SmartPreferredDelivery"
        ) {
          setRoutingPreferenceAutoComplete("Smart Preferred Delivery");
        } else if (
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .paymentInformation.routingPreferences.preferredType === "SmartQuote"
        ) {
          setRoutingPreferenceAutoComplete("Smart Quote");
        } else if (
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .paymentInformation.routingPreferences.preferredType ===
          "PreferredPaymentProvider"
        ) {
          setRoutingPreferenceAutoComplete("Preferred Payment Provider");
        } else if (
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .paymentInformation.routingPreferences.preferredType ===
          "PreferredQuoteID"
        ) {
          setRoutingPreferenceAutoComplete("Preferred Quote");
        }
        if (
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .paymentInformation.routingPreferences.preferredType ===
          "SmartPreferredDelivery"
        ) {
          setPreferredValueAutoComplete(
            JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
              .paymentInformation.routingPreferences.preferredValue
          );
        }
        setSourceOfFundsAutoComplete(
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .paymentInformation.sourceOfFunds
        );
        setPurposeOfTransactionAutoComplete(
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .paymentInformation.purposeOfTransaction
        );
        setRelationshipAutoComplete(
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .paymentInformation.relationship
        );
        setCountryAutoComplete(
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .senderInformation.country
        );
        setRTPNameAutoComplete(
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .receiverInformation.rtpName
        );
        if (
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .receiverInformation.dateOfBirth !== null
        ) {
          setDateofbirthvalue(
            new Date(
              JSON.parse(
                localStorage.getItem("PaymentModel")
              ).paymentDataModel[0].receiverInformation.dateOfBirth
            )
          );
        }
        if (
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .senderInformation.phoneNumber !== null
        ) {
          setPhoneNovalue(
            "+" +
              JSON.parse(localStorage.getItem("PaymentModel"))
                .paymentDataModel[0].senderInformation.phoneNumber.substring(0, 3)
                .replaceAll("0", "") +
              JSON.parse(
                localStorage.getItem("PaymentModel")
              ).paymentDataModel[0].senderInformation.phoneNumber.slice(3)
          );
        }
        if (
          JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
            .receiverInformation.phoneNumber !== null
        ) {
          setRecipientPhoneNovalue(
            "+" +
              JSON.parse(localStorage.getItem("PaymentModel"))
                .paymentDataModel[0].receiverInformation.phoneNumber.substring(
                  0,
                  3
                )
                .replaceAll("0", "") +
              JSON.parse(
                localStorage.getItem("PaymentModel")
              ).paymentDataModel[0].receiverInformation.phoneNumber.slice(3)
          );
        }
      }
      SetQuotedata(JSON.parse(localStorage.getItem("Quotedata")));
      const type = JSON.parse(localStorage.getItem("Type"));
      if (Quotedata !== null) {
        setFromCurrencyAutoComplete(Quotedata.sourceCurrency);
        setToCurrencyAutoComplete(Quotedata.receiveCurrency);
        setPaymentTypeAutoComplete(type);
        paymentDataModel.paymentInformation.sendAmount = Quotedata.sourceAmount;
        paymentDataModel.paymentInformation.receiveAmount = Quotedata.receiveAmount;
        paymentDataModel.paymentInformation.sendCurrency =
          Quotedata.sourceCurrency;
        paymentDataModel.paymentInformation.paymentType = type;
        paymentDataModel.paymentInformation.receiveCurrency =
          Quotedata.receiveCurrency;
        paymentDataModel.paymentInformation.routingPreferences.preferredValue =
          Quotedata.optionId;
        paymentDataModel.paymentInformation.routingPreferences.preferredType =
          "PreferredQuoteID";
        setRoutingPreferenceAutoComplete("PreferredQuoteID");
      }
      const useroobj = localStorage.getItem("User");
      if (useroobj === null || useroobj === undefined) {
        Logout(navigate);
      }
      OnLogoUpdate();
  
      partnershipDetailsByPartnerId(Number(partnerId));
  
      //console.log(JSON.parse(localStorage.getItem("PaymentModel"));
      localStorage.removeItem("Quotedata");
      //localStorage.removeItem("PaymentModel");
    }
  }, []);

  const searchCountry = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < countryList.length; i++) {
      let item = countryList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredCountryList(_filteredItems);
  };
  const searchCurrency = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < currenciesList.length; i++) {
      let item = currenciesList[i];
      if (item.Name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredCurrencyList(_filteredItems);
  };
  const searchTransactionTypes = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < PaymentTransactionTypeList.length; i++) {
      let item = PaymentTransactionTypeList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredTransactionTypeList(_filteredItems);
  };
  const searchRoutingPreference = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < PaymentRoutingPreferenceList.length; i++) {
      let item = PaymentRoutingPreferenceList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredRoutingPreferenceList(_filteredItems);
  };
  const searchPreferedValue = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < PaymentPreferredValueList.length; i++) {
      let item = PaymentPreferredValueList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredPreferedValueList(_filteredItems);
  };
  const searchPaymentType = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < defaultList.length; i++) {
      let item = defaultList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredPaymentTypeList(_filteredItems);
  };
  const searchSourceOfFunds = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < PaymentSourceOfFundsList.length; i++) {
      let item = PaymentSourceOfFundsList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredSourceOfFundList(_filteredItems);
  };
  const searchPurposeOfTransaction = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < PaymentPurposeOfTransactionList.length; i++) {
      let item = PaymentPurposeOfTransactionList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredPurposeOfTransactionList(_filteredItems);
  };
  const searchRelationship = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < PaymentRelationshipList.length; i++) {
      let item = PaymentRelationshipList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredRelationshipList(_filteredItems);
  };
  const searchRTPName = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < RTPNameList.length; i++) {
      let item = RTPNameList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredRTPNameList(_filteredItems);
  };
  const onTransactioTypeChange = (e: any) => {
    setTransactionTypeAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      paymentInformation: {
        ...paymentDataModel.paymentInformation,
        transactionType: e.value.name,
      },
    });
  };
  const onRoutingPreferenceChange = (e: any) => {
    setRoutingPreferenceAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      paymentInformation: {
        ...paymentDataModel.paymentInformation,
        routingPreferences: {
          ...paymentDataModel.paymentInformation.routingPreferences,
          preferredType: e.value.value,
        },
      },
    });
  };
  const onPreferredValueChange = (e: any) => {
    setPreferredValueAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      paymentInformation: {
        ...paymentDataModel.paymentInformation,
        routingPreferences: {
          ...paymentDataModel.paymentInformation.routingPreferences,
          preferredValue: e.value.name,
        },
      },
    });
  };
  const onPaymentTypeChange = (e: any) => {
    setPaymentTypeAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      paymentInformation: {
        ...paymentDataModel.paymentInformation,
        paymentType: e.value.name,
      },
    });
  };
  const onFromCurrencyChange = (e: any) => {
    setFromCurrencyAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      paymentInformation: {
        ...paymentDataModel.paymentInformation,
        sendCurrency: e.value.Name,
      },
    });
  };
  const onToCurrencyChange = (e: any) => {
    setToCurrencyAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      paymentInformation: {
        ...paymentDataModel.paymentInformation,
        receiveCurrency: e.value.Name,
      },
    });
  };
  const onSourceOfFundChange = (e: any) => {
    setSourceOfFundsAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      paymentInformation: {
        ...paymentDataModel.paymentInformation,
        sourceOfFunds: e.value.name,
      },
    });
  };
  const onPurposeOfTransactionChange = (e: any) => {
    setPurposeOfTransactionAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      paymentInformation: {
        ...paymentDataModel.paymentInformation,
        purposeOfTransaction: e.value.name,
      },
    });
  };
  const onRelationshipChange = (e: any) => {
    setRelationshipAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      paymentInformation: {
        ...paymentDataModel.paymentInformation,
        relationship: e.value.name,
      },
    });
  };
  const onCountryChange = (e: any) => {
    setCountryAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      senderInformation: {
        ...paymentDataModel.senderInformation,
        country: e.value.code,
      },
    });
  };
  const onRTPNameChange = (e: any) => {
    setRTPNameAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      receiverInformation: {
        ...paymentDataModel.receiverInformation,
        rtpName: e.value.name,
      },
    });
  };
  const setPaymentModelEmpty = () => {
    setPaymentModel({
      ...paymentModel,
      paymentDataModel: [],
    });
  };
  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null || value === 0) {
      return true;
    }
    return false;
  };
  const isValidate = (values: any) => {
    let formIsValid = true;
    ErrorMessageEmptyModel();
    if (CheckNull(values.paymentInformation.transactionType)) {
      setTransactionTypeErrorMessage("Please select transaction type.");
      formIsValid = false;
    }
    if (CheckNull(values.paymentInformation.paymentType)) {
      setPaymentTypeErrorMessage("Please select payment type.");
      formIsValid = false;
    }
    if (CheckNull(values.paymentInformation.routingPreferences.preferredType)) {
      setPreferredTypeErrorMessage("Please select preferred type.");
      formIsValid = false;
    }
    if (
      values.paymentInformation.routingPreferences.preferredType ===
        "PreferredPaymentProvider" ||
      values.paymentInformation.routingPreferences.preferredType ===
        "PreferredQuoteID" ||
      values.paymentInformation.routingPreferences.preferredType ===
        "SmartPreferredDelivery"
    ) {
      if (
        CheckNull(values.paymentInformation.routingPreferences.preferredValue)
      ) {
        setPreferredValueErrorMessage("Please provide preferred value.");
        formIsValid = false;
      }
    }
    if (CheckNull(values.paymentInformation.sendCurrency)) {
      setSendingFromErrorMessage("Please select sending from.");
      formIsValid = false;
    }
    if (CheckNull(values.paymentInformation.receiveCurrency)) {
      setSendingToErrorMessage("Please select sending to.");
      formIsValid = false;
    }

    if (
      !CheckNull(values.paymentInformation.sendCurrency) &&
      !CheckNull(values.paymentInformation.receiveCurrency)
    ) {
      if (
        values.paymentInformation.sendCurrency ===
        values.paymentInformation.receiveCurrency
      ) {
        setSendingToErrorMessage(
          "Currency should not be the same among sending from and receiving."
        );
        formIsValid = false;
      }
    }
    if(roleType==="SendExact"){
      if (CheckNull(values.paymentInformation.sendAmount)) {
        setSendAmountErrorMessage("Please select send amount.");
        formIsValid = false;
      }
      if (!CheckNull(values.paymentInformation.sendAmount)) {
        if (values.paymentInformation.sendAmount < 10) {
          setSendAmountErrorMessage(
            "The sendamount must be greater or equal to 10."
          );
          formIsValid = false;
        }
        if (
          !values.paymentInformation.sendAmount
            .toString()
            .match(/^\d{1,18}(\.\d{0,2})?$/)
        ) {
          setSendAmountErrorMessage("Send amount valid only upto length(16,2)");
          formIsValid = false;
        }
      }
    }else{

      if (CheckNull(values.paymentInformation.receiveAmount)) {
        setReceiveAmountErrorMessage("Please select receive amount.");
        formIsValid = false;
      }
      if (!CheckNull(values.paymentInformation.receiveAmount)) {
        if (values.paymentInformation.receiveAmount < 10) {
          setReceiveAmountErrorMessage(
            "The receiveamount must be greater or equal to 10."
          );
          formIsValid = false;
        }
        if (
          !values.paymentInformation.receiveAmount
            .toString()
            .match(/^\d{1,18}(\.\d{0,2})?$/)
        ) {
          setReceiveAmountErrorMessage("Receive amount valid only upto length(16,2)");
          formIsValid = false;
        }
      }
    }
  


    if (CheckNull(values.paymentInformation.sourceOfFunds)) {
      setSourceOfFundErrorMessage("Please select source of fund.");
      formIsValid = false;
    }
    if (CheckNull(values.paymentInformation.purposeOfTransaction)) {
      setPurposeOfTransactionErrorMessage(
        "Please select purpose of transaction."
      );
      formIsValid = false;
    }
    if (CheckNull(values.paymentInformation.relationship)) {
      setRelationshipErrorMessage("Please select relationship.");
      formIsValid = false;
    }

    if (!CheckNull(values.paymentInformation.paymentNotes)) {
      if (values.paymentInformation.paymentNotes.length > 500) {
        setPaymentNotesErrorMessage(
          "The length of payment notes must be less than 500 characters."
        );
        formIsValid = false;
      }
    }
    if (
      values.paymentInformation.paymentType === "C2C" ||
      values.paymentInformation.paymentType === "C2B"
    ) {
      if (CheckNull(values.senderInformation.firstName)) {
        setSenderFirstNameErrorMessage("Please enter first name.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.firstName)) {
        if (values.senderInformation.firstName.length > 500) {
          setSenderFirstNameErrorMessage(
            "The length of firstname must be less than 500 characters."
          );
          formIsValid = false;
        }
      }
      if (CheckNull(values.senderInformation.lastName)) {
        setSenderLastNameErrorMessage("Please enter last name.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.lastName)) {
        if (values.senderInformation.lastName.length > 500) {
          setSenderLastNameErrorMessage(
            "The length of lastname must be less than 500 characters."
          );
          formIsValid = false;
        }
      }
      if (CheckNull(values.senderInformation.country)) {
        setSenderCountryErrorMessage("Please select country.");
        formIsValid = false;
      }
      if (CheckNull(paymentDataModel.senderInformation.phoneNumber)) {
        setSenderPhoneNoErrorMessage("Please enter phone number.");
        formIsValid = false;
      }
      if (!CheckNull(paymentDataModel.senderInformation.phoneNumber)) {
        if (
          !/^\d{3}-\d{0,12}$/.test(
            paymentDataModel.senderInformation.phoneNumber
          )
        ) {
          setSenderPhoneNoErrorMessage("Please provide phone number.");
          formIsValid = false;
        }
      }
      if (CheckNull(values.senderInformation.emailAddress)) {
        setSenderEmailErrorMessage("Please enter email address.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.emailAddress)) {
        if (
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            values.senderInformation.emailAddress
          )
        ) {
          setSenderEmailErrorMessage("Please provide valid email address.");
          formIsValid = false;
        }
      }
    }
    if (
      values.paymentInformation.paymentType === "B2B" ||
      values.paymentInformation.paymentType === "B2C"
    ) {
      if (CheckNull(values.senderInformation.businessName)) {
        setSenderBusinessNameErrorMessage("Please enter business name.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.businessName)) {
        if (values.senderInformation.businessName.length > 500) {
          setSenderBusinessNameErrorMessage(
            "The length of business name must be less than 500 characters."
          );
          formIsValid = false;
        }
      }
    }
    if (
      values.paymentInformation.paymentType === "C2C" ||
      values.paymentInformation.paymentType === "B2C"
    ) {
      if (CheckNull(values.receiverInformation.firstName)) {
        setRecipientFirstNameErrorMessage("Please enter first name.");
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.firstName)) {
        if (values.receiverInformation.firstName.length > 500) {
          setRecipientLastNameErrorMessage(
            "The length of firstname must be less than 500 characters."
          );
          formIsValid = false;
        }
      }
      if (CheckNull(values.receiverInformation.lastName)) {
        setRecipientLastNameErrorMessage("Please enter last name.");
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.lastName)) {
        if (values.receiverInformation.lastName.length > 500) {
          setRecipientLastNameErrorMessage(
            "The length of lastname must be less than 500 characters."
          );
          formIsValid = false;
        }
      }
      if (CheckNull(values.receiverInformation.phoneNumber)) {
        setRecipientPhoneNoErrorMessage("Please enter phone number.");
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.phoneNumber)) {
        if (!/^\d{3}-\d{1,12}$/.test(values.receiverInformation.phoneNumber)) {
          setRecipientPhoneNoErrorMessage("Please provide phone number.");
          formIsValid = false;
        }
      }
      if (CheckNull(values.receiverInformation.emailAddress)) {
        setRecipientEmailErrorMessage("Please enter email address.");
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.emailAddress)) {
        if (
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            values.receiverInformation.emailAddress
          )
        ) {
          setRecipientEmailErrorMessage("Please provide valid email address.");
          formIsValid = false;
        }
      }
      if (CheckNull(dateofbirthvalue)) {
        setRecipientDateOfBirthErrorMessage("Please enter date of birth.");
        formIsValid = false;
      }
      // if (CheckNull(values.receiverInformation.rtpid)) {
      //   setRecipientRTPIdErrorMessage("Please enter RTP id.");
      //   formIsValid = false;
      // }
      // if (CheckNull(values.receiverInformation.rtpName)) {
      //   setRecipientRTPNameErrorMessage("Please select RTP name.");
      //   formIsValid = false;
      // }
    }
    if (
      values.paymentInformation.paymentType === "B2B" ||
      values.paymentInformation.paymentType === "C2B"
    ) {
      if (CheckNull(values.receiverInformation.businessName)) {
        setRecipientBusinessNameErrorMessage("Please enter business name.");
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.businessName)) {
        if (values.receiverInformation.businessName.length > 500) {
          setRecipientBusinessNameErrorMessage(
            "The length of business name must be less than 500 characters."
          );
          formIsValid = false;
        }
      }
      if (CheckNull(values.receiverInformation.businessID)) {
        setRecipientBusinessIdErrorMessage("Please enter business id.");
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.businessID)) {
        if (values.receiverInformation.businessID.length > 500) {
          setRecipientBusinessIdErrorMessage(
            "The length of business ID must be less than 500 characters."
          );
          formIsValid = false;
        }
      }
    }
    return formIsValid;
  };
  const OnLogoUpdate = () => {
    let plogo = localStorage.getItem("PartnerLogo")?.replaceAll('"', "");
    setlogo(plogo);
  };
  const ErrorMessageEmptyModel = () => {
    setTransactionTypeErrorMessage("");
    setPaymentTypeErrorMessage("");
    setPreferredTypeErrorMessage("");
    setPreferredValueErrorMessage("");
    setSendingFromErrorMessage("");
    setSendingToErrorMessage("");
    setSendAmountErrorMessage("");
    setSourceOfFundErrorMessage("");
    setPurposeOfTransactionErrorMessage("");
    setRelationshipErrorMessage("");
    setPaymentNotesErrorMessage("");
    setSenderFirstNameErrorMessage("");
    setSenderLastNameErrorMessage("");
    setSenderCountryErrorMessage("");
    setSenderPhoneNoErrorMessage("");
    setSenderEmailErrorMessage("");
    setSenderBusinessNameErrorMessage("");
    setRecipientFirstNameErrorMessage("");
    setRecipientLastNameErrorMessage("");
    setRecipientPhoneNoErrorMessage("");
    setRecipientEmailErrorMessage("");
    setRecipientDateOfBirthErrorMessage("");
    setRecipientRTPIdErrorMessage("");
    setRecipientRTPNameErrorMessage("");
    setRecipientBusinessNameErrorMessage("");
    setRecipientBusinessIdErrorMessage("");
    setReceiveAmountErrorMessage("");
  };
  const getLogs = (paymentid: any) => {
    setButtonLoading(true);
    PaymentService.getPaymentLogs(paymentid)
      .then((response) => {
        setPaymentLogList(response.data);
        setLoading(false);
        setButtonLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Oops, Something went wrong!",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops, Something went wrong!",
            life: 3000,
          });
        }
        setLoading(false);
        setButtonLoading(false);
      });
  };
  const partnershipDetailsByPartnerId = (id: number) => {
    setPageLoading(true);
    PartnershipDetailsService.getPartnershipDetailsByPartnerId(id)
      .then((response) => {
        const data = response.data;
        let paymentList = [];
        if (data.partnerTypes?.includes("B2B")) {
          paymentList.push({ name: "B2B" });
        }
        if (data.partnerTypes?.includes("B2C")) {
          paymentList.push({ name: "B2C" });
        }
        if (data.partnerTypes?.includes("C2C")) {
          paymentList.push({ name: "C2C" });
        }
        setDefaultList(paymentList);
        setPageLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error?.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting partnership details.",
            life: 3000,
          });
        }
        setPageLoading(false);
      });
  };
  const onValidateClick = () => {
    ErrorMessageEmptyModel();
    setLoading(true);
    setPaymentModelEmpty();
    {
      paymentDataModel.paymentInformation.paymentType === "C2C" ||
      paymentDataModel.paymentInformation.paymentType === "B2C" ? (
        (paymentDataModel.receiverInformation.dateOfBirth =
          moment(dateofbirthvalue).format("yyyy-MM-DD"))
      ) : (
        <></>
      );
    }
    {
      paymentDataModel.paymentInformation.paymentType === "C2C" ||
      paymentDataModel.paymentInformation.paymentType === "C2B" ? (
        (paymentDataModel.senderInformation.phoneNumber = (
          formatPhoneNumberIntl(phonenovalue)
            .replace(" ", "-")
            .split("-")[0]
            .replace("+", "")
            .padStart(3, "0") +
          "-" +
          formatPhoneNumberIntl(phonenovalue).replace(" ", "-").split("-")[1]
        ).replaceAll(" ", ""))
      ) : (
        <></>
      );
    }
    {
      paymentDataModel.paymentInformation.paymentType === "C2C" ||
      paymentDataModel.paymentInformation.paymentType === "B2C" ? (
        (paymentDataModel.receiverInformation.phoneNumber = (
          formatPhoneNumberIntl(recipientphonenovalue)
            .replace(" ", "-")
            .split("-")[0]
            .replace("+", "")
            .padStart(3, "0") +
          "-" +
          formatPhoneNumberIntl(recipientphonenovalue)
            .replace(" ", "-")
            .split("-")[1]
        ).replaceAll(" ", ""))
      ) : (
        <></>
      );
    }

    paymentModel.messageInformation.messageCreationDate = moment.utc(
      new Date()
    ).format("yyyy-MM-DD HH:mm:ss");

    if(roleType==="SendExact"){
      paymentDataModel.paymentInformation.receiveAmount=null;
    }else{
      paymentDataModel.paymentInformation.sendAmount=null;
    }
    
    paymentModel.paymentDataModel.push(paymentDataModel);
    if (isValidate(paymentDataModel)) {
      PaymentService.validatePayment(paymentModel, partnerId)
        .then((response: any) => {
          if (response.data.messageInfos !== null) {
            setStatusCodeList(
              response.data.messageInfos.map((d: any) => d.statusCode)
            );
          }
          if (response.data.messageInfos.length === 0) {
            setLoading(false);
            localStorage.removeItem("Quotedata");
            localStorage.removeItem("Type");
            localStorage.setItem(
              "PaymentModel",
              JSON.stringify(response.data.paymentmodel)
            );
            navigate("/submitpayment");
          } else if (
            response.data.messageInfos.length > 0 &&
            response.data?.messageInfos
              .map((d: any) => d.statusCode)
              .includes("100016")
          ) {
            setLoading(false);
            setErrorMessage(response.data.messageInfos[0].message);
          } else {
            setLoading(false);
            localStorage.removeItem("Quotedata");
            localStorage.removeItem("Type");
            localStorage.setItem(
              "PaymentModel",
              JSON.stringify(response.data.paymentmodel)
            );
            localStorage.setItem(
              "MessageInfos",
              JSON.stringify(response.data.messageInfos)
            );
            navigate("/addpaymentfields");
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 500) {
            toast.current?.show({
              severity: "error",
              summary: "Oops, Something went wrong!",
              life: 3000,
            });
          } else if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 400) {
            if (
              Object.is(error.response.data?.messageInfos, null) ||
              Object.is(error.response.data?.messageInfos, undefined)
            ) {
              const errorArray: any = Object.entries(error.response.data.errors)
                .map(([key, val]) => ({ name: key, value: val }))
                .map((data: any) => {
                  const splitValues = data.value[0].split("_");

                  return {
                    ...data,
                    statusCode: splitValues[0],
                    errorMessage: splitValues[1],
                  };
                });
                
              setErrorMessageList(errorArray);
            } else {
              const errorArray: any = Object.entries(
                error.response.data.messageInfos
              )
                .map(([key, val]) => ({ name: key, value: val }))
                .map((data: any) => ({
                  name: "error",
                  value: data.value.message,
                  statusCode: data.value.statusCode,
                  errorMessage: data.value.message,
                }));
             
              setErrorMessageList(errorArray);
            }
            setLoading(false);
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Oops!, Something went wrong.",
              life: 3000,
            });
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  const onAddClick = () => {
    ErrorMessageEmptyModel();
    setPaymentId("");
    setLoading(true);
    setPaymentModelEmpty();
    paymentModel.paymentDataModel.push(paymentDataModel);
    if (isValidate(paymentDataModel)) {
      PaymentService.getPayment(Number(partnerId), paymentModel)
        .then((response: any) => {
          setPaymentId(response.data);
          // setTimeout(() => {
          //     getLogs(response.data);
          // }, 2000);
          var intervalId = window.setInterval(function () {
            getLogs(response.data);
          }, 2000);
          // clearInterval(intervalId)
        })
        .catch((error) => {
          if (error.response.status === 500) {
            toast.current?.show({
              severity: "error",
              summary: "Oops, Something went wrong!",
              life: 3000,
            });
          } else if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data.errors,
              life: 3000,
            });
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Oops!, Something went wrong.",
              life: 3000,
            });
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  const onRefreshClick = () => {
    getLogs(paymentId);
  };



  return (
    <>
      {" "}
      {pageloading ? (
        <div className="spinner-class">
          <ProgressSpinner />{" "}
        </div>
      ) : (
        <>
          <div>
            <div className="wrapper dashboard">
              <Toast ref={toast}></Toast>{" "}
              <div className="togle-button">
                <div id="content">
                  <Scrollbars
                    className="creatpayment-scroll"
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    autoHeight
                    autoHeightMin={100}
                    autoHeightMax={100}
                    thumbMinSize={30}
                    universal={true}
                  >
                    <div className="heading-section">
                      <span className="text-header-purple">Create Payment</span>
                    </div>{" "}
                    <div className="text-center">
                      {" "}
                      <>
                        {" "}
                        <div className="container-fluid acc-screen createpayment-screen">
                          {" "}
                          <div className="col-md-12 text-left  address-heading">
                            {" "}
                            <span className="text-blue-address">
                              {" "}
                              Payment Information
                            </span>{" "}
                          </div>{" "}
                          <div className="row border-bottom-checkQuoet">
                            {" "}
                            <div className="col-md-6 info-section info-section-border">
                              {" "}
                              <div className=" col-md-12 form-group ">
                                {" "}
                                <span className="input-label">
                                  {" "}
                                  Sending From
                                  <span className="color-red">*</span>{" "}
                                </span>{" "}
                                <AutoComplete
                                  field="Name"
                                  dropdown
                                  forceSelection
                                  aria-label="Countries"
                                  dropdownAriaLabel="USD"
                                  className="dropdown-acc"
                                  placeholder="Select sending from currency"
                                  suggestions={filteredcurrencieslist}
                                  completeMethod={searchCurrency}
                                  onChange={(e) => onFromCurrencyChange(e)}
                                  value={fromcurrencyAutoComplete}
                                />{" "}
                                {sendingfromerrorMessage !== null &&
                                  sendingfromerrorMessage.length > 0 && (
                                    <span className="error-msg">
                                      {" "}
                                      {sendingfromerrorMessage}
                                    </span>
                                  )}
                              </div>{" "}
                              <div className=" col-md-12 form-group ">
                                {" "}
                                <span className="input-label">
                                  {" "}
                                  Transaction Type
                                  <span className="color-red">*</span>{" "}
                                </span>{" "}
                                <AutoComplete
                                  field="name"
                                  dropdown
                                  forceSelection
                                  aria-label="Countries"
                                  dropdownAriaLabel="Payment"
                                  className="dropdown-acc"
                                  placeholder="Select transaction type"
                                  suggestions={filteredtransactiontypelist}
                                  completeMethod={searchTransactionTypes}
                                  onChange={(e) => onTransactioTypeChange(e)}
                                  value={transactiontypeAutoComplete}
                                />{" "}
                                {transactiontypeerrorMessage !== null &&
                                  transactiontypeerrorMessage.length > 0 && (
                                    <span className="error-msg">
                                      {" "}
                                      {transactiontypeerrorMessage}
                                    </span>
                                  )}
                              </div>{" "}
                              <div className=" col-md-12 form-group ">
                                {" "}
                                <span className="input-label">
                                  {" "}
                                  Payment Type
                                  <span className="color-red">*</span>{" "}
                                </span>{" "}
                                <AutoComplete
                                  field="name"
                                  dropdown
                                  forceSelection
                                  aria-label="Countries"
                                  dropdownAriaLabel="Payment"
                                  className="dropdown-acc"
                                  placeholder="Select payment type"
                                  suggestions={filteredpaymenttypelist}
                                  completeMethod={searchPaymentType}
                                  onChange={(e) => onPaymentTypeChange(e)}
                                  value={paymenttypeAutoComplete}
                                />{" "}
                                {paymenttypeerrorMessage !== null &&
                                  paymenttypeerrorMessage.length > 0 && (
                                    <span className="error-msg">
                                      {" "}
                                      {paymenttypeerrorMessage}
                                    </span>
                                  )}
                              </div>{" "}
                              { roleType==="SendExact" ?
                              <div className=" col-md-12 form-group ">
                                {" "}
                                <span className="input-label">
                                  {" "}
                                  Send Amount
                                  <span className="color-red">*</span>{" "}
                                </span>{" "}
                                <input
                                  className="form-control "
                                  type="number"
                                  placeholder="Send Amount"
                                  onKeyDown={(evt) =>
                                    evt.key === "e" && evt.preventDefault()
                                  }
                                  name="sendAmount"
                                  value={
                                    paymentDataModel.paymentInformation
                                      .sendAmount
                                  }
                                  onChange={(e) => {
                                    setPaymenDataModel({
                                      ...paymentDataModel,
                                      paymentInformation: {
                                        ...paymentDataModel.paymentInformation,
                                        sendAmount: e.target.value,
                                      },
                                    });
                                  }}
                                />{" "}
                                {sendamounterrorMessage !== null &&
                                  sendamounterrorMessage.length > 0 && (
                                    <span className="error-msg">
                                      {" "}
                                      {sendamounterrorMessage}
                                    </span>
                                  )}
                              </div>:
                              <div className=" col-md-12 form-group ">
                                {" "}
                                <span className="input-label">
                                  {" "}
                                  Receive Amount
                                  <span className="color-red">*</span>{" "}
                                </span>{" "}
                                <input
                                  className="form-control "
                                  type="number"
                                  placeholder="Send Amount"
                                  onKeyDown={(evt) =>
                                    evt.key === "e" && evt.preventDefault()
                                  }
                                  name="receiveAmount"
                                  value={
                                    paymentDataModel.paymentInformation
                                      .receiveAmount
                                  }
                                  onChange={(e) => {
                                    setPaymenDataModel({
                                      ...paymentDataModel,
                                      paymentInformation: {
                                        ...paymentDataModel.paymentInformation,
                                        receiveAmount: e.target.value,
                                      },
                                    });
                                  }}
                                />{" "}
                                {receiveamounterrorMessage !== null &&
                                  receiveamounterrorMessage.length > 0 && (
                                    <span className="error-msg">
                                      {" "}
                                      {receiveamounterrorMessage}
                                    </span>
                                  )}
                              </div>}
                              <div className=" col-md-12 form-group">
                                {" "}
                                <span className="input-label">
                                  {" "}
                                  Purpose Of Transaction:
                                  <span className="color-red">*</span>{" "}
                                </span>{" "}
                                <AutoComplete
                                  field="name"
                                  dropdown
                                  forceSelection
                                  aria-label="Countries"
                                  dropdownAriaLabel="IND"
                                  className="dropdown-acc"
                                  placeholder="Select purpose of transaction"
                                  suggestions={filteredpurposeoftransactionlist}
                                  completeMethod={searchPurposeOfTransaction}
                                  onChange={(e) =>
                                    onPurposeOfTransactionChange(e)
                                  }
                                  value={purposeoftransactionAutoComplete}
                                />{" "}
                                {purposeoftransactionerrorMessage !== null &&
                                  purposeoftransactionerrorMessage.length >
                                    0 && (
                                    <span className="error-msg">
                                      {" "}
                                      {purposeoftransactionerrorMessage}
                                    </span>
                                  )}
                              </div>{" "}
                              <div className=" col-md-12 form-group ">
                                {" "}
                                <span className="input-label">
                                  {" "}
                                  Payment Notes:{" "}
                                </span>{" "}
                                <textarea
                                  className="form-control payment-note "
                                  value={
                                    paymentDataModel.paymentInformation
                                      .paymentNotes
                                  }
                                  onChange={(e) => {
                                    setPaymenDataModel({
                                      ...paymentDataModel,
                                      paymentInformation: {
                                        ...paymentDataModel.paymentInformation,
                                        paymentNotes: e.target.value,
                                      },
                                    });
                                  }}
                                />{" "}
                                {paymentnoteserrorMessage !== null &&
                                  paymentnoteserrorMessage.length > 0 && (
                                    <span className="error-msg">
                                      {" "}
                                      {paymentnoteserrorMessage}
                                    </span>
                                  )}
                              </div>{" "}
                            </div>{" "}
                            <div className="col-md-6 info-section-second">
                              {" "}
                              <div className=" col-md-12 form-group">
                                {" "}
                                <span className="input-label">
                                  {" "}
                                  Sending To:
                                  <span className="color-red">*</span>{" "}
                                </span>{" "}
                                <AutoComplete
                                  field="Name"
                                  dropdown
                                  forceSelection
                                  aria-label="Countries"
                                  dropdownAriaLabel="INR"
                                  className="dropdown-acc"
                                  placeholder="Select sending to currency"
                                  suggestions={filteredcurrencieslist}
                                  completeMethod={searchCurrency}
                                  onChange={(e) => onToCurrencyChange(e)}
                                  value={tocurrencyAutoComplete}
                                />{" "}
                                {sendingtoerrorMessage !== null &&
                                  sendingtoerrorMessage.length > 0 && (
                                    <span className="error-msg">
                                      {" "}
                                      {sendingtoerrorMessage}
                                    </span>
                                  )}
                              </div>{" "}
                              <div className=" col-md-12 form-group ">
                                {" "}
                                <span className="input-label">
                                  {" "}
                                  Routing Preference
                                  <span className="color-red">*</span>{" "}
                                </span>{" "}
                                <AutoComplete
                                  field="name"
                                  dropdown
                                  forceSelection
                                  aria-label="Countries"
                                  dropdownAriaLabel="Smart Preferred Delivery Method"
                                  className="dropdown-acc"
                                  placeholder="Select routing preference"
                                  suggestions={filteredroutingpreferencelist}
                                  completeMethod={searchRoutingPreference}
                                  onChange={(e) => onRoutingPreferenceChange(e)}
                                  value={routingpreferenceAutoComplete}
                                />{" "}
                                {preferredtypeerrorMessage !== null &&
                                  preferredtypeerrorMessage.length > 0 && (
                                    <span className="error-msg">
                                      {" "}
                                      {preferredtypeerrorMessage}
                                    </span>
                                  )}
                              </div>{" "}
                              <div className=" col-md-12 form-group ">
                                {" "}
                                {paymentDataModel.paymentInformation
                                  .routingPreferences.preferredType ===
                                  "SmartQuote" ||
                                paymentDataModel.paymentInformation
                                  .routingPreferences.preferredType ===
                                  "SmartSpeed" ? (
                                  <div className="col-md-12 form group check-quets-visibility-none">
                                    {" "}
                                    <span className="input-label">
                                      ***
                                    </span>{" "}
                                    <input type="text" />{" "}
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    {paymentDataModel.paymentInformation
                                      .routingPreferences.preferredType ===
                                      "PreferredPaymentProvider" ||
                                    paymentDataModel.paymentInformation
                                      .routingPreferences.preferredType ===
                                      "PreferredQuoteID" ? (
                                      <>
                                        {" "}
                                        <span className="input-label">
                                          {" "}
                                          Preferred Value
                                          <span className="color-red">
                                            *
                                          </span>{" "}
                                        </span>{" "}
                                        <input
                                          type="text"
                                          className="form-control "
                                          placeholder="Preferred Value"
                                          value={
                                            paymentDataModel.paymentInformation
                                              .routingPreferences.preferredValue
                                          }
                                          onChange={(e) => {
                                            setPaymenDataModel({
                                              ...paymentDataModel,
                                              paymentInformation: {
                                                ...paymentDataModel.paymentInformation,
                                                routingPreferences: {
                                                  ...paymentDataModel
                                                    .paymentInformation
                                                    .routingPreferences,
                                                  preferredValue:
                                                    e.target.value,
                                                },
                                              },
                                            });
                                          }}
                                        />{" "}
                                        {preferredvalueerrorMessage !== null &&
                                          preferredvalueerrorMessage.length >
                                            0 && (
                                            <span className="error-msg">
                                              {" "}
                                              {preferredvalueerrorMessage}
                                            </span>
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        {paymentDataModel.paymentInformation
                                          .routingPreferences.preferredType ===
                                        "SmartPreferredDelivery" ? (
                                          <>
                                            {" "}
                                            <span className="input-label">
                                              {" "}
                                              Preferred Delivery Type
                                              <span className="color-red">
                                                *
                                              </span>{" "}
                                            </span>{" "}
                                            <AutoComplete
                                              field="name"
                                              dropdown
                                              forceSelection
                                              aria-label="Countries"
                                              dropdownAriaLabel="Payment"
                                              className="dropdown-acc"
                                              placeholder="Select preferred value"
                                              suggestions={
                                                filteredpreferredvaluelist
                                              }
                                              completeMethod={
                                                searchPreferedValue
                                              }
                                              onChange={(e) =>
                                                onPreferredValueChange(e)
                                              }
                                              value={preferredvalueAutoComplete}
                                            />{" "}
                                            {preferredvalueerrorMessage !==
                                              null &&
                                              preferredvalueerrorMessage.length >
                                                0 && (
                                                <span className="error-msg">
                                                  {" "}
                                                  {preferredvalueerrorMessage}
                                                </span>
                                              )}
                                          </>
                                        ) : (
                                          <div className="col-md-12 form group check-quets-visibility-none">
                                            {" "}
                                            <span className="input-label">
                                              ***
                                            </span>{" "}
                                            <input type="text" />{" "}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>{" "}
                              <div className=" col-md-12 form-group">
                                {" "}
                                <span className="input-label">
                                  {" "}
                                  Source Of Funds:
                                  <span className="color-red">*</span>{" "}
                                </span>{" "}
                                <AutoComplete
                                  field="name"
                                  dropdown
                                  forceSelection
                                  aria-label="Countries"
                                  dropdownAriaLabel="IND"
                                  className="dropdown-acc"
                                  placeholder="Select source of fund"
                                  suggestions={filteredsourceoffundlist}
                                  completeMethod={searchSourceOfFunds}
                                  onChange={(e) => onSourceOfFundChange(e)}
                                  value={sourceoffundsAutoComplete}
                                />{" "}
                                {sourceoffunderrorMessage !== null &&
                                  sourceoffunderrorMessage.length > 0 && (
                                    <span className="error-msg">
                                      {" "}
                                      {sourceoffunderrorMessage}
                                    </span>
                                  )}
                              </div>{" "}
                              <div className=" col-md-12 form-group">
                                {" "}
                                <span className="input-label">
                                  {" "}
                                  Relationship :
                                  <span className="color-red">*</span>{" "}
                                </span>{" "}
                                <AutoComplete
                                  field="name"
                                  dropdown
                                  forceSelection
                                  aria-label="Countries"
                                  dropdownAriaLabel="IND"
                                  className="dropdown-acc"
                                  placeholder="Select relationship"
                                  suggestions={filteredrelationshiplist}
                                  completeMethod={searchRelationship}
                                  onChange={(e) => onRelationshipChange(e)}
                                  value={relationshipAutoComplete}
                                />{" "}
                                {relationshiperrorMessage !== null &&
                                  relationshiperrorMessage.length > 0 && (
                                    <span className="error-msg">
                                      {" "}
                                      {relationshiperrorMessage}
                                    </span>
                                  )}
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                          <div className="row payment-type-section">
                            {" "}
                            <div className="col-md-6 info-section info-section-border">
                              {" "}
                              {paymentDataModel.paymentInformation
                                .paymentType === "C2C" ||
                              paymentDataModel.paymentInformation
                                .paymentType === "C2B" ? (
                                <>
                                  {" "}
                                  <div className="col-md-12 address-heading">
                                    {" "}
                                    <span className="text-blue-address">
                                      {" "}
                                      Sender
                                    </span>{" "}
                                  </div>{" "}
                                  <div className=" col-md-12 form-group ">
                                    {" "}
                                    <span className="input-label">
                                      {" "}
                                      First Name{" "}
                                      <span className="color-red">*</span>{" "}
                                    </span>{" "}
                                    <input
                                      className="form-control "
                                      type="text"
                                      name="senderFristName"
                                      autoComplete="nope"
                                      placeholder="Enter first name"
                                      value={
                                        paymentDataModel.senderInformation
                                          .firstName
                                      }
                                      onChange={(e) => {
                                        setPaymenDataModel({
                                          ...paymentDataModel,
                                          senderInformation: {
                                            ...paymentDataModel.senderInformation,
                                            firstName: e.target.value,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    {senderfirstnameerrorMessage !== null &&
                                      senderfirstnameerrorMessage.length >
                                        0 && (
                                        <span className="error-msg">
                                          {" "}
                                          {senderfirstnameerrorMessage}
                                        </span>
                                      )}
                                  </div>{" "}
                                  <div className=" col-md-12 form-group ">
                                    {" "}
                                    <span className="input-label">
                                      {" "}
                                      Last Name
                                      <span className="color-red">*</span>{" "}
                                    </span>{" "}
                                    <input
                                      className="form-control "
                                      type="text"
                                      name="senderLastName"
                                      autoComplete="nope"
                                      placeholder="Enter last name"
                                      value={
                                        paymentDataModel.senderInformation
                                          .lastName
                                      }
                                      onChange={(e) => {
                                        setPaymenDataModel({
                                          ...paymentDataModel,
                                          senderInformation: {
                                            ...paymentDataModel.senderInformation,
                                            lastName: e.target.value,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    {senderlastnameerrorMessage !== null &&
                                      senderlastnameerrorMessage.length > 0 && (
                                        <span className="error-msg">
                                          {" "}
                                          {senderlastnameerrorMessage}
                                        </span>
                                      )}
                                  </div>{" "}
                                  <div className="col-md-12 form-group ">
                                    {" "}
                                    <span className="input-label">
                                      {" "}
                                      Country
                                      <span className="color-red">*</span>{" "}
                                    </span>{" "}
                                    <AutoComplete
                                      field="name"
                                      dropdown
                                      forceSelection
                                      aria-label="Countries"
                                      dropdownAriaLabel="IND"
                                      className="dropdown-acc"
                                      placeholder="Select country"
                                      suggestions={filteredcountrieslist}
                                      completeMethod={searchCountry}
                                      onChange={(e) => onCountryChange(e)}
                                      value={countryAutoComplete}
                                    />{" "}
                                    {sendercountryerrorMessage !== null &&
                                      sendercountryerrorMessage.length > 0 && (
                                        <span className="error-msg">
                                          {" "}
                                          {sendercountryerrorMessage}
                                        </span>
                                      )}
                                  </div>{" "}
                                  <div className="col-md-12 form-group ">
                                    {" "}
                                    <span className="input-label">
                                      {" "}
                                      Phone Number
                                      <span className="color-red">*</span>{" "}
                                    </span>{" "}
                                    <PhoneInput
                                      className="form-control Phone-input"
                                      type="text"
                                      initialValueFormat="national"
                                      addInternationalOption={false}
                                      countryCallingCodeEditable={true}
                                      international={false}
                                      placeholder="Enter sender phone number"
                                      defaultCountry="US"
                                      name="senderPhoneNumber"
                                      autoComplete="nope"
                                      value={phonenovalue}
                                      onChange={(e: any) => {
                                        setPhoneNovalue(e!);
                                      }}
                                    />{" "}
                               
                                    {senderphonenoerrorMessage !== null &&
                                      senderphonenoerrorMessage.length > 0 && (
                                        <span className="error-msg">
                                          {" "}
                                          {senderphonenoerrorMessage}
                                        </span>
                                      )}
                                  </div>{" "}
                                  <div className="col-md-12 form-group ">
                                    {" "}
                                    <span className="input-label">
                                      {" "}
                                      Email Address{" "}
                                      <span className="color-red">*</span>{" "}
                                    </span>{" "}
                                    <input
                                      className="form-control"
                                      type="email"
                                      name="senderEmailAddress"
                                      autoComplete="nope"
                                      placeholder="Enter email address"
                                      value={
                                        paymentDataModel.senderInformation
                                          .emailAddress
                                      }
                                      onChange={(e) => {
                                        setPaymenDataModel({
                                          ...paymentDataModel,
                                          senderInformation: {
                                            ...paymentDataModel.senderInformation,
                                            emailAddress: e.target.value,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    {senderemailerrorMessage !== null &&
                                      senderemailerrorMessage.length > 0 && (
                                        <span className="error-msg">
                                          {" "}
                                          {senderemailerrorMessage}
                                        </span>
                                      )}
                                  </div>{" "}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {paymentDataModel.paymentInformation
                                    .paymentType === "B2B" ||
                                  paymentDataModel.paymentInformation
                                    .paymentType === "B2C" ? (
                                    <>
                                      {" "}
                                      <div className="col-md-12 address-heading">
                                        {" "}
                                        <span className="text-blue-address">
                                          {" "}
                                          Sender
                                        </span>{" "}
                                      </div>{" "}
                                      <div className="col-md-12 form-group ">
                                        {" "}
                                        <span className="input-label">
                                          {" "}
                                          Business Name{" "}
                                          <span className="color-red">
                                            *
                                          </span>{" "}
                                        </span>{" "}
                                        <input
                                          className="form-control"
                                          type="text"
                                          name="senderBusinessName"
                                          autoComplete="nope"
                                          placeholder="Enter business name"
                                          value={
                                            paymentDataModel.senderInformation
                                              .businessName
                                          }
                                          onChange={(e) => {
                                            setPaymenDataModel({
                                              ...paymentDataModel,
                                              senderInformation: {
                                                ...paymentDataModel.senderInformation,
                                                businessName: e.target.value,
                                              },
                                            });
                                          }}
                                        />{" "}
                                        {senderbusinessnameerrorMessage !==
                                          null &&
                                          senderbusinessnameerrorMessage.length >
                                            0 && (
                                            <span className="error-msg">
                                              {" "}
                                              {senderbusinessnameerrorMessage}
                                            </span>
                                          )}
                                      </div>{" "}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </div>{" "}
                            <div className="col-md-6 info-section-second">
                              {" "}
                              {paymentDataModel.paymentInformation
                                .paymentType === "C2C" ||
                              paymentDataModel.paymentInformation
                                .paymentType === "B2C" ? (
                                <>
                                  {" "}
                                  <div className="col-md-12 address-heading">
                                    {" "}
                                    <span className="text-blue-address">
                                      {" "}
                                      Recipient
                                    </span>{" "}
                                  </div>{" "}
                                  <div className=" col-md-12 form-group ">
                                    {" "}
                                    <span className="input-label">
                                      {" "}
                                      First Name{" "}
                                      <span className="color-red">*</span>{" "}
                                    </span>{" "}
                                    <input
                                      className="form-control "
                                      type="text"
                                      placeholder="Enter first name"
                                      name="recipientFirstName"
                                      autoComplete="nope"
                                      value={
                                        paymentDataModel.receiverInformation
                                          .firstName
                                      }
                                      onChange={(e) => {
                                        setPaymenDataModel({
                                          ...paymentDataModel,
                                          receiverInformation: {
                                            ...paymentDataModel.receiverInformation,
                                            firstName: e.target.value,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    {recipientfirstnameerrorMessage !== null &&
                                      recipientfirstnameerrorMessage.length >
                                        0 && (
                                        <span className="error-msg">
                                          {" "}
                                          {recipientfirstnameerrorMessage}
                                        </span>
                                      )}
                                  </div>{" "}
                                  <div className=" col-md-12 form-group ">
                                    {" "}
                                    <span className="input-label">
                                      {" "}
                                      Last Name
                                      <span className="color-red">*</span>{" "}
                                    </span>{" "}
                                    <input
                                      className="form-control "
                                      type="text"
                                      placeholder="Enter last name"
                                      name="recipientLastName"
                                      autoComplete="nope"
                                      value={
                                        paymentDataModel.receiverInformation
                                          .lastName
                                      }
                                      onChange={(e) => {
                                        setPaymenDataModel({
                                          ...paymentDataModel,
                                          receiverInformation: {
                                            ...paymentDataModel.receiverInformation,
                                            lastName: e.target.value,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    {recipientlastnameerrorMessage !== null &&
                                      recipientlastnameerrorMessage.length >
                                        0 && (
                                        <span className="error-msg">
                                          {" "}
                                          {recipientlastnameerrorMessage}
                                        </span>
                                      )}
                                  </div>{" "}
                                  <div className="col-md-12 form-group ">
                                    {" "}
                                    <span className="input-label">
                                      {" "}
                                      Phone Number
                                      <span className="color-red">*</span>{" "}
                                    </span>{" "}
                                    <PhoneInput
                                      className="form-control Phone-input"
                                      type="text"
                                      initialValueFormat="national"
                                      addInternationalOption={false}
                                      countryCallingCodeEditable={true}
                                      international={false}
                                      placeholder="Enter recipient phone number"
                                      defaultCountry="US"
                                      name="recipientPhoneNumber"
                                      autoComplete="nope"
                                      value={recipientphonenovalue}
                                      onChange={(e: any) => {
                                        setRecipientPhoneNovalue(e!);
                                      }}
                                    />{" "}
                                    {recipientphonenoerrorMessage !== null &&
                                      recipientphonenoerrorMessage.length >
                                        0 && (
                                        <span className="error-msg">
                                          {" "}
                                          {recipientphonenoerrorMessage}
                                        </span>
                                      )}
                                  </div>{" "}
                                  <div className="col-md-12 form-group ">
                                    {" "}
                                    <span className="input-label">
                                      {" "}
                                      Email Address{" "}
                                      <span className="color-red">*</span>{" "}
                                    </span>{" "}
                                    <input
                                      className="form-control"
                                      type="email"
                                      placeholder="Enter email address"
                                      name="recipientEmailAddress"
                                      autoComplete="nope"
                                      value={
                                        paymentDataModel.receiverInformation
                                          .emailAddress
                                      }
                                      onChange={(e) => {
                                        setPaymenDataModel({
                                          ...paymentDataModel,
                                          receiverInformation: {
                                            ...paymentDataModel.receiverInformation,
                                            emailAddress: e.target.value,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    {recipientemailerrorMessage !== null &&
                                      recipientemailerrorMessage.length > 0 && (
                                        <span className="error-msg">
                                          {" "}
                                          {recipientemailerrorMessage}
                                        </span>
                                      )}
                                  </div>{" "}
                                  <div className="col-md-12 form-group ">
                                    {" "}
                                    <span className="input-label">
                                      {" "}
                                      Date of Birth{" "}
                                      <span className="color-red">*</span>{" "}
                                    </span>{" "}
                                    <div className="calendar-style">
                                      {" "}
                                      <Calendar
                                        id="icon"
                                        showIcon
                                        className="calendar-style"
                                        placeholder="mm/dd/yyyy"
                                        dateFormat="mm/dd/yy"
                                        name="dateOfBirth"
                                        value={dateofbirthvalue}
                                        onChange={(e) => {
                                          setDateofbirthvalue(e.target.value);
                                        }}
                                      />{" "}
                                      {recipientdateofbirtherrorMessage !==
                                        null &&
                                        recipientdateofbirtherrorMessage.length >
                                          0 && (
                                          <span className="error-msg">
                                            {" "}
                                            {recipientdateofbirtherrorMessage}
                                          </span>
                                        )}
                                    </div>{" "}
                                  </div>{" "}
                                  {/* <div className="col-md-12 form-group ">
                                    {" "}
                                    <span className="input-label">
                                      {" "}
                                      RTP ID{" "}
                                      <span className="color-red">*</span>{" "}
                                    </span>{" "}
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="Enter RTP id"
                                      name="recipientRTPID"
                                      autoComplete="nope"
                                      value={
                                        paymentDataModel.receiverInformation
                                          .rtpid
                                      }
                                      onChange={(e) => {
                                        setPaymenDataModel({
                                          ...paymentDataModel,
                                          receiverInformation: {
                                            ...paymentDataModel.receiverInformation,
                                            rtpid: e.target.value,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    {recipientrtpiderrorMessage !== null &&
                                      recipientrtpiderrorMessage.length > 0 && (
                                        <span className="error-msg">
                                          {" "}
                                          {recipientrtpiderrorMessage}
                                        </span>
                                      )}
                                  </div>{" "}
                                  <div className="col-md-12 form-group ">
                                    {" "}
                                    <span className="input-label">
                                      {" "}
                                      RTP Name{" "}
                                      <span className="color-red">*</span>{" "}
                                    </span>{" "}
                                    <AutoComplete
                                      field="name"
                                      dropdown
                                      forceSelection
                                      aria-label="Countries"
                                      dropdownAriaLabel="IND"
                                      className="dropdown-acc"
                                      placeholder="Select RTP name"
                                      suggestions={filteredrtpnamelist}
                                      completeMethod={searchRTPName}
                                      onChange={(e) => onRTPNameChange(e)}
                                      value={rtpnameAutoComplete}
                                    />{" "}
                                    {recipientrtpnameerrorMessage !== null &&
                                      recipientrtpnameerrorMessage.length >
                                        0 && (
                                        <span className="error-msg">
                                          {" "}
                                          {recipientrtpnameerrorMessage}
                                        </span>
                                      )}
                                  </div>{" "} */}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {paymentDataModel.paymentInformation
                                    .paymentType === "C2B" ||
                                  paymentDataModel.paymentInformation
                                    .paymentType === "B2B" ? (
                                    <>
                                      {" "}
                                      <div className="col-md-12 address-heading">
                                        {" "}
                                        <span className="text-blue-address">
                                          {" "}
                                          Recipient
                                        </span>{" "}
                                      </div>{" "}
                                      <div className="col-md-12 form-group ">
                                        {" "}
                                        <span className="input-label">
                                          {" "}
                                          Business Name{" "}
                                          <span className="color-red">
                                            *
                                          </span>{" "}
                                        </span>{" "}
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder="Enter business name"
                                          name="recipientBusinessName"
                                          autoComplete="nope"
                                          value={
                                            paymentDataModel.receiverInformation
                                              .businessName
                                          }
                                          onChange={(e) => {
                                            setPaymenDataModel({
                                              ...paymentDataModel,
                                              receiverInformation: {
                                                ...paymentDataModel.receiverInformation,
                                                businessName: e.target.value,
                                              },
                                            });
                                          }}
                                        />{" "}
                                        {recipientbusinessnameerrorMessage !==
                                          null &&
                                          recipientbusinessnameerrorMessage.length >
                                            0 && (
                                            <span className="error-msg">
                                              {" "}
                                              {
                                                recipientbusinessnameerrorMessage
                                              }
                                            </span>
                                          )}
                                      </div>{" "}
                                      <div className="col-md-12 form-group ">
                                        {" "}
                                        <span className="input-label">
                                          {" "}
                                          Business ID{" "}
                                          <span className="color-red">
                                            *
                                          </span>{" "}
                                        </span>{" "}
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder="Enter business id"
                                          name="recipientBusinessID"
                                          autoComplete="nope"
                                          value={
                                            paymentDataModel.receiverInformation
                                              .businessID
                                          }
                                          onChange={(e) => {
                                            setPaymenDataModel({
                                              ...paymentDataModel,
                                              receiverInformation: {
                                                ...paymentDataModel.receiverInformation,
                                                businessID: e.target.value,
                                              },
                                            });
                                          }}
                                        />{" "}
                                        {recipientbusinessiderrorMessage !==
                                          null &&
                                          recipientbusinessiderrorMessage.length >
                                            0 && (
                                            <span className="error-msg">
                                              {" "}
                                              {recipientbusinessiderrorMessage}
                                            </span>
                                          )}
                                      </div>{" "}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </div>{" "}
                          </div>{" "}
                          {errorMessage !== null && errorMessage.length > 0 && (
                            <span className="error-msg">{errorMessage}</span>
                          )}
                          {errorMessageList.length !== 0 && (
                            <>
                              {errorMessageList.map((error) => (
                                <p style={{ display: "block" }}>
                                  <span className="error-msg">
                                    {error.errorMessage}
                                  </span>
                                </p>
                              ))}
                            </>
                          )}
                          <div className="button-section validate">
                            {" "}
                            <div className="bottom-btns">
                              <Button
                                iconPos="left"
                                loading={loading}
                                label="Validate"
                                className="btn btn-continue second-btn"
                                onClick={onValidateClick}
                              />{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </>{" "}
                    </div>{" "}
                  </Scrollbars>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </>
      )}
    </>
  );
};
export default CreatePayment;
