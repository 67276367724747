import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import BRLimg from "../../../assets/images/BRLimg.svg";
import CANADAimg from "../../../assets/images/canada-flag.svg";
import INDimg from "../../../assets/images/india.svg";
import USDlogo from "../../../assets/images/USDImg.svg";
import { CurrenciesService } from "../../../services/Partner/Currencies/Currencies";
import { PartnershipDetailsService } from "../../../services/Partner/PartnershipDetails/PartnershipDetailsService";
import { Logout } from "../../../utils/AccountUtils";
import {
  currenciesPaymentList,
  defaultCurrenciesPaymentList,
} from "../../../utils/utils";
import Scrollbars from "react-custom-scrollbars-2";


function addPartnerCurrenciesData(
  Id: Number,
  Code: null,
  PartnerId: Number,
  Country: String,
  Currency: String,
  PartnerPaymentRole: Number,
  WalletId: null
) {
  return {
    Id,
    Code,
    PartnerId,
    Country,
    Currency,
    PartnerPaymentRole,
    WalletId,
  };
}

const Currencies: React.FC<any> = ({
  onSaveAndContinueClick,
  onNextClick,
  buttonLoadingSkip,
  buttonLoadingBack,
  setButtonLoadingBack,
  setButtonLoadingSkip,
  setIsFacilitator
}) => {
  const [currencyerrorMessage, setcurrencyerrorMessage] = React.useState("");
  const id = localStorage.getItem("PartnerId");
  const [readonly, setReadOnly] = useState(false);
  const [status] = useState(localStorage.getItem("OnboardingStatus"));
  const [partnerid] = useState(id);
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [, setDataLength] = useState(0);
  const [currenciesList, setCurrenciesList] = useState(currenciesPaymentList);
  const [freshCurrenciesList] = useState(defaultCurrenciesPaymentList);
  const [partnerPaymentRole, setPartnerPaymentRole] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [displaypopup, setDisplayPopup] = useState(false);
  const [dirtyfield, setDirtyField] = useState(false);
  const defaultHardcodedCurrenciesList: any = [
    {
      Id: 0,
      Country: "USA",
      Currency: "USD",
      Send: false,
      Receive: false,
      WalletId: null,
    },
    {
      Id: 0,
      Country: "IND",
      Currency: "INR",
      Send: false,
      Receive: false,
      WalletId: null,
    },
    {
      Id: 0,
      Country: "BRL",
      Currency: "BRL",
      Send: false,
      Receive: false,
      WalletId: null,
    },
    {
      Id: 0,
      Country: "CAN",
      Currency: "CAD",
      Send: false,
      Receive: false,
      WalletId: null,
    },
  ];

  const onSendChange = (e: any, index: number) => {
    const newList = [...currenciesList];
    const name = e.target.name;
    newList[index].Send = e.value;
    setCurrenciesList([...newList]);
    setDirtyField(true);
  };

  const isValidate = (currencies: any) => {
    let formIsValid = true;
    ErrorMessageEmptyModel();
    if (currencies.length === 0) {
      setcurrencyerrorMessage("Please select currency");
      formIsValid = false;
    }
    if (partnerPaymentRole === 1) {
      if (
        !currenciesList[0].Send &&
        !currenciesList[1].Send &&
        !currenciesList[2].Send &&
        !currenciesList[3].Send
      ) {
        setcurrencyerrorMessage("Please select send currencies");
        formIsValid = false;
      }
    }
    if (partnerPaymentRole === 2) {
      if (
        !currenciesList[0].Receive &&
        !currenciesList[1].Receive &&
        !currenciesList[2].Receive &&
        !currenciesList[3].Receive
      ) {
        setcurrencyerrorMessage("Please select receive currencies");
        formIsValid = false;
      }
    }
    if (partnerPaymentRole === 3 || partnerPaymentRole === 0) {
      if (
        !(
          currenciesList[0].Send ||
          currenciesList[1].Send ||
          currenciesList[2].Send ||
          currenciesList[3].Send
        )
      ) {
        setcurrencyerrorMessage("Please select both the currencies");
        formIsValid = false;
      }
      if (
        !(
          currenciesList[0].Receive ||
          currenciesList[1].Receive ||
          currenciesList[2].Receive ||
          currenciesList[3].Receive
        )
      ) {
        setcurrencyerrorMessage("Please select both the currencies");
        formIsValid = false;
      }
      if (
        !currenciesList[0].Send &&
        !currenciesList[1].Send &&
        !currenciesList[2].Send &&
        !currenciesList[3].Send &&
        !currenciesList[0].Receive &&
        !currenciesList[1].Receive &&
        !currenciesList[2].Receive &&
        !currenciesList[3].Receive
      ) {
        setcurrencyerrorMessage("Please select currencies");
        formIsValid = false;
      }
    }
    return formIsValid;
  };
  const onReceiveChange = (e: any, index: number) => {
    const newList = [...currenciesList];
    const name = e.target.name;
    newList[index].Receive = e.value;
    setCurrenciesList([...newList]);
    setDirtyField(true);
  };
  const handleClose = () => {
    if (dirtyfield === true) {
      setDisplayPopup(true);
    }

  };

  const OnPopupClose = () => {
    setDisplayPopup(false);
  };
  const OnPopupOk = () => {
    getCurrenciesByPartnerId(Number(partnerid));
    setDisplayPopup(false);
    setDirtyField(false);
    ErrorMessageEmptyModel();
  };
  const getCurrenciesByPartnerId = (partnerid: any) => {
    setLoading(true);

    CurrenciesService.getCurrenciesByPartnerId(partnerid)
      .then((data: any) => {
        const responsedata = data.data;
        if (responsedata.length === 0) {
          setDataLength(0);
          setCurrenciesList(defaultHardcodedCurrenciesList);
          setLoading(false);
        } else {
          setDataLength(1);
          const sendReceiveData = responsedata
            .filter((response: any) => response.partnerPaymentRole === 3)
            .map((sendreceive: any) => ({
              Id: sendreceive.id,
              Country: sendreceive.country,
              Code: sendreceive.code,
              Currency: sendreceive.currency,
              Send: true,
              Receive: true,
              WalletId: sendreceive.walletId,
            }));
          const sendData = responsedata
            .filter((response: any) => response.partnerPaymentRole === 1)
            .map((sendreceive: any) => ({
              Id: sendreceive.id,
              Country: sendreceive.country,
              Code: sendreceive.code,
              Currency: sendreceive.currency,
              Send: true,
              Receive: false,
              WalletId: sendreceive.walletId,
            }));
          const receiveData = responsedata
            .filter((response: any) => response.partnerPaymentRole == 2)
            .map((sendreceive: any) => ({
              Id: sendreceive.id,
              Country: sendreceive.country,
              Code: sendreceive.code,
              Currency: sendreceive.currency,
              Send: false,
              Receive: true,
              WalletId: sendreceive.walletId,
            }));
          const currencyData = [
            ...sendReceiveData,
            ...sendData,
            ...receiveData,
          ];
          const array = defaultHardcodedCurrenciesList;
          const uniqueData = array.filter((x: any) => {
            return !currencyData.find(
              (choice) => choice.Currency === x.Currency
            );
          });
          setCurrenciesList([...currencyData, ...uniqueData]);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };
  const ErrorMessageEmptyModel = () => {
    setcurrencyerrorMessage("");
  };

  const getPartnershipDetailsByPartnerId = () => {
    setLoading(true);
    PartnershipDetailsService.getPartnershipDetailsByPartnerId(
      Number(partnerid)
    )
      .then((response) => {
        const data = response.data;
        setPartnerPaymentRole(data.partnerPaymentRole);
        getCurrenciesByPartnerId(Number(partnerid));
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting partnership details.",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };

  const onAddClick = (data: any) => {
    setButtonLoading(true);
    CurrenciesService.addCurrencies(data)
      .then((data) => {
        setButtonLoading(false);
        localStorage.setItem("StepFlag", "7");
        getCurrenciesByPartnerId(Number(partnerid));
        onSaveAndContinueClick("N");
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else if (error.response.status === 404) {
          setcurrencyerrorMessage("Please select atleast one currency");
        } else if (error.response.status === 409) {
          setcurrencyerrorMessage("Cant update currencies since amount was deposited into ledger.")
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while adding currencies info details.",
            life: 3000,
          });
        }
        setButtonLoading(false);
      });
  };


  const onSaveClick = () => {
    ErrorMessageEmptyModel();
    if (isValidate(currenciesList)) {
      const SendReceiveData = currenciesList
        .filter(
          (currency, index) =>
            currency.Send === true && currency.Receive === true
        )
        .map((currency) =>
          addPartnerCurrenciesData(
            currency.Id,
            null,
            Number(partnerid),
            currency.Country,
            currency.Currency,
            3,
            null
          )
        );
      const SendData = currenciesList
        .filter(
          (currency, index) =>
            currency.Send === true && currency.Receive === false
        )
        .map((currency) =>
          addPartnerCurrenciesData(
            currency.Id,
            null,
            Number(partnerid),
            currency.Country,
            currency.Currency,
            1,
            null
          )
        );
      const ReceiveData = currenciesList
        .filter(
          (currency, index) =>
            currency.Send === false && currency.Receive === true
        )
        .map((currency) =>
          addPartnerCurrenciesData(
            currency.Id,
            null,
            Number(partnerid),
            currency.Country,
            currency.Currency,
            2,
            null
          )
        );

      const currenciesListData = [
        ...SendReceiveData,
        ...SendData,
        ...ReceiveData,
      ];
      onAddClick(currenciesListData);
    }
  };

  const onBackClick = () => {
    setButtonLoadingBack(true);
    setTimeout(() => {
      onSaveAndContinueClick("B");
    }, 1000);
  };
  useEffect(() => {
 
    {
      Number(status) === 8 ? setReadOnly(true) : setReadOnly(false);
    }
    getPartnershipDetailsByPartnerId();
    setIsFacilitator(localStorage.getItem('IsFacilitator'));
    setButtonLoadingBack(false);
    setButtonLoadingSkip(false);
  }, []);

  return (
    <>
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <div className="container-fluid acc-screen currency-screen">
            <Toast ref={toast}></Toast>
            <div className="recevie-currency">
              {partnerPaymentRole === 1 && <h3>Choose your send currencies</h3>}
              {partnerPaymentRole === 2 && (
                <h3>Choose your receive currencies</h3>
              )}
              {partnerPaymentRole === 3 && (
                <h3>Choose your send and receive currencies</h3>
              )}
              {partnerPaymentRole === 0 && (
                <h3>Choose your send and receive currencies</h3>
              )}
              <div className="currency-details">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Currency</th>
                      {(partnerPaymentRole === 1 ||
                        partnerPaymentRole === 3 || partnerPaymentRole === 0) && <th>Send</th>}
                      {(partnerPaymentRole === 2 ||
                        partnerPaymentRole === 3 || partnerPaymentRole === 0) && <th>Receive</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {currenciesList
                      .sort((a, b) => (a.Currency > b.Currency ? -1 : 1))
                      .map((currency, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="border-right country-flag">
                            {(() => {
                              if (currency.Currency === "INR") {
                                return (
                                  <img
                                    src={INDimg}
                                    alt="img"
                                    className="currency-img"
                                  />
                                );
                              } else if (currency.Currency === "BRL") {
                                return (
                                  <img
                                    src={BRLimg}
                                    alt="img"
                                    className="currency-img"
                                  />
                                );
                              } else if (currency.Currency === "CAD") {
                                return (
                                  <img
                                    src={CANADAimg}
                                    alt="img"
                                    className="currency-img"
                                  />
                                );
                              } else {
                                return (
                                  <img
                                    src={USDlogo}
                                    alt="img"
                                    className="currency-img"
                                  />
                                );
                              }
                            })()}

                            {currency.Currency}
                          </td>
                          {(partnerPaymentRole === 1 ||
                            partnerPaymentRole === 3 || partnerPaymentRole === 0) && (
                              <td className="switch-style">
                                {/* <input type="checkbox" className="currenecy-check"/> */}
                                <InputSwitch
                                  disabled={readonly}
                                  className="status-check"
                                  name="Send"
                                  checked={currency.Send}
                                  onChange={(e) => onSendChange(e, index)}
                                />
                              </td>
                            )}

                          {(partnerPaymentRole === 2 ||
                            partnerPaymentRole === 3 || partnerPaymentRole === 0) && (
                              <td className="switch-style">
                                <InputSwitch
                                  disabled={readonly}
                                  className="status-check"
                                  name="Receive"
                                  checked={currency.Receive}
                                  onChange={(e) => onReceiveChange(e, index)}
                                />
                              </td>
                            )}
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="fees-error">
                  <span className="error-msg">{currencyerrorMessage}</span>
                </div>
              </div>
            </div>
            <div className="payment-button-section">
              <div className="bottom-btns">
                <Button
                  iconPos="left"
                  label="Back"
                  loading={buttonLoadingBack}
                  onClick={onBackClick}
                  className="btn btn-back second-btn"
                />
                {Number(status) === 8 ? (
                  <>
                    <Button
                      iconPos="left"
                      label="Next"
                      loading={buttonLoadingSkip}
                      onClick={onNextClick}
                      className="btn btn-continue   btn-next second-btn"
                    />
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-back second-btn"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>

                    <Button
                      iconPos="left"
                      label=" Save and Continue"
                      className=" btn btn-continue second-btn"
                      loading={buttonLoading}
                      onClick={() => onSaveClick()}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          {displaypopup ? (
            <>
              <div className="popup-body">
                <div className="Cancel-popup">
                  <div className="text-center ">
                    <div className="awesome-text">
                      <h4>Are you sure you want to cancel?</h4>
                      <p> All unsaved changes will be lost.</p>
                    </div>
                  </div>
                  <div className="payment-screen-btn">
                    <button
                      className="btn btn-cancel second-btn "
                      onClick={OnPopupClose}
                    >
                      {" "}
                      No
                    </button>
                    <button
                      className="btn btn-continue second-btn yes-btn-popup"
                      onClick={OnPopupOk}
                    >
                      {" "}
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default Currencies;
