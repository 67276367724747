import moment from "moment";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { PaymentService } from "../../../services/Payment/PaymentService";
import { Logout } from "../../../utils/AccountUtils";
import {
  BankAccountTypeList,
  BusinessTypeList,
  MobileWalletNameList,
  NationalIDTypeList,
  RTPNameList,
  countryList
} from "../../../utils/utils";

const AddPaymentFields: React.FC = () => {
  const paymentOldDataModel = localStorage.getItem("PaymentModel");

  const [paymentModel, setPaymentModel] = useState(
    JSON.parse(paymentOldDataModel)
  );

  const messageInfos = localStorage.getItem("MessageInfos");

  const [MessageInfos, setMessageInfos] = useState(JSON.parse(messageInfos));

  const [paymentId, setPaymentId] = useState("");

  const [phonenovalue, setPhoneNovalue] = useState(null);

  const [recipientphonenovalue, setRecipientPhoneNovalue] = useState(null);

  const [statusCodeList, setStatusCodeList] = useState([]);

  const id = localStorage.getItem("PartnerId");

  const [partnerId, setPartnerId] = useState(id);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [buttonloading, setButtonLoading] = useState(false);

  const [paymentloglist, setPaymentLogList] = useState<any[]>([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageList, setErrorMessageList] = useState([]);
  const toast = useRef<Toast>(null);

  const [show, setShow] = useState(false);

  const [nationalidexpirydatevalue, setNationalIDExpiryDatevalue] =
    useState(null);

  const [
    recipientnationalidexpirydatevalue,
    setRecipientNationalIDExpiryDatevalue,
  ] = useState(null);

  const [dateofbirthvalue, setDateofbirthvalue] = useState(null);

  const [recipientdateofbirthvalue, setRecipientDateofbirthvalue] =
    useState(null);

  const [filteredcountrieslist, setFilteredCountryList] = useState<any[]>([]);

  const [sendercountryAutoComplete, setSenderCountryAutoComplete] =
    useState("");

  const [recipientcountryAutoComplete, setRecipientCountryAutoComplete] =
    useState("");

  const [
    sendernationalidissuingcountryAutoComplete,
    setSenderNationalIDIssuingCountryAutoComplete,
  ] = useState("");

  const [
    recipientnationalidissuingcountryAutoComplete,
    setRecipientNationalIDIssuingCountryAutoComplete,
  ] = useState("");

  const [
    sendercountryofbirthAutoComplete,
    setSenderCountryOfBirthAutoComplete,
  ] = useState("");

  const [
    recipientcountryofbirthAutoComplete,
    setRecipientCountryOfBirthAutoComplete,
  ] = useState("");

  const [filterednationalIdTypelist, setFilteredNationalIdTypeList] = useState<
    any[]
  >([]);

  const [
    sendernationalIdTypeAutoComplete,
    setSenderNationalIdTypeAutoComplete,
  ] = useState("");

  const [
    recipientnationalIdTypeAutoComplete,
    setRecipientNationalIdTypeAutoComplete,
  ] = useState("");

  const [filteredmobilewalletlist, setFilteredMobileWalletList] = useState<
    any[]
  >([]);

  const [sendermobilewalletAutoComplete, setSenderMobileWalletAutoComplete] =
    useState("");

  const [
    recipientmobilewalletAutoComplete,
    setRecipientMobileWalletAutoComplete,
  ] = useState("");

  const [filteredrtpnamelist, setFilteredRTPNameList] = useState<any[]>([]);

  const [senderrtpnameAutoComplete, setSenderRTPNameAutoComplete] =
    useState("");

  const [recipientrtpnameAutoComplete, setRecipientRTPNameAutoComplete] =
    useState("");

  const [filteredbankaccounttypelist, setFilteredBankAccountTypeList] =
    useState<any[]>([]);

  const [
    senderbankaccounttypeAutoComplete,
    setSenderBankAccountTypeAutoComplete,
  ] = useState("");

  const [
    recipientbankaccounttypeAutoComplete,
    setRecipientBankAccountTypeAutoComplete,
  ] = useState("");

  const [filteredbusinesstypelist, setFilteredBusinessTypeList] = useState<
    any[]
  >([]);

  const [senderbusinesstypeAutoComplete, setSenderBusinessTypeAutoComplete] =
    useState("");

  const [
    recipientbusinesstypeAutoComplete,
    setRecipientBusinessTypeAutoComplete,
  ] = useState("");

  const [senderNamePrefixErrorMessage, setSenderNamePrefixErrorMessage] =
    useState("");

  const [senderFirstNameErrorMessage, setSenderFirstNameErrorMessage] = useState("");

  const [senderLastNameErrorMessage, setSenderLastNameErrorMessage] = useState("");

  const [senderMiddleNameErrorMessage, setSenderMiddleNameErrorMessage] = useState("");

  const [senderMaternalLastNameErrorMessage, setSenderMaternalLastNameErrorMessage,] = useState("");

  const [senderBusinessNameErrorMessage, setSenderBusinessNameErrorMessage] = useState("");

  const [senderPaternalLastNameErrorMessage, setSenderPaternalLastNameErrorMessage] = useState("");

  const [senderBusinessTypeErrorMessage, setSenderBusinessTypeErrorMessage] = useState("");

  const [senderBusinessIDErrorMessage, setSenderBusinessIDErrorMessage] = useState("");

  const [senderPhoneNumberErrorMessage, setSenderPhoneNumberErrorMessage] = useState("");

  const [senderEmailAddressErrorMessage, setSenderEmailAddressErrorMessage] = useState("");

  const [senderPostalAddressLineErrorMessage, setSenderPostalAddressLineErrorMessage,] = useState("");

  const [senderBuildingNumberErrorMessage, setSenderBuildingNumberErrorMessage,] = useState("");

  const [senderTownNameErrorMessage, setSenderTownNameErrorMessage] = useState("");

  const [senderProvinceErrorMessage, setSenderProvinceErrorMessage] = useState("");

  const [senderPostalCodeErrorMessage, setSenderPostalCodeErrorMessage] = useState("");

  const [senderCountryErrorMessage, setSenderCountryErrorMessage] = useState("");

  const [senderNationalIDIssuingCountryErrorMessage, setSenderNationalIDIssuingCountryErrorMessage,] = useState("");

  const [senderNationalIDErrorMessage, setSenderNationalIDErrorMessage] = useState("");

  const [senderNationalIDTypeErrorMessage, setSenderNationalIDTypeErrorMessage,] = useState("");

  const [senderNationalIDExpiryDateErrorMessage, setSenderNationalIDExpiryDateErrorMessage,] = useState("");

  const [senderDateOfBirthErrorMessage, setSenderDateOfBirthErrorMessage] = useState("");

  const [senderTownOfBirthErrorMessage, setSenderTownOfBirthErrorMessage] = useState("");

  const [senderProvinceOfBirthErrorMessage, setSenderProvinceOfBirthErrorMessage,] = useState("");

  const [senderCountryOfBirthErrorMessage, setSenderCountryOfBirthErrorMessage,] = useState("");

  const [senderRTPIDErrorMessage, setSenderRTPIDErrorMessage] = useState("");

  const [senderRTPNameErrorMessage, setSenderRTPNameErrorMessage] = useState("");

  const [senderMobileWalletNameErrorMessage, setSenderMobileWalletNameErrorMessage,] = useState("");

  const [senderMobileWalletIDErrorMessage, setSenderMobileWalletIDErrorMessage] = useState("");

  const [senderBankIBANErrorMessage, setSenderBankIBANErrorMessage] = useState("");

  const [senderBankBICErrorMessage, setSenderBankBICErrorMessage] = useState("");

  const [senderBankAccountNumberErrorMessage, setSenderBankAccountNumberErrorMessage,] = useState("");

  const [senderBankRoutingNumberErrorMessage, setSenderBankRotingNumberErrorMessage,] = useState("");

  const [senderBankSortCodeErrorMessage, setSenderBankSortCodeErrorMessage] = useState("");

  const [senderBankAccountTypeErrorMessage, setSenderBankAccountTypeErrorMessage] = useState("");

  const [senderBankBranchNameErrorMessage, setSenderBankBranchNameErrorMessage] = useState("");

  const [senderBankBranchNumberErrorMessage, setSenderBankBranchNumberErrorMessage] = useState("");

  const [senderBankBranchAddressErrorMessage, setSenderBankBranchAddressErrorMessage] = useState("");

  const [senderCardNumberErrorMessage, setSenderCardNumberErrorMessage] = useState("");

  const [senderReferenceNumberErrorMessage, setSenderReferenceNumberErrorMessage] = useState("");

  const [recipientFirstNameErrorMessage, setRecipientFirstNameErrorMessage] = useState("");

  const [recipientNamePrefixErrorMessage, setRecipientNamePrefixErrorMessage] = useState("");

  const [recipientLastNameErrorMessage, setRecipientLastNameErrorMessage] = useState("");

  const [recipientMiddleNameErrorMessage, setRecipientMiddleNameErrorMessage] = useState("");

  const [recipientMaternalLastNameErrorMessage, setRecipientMaternalLastNameErrorMessage] = useState("");

  const [recipientBusinessNameErrorMessage, setRecipientBusinessNameErrorMessage] = useState("");

  const [recipientPaternalLastNameErrorMessage, setRecipientPaternalLastNameErrorMessage] = useState("");

  const [recipientBusinessTypeErrorMessage, setRecipientBusinessTypeErrorMessage] = useState("");

  const [recipientBusinessIDErrorMessage, setRecipientBusinessIDErrorMessage] = useState("");

  const [recipientPhoneNumberErrorMessage, setRecipientPhoneNumberErrorMessage] = useState("");

  const [recipientEmailAddressErrorMessage, setRecipientEmailAddressErrorMessage] = useState("");

  const [recipientPostalAddressLineErrorMessage, setRecipientPostalAddressLineErrorMessage] = useState("");

  const [recipientTownNameErrorMessage, setRecipientTownNameErrorMessage] = useState("");

  const [recipientProvinceErrorMessage, setRecipientProvinceErrorMessage] = useState("");

  const [recipientPostalCodeErrorMessage, setRecipientPostalCodeErrorMessage] = useState("");

  const [recipientCountryErrorMessage, setRecipientCountryErrorMessage] = useState("");

  const [recipientNationalIDIssuingCountryErrorMessage, setRecipientNationalIDIssuingCountryErrorMessage] = useState("");

  const [recipientNationalIDErrorMessage, setRecipientNationalIDErrorMessage] = useState("");

  const [recipientNationalIDTypeErrorMessage, setRecipientNationalIDTypeErrorMessage] = useState("");

  const [recipientNationalIDExpiryDateErrorMessage, setRecipientNationalIDExpiryDateErrorMessage] = useState("");

  const [recipientDateOfBirthErrorMessage, setRecipientDateOfBirthErrorMessage] = useState("");

  const [recipientTownOfBirthErrorMessage, setRecipientTownOfBirthErrorMessage] = useState("");

  const [recipientProvinceOfBirthErrorMessage, setRecipientProvinceOfBirthErrorMessage] = useState("");

  const [recipientCountryOfBirthErrorMessage, setRecipientCountryOfBirthErrorMessage,] = useState("");

  const [recipientRTPIDErrorMessage, setRecipientRTPIDErrorMessage] = useState("");

  const [recipientRTPNameErrorMessage, setRecipientRTPNameErrorMessage] = useState("");

  const [recipientMobileWalletNameErrorMessage, setRecipientMobileWalletNameErrorMessage] = useState("");

  const [recipientMobileWalletIDErrorMessage, setRecipientMobileWalletIDErrorMessage] = useState("");

  const [recipientBankIBANErrorMessage, setRecipientBankIBANErrorMessage] = useState("");

  const [recipientBankBICErrorMessage, setRecipientBankBICErrorMessage] = useState("");

  const [recipientBankAccountNumberErrorMessage, setRecipientBankAccountNumberErrorMessage] = useState("");

  const [recipientBankRoutingNumberErrorMessage, setRecipientBankRotingNumberErrorMessage] = useState("");

  const [recipientBankSortCodeErrorMessage, setRecipientBankSortCodeErrorMessage] = useState("");

  const [recipientBankAccountTypeErrorMessage, setRecipientBankAccountTypeErrorMessage] = useState("");



  const [recipientBankBranchNameErrorMessage, setRecipientBankBranchNameErrorMessage] = useState("");

  const [recipientBankBranchNumberErrorMessage, setRecipientBankBranchNumberErrorMessage] = useState("");

  const [recipientBankBranchAddressErrorMessage, setRecipientBankBranchAddressErrorMessage] = useState("");

  const [recipientCardNumberErrorMessage, setRecipientCardNumberErrorMessage] = useState("");

  const [recipientReferenceNumberErrorMessage, setRecipientReferenceNumberErrorMessage] = useState("");

  const [recipientBuildingNumberErrorMessage, setRecipientBuildingNumberErrorMessage,] = useState("");


  //payment data model 
  const [paymentDataModel, setPaymenDataModel] = React.useState({
    paymentInformation: {
      paymentID: null,
      sendAmount: null,
      receiveAmount: null,
      requestedDeliveryDate: null,
      sendCurrency: null,
      receiveCurrency: null,
      paymentType: null,
      transactionType: null,
      purposeOfTransaction: null,
      sourceOfFunds: null,
      relationship: null,
      callbackURL: null,
      routingPreferences: {
        preferredType: null,
        preferredValue: null,
      },
      paymentNotes: null,
      recurringFlag: false,
    },
    senderInformation: {
      bankAccountNumber: null,
      bankAccountType: null,
      bankBIC: null,
      bankBranchAddress: null,
      bankBranchName: null,
      bankBranchNumber: null,
      bankIBAN: null,
      bankRoutingNumber: null,
      bankSortCode: null,
      buildingNumber: null,
      businessID: null,
      businessName: null,
      businessType: null,
      cardNumber: null,
      country: null,
      countryOfBirth: null,
      dateOfBirth: null,
      emailAddress: null,
      firstName: null,
      lastName: null,
      maternalLastName: null,
      middleName: null,
      mobileWalletID: null,
      mobileWalletName: null,
      nationalID: null,
      nationalIDExpiryDate: null,
      nationalIDIssuingCountry: null,
      nationalIDType: null,
      paternalLastName: null,
      phoneNumber: null,
      postalAddressLine: null,
      postalCode: null,
      provinceOfBirth: null,
      provinceState: null,
      referenceNumber: null,
      rtpName: null,
      rtpid: null,
      townCity: null,
      townOfBirth: null,
      namePrefix: null,
    },
    receiverInformation: {
      firstName: null,
      lastName: null,
      middleName: null,
      dateOfBirth: null,
      townOfBirth: null,
      countryOfBirth: null,
      nationalID: null,
      nationalIDType: null,
      nationalIDExpiryDate: null,
      nationalIDIssuingCountry: null,
      phoneNumber: null,
      emailAddress: null,
      postalAddressLine: null,
      rtpid: null,
      rtpName: null,
      businessName: null,
      businessID: null,
      provinceOfBirth: null,
      bankAccountNumber: null,
      bankAccountType: null,
      bankBIC: null,
      bankBranchAddress: null,
      bankBranchName: null,
      bankBranchNumber: null,
      bankIBAN: null,
      bankRoutingNumber: null,
      bankSortCode: null,
      buildingNumber: null,
      businessType: null,
      paternalLastName: null,
      cardNumber: null,
      country: null,
      maternalLastName: null,
      mobileWalletID: null,
      mobileWalletName: null,
      postalCode: null,
      provinceState: null,
      referenceNumber: null,
      townCity: null,
      namePrefix: null,
    },
  });

  // error message model 
  const ErrorMessageEmptyModel = () => {
    setSenderNamePrefixErrorMessage("");
    setSenderFirstNameErrorMessage("");
    setSenderLastNameErrorMessage("");
    setSenderMiddleNameErrorMessage("");
    setSenderTownOfBirthErrorMessage("");
    setSenderMaternalLastNameErrorMessage("");
    setSenderPaternalLastNameErrorMessage("");
    setSenderTownNameErrorMessage("");
    setSenderPostalAddressLineErrorMessage("");
    setSenderPhoneNumberErrorMessage("");
    setSenderBusinessNameErrorMessage("");
    setSenderBusinessTypeErrorMessage("");
    setSenderBusinessIDErrorMessage("");
    setSenderEmailAddressErrorMessage("");
    setSenderBuildingNumberErrorMessage("");
    setSenderProvinceErrorMessage("");
    setSenderPostalCodeErrorMessage("");
    setSenderNationalIDIssuingCountryErrorMessage("");
    setSenderNationalIDErrorMessage("");
    setSenderNationalIDTypeErrorMessage("");
    setSenderNationalIDExpiryDateErrorMessage("");
    setSenderDateOfBirthErrorMessage("");
    setSenderCountryErrorMessage("");
    setSenderBankIBANErrorMessage("");
    setSenderReferenceNumberErrorMessage("");
    setSenderRTPIDErrorMessage("");
    setSenderMobileWalletNameErrorMessage("");
    setSenderProvinceOfBirthErrorMessage("");
    setSenderCountryOfBirthErrorMessage("");
    setSenderRTPNameErrorMessage("");
    setSenderBankBICErrorMessage("");
    setSenderBankAccountNumberErrorMessage("");
    setSenderBankRotingNumberErrorMessage("");
    setSenderBankSortCodeErrorMessage("");
    setSenderBankAccountTypeErrorMessage("");
    setSenderBankBranchNameErrorMessage("");
    setSenderBankBranchNumberErrorMessage("");
    setSenderBankBranchAddressErrorMessage("");
    setSenderCardNumberErrorMessage("");

    setRecipientNamePrefixErrorMessage("");
    setRecipientMobileWalletIDErrorMessage("");
    setRecipientFirstNameErrorMessage("");
    setRecipientLastNameErrorMessage("");
    setRecipientMiddleNameErrorMessage("");
    setRecipientTownOfBirthErrorMessage("");
    setRecipientMaternalLastNameErrorMessage("");
    setRecipientPaternalLastNameErrorMessage("");
    setRecipientTownNameErrorMessage("");
    setRecipientPostalAddressLineErrorMessage("");
    setRecipientPhoneNumberErrorMessage("");
    setRecipientBusinessNameErrorMessage("");
    setRecipientBusinessTypeErrorMessage("");
    setRecipientBusinessIDErrorMessage("");
    setRecipientEmailAddressErrorMessage("");
    setRecipientBuildingNumberErrorMessage("");
    setRecipientProvinceErrorMessage("");
    setRecipientPostalCodeErrorMessage("");
    setRecipientNationalIDIssuingCountryErrorMessage("");
    setRecipientNationalIDErrorMessage("");
    setRecipientNationalIDTypeErrorMessage("");
    setRecipientNationalIDExpiryDateErrorMessage("");
    setRecipientDateOfBirthErrorMessage("");
    setRecipientCountryErrorMessage("");
    setRecipientBankIBANErrorMessage("");
    setRecipientReferenceNumberErrorMessage("");
    setRecipientRTPIDErrorMessage("");
    setRecipientMobileWalletNameErrorMessage("");
    setRecipientProvinceOfBirthErrorMessage("");
    setRecipientCountryOfBirthErrorMessage("");
    setRecipientRTPNameErrorMessage("");
    setRecipientBankBICErrorMessage("");
    setRecipientBankAccountNumberErrorMessage("");
    setRecipientBankRotingNumberErrorMessage("");
    setRecipientBankSortCodeErrorMessage("");
    setRecipientBankAccountTypeErrorMessage("");
    setRecipientBankBranchNameErrorMessage("");
    setRecipientBankBranchNumberErrorMessage("");
    setRecipientBankBranchAddressErrorMessage("");
    setRecipientCardNumberErrorMessage("");

  };

  // validate check null value
  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null || value === 0) {
      return true;
    }
    return false;
  };

  // validation handle
  const isValidate = (values: any) => {
    let formIsValid = true;
    ErrorMessageEmptyModel();
    if (statusCodeList.includes("300035")) {
      if (CheckNull(values.senderInformation.firstName)) {
        setSenderFirstNameErrorMessage("Please enter sender first name.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.firstName)) {
        if (values.senderInformation.firstName.trim().length === 0) {
          setSenderFirstNameErrorMessage("Please enter sender first name.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300043")) {
      if (CheckNull(values.senderInformation.namePrefix)) {
        setSenderNamePrefixErrorMessage("Please enter sender name prefix.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.namePrefix)) {
        if (values.senderInformation.namePrefix.trim().length === 0) {
          setSenderNamePrefixErrorMessage("Please enter sender name prefix.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300042")) {
      if (CheckNull(values.senderInformation.middleName)) {
        setSenderMiddleNameErrorMessage("Please enter sender middle name.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.middleName)) {
        if (values.senderInformation.middleName.trim().length === 0) {
          setSenderMiddleNameErrorMessage("Please enter sender middle name.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300036")) {
      if (CheckNull(values.senderInformation.lastName)) {
        setSenderLastNameErrorMessage("Please enter sender last name.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.lastName)) {
        if (values.senderInformation.lastName.trim().length === 0) {
          setSenderLastNameErrorMessage("Please enter sender last name.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300037")) {
      if (CheckNull(values.senderInformation.maternalLastName)) {
        setSenderMaternalLastNameErrorMessage(
          "Please enter sender maternal last name."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.maternalLastName)) {
        if (values.senderInformation.maternalLastName.trim().length === 0) {
          setSenderMaternalLastNameErrorMessage(
            "Please enter sender maternal last name."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300038")) {
      if (CheckNull(values.senderInformation.paternalLastName)) {
        setSenderPaternalLastNameErrorMessage(
          "Please enter sender paternal last name."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.paternalLastName)) {
        if (values.senderInformation.paternalLastName.trim().length === 0) {
          setSenderPaternalLastNameErrorMessage(
            "Please enter sender paternal last name."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300013")) {
      if (CheckNull(values.senderInformation.businessName)) {
        setSenderBusinessNameErrorMessage("Please enter sender business name.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.businessName)) {
        if (values.senderInformation.businessName.trim().length === 0) {
          setSenderBusinessNameErrorMessage(
            "Please enter sender business name."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300016")) {
      if (CheckNull(values.senderInformation.businessType)) {
        setSenderBusinessTypeErrorMessage("Please enter sender business type.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.businessType)) {
        if (values.senderInformation.businessType.trim().length === 0) {
          setSenderBusinessTypeErrorMessage(
            "Please enter sender business type."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300012")) {
      if (CheckNull(values.senderInformation.businessID)) {
        setSenderBusinessIDErrorMessage("Please enter sender business ID.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.businessID)) {
        if (values.senderInformation.businessID.trim().length === 0) {
          setSenderBusinessIDErrorMessage("Please enter sender business ID.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300046")) {
      if (CheckNull(values.senderInformation.phoneNumber)) {
        setSenderPhoneNumberErrorMessage("Please enter sender phone number.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.phoneNumber)) {
        if (values.senderInformation.phoneNumber.trim().length === 0) {
          setSenderPhoneNumberErrorMessage("Please enter sender phone number.");
          formIsValid = false;
        }
        if (!/^\d{3}-\d{0,12}$/.test(values.senderInformation.phoneNumber)) {
          setSenderPhoneNumberErrorMessage(
            "Please provide valid sender phone number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300047")) {
      if (CheckNull(values.senderInformation.emailAddress)) {
        setSenderEmailAddressErrorMessage("Please enter sender email address.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.emailAddress)) {
        if (values.senderInformation.emailAddress.trim().length === 0) {
          setSenderEmailAddressErrorMessage(
            "Please enter sender email address."
          );
          formIsValid = false;
        }

        if (
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            values.senderInformation.emailAddress
          )
        ) {
          setSenderEmailAddressErrorMessage(
            "Please provide valid sender email address."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300005")) {
      if (CheckNull(values.senderInformation.postalAddressLine)) {
        setSenderPostalAddressLineErrorMessage(
          "Please enter sender postal address."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.postalAddressLine)) {
        if (values.senderInformation.postalAddressLine.trim().length === 0) {
          setSenderPostalAddressLineErrorMessage(
            "Please enter sender postal address."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300044")) {
      if (CheckNull(values.senderInformation.buildingNumber)) {
        setSenderBuildingNumberErrorMessage(
          "Please enter sender building number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.buildingNumber)) {
        if (values.senderInformation.buildingNumber.trim().length === 0) {
          setSenderBuildingNumberErrorMessage(
            "Please enter sender building number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300002")) {
      if (CheckNull(values.senderInformation.townCity)) {
        setSenderTownNameErrorMessage("Please enter sender town name.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.townCity)) {
        if (values.senderInformation.townCity.trim().length === 0) {
          setSenderTownNameErrorMessage("Please enter sender town name.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300001")) {
      if (CheckNull(values.senderInformation.provinceState)) {
        setSenderProvinceErrorMessage("Please enter sender province city.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.provinceState)) {
        if (values.senderInformation.provinceState.trim().length === 0) {
          setSenderProvinceErrorMessage("Please enter sender province city.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300034")) {
      if (CheckNull(values.senderInformation.postalCode)) {
        setSenderPostalCodeErrorMessage("Please enter sender postal code.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.postalCode)) {
        if (values.senderInformation.postalCode.trim().length === 0) {
          setSenderPostalCodeErrorMessage("Please enter sender postal code.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300003")) {
      if (CheckNull(values.senderInformation.country)) {
        setSenderCountryErrorMessage("Please enter sender country.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.country)) {
        if (values.senderInformation.country.trim().length === 0) {
          setSenderCountryErrorMessage("Please enter sender country.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300006")) {
      if (CheckNull(values.senderInformation.nationalIDIssuingCountry)) {
        setSenderNationalIDIssuingCountryErrorMessage(
          "Please enter sender national ID issuing country."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.nationalIDIssuingCountry)) {
        if (
          values.senderInformation.nationalIDIssuingCountry.trim().length === 0
        ) {
          setSenderNationalIDIssuingCountryErrorMessage(
            "Please enter sender national ID issuing country."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300008")) {
      if (CheckNull(values.senderInformation.nationalID)) {
        setSenderNationalIDErrorMessage(
          "Please enter sender national ID number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.nationalID)) {
        if (values.senderInformation.nationalID.trim().length === 0) {
          setSenderNationalIDErrorMessage(
            "Please enter sender national ID number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300009")) {
      if (CheckNull(values.senderInformation.nationalIDType)) {
        setSenderNationalIDTypeErrorMessage(
          "Please enter sender national ID type."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.nationalIDType)) {
        if (values.senderInformation.nationalIDType.trim().length === 0) {
          setSenderNationalIDTypeErrorMessage(
            "Please enter sender national ID type."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300010")) {
      if (CheckNull(nationalidexpirydatevalue)) {
        setSenderNationalIDExpiryDateErrorMessage(
          "Please enter sender national ID expiry date."
        );
        formIsValid = false;
      }
      // if (!CheckNull(nationalidexpirydatevalue)) {
      //     if (nationalidexpirydatevalue.trim().length === 0) {
      //         setSenderNationalIDExpiryDateErrorMessage(
      //             "Please enter sender national ID expiry date."
      //         );
      //         formIsValid = false;
      //     }
      // }

      if (nationalidexpirydatevalue == "Invalid Date") {
        setSenderNationalIDExpiryDateErrorMessage(
          "Please enter valid sender national ID expiry date."
        );
        formIsValid = false;
      }
    }

    if (statusCodeList.includes("300004")) {
      if (CheckNull(dateofbirthvalue)) {
        setSenderDateOfBirthErrorMessage("Please enter sender date of birth.");
        formIsValid = false;
      }

      if (dateofbirthvalue == "Invalid Date") {
        setSenderDateOfBirthErrorMessage(
          "Please enter valid sender date of birth."
        );
        formIsValid = false;
      }
      if (
        moment(dateofbirthvalue).format("yyyy-MM-DD").toString() ===
        moment(new Date()).format("yyyy-MM-DD").toString()
      ) {
        setSenderDateOfBirthErrorMessage(
          "Please enter valid sender date of birth."
        );
        formIsValid = false;
      }
    }

    if (statusCodeList.includes("300039")) {
      if (CheckNull(values.senderInformation.townOfBirth)) {
        setSenderTownOfBirthErrorMessage("Please enter sender town of birth.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.townOfBirth)) {
        if (values.senderInformation.townOfBirth.trim().length === 0) {
          setSenderTownOfBirthErrorMessage(
            "Please enter sender town of birth."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300040")) {
      if (CheckNull(values.senderInformation.provinceOfBirth)) {
        setSenderProvinceOfBirthErrorMessage(
          "Please enter sender province of birth."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.provinceOfBirth)) {
        if (values.senderInformation.provinceOfBirth.trim().length === 0) {
          setSenderProvinceOfBirthErrorMessage(
            "Please enter sender province of birth."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300041")) {
      if (CheckNull(values.senderInformation.countryOfBirth)) {
        setSenderCountryOfBirthErrorMessage(
          "Please enter sender country of birth."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.countryOfBirth)) {
        if (values.senderInformation.countryOfBirth.trim().length === 0) {
          setSenderCountryOfBirthErrorMessage(
            "Please enter sender country of birth."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300030")) {
      if (CheckNull(values.senderInformation.rtpid)) {
        setSenderRTPIDErrorMessage("Please enter sender RTP id.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.rtpid)) {
        if (values.senderInformation.rtpid.trim().length === 0) {
          setSenderRTPIDErrorMessage("Please enter sender sender RTP id.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300029")) {
      if (CheckNull(values.senderInformation.rtpName)) {
        setSenderRTPNameErrorMessage("Please enter sender RTP name.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.rtpName)) {
        if (values.senderInformation.rtpName.trim().length === 0) {
          setSenderRTPNameErrorMessage("Please enter sender sender RTP name.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300031")) {
      if (CheckNull(values.senderInformation.mobileWalletName)) {
        setSenderMobileWalletNameErrorMessage(
          "Please enter sender mobile wallet name."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.mobileWalletName)) {
        if (values.senderInformation.mobileWalletName.trim().length === 0) {
          setSenderMobileWalletNameErrorMessage(
            "Please enter sender sender mobile wallet name."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300032")) {
      if (CheckNull(values.senderInformation.mobileWalletID)) {
        setSenderMobileWalletIDErrorMessage(
          "Please enter sender mobile wallet ID."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.mobileWalletID)) {
        if (values.senderInformation.mobileWalletID.trim().length === 0) {
          setSenderMobileWalletIDErrorMessage(
            "Please enter sender sender mobile wallet ID."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300026")) {
      if (CheckNull(values.senderInformation.bankIBAN)) {
        setSenderBankIBANErrorMessage("Please enter sender bank IBAN number.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.bankIBAN)) {
        if (values.senderInformation.bankIBAN.trim().length === 0) {
          setSenderBankIBANErrorMessage(
            "Please enter sender sender bank IBAN number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300025")) {
      if (CheckNull(values.senderInformation.bankBIC)) {
        setSenderBankBICErrorMessage("Please enter sender bank BIC number.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.bankBIC)) {
        if (values.senderInformation.bankBIC.trim().length === 0) {
          setSenderBankBICErrorMessage(
            "Please enter sender sender bank BIC number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300026")) {
      if (CheckNull(values.senderInformation.bankAccountNumber)) {
        setSenderBankAccountNumberErrorMessage(
          "Please enter sender bank account number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.bankAccountNumber)) {
        if (values.senderInformation.bankAccountNumber.trim().length === 0) {
          setSenderBankAccountNumberErrorMessage(
            "Please enter sender sender bank account number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300017")) {
      if (CheckNull(values.senderInformation.bankRoutingNumber)) {
        setSenderBankRotingNumberErrorMessage(
          "Please enter sender bank routing number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.bankRoutingNumber)) {
        if (values.senderInformation.bankRoutingNumber.trim().length === 0) {
          setSenderBankRotingNumberErrorMessage(
            "Please enter sender sender bank routing number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300023")) {
      if (CheckNull(values.senderInformation.bankSortCode)) {
        setSenderBankSortCodeErrorMessage(
          "Please enter sender bank sort code."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.bankSortCode)) {
        if (values.senderInformation.bankSortCode.trim().length === 0) {
          setSenderBankSortCodeErrorMessage(
            "Please enter sender sender bank sort code."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300022")) {
      if (CheckNull(values.senderInformation.bankAccountType)) {
        setSenderBankAccountTypeErrorMessage(
          "Please select sender bank account type."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.bankAccountType)) {
        if (values.senderInformation.bankAccountType.trim().length === 0) {
          setSenderBankAccountTypeErrorMessage(
            "Please select sender sender bank account type."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300024")) {
      if (CheckNull(values.senderInformation.bankBranchName)) {
        setSenderBankBranchNameErrorMessage(
          "Please enter sender bank branch name."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.bankBranchName)) {
        if (values.senderInformation.bankBranchName.trim().length === 0) {
          setSenderBankBranchNameErrorMessage(
            "Please enter sender sender bank branch name."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300023")) {
      if (CheckNull(values.senderInformation.bankBranchNumber)) {
        setSenderBankBranchNumberErrorMessage(
          "Please enter sender bank branch number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.bankBranchNumber)) {
        if (values.senderInformation.bankBranchNumber.trim().length === 0) {
          setSenderBankBranchNumberErrorMessage(
            "Please enter sender bank branch number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300021")) {
      if (CheckNull(values.senderInformation.bankBranchAddress)) {
        setSenderBankBranchAddressErrorMessage(
          "Please enter sender bank branch address."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.bankBranchAddress)) {
        if (values.senderInformation.bankBranchAddress.trim().length === 0) {
          setSenderBankBranchAddressErrorMessage(
            "Please enter sender bank branch address."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300033")) {
      if (CheckNull(values.senderInformation.cardNumber)) {
        setSenderCardNumberErrorMessage("Please enter sender card number.");
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.cardNumber)) {
        if (values.senderInformation.cardNumber.trim().length === 0) {
          setSenderCardNumberErrorMessage("Please enter sender card number.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("300028")) {
      if (CheckNull(values.senderInformation.referenceNumber)) {
        setSenderReferenceNumberErrorMessage(
          "Please enter sender reference number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.senderInformation.referenceNumber)) {
        if (values.senderInformation.referenceNumber.trim().length === 0) {
          setSenderReferenceNumberErrorMessage(
            "Please enter sender reference number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200035")) {
      if (CheckNull(values.receiverInformation.firstName)) {
        setRecipientFirstNameErrorMessage("Please enter recipient first name.");
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.firstName)) {
        if (values.receiverInformation.firstName.trim().length === 0) {
          setRecipientFirstNameErrorMessage(
            "Please enter recipient first name."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200043")) {
      if (CheckNull(values.receiverInformation.namePrefix)) {
        setRecipientNamePrefixErrorMessage(
          "Please enter recipient name prefix."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.namePrefix)) {
        if (values.receiverInformation.namePrefix.trim().length === 0) {
          setRecipientNamePrefixErrorMessage(
            "Please enter recipient name prefix."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200042")) {
      if (CheckNull(values.receiverInformation.middleName)) {
        setRecipientMiddleNameErrorMessage(
          "Please enter recipient middle name."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.middleName)) {
        if (values.receiverInformation.middleName.trim().length === 0) {
          setRecipientMiddleNameErrorMessage(
            "Please enter recipient middle name."
          );
          formIsValid = false;
        }
      }
    }
    if (statusCodeList.includes("200036")) {
      if (CheckNull(values.receiverInformation.lastName)) {
        setRecipientLastNameErrorMessage("Please enter recipient last name.");
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.lastName)) {
        if (values.receiverInformation.lastName.trim().length === 0) {
          setRecipientLastNameErrorMessage("Please enter recipient last name.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200037")) {
      if (CheckNull(values.receiverInformation.maternalLastName)) {
        setRecipientMaternalLastNameErrorMessage(
          "Please enter recipient maternal last name."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.maternalLastName)) {
        if (values.receiverInformation.maternalLastName.trim().length === 0) {
          setRecipientMaternalLastNameErrorMessage(
            "Please enter recipient maternal last name."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200038")) {
      if (CheckNull(values.receiverInformation.paternalLastName)) {
        setRecipientPaternalLastNameErrorMessage(
          "Please enter recipient paternal last name."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.paternalLastName)) {
        if (values.receiverInformation.paternalLastName.trim().length === 0) {
          setRecipientPaternalLastNameErrorMessage(
            "Please enter recipient paternal last name."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200013")) {
      if (CheckNull(values.receiverInformation.businessName)) {
        setRecipientBusinessNameErrorMessage(
          "Please enter recipient business name."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.businessName)) {
        if (values.receiverInformation.businessName.trim().length === 0) {
          setRecipientBusinessNameErrorMessage(
            "Please enter recipient business name."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200016")) {
      if (CheckNull(values.receiverInformation.businessType)) {
        setRecipientBusinessTypeErrorMessage(
          "Please enter recipient business type."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.businessType)) {
        if (values.receiverInformation.businessType.trim().length === 0) {
          setRecipientBusinessTypeErrorMessage(
            "Please enter recipient business type."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200012")) {
      if (CheckNull(values.receiverInformation.businessID)) {
        setRecipientBusinessIDErrorMessage(
          "Please enter recipient business ID."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.businessID)) {
        if (values.receiverInformation.businessID.trim().length === 0) {
          setRecipientBusinessIDErrorMessage(
            "Please enter recipient business ID."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200046")) {
      if (CheckNull(values.receiverInformation.phoneNumber)) {
        setRecipientPhoneNumberErrorMessage(
          "Please enter recipient phone number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.phoneNumber)) {
        if (values.receiverInformation.phoneNumber.trim().length === 0) {
          setRecipientPhoneNumberErrorMessage(
            "Please enter recipient phone number."
          );
          formIsValid = false;
        }
        if (!/^\d{3}-\d{0,12}$/.test(values.receiverInformation.phoneNumber)) {
          setRecipientPhoneNumberErrorMessage(
            "Please provide valid recipient phone number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200047")) {
      if (CheckNull(values.receiverInformation.emailAddress)) {
        setRecipientEmailAddressErrorMessage(
          "Please enter recipient email address."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.emailAddress)) {
        if (values.receiverInformation.emailAddress.trim().length === 0) {
          setRecipientEmailAddressErrorMessage(
            "Please enter recipient email address."
          );
          formIsValid = false;
        }

        if (
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            values.receiverInformation.emailAddress
          )
        ) {
          setRecipientEmailAddressErrorMessage(
            "Please provide valid recipient email address."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200005")) {
      if (CheckNull(values.receiverInformation.postalAddressLine)) {
        setRecipientPostalAddressLineErrorMessage(
          "Please enter recipient postal address."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.postalAddressLine)) {
        if (values.receiverInformation.postalAddressLine.trim().length === 0) {
          setRecipientPostalAddressLineErrorMessage(
            "Please enter recipient postal address."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200044")) {
      if (CheckNull(values.receiverInformation.buildingNumber)) {
        setRecipientBuildingNumberErrorMessage(
          "Please enter recipient building number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.buildingNumber)) {
        if (values.receiverInformation.buildingNumber.trim().length === 0) {
          setRecipientBuildingNumberErrorMessage(
            "Please enter recipient building number."
          );
          formIsValid = false;
        }
      }
    }
    if (statusCodeList.includes("200002")) {
      if (CheckNull(values.receiverInformation.townCity)) {
        setRecipientTownNameErrorMessage("Please enter recipient town name.");
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.townCity)) {
        if (values.receiverInformation.townCity.trim().length === 0) {
          setRecipientTownNameErrorMessage("Please enter recipient town name.");
          formIsValid = false;
        }
      }
    }
    if (statusCodeList.includes("200001")) {
      if (CheckNull(values.receiverInformation.provinceState)) {
        setRecipientProvinceErrorMessage(
          "Please enter recipient province name."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.provinceState)) {
        if (values.receiverInformation.provinceState.trim().length === 0) {
          setRecipientProvinceErrorMessage(
            "Please enter recipient province name."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200034")) {
      if (CheckNull(values.receiverInformation.postalCode)) {
        setRecipientPostalCodeErrorMessage(
          "Please enter recipient postal code."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.postalCode)) {
        if (values.receiverInformation.postalCode.trim().length === 0) {
          setRecipientPostalCodeErrorMessage(
            "Please enter recipient postal code."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200003")) {
      if (CheckNull(values.receiverInformation.country)) {
        setRecipientCountryErrorMessage("Please enter recipient country.");
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.country)) {
        if (values.receiverInformation.country.trim().length === 0) {
          setRecipientCountryErrorMessage("Please enter recipient country.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200006")) {
      if (CheckNull(values.receiverInformation.nationalIDIssuingCountry)) {
        setRecipientNationalIDIssuingCountryErrorMessage(
          "Please enter recipient national ID issuing country."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.nationalIDIssuingCountry)) {
        if (
          values.receiverInformation.nationalIDIssuingCountry.trim().length ===
          0
        ) {
          setRecipientNationalIDIssuingCountryErrorMessage(
            "Please enter recipient national ID issuing country."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200008")) {
      if (CheckNull(values.receiverInformation.nationalID)) {
        setRecipientNationalIDErrorMessage(
          "Please enter recipient national ID number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.nationalID)) {
        if (values.receiverInformation.nationalID.trim().length === 0) {
          setRecipientNationalIDErrorMessage(
            "Please enter recipient national ID number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200009")) {
      if (CheckNull(values.receiverInformation.nationalIDType)) {
        setRecipientNationalIDTypeErrorMessage(
          "Please enter recipient national ID type."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.nationalIDType)) {
        if (values.receiverInformation.nationalIDType.trim().length === 0) {
          setRecipientNationalIDTypeErrorMessage(
            "Please enter recipient national ID type."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200010")) {
      if (CheckNull(recipientnationalidexpirydatevalue)) {
        setRecipientNationalIDExpiryDateErrorMessage(
          "Please enter recipient national ID expiry date."
        );
        formIsValid = false;
      }
      // if (!CheckNull(recipientnationalidexpirydatevalue)) {
      //     if (recipientnationalidexpirydatevalue.trim().length === 0) {
      //         setRecipientNationalIDExpiryDateErrorMessage(
      //             "Please enter recipient national ID expiry date."
      //         );
      //         formIsValid = false;
      //     }
      // }

      if (recipientnationalidexpirydatevalue == "Invalid Date") {
        setRecipientNationalIDExpiryDateErrorMessage(
          "Please enter valid recipient national ID expiry date."
        );
        formIsValid = false;
      }
    }

    if (statusCodeList.includes("200004")) {
      if (CheckNull(recipientdateofbirthvalue)) {
        setRecipientDateOfBirthErrorMessage(
          "Please enter recipient date of birth."
        );
        formIsValid = false;
      }

      if (recipientdateofbirthvalue == "Invalid Date") {
        setRecipientDateOfBirthErrorMessage(
          "Please enter valid recipient date of birth."
        );
        formIsValid = false;
      }
      if (
        moment(recipientdateofbirthvalue).format("yyyy-MM-DD").toString() ===
        moment(new Date()).format("yyyy-MM-DD").toString()
      ) {
        setRecipientDateOfBirthErrorMessage(
          "Please enter valid recipient date of birth."
        );
        formIsValid = false;
      }
    }

    if (statusCodeList.includes("200039")) {
      if (CheckNull(values.receiverInformation.townOfBirth)) {
        setRecipientTownOfBirthErrorMessage(
          "Please enter recipient town of birth."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.townOfBirth)) {
        if (values.receiverInformation.townOfBirth.trim().length === 0) {
          setRecipientTownOfBirthErrorMessage(
            "Please enter recipient town of birth."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200040")) {
      if (CheckNull(values.receiverInformation.provinceOfBirth)) {
        setRecipientProvinceOfBirthErrorMessage(
          "Please enter recipient province of birth."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.provinceOfBirth)) {
        if (values.receiverInformation.provinceOfBirth.trim().length === 0) {
          setRecipientProvinceOfBirthErrorMessage(
            "Please enter recipient province of birth."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200041")) {
      if (CheckNull(values.receiverInformation.countryOfBirth)) {
        setRecipientCountryOfBirthErrorMessage(
          "Please enter recipient country of birth."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.countryOfBirth)) {
        if (values.receiverInformation.countryOfBirth.trim().length === 0) {
          setRecipientCountryOfBirthErrorMessage(
            "Please enter recipient country of birth."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200030")) {
      if (CheckNull(values.receiverInformation.rtpid)) {
        setRecipientRTPIDErrorMessage("Please enter recipient RTP id.");
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.rtpid)) {
        if (values.receiverInformation.rtpid.trim().length === 0) {
          setRecipientRTPIDErrorMessage("Please enter recipient RTP id.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200029")) {
      if (CheckNull(values.receiverInformation.rtpName)) {
        setRecipientRTPNameErrorMessage("Please enter recipient RTP name.");
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.rtpName)) {
        if (values.receiverInformation.rtpName.trim().length === 0) {
          setRecipientRTPNameErrorMessage("Please enter recipient RTP name.");
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200031")) {
      if (CheckNull(values.receiverInformation.mobileWalletName)) {
        setRecipientMobileWalletNameErrorMessage(
          "Please enter recipient mobile wallet name."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.mobileWalletName)) {
        if (values.receiverInformation.mobileWalletName.trim().length === 0) {
          setRecipientMobileWalletNameErrorMessage(
            "Please enter recipient mobile wallet name."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200032")) {
      if (CheckNull(values.receiverInformation.mobileWalletID)) {
        setRecipientMobileWalletIDErrorMessage(
          "Please enter recipient mobile wallet ID."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.mobileWalletID)) {
        if (values.receiverInformation.mobileWalletID.trim().length === 0) {
          setRecipientMobileWalletIDErrorMessage(
            "Please enter recipient mobile wallet ID."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200026")) {
      if (CheckNull(values.receiverInformation.bankIBAN)) {
        setRecipientBankIBANErrorMessage(
          "Please enter recipient bank IBAN number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.bankIBAN)) {
        if (values.receiverInformation.bankIBAN.trim().length === 0) {
          setRecipientBankIBANErrorMessage(
            "Please enter recipient bank IBAN number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200025")) {
      if (CheckNull(values.receiverInformation.bankBIC)) {
        setRecipientBankBICErrorMessage(
          "Please enter recipient bank BIC number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.bankBIC)) {
        if (values.receiverInformation.bankBIC.trim().length === 0) {
          setRecipientBankBICErrorMessage(
            "Please enter recipient bank BIC number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200026")) {
      if (CheckNull(values.receiverInformation.bankAccountNumber)) {
        setRecipientBankAccountNumberErrorMessage(
          "Please enter recipient bank account number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.bankAccountNumber)) {
        if (values.receiverInformation.bankAccountNumber.trim().length === 0) {
          setRecipientBankAccountNumberErrorMessage(
            "Please enter recipient bank account number."
          );
          formIsValid = false;
        }
      }
    }





    if (statusCodeList.includes("200017")) {
      if (CheckNull(values.receiverInformation.bankRoutingNumber)) {
        setRecipientBankRotingNumberErrorMessage(
          "Please enter recipient bank routing number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.bankRoutingNumber)) {
        if (values.receiverInformation.bankRoutingNumber.trim().length === 0) {
          setRecipientBankRotingNumberErrorMessage(
            "Please enter recipient bank routing number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200023")) {
      if (CheckNull(values.receiverInformation.bankSortCode)) {
        setRecipientBankSortCodeErrorMessage(
          "Please enter recipient bank sort code."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.bankSortCode)) {
        if (values.receiverInformation.bankSortCode.trim().length === 0) {
          setRecipientBankSortCodeErrorMessage(
            "Please enter recipient bank sort code."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200022")) {
      if (CheckNull(values.receiverInformation.bankAccountType)) {
        setRecipientBankAccountTypeErrorMessage(
          "Please enter recipient bank account type."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.bankAccountType)) {
        if (values.receiverInformation.bankAccountType.trim().length === 0) {
          setRecipientBankAccountTypeErrorMessage(
            "Please enter recipient bank account type."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200024")) {
      if (CheckNull(values.receiverInformation.bankBranchName)) {
        setRecipientBankBranchNameErrorMessage(
          "Please enter recipient bank branch name."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.bankBranchName)) {
        if (values.receiverInformation.bankBranchName.trim().length === 0) {
          setRecipientBankBranchNameErrorMessage(
            "Please enter recipient bank branch name."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200023")) {
      if (CheckNull(values.receiverInformation.bankBranchNumber)) {
        setRecipientBankBranchNumberErrorMessage(
          "Please enter recipient bank branch number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.bankBranchNumber)) {
        if (values.receiverInformation.bankBranchNumber.trim().length === 0) {
          setRecipientBankBranchNumberErrorMessage(
            "Please enter recipient bank branch number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200021")) {
      if (CheckNull(values.receiverInformation.bankBranchAddress)) {
        setRecipientBankBranchAddressErrorMessage(
          "Please enter recipient bank branch address."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.bankBranchAddress)) {
        if (values.receiverInformation.bankBranchAddress.trim().length === 0) {
          setRecipientBankBranchAddressErrorMessage(
            "Please enter recipient bank branch address."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("200033")) {
      if (CheckNull(values.receiverInformation.cardNumber)) {
        setRecipientCardNumberErrorMessage(
          "Please enter recipient card number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.cardNumber)) {
        if (values.receiverInformation.cardNumber.trim().length === 0) {
          setRecipientCardNumberErrorMessage(
            "Please enter recipient card number."
          );
          formIsValid = false;
        }
      }
    }

    if (statusCodeList.includes("400028")) {
      if (CheckNull(values.receiverInformation.referenceNumber)) {
        setRecipientReferenceNumberErrorMessage(
          "Please enter recipient reference number."
        );
        formIsValid = false;
      }
      if (!CheckNull(values.receiverInformation.referenceNumber)) {
        if (values.receiverInformation.referenceNumber.trim().length === 0) {
          setRecipientReferenceNumberErrorMessage(
            "Please enter recipient reference number."
          );
          formIsValid = false;
        }
      }
    }

    return formIsValid;
  };

  //on next click handle
  const onNextClick = () => {
    setErrorMessage("");
    paymentModel.paymentDataModel = [];

    if (statusCodeList.includes("300010")) {
      paymentDataModel.senderInformation.nationalIDExpiryDate = moment(
        nationalidexpirydatevalue
      ).format("yyyy-MM-DD");
    }

    if (statusCodeList.includes("200010")) {
      paymentDataModel.receiverInformation.nationalIDExpiryDate = moment(
        recipientnationalidexpirydatevalue
      ).format("yyyy-MM-DD");
    }

    if (statusCodeList.includes("300004")) {
      paymentDataModel.senderInformation.dateOfBirth =
        moment(dateofbirthvalue).format("yyyy-MM-DD");
    }

    if (statusCodeList.includes("200004")) {
      paymentDataModel.receiverInformation.dateOfBirth = moment(
        recipientdateofbirthvalue
      ).format("yyyy-MM-DD");
    }

    if (statusCodeList.includes("300046")) {
      paymentDataModel.senderInformation.phoneNumber = (
        formatPhoneNumberIntl(phonenovalue)
          .replace(" ", "-")
          .split("-")[0]
          .replace("+", "")
          .padStart(3, "0") +
        "-" +
        formatPhoneNumberIntl(phonenovalue).replace(" ", "-").split("-")[1]
      ).replaceAll(" ", "");
    }

    if (statusCodeList.includes("200046")) {
      paymentDataModel.receiverInformation.phoneNumber = (
        formatPhoneNumberIntl(recipientphonenovalue)
          .replace(" ", "-")
          .split("-")[0]
          .replace("+", "")
          .padStart(3, "0") +
        "-" +
        formatPhoneNumberIntl(recipientphonenovalue)
          .replace(" ", "-")
          .split("-")[1]
      ).replaceAll(" ", "");
    }

    setLoading(true);
    ErrorMessageEmptyModel();
    if (isValidate(paymentDataModel)) {
      paymentModel.paymentDataModel.push(paymentDataModel);

      PaymentService.validatePayment(paymentModel, partnerId)
        .then((response: any) => {
          if (response.data.messageInfos.length === 0) {
            setLoading(false);
            localStorage.setItem(
              "PaymentModel",
              JSON.stringify(response.data.paymentmodel)
            );
            localStorage.removeItem("MessageInfos");
            navigate("/submitpayment");
          } else {
            setLoading(false);
            localStorage.setItem(
              "MessageInfos",
              JSON.stringify(response.data.messageInfos)
            );
            setErrorMessage(response.data.messageInfos[0].message);

          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            toast.current?.show({
              severity: "error",
              summary: "Oops, Something went wrong!",
              life: 3000,
            });
          } else if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 400) {
            if (
              Object.is(error.response.data?.messageInfos, null) ||
              Object.is(error.response.data?.messageInfos, undefined)
            ) {
              const errorArray: any = Object.entries(error.response.data.errors)
                .map(([key, val]) => ({ name: key, value: val }))
                .map((data: any) => {
                  const splitValues = data.value[0].split("_");

                  return {
                    ...data,
                    statusCode: splitValues[0],
                    errorMessage: splitValues[1],
                  };
                });
              setErrorMessageList(errorArray);
            } else {
              const errorArray: any = Object.entries(
                error.response.data.messageInfos
              )
                .map(([key, val]) => ({ name: key, value: val }))
                .map((data: any) => ({
                  name: "error",
                  value: data.value.message,
                  statusCode: data.value.statusCode,
                  errorMessage: data.value.message,
                }));
             
              setErrorMessageList(errorArray);
            }
            setLoading(false);
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Oops!, Something went wrong.",
              life: 3000,
            });
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  //show hide handle
  const OnShowHideClick = () => {
    if (show === true) {
      setShow(false);
    } else if (show === false) {
      setShow(true);
    }
  };

  // search country
  const searchCountry = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < countryList.length; i++) {
      let item = countryList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredCountryList(_filteredItems);
  };

  // search mobile wallet name
  const searchMobileWalletName = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < MobileWalletNameList.length; i++) {
      let item = MobileWalletNameList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredMobileWalletList(_filteredItems);
  };

  // sedn mobile wallet name change
  const onSenderMobileWalletNameChange = (e: any) => {
    setSenderMobileWalletAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      senderInformation: {
        ...paymentDataModel.senderInformation,
        mobileWalletName: e.value.name,
      },
    });
  };

  // recipient mobile wallet name change
  const onRecipientMobileWalletNameChange = (e: any) => {
    setRecipientMobileWalletAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      receiverInformation: {
        ...paymentDataModel.receiverInformation,
        mobileWalletName: e.value.name,
      },
    });
  };

  // search national id by type
  const searchNationalIDType = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < NationalIDTypeList.length; i++) {
      let item = NationalIDTypeList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredNationalIdTypeList(_filteredItems);
  };

  //on send national type
  const onSenderNationalIDTypeChange = (e: any) => {
    setSenderNationalIdTypeAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      senderInformation: {
        ...paymentDataModel.senderInformation,
        nationalIDType: e.value.name,
      },
    });
  };

  //reacipient national type change
  const onRecipientNationalIDTypeChange = (e: any) => {
    setRecipientNationalIdTypeAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      receiverInformation: {
        ...paymentDataModel.receiverInformation,
        nationalIDType: e.value.name,
      },
    });
  };

  // on send country change 
  const onSenderCountryChange = (e: any) => {
    setSenderCountryAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      senderInformation: {
        ...paymentDataModel.senderInformation,
        country: e.value.code,
      },
    });
  };

  // recipient country change 
  const onRecipientCountryChange = (e: any) => {
    setRecipientCountryAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      receiverInformation: {
        ...paymentDataModel.receiverInformation,
        country: e.value.code,
      },
    });
  };

  // handle send national id using country chnage 
  const onSenderNationalIDIssuingCountryChange = (e: any) => {
    setSenderNationalIDIssuingCountryAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      senderInformation: {
        ...paymentDataModel.senderInformation,
        nationalIDIssuingCountry: e.value.code,
      },
    });
  };

  // on reacipient national id country changes 
  const onRecipientNationalIDIssuingCountryChange = (e: any) => {
    setRecipientNationalIDIssuingCountryAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      receiverInformation: {
        ...paymentDataModel.receiverInformation,
        nationalIDIssuingCountry: e.value.code,
      },
    });
  };

  // on send country of birth change 
  const onSenderCountryOfBirthChange = (e: any) => {
    setSenderCountryOfBirthAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      senderInformation: {
        ...paymentDataModel.senderInformation,
        countryOfBirth: e.value.code,
      },
    });
  };

  //on recipient country of birth change 
  const onRecipientCountryOfBirthChange = (e: any) => {
    setRecipientCountryOfBirthAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      receiverInformation: {
        ...paymentDataModel.receiverInformation,
        countryOfBirth: e.value.code,
      },
    });
  };

  // search RTP type by name
  const searchRTPName = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < RTPNameList.length; i++) {
      let item = RTPNameList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredRTPNameList(_filteredItems);
  };

  // sender RTP name change
  const onSenderRTPNameChange = (e: any) => {
    setSenderRTPNameAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      senderInformation: {
        ...paymentDataModel.senderInformation,
        rtpName: e.value.name,
      },
    });
  };

  // on recipient RTP name changes 
  const onRecipientRTPNameChange = (e: any) => {
    setRecipientRTPNameAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      receiverInformation: {
        ...paymentDataModel.receiverInformation,
        rtpName: e.value.name,
      },
    });
  };

  // search bank account type changes
  const searchBankAccountType = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < BankAccountTypeList.length; i++) {
      let item = BankAccountTypeList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredBankAccountTypeList(_filteredItems);
  };

  // sender bank account type change 
  const onSenderBankAccountTypeChange = (e: any) => {
    setSenderBankAccountTypeAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      senderInformation: {
        ...paymentDataModel.senderInformation,
        bankAccountType: e.value.name,
      },
    });
  };

  // recipient bank account type changes
  const onRecipientBankAccountTypeChange = (e: any) => {
    setRecipientBankAccountTypeAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      receiverInformation: {
        ...paymentDataModel.receiverInformation,
        bankAccountType: e.value.name,
      },
    });
  };

  // search business type change
  const searchBusinessType = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < BusinessTypeList.length; i++) {
      let item = BusinessTypeList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredBusinessTypeList(_filteredItems);
  };

  // on sender business type of changes 
  const onSenderBusinessTypeChange = (e: any) => {
    setSenderBusinessTypeAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      senderInformation: {
        ...paymentDataModel.senderInformation,
        businessType: e.value.name,
      },
    });
  };

  //on recipient business type changes 
  const onRecipientBusinessTypeChange = (e: any) => {
    setRecipientBusinessTypeAutoComplete(e.value);
    setPaymenDataModel({
      ...paymentDataModel,
      receiverInformation: {
        ...paymentDataModel.receiverInformation,
        businessType: e.value.name,
      },
    });
  };

  // on back click
  const onBackClick = () => {
    localStorage.setItem(
      "PaymentModel",
      JSON.stringify(paymentModel)
    );
    navigate("/createpayment");
  };



  useEffect(() => {
    if (JSON.parse(localStorage.getItem("PaymentModel")) !== null) {
      setPaymentModel(JSON.parse(localStorage.getItem("PaymentModel")));

      setPaymenDataModel(
        JSON.parse(localStorage.getItem("PaymentModel")).paymentDataModel[0]
      );
    }
    else {
      navigate("/createpayment");
    }
    if (JSON.parse(localStorage.getItem("MessageInfos")) !== null) {
      setMessageInfos(JSON.parse(localStorage.getItem("MessageInfos")));
      setStatusCodeList(MessageInfos.map((d: any) => d.statusCode));
    }


    setPartnerId(localStorage.getItem("PartnerId"));
    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    localStorage.removeItem("PaymentModel");

  }, []);

  return (
    <>
      {" "}
      <div>
        <div className=" dashboard">
          <Toast ref={toast}></Toast>

          <Scrollbars
            className="creatpayment-scroll"
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={500}
            autoHeight
            autoHeightMin={100}
            autoHeightMax={100}
            thumbMinSize={30}
            universal={true}
          >
            <div className="text-center">
              <>
                <div className="heading-section">
                  <span className="text-header-purple">Create Payment</span>
                </div>
                <div className="container-fluid acc-screen createpayment-screen createpaymnetbtn">
                  <div className="transaction-screen">
                    <div className="transaction-details">
                      <h2>Payment Information</h2>
                      <div className="d-flex">
                        <div className="detials-col">
                          <h4>Sending From:</h4>
                          <p>
                            {" "}
                            {
                              paymentDataModel.paymentInformation
                                .sendCurrency
                            }
                          </p>
                        </div>
                        <div className="detials-col">
                          <h4>Sending To:</h4>
                          <p>
                            {
                              paymentDataModel.paymentInformation
                                .receiveCurrency
                            }
                          </p>
                        </div>
                        <div className="detials-col">
                          <h4>Transaction Type :</h4>
                          <p>
                            {
                              paymentDataModel.paymentInformation
                                .transactionType
                            }
                          </p>
                        </div>
                        <div className="detials-col">
                          <h4>Payment Type:</h4>
                          <p>
                            {" "}
                            {
                              paymentDataModel.paymentInformation
                                .paymentType
                            }
                          </p>
                        </div>
                        <div className="detials-col">
                          <h4>Routing Preference:</h4>
                          <p>
                            {" "}
                            {
                              paymentDataModel.paymentInformation
                                .routingPreferences.preferredType
                            }
                          </p>
                        </div>
                      </div>
                      <div className="d-flex">
                        {paymentDataModel.paymentInformation
                          .routingPreferences.preferredType ===
                          "PreferredPaymentProvider" ||
                          paymentDataModel.paymentInformation
                            .routingPreferences.preferredType ===
                          "PreferredQuoteID" ? (
                          <div className="detials-col">
                            <h4>Preferred Value :</h4>
                            <p>
                              {" "}
                              {
                                paymentDataModel.paymentInformation
                                  .routingPreferences.preferredValue
                              }
                            </p>
                          </div>
                        ) : paymentDataModel.paymentInformation
                          .routingPreferences.preferredType ===
                          "SmartPreferredDelivery" ? (
                          <div className="detials-col">
                            <h4> Preferred Delivery Type:</h4>
                            <p>
                              {" "}
                              {
                                paymentDataModel.paymentInformation
                                  .routingPreferences.preferredValue
                              }
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}
                        {
                          (paymentDataModel.paymentInformation.sendAmount===0||paymentDataModel.paymentInformation.sendAmount===null) ?  <div className="detials-col">
                          <h4>Receive Amount :</h4>
                          <p>
                            {paymentDataModel.paymentInformation.receiveAmount}
                          </p>
                        </div>: <div className="detials-col">
                          <h4>Send Amount :</h4>
                          <p>
                            {paymentDataModel.paymentInformation.sendAmount}
                          </p>
                        </div>
                        }
                      
                        <div className="detials-col">
                          <h4>Source Of Fund :</h4>
                          <p>
                            {" "}
                            {
                              paymentDataModel.paymentInformation
                                .sourceOfFunds
                            }
                          </p>
                        </div>
                        <div className="detials-col">
                          <h4>Purpose Of Transaction:</h4>
                          <p>
                            {" "}
                            {
                              paymentDataModel.paymentInformation
                                .purposeOfTransaction
                            }
                          </p>
                        </div>
                        <div className="detials-col">
                          <h4>Relationship:</h4>
                          <p>
                            {" "}
                            {
                              paymentDataModel.paymentInformation
                                .relationship
                            }
                          </p>
                        </div>
                      </div>

                      {show ? (
                        <>
                          <div className="d-flex">
                            <div className="detials-col">
                              <h4>Payment Notes:</h4>
                              <p>
                                {" "}
                                {
                                  paymentDataModel.paymentInformation
                                    .paymentNotes
                                }
                              </p>
                            </div>
                          </div>
                          <h2>Sender Information</h2>
                          <div className="d-flex">
                            {paymentDataModel.paymentInformation
                              .paymentType === "C2C" ||
                              paymentDataModel.paymentInformation
                                .paymentType === "C2B" ? (
                              <>
                                <div className="detials-col">
                                  <h4>First Name:</h4>
                                  <p>
                                    {
                                      paymentDataModel.senderInformation
                                        .firstName
                                    }
                                  </p>
                                </div>

                                <div className="detials-col">
                                  <h4> Last Name :</h4>
                                  <p>
                                    {
                                      paymentDataModel.senderInformation
                                        .lastName
                                    }
                                  </p>
                                </div>
                                <div className="detials-col">
                                  <h4>Country :</h4>
                                  <p>
                                    {
                                      paymentDataModel.senderInformation
                                        .country
                                    }
                                  </p>
                                </div>
                                <div className="detials-col">
                                  <h4>Phone Number :</h4>
                                  <p>
                                    {
                                      paymentDataModel.senderInformation
                                        .phoneNumber
                                    }
                                  </p>
                                </div>
                                <div className="detials-col">
                                  <h4> Email Address :</h4>
                                  <p>
                                    {
                                      paymentDataModel.senderInformation
                                        .emailAddress
                                    }
                                  </p>
                                </div>
                              </>
                            ) : (
                              <>
                                {paymentDataModel.paymentInformation
                                  .paymentType === "B2B" ||
                                  paymentDataModel.paymentInformation
                                    .paymentType === "B2C" ? (
                                  <>
                                    <div className="detials-col">
                                      <h4> Business Name :</h4>
                                      <p>
                                        {
                                          paymentDataModel.senderInformation
                                            .businessName
                                        }
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </div>
                          <h2>Recipient Information</h2>

                          {paymentDataModel.paymentInformation
                            .paymentType === "C2C" ||
                            paymentDataModel.paymentInformation
                              .paymentType === "B2C" ? (
                            <>
                              <div className="d-flex">
                                <div className="detials-col">
                                  <h4>First Name:</h4>
                                  <p>
                                    {
                                      paymentDataModel.receiverInformation
                                        .firstName
                                    }
                                  </p>
                                </div>
                                <div className="detials-col">
                                  <h4>Last Name :</h4>
                                  <p>
                                    {
                                      paymentDataModel.receiverInformation
                                        .lastName
                                    }
                                  </p>
                                </div>

                                <div className="detials-col">
                                  <h4>Phone Number :</h4>
                                  <p>
                                    {
                                      paymentDataModel.receiverInformation
                                        .phoneNumber
                                    }
                                  </p>
                                </div>
                                <div className="detials-col">
                                  <h4>Email Address :</h4>
                                  <p>
                                    {
                                      paymentDataModel.receiverInformation
                                        .emailAddress
                                    }
                                  </p>
                                </div>
                                <div className="detials-col">
                                  <h4>Date of Birth :</h4>
                                  <p>
                                    {moment(
                                      paymentDataModel.receiverInformation
                                        .dateOfBirth
                                    ).format("MM/DD/YYYY")}
                                  </p>
                                </div>
                              </div>
                              {/* <div className="d-flex">
                                <div className="detials-col">
                                  <h4>RTPID :</h4>
                                  <p>
                                    {
                                      paymentDataModel.receiverInformation
                                        .rtpid
                                    }
                                  </p>
                                </div>

                                <div className="detials-col">
                                  <h4> RTP Name :</h4>
                                  <p>
                                    {
                                      paymentDataModel.receiverInformation
                                        .rtpName
                                    }
                                  </p>
                                </div>
                              </div> */}
                            </>
                          ) : (
                            <>
                              {paymentDataModel.paymentInformation
                                .paymentType === "C2B" ||
                                paymentDataModel.paymentInformation
                                  .paymentType === "B2B" ? (
                                <>
                                  <div className="d-flex">
                                    <div className="detials-col">
                                      <h4> Business Name </h4>
                                      <p>
                                        {
                                          paymentDataModel
                                            .receiverInformation
                                            .businessName
                                        }
                                      </p>
                                    </div>

                                    <div className="detials-col">
                                      <h4> Business ID </h4>
                                      <p>
                                        {
                                          paymentDataModel
                                            .receiverInformation.businessID
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="show-more">
                        <button onClick={OnShowHideClick}>
                          {show === true ? "Show-less" : "Show-more"}
                          {show === true ? (
                            <i className="pi pi-angle-up"> </i>
                          ) : (
                            <i className="pi pi-angle-down"> </i>
                          )}{" "}
                        </button>
                      </div>
                    </div>

                    <>
                      <div className="row payment-informatione-main">
                        {statusCodeList.includes("300043") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Name Prefix
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender name prefix"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    namePrefix: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderNamePrefixErrorMessage !== null &&
                              senderNamePrefixErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderNamePrefixErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300035") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender First Name
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender first name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    firstName: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderFirstNameErrorMessage !== null &&
                              senderFirstNameErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderFirstNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300042") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Middle Name
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender middle name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    middleName: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderMiddleNameErrorMessage !== null &&
                              senderMiddleNameErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderMiddleNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}
                        {statusCodeList.includes("300036") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Last Name
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender last name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    lastName: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderLastNameErrorMessage !== null &&
                              senderLastNameErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderLastNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300039") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Town of Birth
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender town of birth"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    townOfBirth: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderTownOfBirthErrorMessage !== null &&
                              senderTownOfBirthErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderTownOfBirthErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300010") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender National ID Expiry Date
                              <span className="color-red">*</span>
                            </span>
                            <div className="calendar-style">
                              <Calendar
                                id="icon"
                                showIcon
                                className="calendar-style"
                                placeholder="mm/dd/yyyy"
                                dateFormat="mm/dd/yy"
                                name="dateOfBirth"
                                value={nationalidexpirydatevalue}
                                onChange={(e) => {
                                  setNationalIDExpiryDatevalue(
                                    e.target.value
                                  );
                                }}
                              />
                              {senderNationalIDExpiryDateErrorMessage !==
                                null &&
                                senderNationalIDExpiryDateErrorMessage.length >
                                0 && (
                                  <span className="error-msg">
                                    {senderNationalIDExpiryDateErrorMessage}
                                  </span>
                                )}
                            </div>
                        
                          </div>
                        ) : null}

                        {statusCodeList.includes("300034") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Postal Code
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender postal code"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    postalCode: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderPostalCodeErrorMessage !== null &&
                              senderPostalCodeErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderPostalCodeErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300003") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Country
                              <span className="color-red">*</span>
                            </span>
                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select sender country"
                              suggestions={filteredcountrieslist}
                              completeMethod={searchCountry}
                              onChange={(e) => onSenderCountryChange(e)}
                              value={sendercountryAutoComplete}
                            />
                            {senderCountryErrorMessage !== null &&
                              senderCountryErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderCountryErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300026") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Bank IBAN Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender bank IBAN number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    bankIBAN: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderBankIBANErrorMessage !== null &&
                              senderBankIBANErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderBankIBANErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300028") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Reference Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender reference number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    referenceNumber: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderReferenceNumberErrorMessage !== null &&
                              senderReferenceNumberErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderReferenceNumberErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300030") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender RTP ID
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender RTP id"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    rtpid: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderRTPIDErrorMessage !== null &&
                              senderRTPIDErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderRTPIDErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300031") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Mobile Wallet Name
                              <span className="color-red">*</span>
                            </span>
                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select sender mobile wallet name"
                              suggestions={filteredmobilewalletlist}
                              completeMethod={searchMobileWalletName}
                              onChange={(e) =>
                                onSenderMobileWalletNameChange(e)
                              }
                              value={sendermobilewalletAutoComplete}
                            />
                            {senderMobileWalletNameErrorMessage !== null &&
                              senderMobileWalletNameErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderMobileWalletNameErrorMessage}
                                </span>
                              )}
                          
                          </div>
                        ) : null}

                        {statusCodeList.includes("300037") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Maternal Last Name
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender maternal last name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    maternalLastName: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderMaternalLastNameErrorMessage !== null &&
                              senderMaternalLastNameErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderMaternalLastNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300038") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Paternal Last Name
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender paternal last name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    paternalLastName: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderPaternalLastNameErrorMessage !== null &&
                              senderPaternalLastNameErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderPaternalLastNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300013") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Business Name
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender business name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    businessName: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderBusinessNameErrorMessage !== null &&
                              senderBusinessNameErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderBusinessNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300016") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Business Type
                              <span className="color-red">*</span>
                            </span>
                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select sender business type"
                              suggestions={filteredbusinesstypelist}
                              completeMethod={searchBusinessType}
                              onChange={(e) =>
                                onSenderBusinessTypeChange(e)
                              }
                              value={senderbusinesstypeAutoComplete}
                            />
                            {senderBusinessTypeErrorMessage !== null &&
                              senderBusinessTypeErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderBusinessTypeErrorMessage}
                                </span>
                              )}
                   
                          </div>
                        ) : null}

                        {statusCodeList.includes("300012") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Business ID
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender business ID"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    businessID: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderBusinessIDErrorMessage !== null &&
                              senderBusinessIDErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderBusinessIDErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300046") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Phone Number
                              <span className="color-red">*</span>
                            </span>
                            <PhoneInput
                              className="form-control Phone-input"
                              type="text"
                              initialValueFormat="national"
                              addInternationalOption={false}
                              countryCallingCodeEditable={true}
                              international={false}
                              placeholder="Enter sender phone number"
                              defaultCountry="US"
                              name="phoneNumber"
                              value={phonenovalue}
                              onChange={(e: any) => {
                                setPhoneNovalue(e!);
                              }}
                            />
                
                            {senderPhoneNumberErrorMessage !== null &&
                              senderPhoneNumberErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderPhoneNumberErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300047") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Email Address
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender email address"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    emailAddress: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderEmailAddressErrorMessage !== null &&
                              senderEmailAddressErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderEmailAddressErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300005") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Postal Address Line
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender postal address line"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    postalAddressLine: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderPostalAddressLineErrorMessage !== null &&
                              senderPostalAddressLineErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderPostalAddressLineErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300044") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Building Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender building number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    buildingNumber: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderBuildingNumberErrorMessage !== null &&
                              senderBuildingNumberErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderBuildingNumberErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300002") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Town Name/City
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender town name/city"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    townCity: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderTownNameErrorMessage !== null &&
                              senderTownNameErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderTownNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300001") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Province/State
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender province/state"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    provinceState: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderProvinceErrorMessage !== null &&
                              senderProvinceErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderProvinceErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300006") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender National ID Issuing Country
                              <span className="color-red">*</span>
                            </span>
                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select sender national ID issuing country"
                              suggestions={filteredcountrieslist}
                              completeMethod={searchCountry}
                              onChange={(e) =>
                                onSenderNationalIDIssuingCountryChange(e)
                              }
                              value={
                                sendernationalidissuingcountryAutoComplete
                              }
                            />
                            {senderNationalIDIssuingCountryErrorMessage !==
                              null &&
                              senderNationalIDIssuingCountryErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {
                                    senderNationalIDIssuingCountryErrorMessage
                                  }
                                </span>
                              )}
                       
                          </div>
                        ) : null}

                        {statusCodeList.includes("300008") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender National Identity Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender national identity number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    nationalID: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderNationalIDErrorMessage !== null &&
                              senderNationalIDErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderNationalIDErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300009") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender National ID Type
                              <span className="color-red">*</span>
                            </span>
                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select sender national ID type"
                              suggestions={filterednationalIdTypelist}
                              completeMethod={searchNationalIDType}
                              onChange={(e) =>
                                onSenderNationalIDTypeChange(e)
                              }
                              value={sendernationalIdTypeAutoComplete}
                            />
                            {senderNationalIDTypeErrorMessage !== null &&
                              senderNationalIDTypeErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderNationalIDTypeErrorMessage}
                                </span>
                              )}
                     
                          </div>
                        ) : null}

                        {statusCodeList.includes("300004") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Date of Birth
                              <span className="color-red">*</span>
                            </span>

                            <div className="calendar-style">
                              <Calendar
                                id="icon"
                                showIcon
                                className="calendar-style"
                                placeholder="mm/dd/yyyy"
                                dateFormat="mm/dd/yy"
                                name="dateOfBirth"
                                value={dateofbirthvalue}
                                onChange={(e) => {
                                  setDateofbirthvalue(e.target.value);
                                }}
                              />
                              {senderDateOfBirthErrorMessage !== null &&
                                senderDateOfBirthErrorMessage.length >
                                0 && (
                                  <span className="error-msg">
                                    {senderDateOfBirthErrorMessage}
                                  </span>
                                )}
                            </div>
                    
                          </div>
                        ) : null}

                        {statusCodeList.includes("300040") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Province of Birth
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender province of birth"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    provinceOfBirth: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderProvinceOfBirthErrorMessage !== null &&
                              senderProvinceOfBirthErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderProvinceOfBirthErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300041") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Country of Birth
                              <span className="color-red">*</span>
                            </span>

                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select sender country of birth"
                              suggestions={filteredcountrieslist}
                              completeMethod={searchCountry}
                              onChange={(e) =>
                                onSenderCountryOfBirthChange(e)
                              }
                              value={sendercountryofbirthAutoComplete}
                            />
                            {senderCountryOfBirthErrorMessage !== null &&
                              senderCountryOfBirthErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderCountryOfBirthErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300029") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender RTP Name
                              <span className="color-red">*</span>
                            </span>
                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select sender RTP name"
                              suggestions={filteredrtpnamelist}
                              completeMethod={searchRTPName}
                              onChange={(e) => onSenderRTPNameChange(e)}
                              value={senderrtpnameAutoComplete}
                            />
                            {senderRTPNameErrorMessage !== null &&
                              senderRTPNameErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderRTPNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300032") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Mobile Wallet ID
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender mobile wallet ID"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    mobileWalletID: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderMobileWalletIDErrorMessage !== null &&
                              senderMobileWalletIDErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderMobileWalletIDErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300025") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Bank BIC
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender bank BIC"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    bankBIC: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderBankBICErrorMessage !== null &&
                              senderBankBICErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderBankBICErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300026") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Bank Account Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender bank account number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    bankAccountNumber: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderBankAccountNumberErrorMessage !== null &&
                              senderBankAccountNumberErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderBankAccountNumberErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300017") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Bank Routing Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender bank routing number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    bankRoutingNumber: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderBankRoutingNumberErrorMessage !== null &&
                              senderBankRoutingNumberErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderBankRoutingNumberErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300023") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Bank Sort Code
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender bank sort code"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    bankSortCode: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderBankSortCodeErrorMessage !== null &&
                              senderBankSortCodeErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderBankSortCodeErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300022") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Bank Account Type
                              <span className="color-red">*</span>
                            </span>
                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select sender bank account type"
                              suggestions={filteredbankaccounttypelist}
                              completeMethod={searchBankAccountType}
                              onChange={(e) =>
                                onSenderBankAccountTypeChange(e)
                              }
                              value={senderbankaccounttypeAutoComplete}
                            />
                            {senderBankAccountTypeErrorMessage !== null &&
                              senderBankAccountTypeErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderBankAccountTypeErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300024") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Bank Branch Name
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender bank branch name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    bankBranchName: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderBankBranchNameErrorMessage !== null &&
                              senderBankBranchNameErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderBankBranchNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300023") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Bank Branch Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender bank branch number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    bankBranchNumber: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderBankBranchNumberErrorMessage !== null &&
                              senderBankBranchNumberErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderBankBranchNumberErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300021") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Bank Branch Address
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender bank branch address"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    bankBranchAddress: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderBankBranchAddressErrorMessage !== null &&
                              senderBankBranchAddressErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {senderBankBranchAddressErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("300033") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Sender Card Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter sender card number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  senderInformation: {
                                    ...paymentDataModel.senderInformation,
                                    cardNumber: e.target.value,
                                  },
                                });
                              }}
                            />
                            {senderCardNumberErrorMessage !== null &&
                              senderCardNumberErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {senderCardNumberErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200043") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Name Prefix
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient name prefix"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    namePrefix: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientNamePrefixErrorMessage !== null &&
                              recipientNamePrefixErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientNamePrefixErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200035") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient First Name
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient first name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    firstName: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientFirstNameErrorMessage !== null &&
                              recipientFirstNameErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {recipientFirstNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}
                        {statusCodeList.includes("200042") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Middle Name{" "}
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient middle name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    middleName: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientMiddleNameErrorMessage !== null &&
                              recipientMiddleNameErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientMiddleNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}
                        {statusCodeList.includes("200036") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Last Name
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient last name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    lastName: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientLastNameErrorMessage !== null &&
                              recipientLastNameErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {recipientLastNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200037") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Maternal Last Name
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient maternal last name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    maternalLastName: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientMaternalLastNameErrorMessage !==
                              null &&
                              recipientMaternalLastNameErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientMaternalLastNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200038") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Paternal Last Name
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient paternal last name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    paternalLastName: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientPaternalLastNameErrorMessage !==
                              null &&
                              recipientPaternalLastNameErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientPaternalLastNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200013") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Business Name
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient business name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    businessName: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientBusinessNameErrorMessage !== null &&
                              recipientBusinessNameErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientBusinessNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200012") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Business ID
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient business ID"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    businessID: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientBusinessIDErrorMessage !== null &&
                              recipientBusinessIDErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientBusinessIDErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200047") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Email Address
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient email address"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    emailAddress: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientEmailAddressErrorMessage !== null &&
                              recipientEmailAddressErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientEmailAddressErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}
                        {statusCodeList.includes("200005") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Postal Address Line
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient postal address line"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    postalAddressLine: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientPostalAddressLineErrorMessage !==
                              null &&
                              recipientPostalAddressLineErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientPostalAddressLineErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200044") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Building Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient building number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    buildingNumber: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientBuildingNumberErrorMessage !== null &&
                              recipientBuildingNumberErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientBuildingNumberErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200001") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Province/State
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient province/state"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    provinceState: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientProvinceErrorMessage !== null &&
                              recipientProvinceErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {recipientProvinceErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200003") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Country
                              <span className="color-red">*</span>
                            </span>
                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select recipient country"
                              suggestions={filteredcountrieslist}
                              completeMethod={searchCountry}
                              onChange={(e) => onRecipientCountryChange(e)}
                              value={recipientcountryAutoComplete}
                            />
                            {recipientCountryErrorMessage !== null &&
                              recipientCountryErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {recipientCountryErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}
                        {statusCodeList.includes("200039") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Town of Birth{" "}
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient town of birth"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    townOfBirth: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientTownOfBirthErrorMessage !== null &&
                              recipientTownOfBirthErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientTownOfBirthErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}
                        {statusCodeList.includes("200040") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Province of Birth{" "}
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter Recipient province of birth"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    provinceOfBirth: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientProvinceOfBirthErrorMessage !==
                              null &&
                              recipientProvinceOfBirthErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientProvinceOfBirthErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}
                        {statusCodeList.includes("200046") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Phone Number
                              <span className="color-red">*</span>
                            </span>
                            <PhoneInput
                              className="form-control Phone-input"
                              type="text"
                              initialValueFormat="national"
                              addInternationalOption={false}
                              countryCallingCodeEditable={true}
                              international={false}
                              placeholder="Enter recipient phone number"
                              defaultCountry="US"
                              name="phoneNumber"
                              value={recipientphonenovalue}
                              onChange={(e: any) => {
                                setRecipientPhoneNovalue(e!);
                              }}
                            />
                     
                            {recipientPhoneNumberErrorMessage !== null &&
                              recipientPhoneNumberErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientPhoneNumberErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}
                        {statusCodeList.includes("200016") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Business Type{" "}
                              <span className="color-red">*</span>
                            </span>
                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select recipient business type"
                              suggestions={filteredbusinesstypelist}
                              completeMethod={searchBusinessType}
                              onChange={(e) =>
                                onRecipientBusinessTypeChange(e)
                              }
                              value={recipientbusinesstypeAutoComplete}
                            />
                            {recipientBusinessTypeErrorMessage !== null &&
                              recipientBusinessTypeErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientBusinessTypeErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}
                        {statusCodeList.includes("200029") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient RTP Name{" "}
                              <span className="color-red">*</span>
                            </span>
                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select sender RTP name"
                              suggestions={filteredrtpnamelist}
                              completeMethod={searchRTPName}
                              onChange={(e) => onRecipientRTPNameChange(e)}
                              value={recipientrtpnameAutoComplete}
                            />
                            {recipientRTPNameErrorMessage !== null &&
                              recipientRTPNameErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {recipientRTPNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}
                        {statusCodeList.includes("200031") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Mobile Wallet Name
                              <span className="color-red">*</span>
                            </span>
                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select recipient mobile wallet name"
                              suggestions={filteredmobilewalletlist}
                              completeMethod={searchMobileWalletName}
                              onChange={(e) =>
                                onRecipientMobileWalletNameChange(e)
                              }
                              value={recipientmobilewalletAutoComplete}
                            />
                            {recipientMobileWalletNameErrorMessage !==
                              null &&
                              recipientMobileWalletNameErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientMobileWalletNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200034") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Postal Code
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient postal code"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    postalCode: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientPostalCodeErrorMessage !== null &&
                              recipientPostalCodeErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientPostalCodeErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200002") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Town
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient town name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    townCity: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientTownNameErrorMessage !== null &&
                              recipientTownNameErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {recipientTownNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200006") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient National ID Issuing Country
                              <span className="color-red">*</span>
                            </span>
                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select recipient national ID issuing country"
                              suggestions={filteredcountrieslist}
                              completeMethod={searchCountry}
                              onChange={(e) =>
                                onRecipientNationalIDIssuingCountryChange(e)
                              }
                              value={
                                recipientnationalidissuingcountryAutoComplete
                              }
                            />
                            {recipientNationalIDIssuingCountryErrorMessage !==
                              null &&
                              recipientNationalIDIssuingCountryErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {
                                    recipientNationalIDIssuingCountryErrorMessage
                                  }
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200008") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient National Identity Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient national identity number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    nationalID: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientNationalIDErrorMessage !== null &&
                              recipientNationalIDErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientNationalIDErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200009") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient National ID Type
                              <span className="color-red">*</span>
                            </span>
                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select recipient national ID type"
                              suggestions={filterednationalIdTypelist}
                              completeMethod={searchNationalIDType}
                              onChange={(e) =>
                                onRecipientNationalIDTypeChange(e)
                              }
                              value={recipientnationalIdTypeAutoComplete}
                            />
                            {recipientNationalIDTypeErrorMessage !== null &&
                              recipientNationalIDTypeErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientNationalIDTypeErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200010") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient National ID Expiry Date
                              <span className="color-red">*</span>
                            </span>
                            <div className="calendar-style">
                              <Calendar
                                id="icon"
                                showIcon
                                className="calendar-style"
                                placeholder="mm/dd/yyyy"
                                dateFormat="mm/dd/yy"
                                name="dateOfBirth"
                                value={recipientnationalidexpirydatevalue}
                                onChange={(e) => {
                                  setRecipientNationalIDExpiryDatevalue(
                                    e.target.value
                                  );
                                }}
                              />
                              {recipientNationalIDExpiryDateErrorMessage !==
                                null &&
                                recipientNationalIDExpiryDateErrorMessage.length >
                                0 && (
                                  <span className="error-msg">
                                    {
                                      recipientNationalIDExpiryDateErrorMessage
                                    }
                                  </span>
                                )}
                            </div>
                          </div>
                        ) : null}

                        {statusCodeList.includes("200004") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Date of Birth
                              <span className="color-red">*</span>
                            </span>
                            <div className="calendar-style">
                              <Calendar
                                id="icon"
                                showIcon
                                className="calendar-style"
                                placeholder="mm/dd/yyyy"
                                dateFormat="mm/dd/yy"
                                name="dateOfBirth"
                                value={recipientdateofbirthvalue}
                                onChange={(e) => {
                                  setRecipientDateofbirthvalue(
                                    e.target.value
                                  );
                                }}
                              />
                              {recipientDateOfBirthErrorMessage !== null &&
                                recipientDateOfBirthErrorMessage.length >
                                0 && (
                                  <span className="error-msg">
                                    {recipientDateOfBirthErrorMessage}
                                  </span>
                                )}
                            </div>
                          </div>
                        ) : null}

                        {statusCodeList.includes("200041") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Country of Birth
                              <span className="color-red">*</span>
                            </span>

                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select recipient country of birth"
                              suggestions={filteredcountrieslist}
                              completeMethod={searchCountry}
                              onChange={(e) =>
                                onRecipientCountryOfBirthChange(e)
                              }
                              value={recipientcountryofbirthAutoComplete}
                            />
                            {recipientCountryOfBirthErrorMessage !== null &&
                              recipientCountryOfBirthErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientCountryOfBirthErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200030") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient RTP Instant ID
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient RTP instant ID"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    rtpid: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientRTPIDErrorMessage !== null &&
                              recipientRTPIDErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {recipientRTPIDErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200032") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Mobile Wallet ID
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient mobile wallet ID"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    mobileWalletID: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientMobileWalletIDErrorMessage !== null &&
                              recipientMobileWalletIDErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientMobileWalletIDErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200026") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Bank IBAN
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient bank IBAN"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    bankIBAN: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientBankIBANErrorMessage !== null &&
                              recipientBankIBANErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {recipientBankIBANErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200025") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Bank BIC
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient bank BIC"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    bankBIC: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientBankBICErrorMessage !== null &&
                              recipientBankBICErrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {recipientBankBICErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200026") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Bank Account Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient bank account number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    bankAccountNumber: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientBankAccountNumberErrorMessage !==
                              null &&
                              recipientBankAccountNumberErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientBankAccountNumberErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200017") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Bank Routing Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient bank routing number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    bankRoutingNumber: e.target.value,
                                  },
                                });
                              }}
                            />

                            {recipientBankRoutingNumberErrorMessage !==
                              null &&
                              recipientBankRoutingNumberErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientBankRoutingNumberErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200023") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Bank Sort Code
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient bank sort code"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    bankSortCode: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientBankSortCodeErrorMessage !== null &&
                              recipientBankSortCodeErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientBankSortCodeErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200022") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Bank Account Type
                              <span className="color-red">*</span>
                            </span>
                            <AutoComplete
                              field="name"
                              dropdown
                              forceSelection
                              aria-label="Countries"
                              dropdownAriaLabel="IND"
                              className="dropdown-acc"
                              placeholder="Select recipient bank account type"
                              suggestions={filteredbankaccounttypelist}
                              completeMethod={searchBankAccountType}
                              onChange={(e) =>
                                onRecipientBankAccountTypeChange(e)
                              }
                              value={recipientbankaccounttypeAutoComplete}
                            />
                            {recipientBankAccountTypeErrorMessage !==
                              null &&
                              recipientBankAccountTypeErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientBankAccountTypeErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}


                        {statusCodeList.includes("200024") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Bank Branch Name
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient bank branch name"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    bankBranchName: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientBankBranchNameErrorMessage !== null &&
                              recipientBankBranchNameErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientBankBranchNameErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200023") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Bank Branch Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient bank branch number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    bankBranchNumber: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientBankBranchNumberErrorMessage !==
                              null &&
                              recipientBankBranchNumberErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientBankBranchNumberErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200021") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Bank Branch Address
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient bank branch address"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    bankBranchAddress: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientBankBranchAddressErrorMessage !==
                              null &&
                              recipientBankBranchAddressErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientBankBranchAddressErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}

                        {statusCodeList.includes("200033") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Card Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient card number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    cardNumber: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientCardNumberErrorMessage !== null &&
                              recipientCardNumberErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientCardNumberErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}
                        {statusCodeList.includes("400028") === true ? (
                          <div className=" col-md-4 form-group ">
                            <span className="input-label">
                              Recipient Reference Number
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="text"
                              placeholder="Enter recipient reference number"
                              onChange={(e) => {
                                setPaymenDataModel({
                                  ...paymentDataModel,
                                  receiverInformation: {
                                    ...paymentDataModel.receiverInformation,
                                    referenceNumber: e.target.value,
                                  },
                                });
                              }}
                            />
                            {recipientReferenceNumberErrorMessage !==
                              null &&
                              recipientReferenceNumberErrorMessage.length >
                              0 && (
                                <span className="error-msg">
                                  {recipientReferenceNumberErrorMessage}
                                </span>
                              )}
                          </div>
                        ) : null}
                      </div>
                    </>
                    {errorMessageList.length !== 0 && (
                            <>
                              {errorMessageList.map((error) => (
                                <p style={{ display: "block" }}>
                                  <span className="error-msg">
                                    {error.errorMessage}
                                  </span>
                                </p>
                              ))}
                            </>
                          )}
                  </div>

                  <div className="button-section create-payment-error-msg">
                    {errorMessage !== null && errorMessage.length > 0 && (
                      <span className="error-msg">{errorMessage}</span>
                    )}
                    <div className="bottom-btns">
                      <Button
                        iconPos="left"
                        label="Back"
                        onClick={onBackClick}
                        className="btn btn-back second-btn"
                      />
                      <Button
                        iconPos="left"
                        loading={loading}
                        label="Next"
                        className="btn btn-continue second-btn"
                        onClick={onNextClick}
                      />
                    </div>
                  </div>
                </div>
              </>
            </div>
          </Scrollbars>
        </div>
      </div>

    </>
  );
};

export default AddPaymentFields;
