import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Logout } from "../utils/AccountUtils";
import Header from "./Header";
import Sidebar from "./Sidebar";




const DashboardLayout: React.FC<any> = ({}) => {
    const navigate = useNavigate();
    const id = localStorage.getItem("PartnerId");
    const [partnerId, setpartnerId] = useState(id);

  
  
    useEffect(() => {
      const useroobj = localStorage.getItem("User");
      if (useroobj === null || useroobj === undefined) {
        Logout(navigate);
      }
      if (Number(partnerId) !== 0) {
  
      }
  
    }, []);
    return (
      
      <div>
        <div className="wrapper dashboard">
          {/* <Toast ref={toast}></Toast> */}
          <div className="togle-button">
            <Sidebar />
            <div id="content">
             
              <Header  />
              <Outlet/>
            </div>
          </div>
        </div>
      </div>
    );
  };
  export default DashboardLayout;
  