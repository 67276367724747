import React, { useContext, useEffect, useState } from "react";
import "./Sidebar.css";
import IRLOGO from "../assets/images/icon/logo-icon.png";
import Home from "../assets/images/icon/whitehome.png";
import CheckQuotes from "../assets/images/icon/check-quotes.png";
import Createpayment from "../assets/images/icon/create-payment.png";
import Transactions from "../assets/images/icon/transaction.png";
import DeveloperResources from "../assets/images/icon/developer-resources.png";
import Logouticon from "../assets/images/logoutwhite.svg";
import Report from "../assets/images/icon/reports.png";
import FacilitatorIcon from "../assets/images/icon/whitefacilitatoricon.png";
import User from "../assets/images/icon/users.png";
import { Link, NavLink } from "react-router-dom";
import { LogoClick, Logout } from "../utils/AccountUtils";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import Ledger from "../assets/images/icon/Ledger.svg";
import UserIcon from "../assets/images/icon/User-icon.svg";
import LocalStorageContext from "../components/Context/LocalStorageContext";
import FxRate from "../components/PartnerOnboardReady/FxRate/FxRate";
const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(0);
  const [stepflag, setStepFlag] = useState(0);
  const [role, setRole] = useState(0);
  const [isFacilitator, setIsFacilitator] = useState("");
  const [partnerid, setPartnerId] = useState(0);
  const [displaypopup, setDisplayPopup] = useState(false);
  const context = useContext(LocalStorageContext);
  useEffect(() => {
    // Update the document title using the browser API
    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    setStatus(Number(localStorage.getItem("OnboardingStatus")));
    setStepFlag(Number(localStorage.getItem("StepFlag")));
    setPartnerId(Number(localStorage.getItem("PartnerId")));
    setRole(Number(localStorage.getItem("PartnerPaymentRole")));
    setIsFacilitator(localStorage.getItem("IsFacilitator"));
  }, []);
  const onImageClick = () => {
    LogoClick(Number(localStorage.getItem("PartnerId")), Number(localStorage.getItem("StepFlag")), Number(localStorage.getItem("OnboardingStatus")), navigate);
  };
  const accept = () => {
    navigate("/");
  };
  const confirm = (e: any) => {
    e.preventDefault();
    setDisplayPopup(true);
    confirm1();
  };
  const reject = () => {
    setDisplayPopup(false);
  };
  const confirm1 = () => {
    confirmDialog({
      message: "Are you sure you want to logout?",
      header: "Logout",
      icon: "pi pi-info-circle",
      accept,
      reject,
    });
  };

  return (
    <div className="wrapper">
      <div className="togle-button">
        <nav id="sidebar">
          <div className="sidebar-header">
            {displaypopup === true ? (
              <ConfirmDialog id="confirm-popup" />
            ) : null}
            <img
              src={IRLOGO}
              alt="img"
              className="logo-white"
              onClick={onImageClick}
            />
            <strong>IR</strong>
          </div>
          <ul className="list-unstyled components">
            <li
              className={
                context.status == 8 ? "sidebar-mb-4  first-item" : "sidebar-mb-4 disabled first-item"
              }
            >
              <NavLink
                to="/dashboard"
                className={({ isActive }) => (isActive ? "active " : "inactive")}
              >
                <img src={Home} alt="img" className="" />
                <span className="ml-10">Home</span>
              </NavLink>
            </li>
            <li
              className={context.status == 8 ? "sidebar-mb-4" : "sidebar-mb-4 disabled"}
            >
              <NavLink
                to="/checkquotes"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <img src={CheckQuotes} alt="img" className="" />
                <span className="ml-10">Check Quotes</span>
              </NavLink>
            </li>
            {context.facilitator === true ? null : Number(context.role) === 1 || Number(context.role) === 3 ? (
              <li
                className={
                  context.status == 8 ? "sidebar-mb-4" : "sidebar-mb-4 disabled"
                }
              >
                <NavLink
                  to="/createpayment"
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                >
                  <img src={Createpayment} alt="img" className="" />
                  <span className="ml-10">Create Payment</span>
                </NavLink>
              </li>
            ) : null}
            {context.facilitator === true ? null : Number(context.role) === 2 || Number(context.role) === 3 ? (
              <li
                id="sidebar"
                className={
                  context.status == 8 ? "sidebar-mb-4" : "sidebar-mb-4 disabled"
                }
              >
                <NavLink
                  to="/fxrate"
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                >
                  <img
                    src={DeveloperResources}
                    alt="img"
                    className=""
                    style={{ height: "21px", width: "21px" }}
                  />
                  <span className="ml-10">FxRate</span>
                </NavLink>
              </li>
            ) : null}
            <li
              className={context.status == 8 ? "sidebar-mb-4" : "sidebar-mb-4 disabled"}
            >
              <NavLink
                to="/transactions"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <img
                  src={Transactions}
                  alt="img"
                  className=""
                  style={{ height: "20px" }}
                />
                <span className="ml-10">Transactions</span>
              </NavLink>
            </li>
            {context.facilitator === true ? null : (
              <li
                id="sidebar"
                className={
                  context.status == 8 ? "sidebar-mb-4" : "sidebar-mb-4 disabled"
                }
              >
                <NavLink
                  to="/developerresources"
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                >
                  <img
                    src={DeveloperResources}
                    alt="img"
                    className=""
                    style={{ height: "21px", width: "21px" }}
                  />
                  <span className="ml-10">Resources</span>
                </NavLink>
              </li>
            )}

            <li
              id="sidebar"
              className={context.status == 8 ? "sidebar-mb-4" : "sidebar-mb-4 disabled"}
            >

              <NavLink to="/ledger" className={({ isActive }) =>
                isActive ? "active" : "inactive"
              }>

                <img
                  src={Ledger}
                  alt="img"
                  className=""
                  style={{ height: "18px", width: "18px" }}
                />
                <span className="ml-10">Wallet</span>
              </NavLink>

            </li>
            <li
              id="sidebar"
              className={context.status == 8 ? "sidebar-mb-4" : "sidebar-mb-4 disabled"}
            >
              <NavLink
                to="/reports"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <img src={Report} alt="img" className="" />
                <span className="ml-10">Reports</span>
              </NavLink>
            </li>
            <li
              className={context.status == 8 ? "sidebar-mb-4" : "sidebar-mb-4 disabled"}
            >
              <NavLink
                to="/users"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <img src={User} alt="img" className="" />
                <span className="ml-10">Users</span>
              </NavLink>
            </li>
            {context.facilitator === true ? (
              <>
                <li
                  className={
                    context.status == 8 ? "sidebar-mb-4" : "sidebar-mb-4 disabled"
                  }
                >
                  <NavLink
                    to="/facilitator"
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                  >
                    <img
                      src={FacilitatorIcon}
                      alt="img"
                      className=""
                      style={{ width: "23px" }}
                    />
                    <span className="ml-10">Facilitator</span>
                  </NavLink>
                </li>
              </>
            ) : (
              null
            )}
            <li>
              <NavLink

                to="/profile"
                className={({ isActive }) =>
                  isActive ? "active" : "inactive"
                }
              >
                <img
                  src={UserIcon}
                  alt="img"
                  className=""
                  style={{ height: "20px" }}
                />
                <span className="ml-10">Profile</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={confirm}
                to="/"
                className={({ isActive }) =>
                  isActive ? "active" : "inactive"
                }
              >
                <img
                  src={Logouticon}
                  alt="img"
                  className=""
                  style={{ height: "20px" }}
                />
                <span className="ml-10">Logout</span>
              </NavLink>
            </li>


          </ul>
        </nav>
      </div>
    </div>
  );
};
export default Sidebar;
