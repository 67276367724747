
import PaymentTabcontent from "./PaymentTabcontent";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Tabcontent.css";
import { Logout } from "../utils/AccountUtils";

const Payment = () => {
  const navigate = useNavigate();

  const [status, setstatus] = useState(
    parseInt(localStorage.getItem("OnboardingStatus"))

  );

  useEffect(() => {
    setstatus(parseInt(localStorage.getItem("OnboardingStatus")));

    const useroobj = localStorage.getItem("User");

    if (useroobj === null || useroobj === undefined) {

      Logout(navigate);
    }
  }, []);

  return (
    <>


      <div className="right-tab-section">
        <div className="heading-section">
          <span className="text-header-purple">Account Settings</span>
        </div>
        <PaymentTabcontent />
      </div>

    </>
  );
};
export default Payment;
