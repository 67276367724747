import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import IRLOGO from "../../../assets/images/icon/logo-icon.png";
import RegisterSuccess from "../../../assets/images/tick.svg";
import username from "../../../assets/images/username-img.svg";
import { ILogin } from "../../../models/ILogin";
import { SetPasswordService } from "../../../services/Account/SetPasswordService";
import { Logindata, Logout } from "../../../utils/AccountUtils";
import { validEmail } from "../../../utils/utils";
import LocalStorageContext from "../../Context/LocalStorageContext";


interface IState {
  user: ILogin;
}

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [state, setState] = useState<IState>({
    user: {
      userName: "",
      password: "",
    },
  });
  const [userErrorMessage, setuserErrorMessage] = React.useState("");
  const [passErrorMessage, setpassErrorMessage] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false);

  const ErrorMessageEmptyModel = () => {
    setuserErrorMessage("");
    setpassErrorMessage("");
  };
  const context = useContext(LocalStorageContext);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null) {
      return true;
    }
    return false;
  };

  const isValidate = (values: any) => {
    let formIsValid = true;
    ErrorMessageEmptyModel();
    if (!validEmail.test(values.userName)) {
      setuserErrorMessage("Please enter valid username");
      formIsValid = false;
    }
    if (CheckNull(values.userName)) {
      setuserErrorMessage("Please enter username");
      formIsValid = false;
    }

    return formIsValid;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({
      user: {
        ...state.user,
        [event.target.name]: event.target.value,
      },
    });
  };

  // form handling
  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoading(true);
    if (isValidate(state.user)) {

      SetPasswordService.forgotPassword(state.user.userName)
        .then((response: any) => {

       
     
          setTimeout(() => {
            setOnSuccess(true);
          }, 500);
       
          setLoading(false);
          Logindata(response, navigate);
          context.updateLogoSrc(response.data.logoURL);
          context.updateStatus(response.data.onboardingStatus);
          context.updateStepFlag(response.data.stepFlag);
          context.updateFacilitator(response.data.isfacilitator);
          context.updateRole(response.data.partnerRole);

        })
        .catch((error) => {

          setLoading(false);
          setOnSuccess(false);
        });
    } else {
      setLoading(false);
    }
  };


  const onHideClick = () => {
    Logout(navigate);
  };

  return (
<>
      <div className="Sigin-page bg-height">
        <div className="Login-form">
          <div className="login-page">
            <img src={IRLOGO} alt="img" className="logo-white" />
          </div>
          <div className="signin-form-display">
            <div className="login-form-display">
              <div className="text-center">
                <h2>Forgot Password ?</h2>
                <h3>Access the instant payment network</h3>
              </div>
              <div className="username-form">
                <form>
                  <div className="form-group login-form-input forget-pass-input ">
                    <p>
                      {" "}
                      <img src={username} alt="img" /> Username
                    </p>
                    <input
                      id="exampleInputEmail1"
                      name="userName"
                      type="email"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter username"
                      onChange={handleChange}
                      value={state.user.userName}
                    />
                    {userErrorMessage !== null &&
                      userErrorMessage.length > 0 ? (
                      <span className="login-error-msg">
                        {userErrorMessage}
                      </span>
                    ) : null}
                  </div>

                  <Button
                    loading={loading}
                    label="Submit"
                    className="btn btn-submit btn-login"
                    onClick={handleSubmit}
                  />
                </form>
              </div>

              <div className="text-center forgot-password">
                <Link to="/login">
                  Sign in to discover a world of global instant payments!
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        className="forget-pass-popup  popup-screen "
        visible={onSuccess}
        style={{ width: "390px", height: "320px", margin: "0", left: "592" }}
        onHide={onHideClick}
      >
        <div className="text-center mt-3 ">
          <img src={RegisterSuccess} alt="img" style={{ width: "50px" }} />{" "}
          <p className="awesome-text">
            Thank you !<br />
          </p>
        </div>

        <p className="text-center">
          Please click on the link that has just been sent to your email account
          to verify your email and continue to complete the process.
          <br />
          <Button
            iconPos="left"
            label="Ok"
            className="btn btn-register-ok  "
            onClick={() => Logout(navigate)}
          />
        </p>
      </Dialog>
    </>
  );
};

export default ForgotPassword;
