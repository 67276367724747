const ContactToolTips = [
    {
        "id":0,
         "Info":`<h4>Phone number of the organization </h4>`
    },
   {
    "id":1,
     "Info":`<h4>Optional fax number of the organization </h4>`
},
{
    "id":2,
     "Info":`<h4>Official email address associated with the organization. We do not accept personal emails. </h4>`
},

]


export default ContactToolTips;