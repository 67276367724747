
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tabcontent from "./Tabcontent";
import "./Tabcontent.css";
import { Logout } from "../utils/AccountUtils";


const Details: React.FC<any>= () =>{

  const navigate = useNavigate();
  const [status, setstatus] = useState( 
    parseInt(localStorage.getItem("OnboardingStatus"))
  );



  useEffect(() => {

    setstatus(parseInt(localStorage.getItem("OnboardingStatus")));

    const useroobj = localStorage.getItem("User");

    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
  }, []);

  return (
    <>
   
              <div className="right-tab-section">
                <div className="heading-section">
                  <h2 className="text-header-purple">
                    Create a business account screen
                  </h2>
                </div>
                <Tabcontent   />
              </div>
         
    </>
  );
};
export default Details;
