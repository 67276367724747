import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import * as signalR from "@microsoft/signalr";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import group from "../../assets/images/Group.png";
import incredibleicon from "../../assets/images/IncredibleIcon.svg";
import { PartnershipDetailsService } from "../../services/Partner/PartnershipDetails/PartnershipDetailsService";
import { PartnerStatusUpdateService } from "../../services/Partner/PartnerStatusUpdate/PartnerStatusUpdateService";
import { Logout } from "../../utils/AccountUtils";
import { NotificatiosService } from "../../services/Notifications/NotificatiosService";
import LocalStorageContext from "../Context/LocalStorageContext";

const OnboardingDialogue: React.FC<any> = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState<any>(
    localStorage.getItem("OnboardingStatus")
  );
  const context = useContext(LocalStorageContext);
  const [connection, setConnection] = useState(null);
  const [connectionID, setConnectionID] = useState(null);
  const [message, setMessage] = useState('');
  const [value, setValue] = useState(parseInt(status));
  const [comment, setComment] = useState('');
  const [role, setRole] = useState<any>();
  const [loading, setLoading] = useState(false);
  const id = localStorage.getItem("PartnerId");
  const [partnerid, setpartnerId] = useState(id);
  const [okButtonLoading, setOkButtonLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [displayPopup, setDisplayPopup] = useState(true);
  const toast = useRef<Toast>(null);

  const onHideClick = () => {
    setButtonLoading(true);

    setDisplayPopup(false);
  };

  const navigateToPayment = () => {

    setButtonLoading(true);

    navigate("/payment");
  };

  const setNavigateDetails = () => {
    setOkButtonLoading(true);
    setTimeout(() => {
      navigate(`/details`)
    }, 1000);


  }
  const getStepFlagAndOnboardingStatus = () => {
    setLoading(true);
    PartnershipDetailsService.getStepFlagAndOnboardingStatus(Number(partnerid))
      .then((response) => {
        const data = response.data;
        localStorage.setItem("OnboardingStatus", JSON.stringify(data.value));
        localStorage.setItem("StepFlag", JSON.stringify(data.stepFlag));
        setMessage(data.message);
        setValue(data.value);
        setComment(data.comment);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting partnership details Role.",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };



  const onSetStateReady = () => {

    setButtonLoading(true);
    PartnerStatusUpdateService.updatestatus()
      .then((response: any) => {

        context.updateStatus("8");
        localStorage.setItem("OnboardingStatus", "8");

        navigate("/dashboard");
        setButtonLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setButtonLoading(false);
      });
  };

  const [partnerlogo, setlogo] = useState(localStorage.getItem("PartnerLogo"));

  const OnLogoUpdate = () => {
    let plogo = localStorage.getItem("PartnerLogo")?.replaceAll('"', "");
    setlogo(plogo);
  };

  useEffect(() => {
    const URL = process.env.REACT_APP_SIGNALR_API;
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(URL)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
    getStepFlagAndOnboardingStatus();
    //getPartnershipDetailsByPartnerId();
    OnLogoUpdate();

    // stop the connection when leaving the screen
    //  return () => {
    //   connection.stop();
    // };
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          console.log("SignalR Connected");
        })
        .then(() => getConnectionId())
        .catch((err: any) => {
          console.log("SignalR Connection Error: ", err);
        });

      connection.on("ReceiveMessage", (res: any) => {
        localStorage.setItem("OnboardingStatus", JSON.stringify(res.value));
        localStorage.setItem("StepFlag", JSON.stringify(res.stepFlag));
        setValue(res.value);
        setMessage(res.message);
        setComment(res.comment);
      });


      return () => {
        connection.stop();
    }
    
    }
  }, [connection]);

  const getConnectionId = () => {
    connection.invoke("getconnectionid").then((data: any) => {

      setConnectionID(data);
      const localStoragedata = JSON.parse(localStorage.getItem("User"));
      const userId = localStoragedata.id;
      const id = 0;
      const partnerId = localStorage.getItem("PartnerId");
      const partnerNotificationsRequestModelNew = {
        id: id.toString(),
        userId: userId.toString(),
        partnerId: partnerId,
      };

      connection.invoke(
        "UpdatePartnerConnectionId",
        partnerNotificationsRequestModelNew,
        data
      );
    });
  };

  return (
    <>
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <div>
          <Toast ref={toast}></Toast>
          <div className="wrapper">
            <div className="togle-button">
              {/* <div id="content"> */}
              {value != 7 ? (
                <>
                  <Dialog
                    visible={displayPopup}
                    className="popup-screen review-screen popup-1"
                    onHide={onHideClick}
                    modal={false}
                  >
                    <div className="text-center  ">
                      <img src={group} alt="img" className="popup-img" />{" "}
                      {value === 2 ? (
                        <>
                          <p className="awesome-text">
                            Your application is being{" "}
                            <b style={{ color: "orange" }}>{message}</b> <br />
                            by our Compliance  team
                          </p>
                        </>
                      ) : (
                        <>
                          {value === 5 ? (
                            <>
                              <p
                                className="awesome-text "
                                style={{ color: "red" }}
                              >
                                Your application is{" "}
                                <b style={{ color: "red" }}>{message}</b>{" "}
                                <br />
                                by our Compliance  team
                              </p>
                            </>
                          ) : (
                            <>
                              {value === 4 ? (
                                <>
                                  {" "}
                                  <p
                                    className="awesome-text "
                                    style={{ color: "green" }}
                                  >
                                    Congratulations! Your application is{" "}
                                    <b style={{ color: "green" }}>{message}</b>{" "}
                                    <br />
                                    by our Compliance  team
                                  </p>
                                </>
                              ) : (
                                <>
                                  {value === 3 ? (
                                    <>
                                      {" "}
                                      <p
                                        className="awesome-text "
                                        style={{ color: "orange" }}
                                      >
                                        {" "}
                                        <b style={{ color: "orange" }}>{message}</b>{" "}
                                        <br />
                                        Our Compliance team needs more information to approve your account
                                      </p>
                                      <div
                                       className="text-left"
                                      ><span dangerouslySetInnerHTML={{ __html: comment }}></span></div>


                                    </>
                                  ) : (
                                    <>
                                    </>
                                  )}</>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    {value === 2 ? (
                      <>
                        {" "}
                        <p className="text-center">
                          "It takes less time to do things right,than to
                          explain why you did it wrong". - Henry Wadsworth
                          <br />
                        </p>

                      </>
                    ) : (
                      <>
                        {value === 4 ? (
                          <>
                            {" "}
                            <div className="text-center">
                              <p> You can now follow these steps </p>
                              <p> to complete your account settings. </p>
                            </div>
                            <Button
                              iconPos="left"
                              label="Ok"
                              loading={buttonLoading}
                              className="btn user-ok"
                              onClick={() => navigateToPayment()}
                            />
                          </>
                        ) : (
                          <>
                            {value === 3 ? (
                              <>
                                <Button
                                  iconPos="left"
                                  loading={okButtonLoading}
                                  label="Ok"
                                  className="btn user-ok"
                                  onClick={() => setNavigateDetails()}
                                />
                              </>
                            ) : (
                              <>
                                {value === 5 ? (
                                  <>


                                    <div className="text-center">
                                      <p>  Please contact</p>
                                      <p>   <b>support@instarails.io</b> for support </p>

                                    </div>





                                  </>) : (<></>)}



                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Dialog>
                </>
              ) : (
                <>
                  {value === 7 ? (
                    <>
                      <Dialog
                        visible={true}
                        className="popup-screen review-screen popup-2"
                        onHide={onHideClick}
                        modal={false}
                      >
                        <div className="text-center">
                          <img
                            src={incredibleicon}
                            alt="img"
                            className="incredible-img"
                          />{" "}
                          <p className="incredible-text">
                            {" "}
                            You are incredible!
                          </p>
                          <p>
                            {" "}
                            You have completed setting up your
                            account,woo hoo!
                          </p>
                        </div>
                        <Button
                          iconPos="left"
                          label="Ok"
                          loading={buttonLoading}
                          className="btn user-ok"
                          onClick={() => onSetStateReady()}
                        />
                      </Dialog>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        // </div>
      )}
    </>
  );
};
export default OnboardingDialogue;
