import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { DocumentService } from "../../../services/Partner/Documents/DocumentsService";
import DocumentToolTips from "../../../services/Partner/Documents/DocumentToolTips";
import { Logout } from "../../../utils/AccountUtils";
import { brazildocumentList, documentList } from "../../../utils/utils";
import UploadIcon from "../../../assets/images/icon/upload-icon.png";
import Document from "../../../assets/images/icon/file-doc.svg";

import ToolTip from "../ToolTipsData/ToolTip";
import { AddressService } from "../../../services/Partner/Address/AddressService";
import { Empty } from "antd";

const Documents: React.FC<any> = ({
  onSaveAndContinueClick,
  onNextClick,
  buttonLoadingSkip,
  buttonLoadingBack,
  setButtonLoadingBack,
  setButtonLoadingSkip,
}) => {
  const id = localStorage.getItem("PartnerId");
  const toast = useRef<Toast>(null);
  const [country, setCountry] = useState("");
  const [readonly, setReadOnly] = useState(false);
  const [status, setstatus] = useState(
    localStorage.getItem("OnboardingStatus")
  );
  const navigate = useNavigate();
  const [displaypopup, setDisplayPopup] = useState(false);
  const [deletedata, SetDeleteData] = useState(null);
  const [displaydeletepopup, setDisplayDeletePopup] = useState(false);
  const [documentTypeAutoComplete, setDocumentTypeAutoComplete] = useState("");
  const [seconddocumentTypeAutoComplete, setSecondDocumentTypeAutoComplete] =
    useState("");
  const [dirtyfield, setDirtyField] = useState(false);
  const [partnerid, setpartnerId] = useState(id);
  const [fileName, setFileName] = useState("");
  const [fileSecondName, setFileSecondName] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const input2Ref = useRef<HTMLInputElement>(null);
  const [doctypeerrorMessage, setdoctypeerrorMessage] = useState("");
  const [seconddoctypeerrorMessage, setseconddoctypeerrorMessage] =
    useState("");
  const [seconddoctypeerrorMessage2, setseconddoctypeerrorMessage2] =
    useState("");
  const [docfileerrorMessage, setdocfileeerrorMessage] = useState("");
  const [seconddocfileerrorMessage, setseconddocfileeerrorMessage] =
    useState("");
  const [docfileerrorMessage1, setdocfileeerrorMessage1] = useState("");
  const [seconddocfileerrorMessage1, setseconddocfileeerrorMessage1] =
    useState("");
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [firstUploadLoading, setFirstUploadLoading] = useState(false);
  const [secondUploadLoading, setSecondUploadLoading] = useState(false);
  const [fileNameShow, setFileNameShow] = useState(false);
  const [secondfileNameShow, setSecondFileNameShow] = useState(false);
  const [descButtonLoading, setDescButtonLoading] = useState(false);
  const [filtereddocumentslist, setFilteredDocumentList] = useState<any[]>([]);
  const [newdocumentlist, setNewDocumentList] = useState<any[]>([]);
  const [filteredseconddocumentslist, setFilteredSecondDocumentList] = useState<
    any[]
  >([]);
  const [documentToolTipsData, setDocumentToolTipData] =
    useState<any>(DocumentToolTips);

  const [documentmodelNew, setDocumentmodelNew]: any = useState({
    id: 0,
    partnerId: Number(partnerid),
    document: [
      {
        id: 0,
        document: "",
        documentType: "",
        documentTypeId: 1,
      },
      {
        id: 0,
        document: "",
        documentType: "",
        documentTypeId: 2,
      },
    ],
    businessDescription: "",
    reasonForJoiningTheInstarailsNetwork: "",
  });

  const [defaultDocumentList, setDefaultDocumentList]: any = useState({
    document: [
      {
        id: 0,
        document: "",
        documentType: "",
        documentTypeId: 1,
      },
      {
        id: 0,
        document: "",
        documentType: "",
        documentTypeId: 2,
      },
    ],
  });
  const setModelEmpty = () => {
    setDocumentmodelNew({
      id: 0,
      partnerId: Number(partnerid),
      document: [
        {
          id: 0,
          document: "",
          documentType: "",
          documentTypeId: 1,
        },
        {
          id: 0,
          document: "",
          documentType: "",
          documentTypeId: 2,
        },
      ],
      businessDescription: "",
      reasonForJoiningTheInstarailsNetwork: "",
    });
    setDefaultDocumentList({
      document: [
        {
          id: 0,
          document: "",
          documentType: "",
          documentTypeId: 1,
        },
        {
          id: 0,
          document: "",
          documentType: "",
          documentTypeId: 2,
        },
      ],
    });
    setDocumentTypeAutoComplete("");
    setSecondDocumentTypeAutoComplete("");
  };

  const getDocumentByPartnerId = (id: any) => {
    setLoading(true);
    DocumentService.getDocumentByPartnerId(id)
      .then((response: any) => {
        if (response.data.id === 0) {
          response.data.document.push(defaultDocumentList);
          setDocumentmodelNew(response.data);
          setDocumentTypeAutoComplete(response.data[0].documentType);
          setSecondDocumentTypeAutoComplete(response.data[1].documentType);
          setLoading(false);
        } else {
          if (response.data.document[0].id === 0) {
            const documentData: any = response.data;
            documentData.document = defaultDocumentList.document;
            setDocumentmodelNew(documentData);
            setDocumentTypeAutoComplete(documentData.document[0].documentType);
            setSecondDocumentTypeAutoComplete(
              documentData.document[1].documentType
            );
          } else if (response.data.document.length == 2) {
            const documentData: any = response.data;
            const finalDocumentData: any = response.data.document.sort(
              (a: any, b: any) => a.documentTypeId - b.documentTypeId
            );
            documentData.document = finalDocumentData;
            setDocumentmodelNew(documentData);
            setDocumentTypeAutoComplete(documentData.document[0].documentType);
            setSecondDocumentTypeAutoComplete(
              documentData.document[1].documentType
            );
          } else {
            const documentData = response.data;

            const dummyData = {
              id: 0,
              document: "",
              documentType: "",
              documentTypeId:
                documentData.document[0].documentTypeId === 1 ? 2 : 1,
            };
            if (documentData.document[0].documentTypeId === 1) {
              documentData.document.push(dummyData);
            } else {
              documentData.document.unshift(dummyData);
            }
            setDocumentmodelNew(documentData);
            setDocumentTypeAutoComplete(documentData.document[0].documentType);
            setSecondDocumentTypeAutoComplete(
              documentData.document[1].documentType
            );
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const [businessdescerrorMessage, setbusinessdescerrorMessage] = useState("");
  const [instarailnetworkerrorMessage, setinstarailnetworkerrorMessage] =
    useState("");

  const getAddressByPartnerId = (id: number) => {
    setLoading(true);
    AddressService.getAddressByPartnerId(id)
      .then((response: any) => {
        const data = response.data;
        if (
          data.filter((x: any) => x.addressType === false)[0].country === "BRA"
        ) {
          setNewDocumentList(brazildocumentList);
        } else {
          setNewDocumentList(documentList);
        }
        setLoading(false);

        // setTimeout(() => { }, 100);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting the address details",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };

  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null) {
      return true;
    }
    return false;
  };

  const isValidate = (values: any) => {
    let formIsValid = true;
    if (CheckNull(values.businessDescription)) {
      setbusinessdescerrorMessage("Please enter business description.");
      formIsValid = false;
    }

    if (!CheckNull(values.businessDescription)) {
      if (values.businessDescription.trim().length === 0) {
        setbusinessdescerrorMessage("Please enter business description.");
        formIsValid = false;
      }
    }
    if (CheckNull(values.reasonForJoiningTheInstarailsNetwork)) {
      setinstarailnetworkerrorMessage(
        "Please enter reason for joining the instarails network."
      );
      formIsValid = false;
    }
    if (!CheckNull(values.reasonForJoiningTheInstarailsNetwork)) {
      if (values.reasonForJoiningTheInstarailsNetwork.trim().length === 0) {
        setinstarailnetworkerrorMessage(
          "Please enter reason for joining the instarails network."
        );
        formIsValid = false;
      }
    }

    if (CheckNull(values.document[0].document)) {
      setdoctypeerrorMessage("Please upload document file.");
      formIsValid = false;
    }
    if (CheckNull(values.document[0].documentType)) {
      setdoctypeerrorMessage("Please select document type.");
      formIsValid = false;
    }

    if (CheckNull(values.document[1].document)) {
      setseconddoctypeerrorMessage("Please upload document file.");
      formIsValid = false;
    }
    if (CheckNull(values.document[1].documentType)) {
      setseconddoctypeerrorMessage("Please select document type.");
      formIsValid = false;
    }
    if (
      CheckNull(values.document[0].documentType) &&
      CheckNull(values.document[1].documentType)
    ) {
      setdoctypeerrorMessage("Please select document type.");
      setseconddoctypeerrorMessage("Please select document type.");
      formIsValid = false;
    }
    if (
      !CheckNull(values.document[0].documentType) &&
      !CheckNull(values.document[1].documentType)
    ) {
      if (values.document[0].documentType === values.document[1].documentType) {
        setseconddoctypeerrorMessage("");

        setseconddoctypeerrorMessage2(
          "Document type cannot be the same, please upload a different document type."

        );

        formIsValid = false;
      }
    }

    return formIsValid;
  };

  const onFileChange = (event: any) => {


    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    if ((fileObj.type === "application/pdf" || fileObj === "image/tiff") && fileObj.size <= 4 * 1024 * 1024) {
      const imagemodel = event.target.files[0];
      const formData = new FormData();
      formData.append("Document", imagemodel);
      formData.append("PartnerId", Number(id).toString());
      setFirstUploadLoading(true);
      DocumentService.uploadDocument(formData)
        .then((data: any) => {
          setDocumentmodelNew((prevState: any) => {
            prevState.document[0].document = data.data.logoUrl || "";
            return {
              ...prevState,
            };
          });
          setDirtyField(true);
          setFirstUploadLoading(false);
          setFileNameShow(true);
        })
        .catch((error) => {
          console.log(error);
          setFirstUploadLoading(false);
        });
      setFileName(imagemodel.name);
      setdocfileeerrorMessage1("");
    } else {
      setdocfileeerrorMessage("");
      setdocfileeerrorMessage1("Please select a PDF or TIFF file with a maximum size of 4MB.")
    }
  };

  const onFileSecondChange = (event: any) => {

    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    if ((fileObj.type === "application/pdf" || fileObj === "image/tiff") && fileObj.size <= 4 * 1024 * 1024) {
      const imagemodel = event.target.files[0];
      const formData = new FormData();
      formData.append("Document", imagemodel);
      formData.append("PartnerId", Number(id).toString());
      setSecondUploadLoading(true);
      DocumentService.uploadDocument(formData)
        .then((data: any) => {
          setDocumentmodelNew((prevState: any) => {
            prevState.document[1].document = data.data.logoUrl || "";
            return {
              ...prevState,
            };
          });
          setSecondUploadLoading(false);
          setDirtyField(true);

          setSecondFileNameShow(true);
        })
        .catch((error) => {
          console.log(error);
          setSecondUploadLoading(false);
        });
      setFileSecondName(imagemodel.name);
      setseconddocfileeerrorMessage1("");
    } else {
      setseconddocfileeerrorMessage("");
      setseconddocfileeerrorMessage1("Please select a PDF or TIFF file with a maximum size of 4MB.");
    }
  };

  const ClearErrorMessage = () => {
    setbusinessdescerrorMessage("");
    setinstarailnetworkerrorMessage("");
    setdoctypeerrorMessage("");
    setseconddoctypeerrorMessage("");
    setseconddoctypeerrorMessage2("");
    setdocfileeerrorMessage("");
    setseconddocfileeerrorMessage("");
    setdocfileeerrorMessage1("");
    setseconddocfileeerrorMessage1("");
  };

  const onAddClick = () => {
    ClearErrorMessage();
    setButtonLoading(true);
    if (isValidate(documentmodelNew)) {
      DocumentService.addDocument(documentmodelNew)
        .then((res) => {
          getDocumentByPartnerId(Number(partnerid));
          setFileName("");
          setFileSecondName("");
          localStorage.setItem("StepFlag", "4");
          onSaveAndContinueClick("N");
          setButtonLoading(false);
        })
        .catch((error: any) => {
          if (error.response.status === 500) {
            toast.current?.show({
              severity: "error",
              summary: "Something went wrong",
              life: 3000,
            });
          } else if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Error while getting partner document.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };

  const onUpdateClick = (val: any) => {
    ClearErrorMessage();
    setButtonLoading(true);
    if (isValidate(documentmodelNew)) {
      DocumentService.updateDocument(documentmodelNew)
        .then((res) => {
          getDocumentByPartnerId(Number(partnerid));
          setFileName("");
          setFileSecondName("");
          localStorage.setItem("StepFlag", "4");
          onSaveAndContinueClick("N");
          setButtonLoading(false);
        })
        .catch((error: any) => {
          if (error.response.status === 500) {
            toast.current?.show({
              severity: "error",
              summary: "Something went wrong",
              life: 3000,
            });
          } else if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Error while getting partner document.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };

  const onBackClick = () => {
    setButtonLoadingBack(true);
    setTimeout(() => {
      onSaveAndContinueClick("B");
    }, 1000);
  };

  const searchDocumentType = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < newdocumentlist.length; i++) {
      let item = newdocumentlist[i];
      if (item.type.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredDocumentList(_filteredItems);
  };

  const searchSecondDocumentType = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < newdocumentlist.length; i++) {
      let item = newdocumentlist[i];
      if (item.type.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredSecondDocumentList(_filteredItems);
  };

  // const onDeleteHandleClick = (document: any) => {

  //   confirmDialog({
  //     message: "Are you sure you want to Delete?",

  //     accept: () => onDocumentDelete(document.id),
  //     reject,
  //   });
  // };

  const onDeleteHandleClick = (document: any) => {
    SetDeleteData(document);
    setDisplayDeletePopup(true);
  };

  const onDeleteClick = () => {
    onDocumentDelete(deletedata.id);
  };
  const onDocumentDelete = (id: any) => {
    setDisplayDeletePopup(false);
    DocumentService.deleteDocument(Number(id))
      .then((data: any) => {
        getDocumentByPartnerId(partnerid);
        localStorage.setItem("StepFlag", "3");
        onSaveAndContinueClick("D");
        setDirtyField(true);
        SetDeleteData(null);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while deleting document details.",
            life: 3000,
          });
        }
      });
  };

  const reject = () => {

    setDisplayDeletePopup(false);
  };

  const handleClose = () => {
    if (dirtyfield === true) {
      setDisplayPopup(true);
    } else {
      ClearErrorMessage();
    }

  };

  const OnPopupClose = () => {
    setDisplayPopup(false);
  };
  const OnPopupOk = () => {
    documentmodelNew.id === 0
      ? setModelEmpty()
      : getDocumentByPartnerId(Number(partnerid));
    setFileNameShow(false);
    setSecondFileNameShow(false);
    setDisplayPopup(false);
    setDirtyField(false);
    ClearErrorMessage();

  };

  useEffect(() => {
    setButtonLoadingBack(false);
    setButtonLoadingSkip(false);
    {
      Number(status) === 8 ? setReadOnly(true) : setReadOnly(false);
    }

    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    getDocumentByPartnerId(partnerid);
    getAddressByPartnerId(Number(partnerid));
  }, []);

  return (
    <>
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <div className="container-fluid acc-screen document-main-div">
            <Toast ref={toast}></Toast>
            <div className="row ">
              {/* <ConfirmDialog id="confirm-popup" /> */}
              <div className="col-md-12 info-section ">
                <div className="row text-area-row">
                  <div className="col-md-6  info-section ">
                    <span className="input-label">
                      Business Description <span className="color-red">*</span>
                      <ToolTip props={documentToolTipsData[0]} />
                    </span>
                    <div className="textarea-wrap">
                      <textarea
                        readOnly={readonly}
                        className="form-control text-area"
                        name="businessDescription"
                        placeholder="Enter business description"
                        value={documentmodelNew.businessDescription}
                        onChange={(e) => {
                          setDocumentmodelNew({
                            ...documentmodelNew,
                            businessDescription: e.target.value,
                          });
                          setDirtyField(true);
                        }}
                      />
                      {businessdescerrorMessage !== null &&
                        businessdescerrorMessage.length > 0 ? (
                        <span className="error-msg">
                          {businessdescerrorMessage}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6 info-section ">
                    <span className="input-label">
                      {" "}
                      Reason for joining the instarails network{" "}
                      <span className="color-red">*</span>
                      <ToolTip props={documentToolTipsData[1]} />
                    </span>
                    <div className="textarea-wrap">
                      <textarea
                        readOnly={readonly}
                        className="form-control text-area"
                        name="reasonForJoiningTheInstarailsNetwork"
                        placeholder="Enter reason for joining the instarails network"
                        value={
                          documentmodelNew.reasonForJoiningTheInstarailsNetwork
                        }
                        onChange={(e) => {
                          setDocumentmodelNew({
                            ...documentmodelNew,
                            reasonForJoiningTheInstarailsNetwork:
                              e.target.value,
                          });
                          setDirtyField(true);
                        }}
                      />
                      {instarailnetworkerrorMessage !== null &&
                        instarailnetworkerrorMessage.length > 0 ? (
                        <span className="error-msg">
                          {instarailnetworkerrorMessage}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-md-6 info-section info-section-border">
                    <div className="col form-group ">
                      <span className="input-label">
                        Document Type <span className="color-red">*</span>{" "}
                        <ToolTip props={documentToolTipsData[2]} />
                      </span>
                      <AutoComplete
                        readOnly={readonly}
                        disabled={readonly}
                        field="type"
                        dropdown
                        aria-label="Countries"
                        dropdownAriaLabel="Select document type"
                        className="dropdown-acc"
                        placeholder="Select document type"
                        suggestions={filtereddocumentslist}
                        completeMethod={searchDocumentType}
                        onChange={(e: any) => {
                          if (e.value !== null) {
                            setDocumentTypeAutoComplete(e.value);
                            setDocumentmodelNew((prevState: any) => {
                              prevState.document[0].documentType =
                                e.value.type || "";
                              return {
                                ...prevState,
                              };
                            });
                            setDirtyField(true);
                          }
                        }}
                        value={documentTypeAutoComplete}
                        forceSelection
                      />
                      {doctypeerrorMessage !== null &&
                        doctypeerrorMessage.length > 0 ? (
                        <span className="error-msg">{doctypeerrorMessage}</span>
                      ) : null}
                    </div>
                    <div className="col form-group">
                      <span className="input-label">
                        Document Type <span className="color-red">*</span>{" "}
                        <ToolTip props={documentToolTipsData[2]} />
                      </span>
                      <AutoComplete
                        readOnly={readonly}
                        disabled={readonly}
                        field="type"
                        dropdown
                        aria-label="Countries"
                        dropdownAriaLabel="Select document type"
                        className="dropdown-acc"
                        placeholder="Select document type"
                        suggestions={filteredseconddocumentslist}
                        completeMethod={searchSecondDocumentType}
                        onChange={(e: any) => {
                          if (e.value !== null) {
                            setSecondDocumentTypeAutoComplete(e.value);
                            setDocumentmodelNew((prevState: any) => {
                              prevState.document[1].documentType =
                                e.value.type || "";
                              return {
                                ...prevState,
                              };
                            });
                            setDirtyField(true);
                          }
                        }}
                        value={seconddocumentTypeAutoComplete}
                        forceSelection
                      />
                      {seconddoctypeerrorMessage !== null &&
                        seconddoctypeerrorMessage.length > 0 ? (
                        <>

                          <span className="error-msg ">
                            {seconddoctypeerrorMessage}
                          </span>

                          <span className="error-msg second-doc-error">
                            {seconddoctypeerrorMessage2}
                          </span>
                        </>
                      ) : null}
                      {seconddoctypeerrorMessage2 !== null &&
                        seconddoctypeerrorMessage2.length > 0 ? (
                        <>
                          <span className="error-msg second-doc-error">
                            {seconddoctypeerrorMessage2}
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 info-section upload-doc">
                    <div className=" form-group first-document-upload doc-upload-section">
                      <div id="fileupload">
                        <span className="input-label">Upload Documents</span>
                        {documentmodelNew.document[0].id === 0 ? (
                          <div
                            className="myfileupload-buttonbar"
                            key={new Date().toString()}
                          >
                            {fileNameShow ? (
                              <label className="myui-button ">
                                <span className="file-name">{fileName}</span>
                              </label>
                            ) : null}

                            <Button
                              hidden={readonly}
                              disabled={secondUploadLoading ? true : false}
                              className="document-upload"
                              loading={firstUploadLoading}
                            >
                              <img src={UploadIcon} />
                              <input
                                hidden={readonly}
                                disabled={secondUploadLoading ? true : false}
                                ref={inputRef}
                                id="FileUpload1"
                                name="Name"
                                accept=".pdf, .tiff, .tif"
                                type="file"
                                multiple
                                onChange={(e) => onFileChange(e)}
                                className="inputdata"

                              />
                            </Button>
                          </div>
                        ) : (
                          <div className="file-name-delete">
                            <a
                              className="pdf-btn"
                              href={documentmodelNew.document[0].document}
                              target="_blank"
                            >
                              <img
                                src={Document}
                                alt="img"
                                className="doc-img"
                              />
                              <p>
                                {documentmodelNew.document[0].document
                                  .split("/")
                                  .pop()}
                              </p>
                            </a>

                            <button
                              className="delete-doc"
                              hidden={readonly}
                              title="Delete"
                            >
                              <i
                                className="pi pi-trash"
                                onClick={(e) =>
                                  onDeleteHandleClick(
                                    documentmodelNew.document[0]
                                  )
                                }
                              ></i>
                            </button>
                          </div>
                        )}
                      </div>
                      {docfileerrorMessage !== null &&
                        docfileerrorMessage.length > 0 ? (
                        <span className="error-msg">{docfileerrorMessage}</span>
                      ) : null}
                      {docfileerrorMessage1 !== null &&
                        docfileerrorMessage1.length > 0 ? (
                        <span className="error-msg">{docfileerrorMessage1}</span>
                      ) : null}

                    </div>

                    <div className="col-md-12 form-group first-document-upload doc-upload-section">
                      <div id="fileupload" >
                        <span className="input-label">Upload Documents</span>
                        {documentmodelNew.document[1].id === 0 ? (
                          <div
                            className="myfileupload-buttonbar "
                            key={new Date().toString()}
                          >
                            {secondfileNameShow ? (
                              <label className="myui-button ">
                                <span className="file-name">
                                  {fileSecondName}
                                </span>
                              </label>
                            ) : null}
                            <Button
                              hidden={readonly}
                              disabled={firstUploadLoading ? true : false}
                              className="document-upload"
                              loading={secondUploadLoading}
                            >
                              <img src={UploadIcon} />
                              <input
                                hidden={readonly}
                                disabled={firstUploadLoading ? true : false}
                                ref={input2Ref}
                                name="Name"
                                accept=".pdf, .tiff, .tif"
                                type="file"
                                multiple
                                onChange={(e) => onFileSecondChange(e)}
                                className="inputdata"

                              />
                            </Button>
                          </div>
                        ) : (
                          <div className="file-name-delete">
                            <a
                              className="pdf-btn"
                              href={documentmodelNew.document[1].document}
                              target="_blank"
                            >
                              <img
                                src={Document}
                                alt="img"
                                className="doc-img"
                              />
                              <p>
                                {documentmodelNew.document[1].document
                                  .split("/")
                                  .pop()}
                              </p>
                            </a>

                            <button
                              className="delete-doc"
                              hidden={readonly}
                              title="Delete"
                            >
                              <i
                                className="pi pi-trash"
                                onClick={(e) =>
                                  onDeleteHandleClick(
                                    documentmodelNew.document[1]
                                  )
                                }
                              ></i>
                            </button>
                          </div>
                        )}
                      </div>

                      {seconddocfileerrorMessage !== null &&
                        seconddocfileerrorMessage.length > 0 ? (
                        <span className="error-msg">
                          {seconddocfileerrorMessage}
                        </span>
                      ) : null}
                      {seconddocfileerrorMessage1 !== null &&
                        seconddocfileerrorMessage1.length > 0 ? (
                        <span className="error-msg">
                          {seconddocfileerrorMessage1}
                        </span>
                      ) : null}
                    </div>

                  </div>
                </div>
                <div className="button-section">
                  <div className="bottom-btns">
                    <Button
                      iconPos="left"
                      label="Back"
                      loading={buttonLoadingBack}
                      onClick={onBackClick}
                      className="btn btn-back second-btn"
                    />

                    {Number(status) === 8 ? (
                      <>
                        <Button
                          iconPos="left"
                          label="Next"
                          loading={buttonLoadingSkip}
                          onClick={onNextClick}
                          className="btn btn-continue   btn-next second-btn"
                        />
                      </>
                    ) : (
                      <>
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          className="btn btn-cancel  second-btn"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                        <Button
                          disabled={firstUploadLoading ? true : secondUploadLoading ? true : false}
                          iconPos="left"
                          loading={buttonLoading}
                          label=" Save and Continue"
                          className="btn btn-continue  second-btn"
                          onClick={
                            documentmodelNew.id === 0
                              ? onAddClick
                              : onUpdateClick
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {displaypopup ? (
            <>
              <div className="popup-body">
                <div className="Cancel-popup">
                  <div className="text-center ">
                    <div className="awesome-text">
                      <h4>Are you sure you want to cancel?</h4>
                      <p> All unsaved changes will be lost.</p>
                    </div>
                  </div>
                  <div className="payment-screen-btn">
                    <button
                      className="btn btn-cancel second-btn"
                      onClick={OnPopupClose}
                    >
                      {" "}
                      No
                    </button>
                    <button
                      className="btn btn-continue second-btn yes-btn-popup"
                      onClick={OnPopupOk}
                    >
                      {" "}
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {displaydeletepopup ? (
            <div className="popup-body">
              <div className="regiter-popup Payment-screen">
                <div className="text-center ">
                  <div className="awesome-text">
                    <h4>
                      <i className="pi pi-info-circle"></i> Are you sure you
                      want to Delete?
                    </h4>
                  </div>
                </div>
                <div className="payment-screen-btn">
                  <button
                    className="btn btn-cancel second-btn "
                    onClick={reject}
                  >
                    {" "}
                    No
                  </button>
                  <button
                    className="btn btn-continue second-btn yes-btn-popup"
                    onClick={onDeleteClick}
                  >
                    {" "}
                    Yes
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};
export default Documents;
