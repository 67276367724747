import axios from "axios";
import { environment } from "../../../environments/environment";
import { IUserManagement } from "../../../models/IUserManagement";

export class UserManagementService {
  private static URL: any = environment.userApiUrl;

  public static getUserByPartnerId = () => {
    let UserURL: string = `${this.URL}/user/partner`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    };
    return axios.get<any>(UserURL, config);
  };

  public static getUserById = (id:any) => {
    let UserURL: string = `${this.URL}/user/getbyid?id=${id}`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    };
    return axios.get<any>(UserURL, config);
  };

  public static addUser(user: any) {
    let UserURL: string = `${this.URL}/user`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    };
    return axios.post<any>(UserURL, user, config);
  }

  public static updateUser(user: any) {
    let UserURL: string = `${this.URL}/user`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    };
    return axios.put<any>(UserURL, user, config);
  }

  public static updateUserProfileStatus(Id: any,changeStatus :any) {
  
    let UserURL: string = `${this.URL}/user/id?id=${Id}&status=${changeStatus}`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    };
    return axios.put<any>(UserURL,Id, config);
  }

  public static getUserRole() {
    let UserURL: string = `${this.URL}/common/role`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    };
    return axios.get<any>(UserURL, config);
  }

  public static nextUser() {
    let UserURL: string = `${this.URL}/user/updatestep`;
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    };
    return axios.post<any>(UserURL, '', config);
}

}
