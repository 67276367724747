import axios from "axios";
import { environment } from "../../environments/environment";

export class CheckQuoteService {
  private static PartnerURL: any = environment.partnerApiUrl;

  public static getApikeyByPartnerId = (partnerid: any, quoteModel: any) => {
    let UserURL: string = `${this.PartnerURL}/partnersecurity/checkquotes?partnerId=${partnerid}`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    };
    return axios.post<any>(UserURL, quoteModel, config);
  };
}
