import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PartnershipDetailsService } from "../../../services/Partner/PartnershipDetails/PartnershipDetailsService";
import { AutoComplete } from "primereact/autocomplete";
import { Logout } from "../../../utils/AccountUtils";
import { SLATypeList, validTimeInSeconds } from "../../../utils/utils";
import Scrollbars from "react-custom-scrollbars-2";
import Facilitator from "../../PartnerOnboardReady/Facilitator/Facilitator";
import FacilitatorTabContent from "../../PartnerOnboardReady/Facilitator/FacilitatorTabcontent";
import { TabPanel, TabView } from "primereact/tabview";
import AddFacilitator from "../../PartnerOnboardReady/Facilitator/AddFacilitator";
import { UserManagementService } from "../../../services/Partner/UserManagement/UserManagementService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import LocalStorageContext from "../../Context/LocalStorageContext";

const PartnershipDetails: React.FC<any> = ({
  onSaveAndContinueClick,
  onNextClick,
  buttonLoadingSkip,
  buttonLoadingBack,
  setButtonLoadingBack,
  setButtonLoadingSkip,
  setIsFacilitator
}) => {
  const [readonly, setReadOnly] = useState(false);
  const [status, setstatus] = useState(
    localStorage.getItem("OnboardingStatus")
  );
  const context = useContext(LocalStorageContext);
  const [displaypopup, setDisplayPopup] = useState(false);
  const [dirtyfield, setDirtyField] = useState(false);
  const navigate = useNavigate();
  const [filteredslatype, setFilteredSLAType] = useState<any>(null);
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(true);
  const id = localStorage.getItem("PartnerId");
  const [partnerId, setpartnerId] = useState(id);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [yesfacilitatorvalue, setYesFacilitatorValue] = useState(false);
  const [index, setIndex] = useState(0);
  const [checkfacilitator, setCheckFacilitator] = useState(false);
  const [nofacilitatorvalue, setNoFacilitatorValue] = useState(false);
  const [partnerTypesMultiSelectList, setPartnerTypesMultiSelectList] =
    useState<any[]>([]);
  const [deliveryTypesMultiSelectList, setDeliveryTypesMultiSelectList] =
    useState<any[]>([]);
  const [partnertypeserrormessage, setPartnerTypesErrorMessage] = useState("");
  const [networktypeserrormessage, setNetworkTypesErrorMessage] = useState("");
  const [deliverytypeserrormessage, setDeliveryTypesErrorMessage] =
    useState("");
  const [rtperrormessage, setRTPsErrorMessage] = useState("");
  const [mobilewalleterrormessage, setMobileWalletErrorMessage] = useState("");
  const [bankerrormessage, setBankErrorMessage] = useState("");
  const [cashpickuperrormessage, setCashPickupErrorMessage] = useState("");
  const [rtptypeAutoComplete, setRTPTypeAutoComplete] = useState("Seconds");
  const [readyPopup, setReadyPopup] = useState(false);

  const [mobilewallettypeAutoComplete, setMobileWalletTypeAutoComplete] =
    useState("Seconds");
  const [banktypeAutoComplete, setBankTypeAutoComplete] = useState("Seconds");
  const [cashpickuptypeAutoComplete, setCashPickupTypeAutoComplete] =
    useState("Seconds");
  const [errormessage, setErrorMessage] = useState("");
  const [partnershipDetailsModel, setPartnershipDetailsModel] = React.useState({
    id: 0,
    partnerId: Number(partnerId),
    isFacilitator: false,
    partnerDeliveryType: "",
    partnerPaymentRole: 0,
    partnerTypes: "",
    facilitator: 0,
    deliveryTypeSLA: [
      {
        id: 0,
        deliveryType: "RTP",
        timeInSeconds: "1",
        timeFormat: "Seconds",
        actualTime: "1",
      },
      {
        id: 0,
        deliveryType: "MobileWallet",
        timeInSeconds: "1",
        timeFormat: "Seconds",
        actualTime: "1",
      },
      {
        id: 0,
        deliveryType: "BankAccount",
        timeInSeconds: "1",
        timeFormat: "Seconds",
        actualTime: "1",
      },
      {
        id: 0,
        deliveryType: "CashPickup",
        timeInSeconds: "1",
        timeFormat: "Seconds",
        actualTime: "1",
      },
    ],
  });

  const onTabChange = (e: any) => {
    setIndex(e.index);
  };
  const setModelEmpty = () => {
    setPartnershipDetailsModel({
      id: 0,
      partnerId: Number(partnerId),
      isFacilitator: false,
      partnerDeliveryType: "",
      partnerPaymentRole: 0,
      partnerTypes: "",
      facilitator: 0,
      deliveryTypeSLA: [
        {
          id: 0,
          deliveryType: "RTP",
          timeInSeconds: "1",
          timeFormat: "Seconds",
          actualTime: "1",
        },
        {
          id: 0,
          deliveryType: "MobileWallet",
          timeInSeconds: "1",
          timeFormat: "Seconds",
          actualTime: "1",
        },
        {
          id: 0,
          deliveryType: "BankAccount",
          timeInSeconds: "1",
          timeFormat: "Seconds",
          actualTime: "1",
        },
        {
          id: 0,
          deliveryType: "CashPickup",
          timeInSeconds: "1",
          timeFormat: "Seconds",
          actualTime: "1",
        },
      ],
    });
    setRTPTypeAutoComplete("Seconds");
    setMobileWalletTypeAutoComplete("Seconds");
    setBankTypeAutoComplete("Seconds");
    setCashPickupTypeAutoComplete("Seconds");
  };
  const [defaultdeliveryTypeSLAList, setDefaultdeliveryTypeSLAList]: any =
    useState({
      deliveryTypeSLA: [
        {
          id: 0,
          deliveryType: "RTP",
          timeInSeconds: "1",
          timeFormat: "Seconds",
          actualTime: "1",
        },
        {
          id: 0,
          deliveryType: "MobileWallet",
          timeInSeconds: "1",
          timeFormat: "Seconds",
          actualTime: "1",
        },
        {
          id: 0,
          deliveryType: "BankAccount",
          timeInSeconds: "1",
          timeFormat: "Seconds",
          actualTime: "1",
        },
        {
          id: 0,
          deliveryType: "CashPickup",
          timeInSeconds: "1",
          timeFormat: "Seconds",
          actualTime: "1",
        },
      ],
    });

  const ErrorMessageEmptyModel = () => {
    setPartnerTypesErrorMessage("");
    setNetworkTypesErrorMessage("");
    setDeliveryTypesErrorMessage("");
    setRTPsErrorMessage("");
    setMobileWalletErrorMessage("");
    setBankErrorMessage("");
    setCashPickupErrorMessage("");
    setErrorMessage("");
  };

  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null || value === 0) {
      return true;
    }
    return false;
  };
  const setData = (Data: any) => {
    if (Data.partnerDeliveryType.includes("RTP")) {
      partnershipDetailsModel.deliveryTypeSLA[0] = Data.deliveryTypeSLA.filter(
        (x: any) => x.deliveryType === 1
      )[0];
    } else {
      partnershipDetailsModel.deliveryTypeSLA[0] =
        defaultdeliveryTypeSLAList.deliveryTypeSLA[0];
    }
    if (Data.partnerDeliveryType.includes("MobileWallet")) {
      partnershipDetailsModel.deliveryTypeSLA[1] = Data.deliveryTypeSLA.filter(
        (x: any) => x.deliveryType === 4
      )[0];
    } else {
      partnershipDetailsModel.deliveryTypeSLA[1] =
        defaultdeliveryTypeSLAList.deliveryTypeSLA[1];
    }
    if (Data.partnerDeliveryType.includes("BankAccount")) {
      partnershipDetailsModel.deliveryTypeSLA[2] = Data.deliveryTypeSLA.filter(
        (x: any) => x.deliveryType === 2
      )[0];
    } else {
      partnershipDetailsModel.deliveryTypeSLA[2] =
        defaultdeliveryTypeSLAList.deliveryTypeSLA[2];
    }
    if (Data.partnerDeliveryType.includes("CashPickup")) {
      partnershipDetailsModel.deliveryTypeSLA[3] = Data.deliveryTypeSLA.filter(
        (x: any) => x.deliveryType === 5
      )[0];
    } else {
      partnershipDetailsModel.deliveryTypeSLA[3] =
        defaultdeliveryTypeSLAList.deliveryTypeSLA[3];
    }

    Data.deliveryTypeSLA = partnershipDetailsModel.deliveryTypeSLA;
    {
      Data.deliveryTypeSLA[0].deliveryType = 1
        ? (Data.deliveryTypeSLA[0].deliveryType = "RTP")
        : null;
    }
    {
      Data.deliveryTypeSLA[1].deliveryType = 4
        ? (Data.deliveryTypeSLA[1].deliveryType = "MobileWallet")
        : null;
    }
    {
      Data.deliveryTypeSLA[2].deliveryType = 2
        ? (Data.deliveryTypeSLA[2].deliveryType = "BankAccount")
        : null;
    }
    {
      Data.deliveryTypeSLA[3].deliveryType = 5
        ? (Data.deliveryTypeSLA[3].deliveryType = "CashPickup")
        : null;
    }
    if (Data.deliveryTypeSLA[0].timeFormat === "Seconds") {
      setRTPTypeAutoComplete("Seconds");
      Data.deliveryTypeSLA[0].timeFormat = "Seconds";
    }
    if (Data.deliveryTypeSLA[1].timeFormat === "Seconds") {
      setMobileWalletTypeAutoComplete("Seconds");
      Data.deliveryTypeSLA[1].timeFormat = "Seconds";
    }
    if (Data.deliveryTypeSLA[2].timeFormat === "Seconds") {
      setBankTypeAutoComplete("Seconds");
      Data.deliveryTypeSLA[2].timeFormat = "Seconds";
    }
    if (Data.deliveryTypeSLA[3].timeFormat === "Seconds") {
      setCashPickupTypeAutoComplete("Seconds");
      Data.deliveryTypeSLA[3].timeFormat = "Seconds";
    }

    if (Data.deliveryTypeSLA[0].timeFormat === "Minutes") {
      Data.deliveryTypeSLA[0].timeInSeconds = (
        Data.deliveryTypeSLA[0].timeInSeconds / 60
      ).toString();
      setRTPTypeAutoComplete("Minutes");
      Data.deliveryTypeSLA[0].timeFormat = "Minutes";
    }
    if (Data.deliveryTypeSLA[1].timeFormat === "Minutes") {
      Data.deliveryTypeSLA[1].timeInSeconds = (
        Data.deliveryTypeSLA[1].timeInSeconds / 60
      ).toString();
      setMobileWalletTypeAutoComplete("Minutes");
      Data.deliveryTypeSLA[1].timeFormat = "Minutes";
    }
    if (Data.deliveryTypeSLA[2].timeFormat === "Minutes") {
      Data.deliveryTypeSLA[2].timeInSeconds = (
        Data.deliveryTypeSLA[2].timeInSeconds / 60
      ).toString();
      setBankTypeAutoComplete("Minutes");
      Data.deliveryTypeSLA[2].timeFormat = "Minutes";
    }
    if (Data.deliveryTypeSLA[3].timeFormat === "Minutes") {
      Data.deliveryTypeSLA[3].timeInSeconds = (
        Data.deliveryTypeSLA[3].timeInSeconds / 60
      ).toString();
      setCashPickupTypeAutoComplete("Minutes");
      Data.deliveryTypeSLA[3].timeFormat = "Minutes";
    }

    if (Data.deliveryTypeSLA[0].timeFormat === "Hours") {
      Data.deliveryTypeSLA[0].timeInSeconds = (
        Data.deliveryTypeSLA[0].timeInSeconds / 3600
      ).toString();
      setRTPTypeAutoComplete("Hours");
      Data.deliveryTypeSLA[0].timeFormat = "Hours";
    }
    if (Data.deliveryTypeSLA[1].timeFormat === "Hours") {
      Data.deliveryTypeSLA[1].timeInSeconds = (
        Data.deliveryTypeSLA[1].timeInSeconds / 3600
      ).toString();
      setMobileWalletTypeAutoComplete("Hours");
      Data.deliveryTypeSLA[1].timeFormat = "Hours";
    }
    if (Data.deliveryTypeSLA[2].timeFormat === "Hours") {
      Data.deliveryTypeSLA[2].timeInSeconds = (
        Data.deliveryTypeSLA[2].timeInSeconds / 3600
      ).toString();
      setBankTypeAutoComplete("Hours");
      Data.deliveryTypeSLA[2].timeFormat = "Hours";
    }
    if (Data.deliveryTypeSLA[3].timeFormat === "Hours") {
      Data.deliveryTypeSLA[3].timeInSeconds = (
        Data.deliveryTypeSLA[3].timeInSeconds / 3600
      ).toString();
      setCashPickupTypeAutoComplete("Hours");
      Data.deliveryTypeSLA[3].timeFormat = "Hours";
    }

    if (Data.deliveryTypeSLA[0].timeFormat === "Days") {
      Data.deliveryTypeSLA[0].timeInSeconds = (
        Data.deliveryTypeSLA[0].timeInSeconds / 86400
      ).toString();
      setRTPTypeAutoComplete("Days");
      Data.deliveryTypeSLA[0].timeFormat = "Days";
    }
    if (Data.deliveryTypeSLA[1].timeFormat === "Days") {
      Data.deliveryTypeSLA[1].timeInSeconds = (
        Data.deliveryTypeSLA[1].timeInSeconds / 86400
      ).toString();
      setMobileWalletTypeAutoComplete("Days");
      Data.deliveryTypeSLA[1].timeFormat = "Days";
    }
    if (Data.deliveryTypeSLA[2].timeFormat === "Days") {
      Data.deliveryTypeSLA[2].timeInSeconds = (
        Data.deliveryTypeSLA[2].timeInSeconds / 86400
      ).toString();
      setBankTypeAutoComplete("Days");
      Data.deliveryTypeSLA[2].timeFormat = "Days";
    }
    if (Data.deliveryTypeSLA[3].timeFormat === "Days") {
      Data.deliveryTypeSLA[3].timeInSeconds = (
        Data.deliveryTypeSLA[3].timeInSeconds / 86400
      ).toString();
      setCashPickupTypeAutoComplete("Days");
      Data.deliveryTypeSLA[3].timeFormat = "Days";
    }

    setPartnershipDetailsModel(Data);
  };
  const partnershipDetailsByPartnerId = (id: number) => {
    setLoading(true);
    ErrorMessageEmptyModel();
    setModelEmpty();
    PartnershipDetailsService.getPartnershipDetailsByPartnerId(id)
      .then((response) => {
        const data = response.data;
        if (response.data.partnerDeliveryType === null) {
          response.data.deliveryTypeSLA =
            defaultdeliveryTypeSLAList.deliveryTypeSLA;
          setPartnershipDetailsModel(response.data);
          setLoading(false);
          if (data.isFacilitator === true) {
            setCheckFacilitator(true);
            setIsFacilitator('true');
            setYesFacilitatorValue(true);
          } else {
            setCheckFacilitator(false);
            setIsFacilitator('false');
            setNoFacilitatorValue(true);
          }
        } else if (
          data.deliveryTypeSLA.length === 1 &&
          data.deliveryTypeSLA[0].deliveryType === 0
        ) {
          const Data: any = response.data;
          Data.deliveryTypeSLA = defaultdeliveryTypeSLAList.deliveryTypeSLA;
          if (data.isFacilitator === true) {
            setCheckFacilitator(true);
            setYesFacilitatorValue(true);
            setIsFacilitator('true');
          } else {
            setIsFacilitator('false');
            setCheckFacilitator(false);
            setNoFacilitatorValue(true);
          }
          setPartnershipDetailsModel(Data);
          setLoading(false);
        } else {
          if (data.deliveryTypeSLA.length < 4) {
            const Data: any = response.data;
            setData(Data);
            if (data.isFacilitator === true) {
              setCheckFacilitator(true);
              setIsFacilitator('true');
              setYesFacilitatorValue(true);
            } else {
              setCheckFacilitator(false);
              setIsFacilitator('false');
              setNoFacilitatorValue(true);
            }
            setLoading(false);
          } else {
            const Data: any = response.data;
            setData(Data);
            if (data.isFacilitator === true) {
              setCheckFacilitator(true);
              setIsFacilitator('true');
              setYesFacilitatorValue(true);
            } else {
              setCheckFacilitator(false);
              setIsFacilitator('false');
              setNoFacilitatorValue(true);
            }
            setLoading(false);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting partnership details.",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };

  const isValidate = (values: any) => {
    let formIsValid = true;
    ErrorMessageEmptyModel();
    if (CheckNull(values.partnerTypes) || values.partnerTypes.length <= 2) {
      setPartnerTypesErrorMessage("Please select type of payments.");
      formIsValid = false;
    }
    if (CheckNull(values.partnerPaymentRole)) {
      setNetworkTypesErrorMessage("Please select instarails network.");
      formIsValid = false;
    }
    if (values.partnerPaymentRole == 2 || values.partnerPaymentRole == 3) {
      if (
        CheckNull(values.partnerDeliveryType) ||
        values.partnerDeliveryType.length <= 2
      ) {
        setDeliveryTypesErrorMessage("Please select delivery types.");
        formIsValid = false;
      }
      if (
        !(
          CheckNull(values.partnerDeliveryType) ||
          values.partnerDeliveryType.length <= 2
        )
      ) {
        if (values.partnerDeliveryType.includes("RTP")) {
          if (
            CheckNull(values.deliveryTypeSLA[0].timeInSeconds) ||
            CheckNull(rtptypeAutoComplete) ||
            !validTimeInSeconds.test(values.deliveryTypeSLA[0].timeInSeconds)
          ) {
            setRTPsErrorMessage("Please enter time for RTP delivery type.");
            formIsValid = false;
          }
          if (!CheckNull(values.deliveryTypeSLA[0].timeInSeconds)) {
            if (values.deliveryTypeSLA[0].timeInSeconds < 1) {
              setRTPsErrorMessage(
                "Please enter valid time for RTP delivery type."
              );
              formIsValid = false;
            }
          }
        }
        if (values.partnerDeliveryType.includes("MobileWallet")) {
          if (
            CheckNull(values.deliveryTypeSLA[1].timeInSeconds) ||
            CheckNull(mobilewallettypeAutoComplete) ||
            !validTimeInSeconds.test(values.deliveryTypeSLA[1].timeInSeconds)
          ) {
            setMobileWalletErrorMessage(
              "Please enter time for mobile wallet delivery type."
            );
            formIsValid = false;
          }

          if (!CheckNull(values.deliveryTypeSLA[1].timeInSeconds)) {
            if (values.deliveryTypeSLA[1].timeInSeconds < 1) {
              setMobileWalletErrorMessage(
                "Please enter valid time for mobile wallet delivery type."
              );
              formIsValid = false;
            }
          }
        }
        if (values.partnerDeliveryType.includes("BankAccount")) {
          if (
            CheckNull(values.deliveryTypeSLA[2].timeInSeconds) ||
            CheckNull(banktypeAutoComplete) ||
            !validTimeInSeconds.test(values.deliveryTypeSLA[2].timeInSeconds)
          ) {
            setBankErrorMessage("Please enter time for bank delivery type.");
            formIsValid = false;
          }
          if (!CheckNull(values.deliveryTypeSLA[2].timeInSeconds)) {
            if (values.deliveryTypeSLA[2].timeInSeconds < 1) {
              setBankErrorMessage(
                "Please enter valid time for bank delivery type."
              );
              formIsValid = false;
            }
          }
        }
        if (values.partnerDeliveryType.includes("CashPickup")) {
          if (
            CheckNull(values.deliveryTypeSLA[3].timeInSeconds) ||
            CheckNull(cashpickuptypeAutoComplete) ||
            !validTimeInSeconds.test(values.deliveryTypeSLA[3].timeInSeconds)
          ) {
            setCashPickupErrorMessage(
              "Please enter time for cash pickup delivery type."
            );
            formIsValid = false;
          }
          if (!CheckNull(values.deliveryTypeSLA[3].timeInSeconds)) {
            if (values.deliveryTypeSLA[3].timeInSeconds < 1) {
              setCashPickupErrorMessage(
                "Please enter valid time for cash pickup delivery type."
              );
              formIsValid = false;
            }
          }
        }
      }
    }
    return formIsValid;
  };

  const handleClose = () => {
    if (dirtyfield === true) {
      setDisplayPopup(true);
    }

  };

  const OnPopupClose = () => {
    setDisplayPopup(false);
  };
  const OnPopupOk = () => {
    partnershipDetailsByPartnerId(Number(partnerId));

    setDisplayPopup(false);
    setDirtyField(false);
    ErrorMessageEmptyModel();
  };
  const searchSLAType = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < SLATypeList.length; i++) {
      let item = SLATypeList[i];

      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredSLAType(_filteredItems);
  };
  const onAddClick = () => {
    partnershipDetailsModel.partnerId = Number(partnerId);
    setButtonLoading(true);
    if (isValidate(partnershipDetailsModel)) {
      PartnershipDetailsService.addPartnershipDetails(partnershipDetailsModel)
        .then((response) => {
          localStorage.setItem("StepFlag", "6");
          localStorage.setItem("OnboardingStatus", "6");
          localStorage.setItem("IsFacilitator", response.data);
          context.updateFacilitator(response.data);
          setButtonLoading(false);
          onSaveAndContinueClick("N");
        })
        .catch((error) => {
          if (error.response.status === 500) {
            toast.current?.show({
              severity: "error",
              summary: "Something went wrong",
              life: 3000,
            });
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data[0].errorMessage,
              life: 3000,
            });
          } else if (error.response.status === 401) {
            Logout(navigate);
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Error while updating partnership details.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };
  const cancelPopup = () => {
    setReadyPopup(false);
  };

  // const onConfirmClick = () => {

  //   confirmDialog({
  //     message:
  //       "Are you sure you want to submit? Once you submit, you will not be able to undo your changes.",
  //     header: "Confirmation",
  //     icon: "pi pi-info-circle",
  //     accept: () => accept(),
  //     reject,
  //   });
  // };

  const reject = () => {
    setButtonLoading(false);
  };

  const accept = () => {
    UserManagementService.nextUser()
      .then((response: any) => {
        localStorage.setItem("StepFlag", "11");
        localStorage.setItem("OnboardingStatus", "7");
        onSaveAndContinueClick("N");
        navigate("/onboarding");
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
      });
  };

  const onReadyClick = () => {
    setReadyPopup(true);
  };

  const onUpdateClick = () => {
    setButtonLoading(true);

    partnershipDetailsModel.deliveryTypeSLA.forEach((type: any) => {
      if (type.timeFormat === "Minutes") {
        type.actualTime = (type.timeInSeconds * 60).toString();
      }
      if (type.timeFormat === "Hours") {
        type.actualTime = (type.timeInSeconds * 3600).toString();
      }
      if (type.timeFormat === "Days") {
        type.actualTime = (type.timeInSeconds * 86400).toString();
      }
      if (type.timeFormat === "Seconds") {
        type.actualTime = type.timeInSeconds.toString();
      }
    });

    ErrorMessageEmptyModel();
    if (isValidate(partnershipDetailsModel)) {
      PartnershipDetailsService.updatePartnershipDetails(
        partnershipDetailsModel
      )
        .then((data: any) => {
          localStorage.setItem("StepFlag", "6");
          localStorage.setItem("OnboardingStatus", "6");
          localStorage.setItem("IsFacilitator", data.data);
          context.updateFacilitator(data.data);
          localStorage.setItem(
            "PartnerPaymentRole",
            partnershipDetailsModel.partnerPaymentRole.toString()
          );
          context.updateRole(partnershipDetailsModel.partnerPaymentRole);
          setButtonLoading(false);
          onSaveAndContinueClick("N");
        })
        .catch((error: any) => {
          if (error.response.status === 500) {
            toast.current?.show({
              severity: "error",
              summary: "Something went wrong",
              life: 3000,
            });
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data[0].errorMessage,
              life: 3000,
            });
          } else if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 409) {
            setErrorMessage(
              "Cant update details since amount was deposited into ledger."
            );
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Error while updating contact details.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };

  const onBackClick = () => {
    setButtonLoadingBack(true);
    setTimeout(() => {
      navigate("/details");
    }, 1000);
  };

  const onFacilitatorValueChange = (e: any) => {
    setDirtyField(true);
    if (e.target.value === "Yes") {
      setNoFacilitatorValue(false);
      setYesFacilitatorValue(true);
      setIsFacilitator('true');
      setPartnershipDetailsModel({
        ...partnershipDetailsModel,
        isFacilitator: true,
      });
    } else if (e.target.value === "No") {
      setIsFacilitator('false');
      setYesFacilitatorValue(false);
      setCheckFacilitator(false);
      setNoFacilitatorValue(true);
      setPartnershipDetailsModel({
        ...partnershipDetailsModel,
        isFacilitator: false,
      });
    }
  };
  const onMultiSelectChange = (e: any) => {
    setDirtyField(true);
    if (
      partnerTypesMultiSelectList
        .map((d: any) => d.name)
        .includes(e.target.value) === false
    ) {
      partnerTypesMultiSelectList.push({ name: e.target.value });
    } else if (
      partnerTypesMultiSelectList
        .map((d: any) => d.name)
        .includes(e.target.value) === true
    ) {
      const index = partnerTypesMultiSelectList
        .map((d: any) => d.name)
        .indexOf(e.target.value);
      if (index > -1) {
        partnerTypesMultiSelectList.splice(index, 1);
      }
    }

    const types = partnerTypesMultiSelectList.map((d: any) => d.name);

    setPartnershipDetailsModel({
      ...partnershipDetailsModel,
      partnerTypes: JSON.stringify(types),
    });
  };
  const onDeliveryTypeChange = (e: any) => {
    setDirtyField(true);
    partnershipDetailsModel.deliveryTypeSLA =
      defaultdeliveryTypeSLAList.deliveryTypeSLA;
    setRTPTypeAutoComplete("Seconds");
    setMobileWalletTypeAutoComplete("Seconds");
    setBankTypeAutoComplete("Seconds");
    setCashPickupTypeAutoComplete("Seconds");
    setRTPsErrorMessage("");
    setMobileWalletErrorMessage("");
    setBankErrorMessage("");
    setCashPickupErrorMessage("");
    if (
      deliveryTypesMultiSelectList
        .map((d: any) => d.name)
        .includes(e.target.value) === false
    ) {
      deliveryTypesMultiSelectList.push({ name: e.target.value });
    } else if (
      deliveryTypesMultiSelectList
        .map((d: any) => d.name)
        .includes(e.target.value) === true
    ) {
      const index = deliveryTypesMultiSelectList
        .map((d: any) => d.name)
        .indexOf(e.target.value);
      if (index > -1) {
        deliveryTypesMultiSelectList.splice(index, 1);
      }
    }

    const deliverytypes = deliveryTypesMultiSelectList.map((d: any) => d.name);
    setPartnershipDetailsModel({
      ...partnershipDetailsModel,
      partnerDeliveryType: JSON.stringify(deliverytypes),
    });
  };
  const onNetworkClick = (e: any) => {
    setDirtyField(true);
    if (e.target.value === "1") {
      partnershipDetailsModel.partnerDeliveryType = "";
    }

    if (partnershipDetailsModel.partnerPaymentRole === e.target.value) {
      setPartnershipDetailsModel({
        ...partnershipDetailsModel,
        partnerPaymentRole: 0,
      });
    } else {
      setPartnershipDetailsModel({
        ...partnershipDetailsModel,
        partnerPaymentRole: e.target.value,
      });
    }
  };

  const onRTPTypeChange = (e: any) => {
    if (e.value !== null) {
      setRTPTypeAutoComplete(e.value);
      setPartnershipDetailsModel((item: any) => {
        item.deliveryTypeSLA[0].timeFormat = e.value.name;
        return {
          ...item,
        };
      });
    }

    setDirtyField(true);
  };
  const onMobileWalletTypeChange = (e: any) => {
    if (e.value !== null) {
      setMobileWalletTypeAutoComplete(e.value);
      setPartnershipDetailsModel((item: any) => {
        item.deliveryTypeSLA[1].timeFormat = e.value.name;
        return {
          ...item,
        };
      });
    }
    setDirtyField(true);
  };
  const onBankTypeChange = (e: any) => {
    if (e.value !== null) {
      setBankTypeAutoComplete(e.value);
      setPartnershipDetailsModel((item: any) => {
        item.deliveryTypeSLA[2].timeFormat = e.value.name;
        return {
          ...item,
        };
      });
    }
    setDirtyField(true);
  };
  const onCashPickupTypeChange = (e: any) => {
    if (e.value !== null) {
      setCashPickupTypeAutoComplete(e.value);
      setPartnershipDetailsModel((item: any) => {
        item.deliveryTypeSLA[3].timeFormat = e.value.name;
        return {
          ...item,
        };
      });
    }
    setDirtyField(true);
  };

  useEffect(() => {
    setButtonLoadingBack(false);
    setButtonLoadingSkip(false);
    {
      Number(status) === 8 ? setReadOnly(true) : setReadOnly(false);
    }

    // Update the document title using the browser API
    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    if (Number(partnerId) !== 0) {
      partnershipDetailsByPartnerId(Number(partnerId));
    }

    if (
      partnershipDetailsModel.partnerPaymentRole === 1 ||
      partnershipDetailsModel.partnerPaymentRole === 0
    ) {
      partnershipDetailsModel.partnerDeliveryType = "";
    }
    
  }, []);


  const onFacilitatorSave = () => {
    setButtonLoading(true);
    if (partnershipDetailsModel.isFacilitator === true) {
      partnershipDetailsModel.partnerDeliveryType = null;
      partnershipDetailsModel.partnerPaymentRole = null;
      partnershipDetailsModel.partnerTypes = null;
    }
    ErrorMessageEmptyModel();
    PartnershipDetailsService.updateIsFacilitator(partnershipDetailsModel)
      .then((data: any) => {

        localStorage.setItem("IsFacilitator", data.data);
        context.updateFacilitator(data.data);
        setButtonLoading(false);
        localStorage.setItem("StepFlag", "6");
        localStorage.setItem("OnboardingStatus", "6");
        onSaveAndContinueClick("N");
      })
      .catch((error: any) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 409) {
          setErrorMessage(
            "Cant update details since amount was deposited into ledger."
          );
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while updating contact details.",
            life: 3000,
          });
        }
        setButtonLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <Scrollbars
            className="contain-scroll"
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={100}
            autoHeightMax={100}
            thumbMinSize={30}
            universal={true}
          >
            <div className="container-fluid acc-screen payment-screen">
              <ConfirmDialog id="confirm-popup" />
              <div className="payment-section facilitator-yes">
                <div className="">
                  <h2>
                    Are you a Payment Facilitator for other companies?
                    <span className="color-red">*</span>
                  </h2>
                  <div className=" check-display">
                    <div className="form-check yes-check">
                      <input
                        readOnly={readonly}
                        disabled={readonly}
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        checked={yesfacilitatorvalue}
                        value="Yes"
                        onClick={(e) => onFacilitatorValueChange(e)}
                      />
                      <label className="form-check-label">Yes</label>
                    </div>
                    <div className="form-check">
                      <input
                        readOnly={readonly}
                        disabled={readonly}
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="No"
                        checked={nofacilitatorvalue}
                        onClick={(e) => onFacilitatorValueChange(e)}
                      />
                      <label className="form-check-label">No</label>
                    </div>
                  </div>
                </div>
                {/* <div className="">
                  {Number(status) === 8 ? null : (
                    yesfacilitatorvalue === true && checkfacilitator === true ? (
                      <>
                        <Button
                          loading={buttonLoadingSkip}
                          label="Next"
                          className="btn btn-continue"
                          onClick={onNextClick}
                        />
                      </>
                    ) : null
                  )}
                </div> */}
              </div>

              {yesfacilitatorvalue === false ? (
                <>
                  <div className=" payment-section ">
                    <div className="">
                      <h2>
                        Describe the type of payments
                        <span className="color-red">*</span>
                      </h2>
                    </div>
                    <div className="">
                      <button
                        disabled={readonly}
                        type="button"
                        className={
                          partnershipDetailsModel.partnerTypes !== null &&
                            partnershipDetailsModel.partnerTypes.length > 0 &&
                            partnershipDetailsModel.partnerTypes.includes(
                              "B2B"
                            ) === true
                            ? "btn btn-payment-selected payment-btns"
                            : "btn btn-payment payment-btns"
                        }
                        value="B2B"
                        onClick={onMultiSelectChange}
                      >
                        B2B
                      </button>
                      <button
                        disabled={readonly}
                        type="button"
                        className={
                          partnershipDetailsModel.partnerTypes !== null &&
                            partnershipDetailsModel.partnerTypes.length > 0 &&
                            partnershipDetailsModel.partnerTypes.includes(
                              "B2C"
                            ) === true
                            ? "btn btn-payment-selected payment-btns"
                            : "btn btn-payment payment-btns"
                        }
                        value="B2C"
                        onClick={onMultiSelectChange}
                      >
                        B2C
                      </button>
                      <button
                        disabled={readonly}
                        type="button"
                        className={
                          partnershipDetailsModel.partnerTypes !== null &&
                            partnershipDetailsModel.partnerTypes.length > 0 &&
                            partnershipDetailsModel.partnerTypes.includes(
                              "C2C"
                            ) === true
                            ? "btn btn-payment-selected payment-btns"
                            : "btn btn-payment payment-btns"
                        }
                        value="C2C"
                        onClick={onMultiSelectChange}
                      >
                        C2C
                      </button>
                    </div>
                    {partnertypeserrormessage !== null &&
                      partnertypeserrormessage.length > 0 && (
                        <span className="error-msg">
                          {partnertypeserrormessage}
                        </span>
                      )}
                  </div>

                  <div className="ro payment-section">
                    <div className="">
                      <h2>
                        How will you use Instarails network?
                        <span className="color-red">*</span>
                      </h2>
                    </div>
                    <div className="">
                      <button
                        disabled={readonly}
                        type="button"
                        className={
                          partnershipDetailsModel.partnerPaymentRole == 1
                            ? "btn btn-payment-selected payment-btns"
                            : "btn btn-payment payment-btns"
                        }
                        value={1}
                        onClick={onNetworkClick}
                      >
                        Send Payment
                      </button>
                      <button
                        disabled={readonly}
                        type="button"
                        className={
                          partnershipDetailsModel.partnerPaymentRole == 2
                            ? "btn btn-payment-selected payment-btns"
                            : "btn btn-payment payment-btns"
                        }
                        value={2}
                        onClick={onNetworkClick}
                      >
                        Receive Payment
                      </button>
                      <button
                        disabled={readonly}
                        type="button"
                        className={
                          partnershipDetailsModel.partnerPaymentRole == 3
                            ? "btn btn-payment-selected payment-btns"
                            : "btn btn-payment payment-btns"
                        }
                        value={3}
                        onClick={onNetworkClick}
                      >
                        Both
                      </button>
                    </div>
                    {networktypeserrormessage !== null &&
                      networktypeserrormessage.length > 0 && (
                        <span className="error-msg">
                          {networktypeserrormessage}
                        </span>
                      )}
                  </div>
                  {partnershipDetailsModel.partnerPaymentRole == 2 ||
                    partnershipDetailsModel.partnerPaymentRole == 3 ? (
                    <>
                      <div className=" payment-section">
                        <div className="">
                          <h2>
                            Select supported delivery type
                            <span className="color-red">*</span>
                          </h2>
                        </div>

                        <div>
                          <button
                            disabled={readonly}
                            type="button"
                            className={
                              partnershipDetailsModel.partnerDeliveryType !==
                                null &&
                                partnershipDetailsModel.partnerDeliveryType
                                  .length > 0 &&
                                partnershipDetailsModel.partnerDeliveryType.includes(
                                  "RTP"
                                ) === true
                                ? "btn btn-payment-selected payment-btns"
                                : "btn btn-payment payment-btns"
                            }
                            value="RTP"
                            onClick={onDeliveryTypeChange}
                          >
                            RTP
                          </button>
                          <button
                            disabled={readonly}
                            type="button"
                            className={
                              partnershipDetailsModel.partnerDeliveryType !==
                                null &&
                                partnershipDetailsModel.partnerDeliveryType
                                  .length > 0 &&
                                partnershipDetailsModel.partnerDeliveryType.includes(
                                  "MobileWallet"
                                ) === true
                                ? "btn btn-payment-selected payment-btns"
                                : "btn btn-payment payment-btns"
                            }
                            value="MobileWallet"
                            onClick={onDeliveryTypeChange}
                          >
                            Mobile Wallet
                          </button>
                          <button
                            disabled={readonly}
                            type="button"
                            className={
                              partnershipDetailsModel.partnerDeliveryType !==
                                null &&
                                partnershipDetailsModel.partnerDeliveryType
                                  .length > 0 &&
                                partnershipDetailsModel.partnerDeliveryType.includes(
                                  "BankAccount"
                                ) === true
                                ? "btn btn-payment-selected payment-btns"
                                : "btn btn-payment payment-btns"
                            }
                            value="BankAccount"
                            onClick={onDeliveryTypeChange}
                          >
                            Bank Account
                          </button>
                          <button
                            disabled={readonly}
                            type="button"
                            className={
                              partnershipDetailsModel.partnerDeliveryType !==
                                null &&
                                partnershipDetailsModel.partnerDeliveryType
                                  .length > 0 &&
                                partnershipDetailsModel.partnerDeliveryType.includes(
                                  "CashPickup"
                                ) === true
                                ? "btn btn-payment-selected payment-btns"
                                : "btn btn-payment payment-btns"
                            }
                            value="CashPickup"
                            onClick={onDeliveryTypeChange}
                          >
                            Cash Pickup
                          </button>
                        </div>
                        {deliverytypeserrormessage !== null &&
                          deliverytypeserrormessage.length > 0 && (
                            <span className="error-msg">
                              {deliverytypeserrormessage}
                            </span>
                          )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {partnershipDetailsModel.partnerDeliveryType !== null &&
                    partnershipDetailsModel.partnerDeliveryType.length > 2 ? (
                    <>
                      <hr />

                      <div
                        className="row payment-section Provide-the-delivery"
                        style={{ marginBottom: "15px" }}
                      >
                        <h2>
                          Provide the delivery time SLA for each of the
                          supported delivery type below:{" "}
                          <span className="color-red">*</span>
                        </h2>
                        {partnershipDetailsModel.partnerDeliveryType !== null &&
                          partnershipDetailsModel.partnerDeliveryType.length >
                          2 &&
                          partnershipDetailsModel.partnerDeliveryType.includes(
                            "RTP"
                          ) === true ? (
                          <>
                            <div className="col-md-2">
                              <input
                                disabled={readonly}
                                type="text"
                                className="realtime-payment-inputbox input-with-icon-realtime"
                                value={"RTP"}
                              ></input>
                            </div>
                          </>
                        ) : null}
                        {partnershipDetailsModel.partnerDeliveryType !== null &&
                          partnershipDetailsModel.partnerDeliveryType.length >
                          2 &&
                          partnershipDetailsModel.partnerDeliveryType?.includes(
                            "MobileWallet"
                          ) === true ? (
                          <>
                            <div className="col-md-2">
                              <input
                                disabled={readonly}
                                type="text"
                                className="realtime-payment-inputbox input-with-icon-mobilewallet"
                                value={"Mobile Wallet"}
                              ></input>
                            </div>
                          </>
                        ) : null}
                        {partnershipDetailsModel.partnerDeliveryType !== null &&
                          partnershipDetailsModel.partnerDeliveryType.length >
                          2 &&
                          partnershipDetailsModel.partnerDeliveryType.includes(
                            "BankAccount"
                          ) === true ? (
                          <>
                            <div className="col-md-2">
                              <input
                                disabled={readonly}
                                type="text"
                                className="realtime-payment-inputbox input-with-icon-bank"
                                id="disabledTextInput"
                                value={
                                  "Bank Account"
                                }
                              ></input>
                            </div>
                          </>
                        ) : null}
                        {partnershipDetailsModel.partnerDeliveryType !== null &&
                          partnershipDetailsModel.partnerDeliveryType.length >
                          2 &&
                          partnershipDetailsModel.partnerDeliveryType?.includes(
                            "CashPickup"
                          ) === true ? (
                          <>
                            <div className="col-md-2">
                              <input
                                disabled={readonly}
                                type="text"
                                className="realtime-payment-inputbox input-with-icon-cashpickup"
                                id="disabledTextInput"
                                value={
                                  "Cash Pickup"
                                }
                              ></input>
                            </div>
                          </>
                        ) : null}
                      </div>

                      <div
                        className="row payment-section partnership-drop"
                        style={{ display: "flex" }}
                      >
                        {partnershipDetailsModel.partnerDeliveryType !== null &&
                          partnershipDetailsModel.partnerDeliveryType.length >
                          2 &&
                          partnershipDetailsModel.partnerDeliveryType?.includes(
                            "RTP"
                          ) === true ? (
                          <>
                            <div className="col-md-2">
                              <input
                                disabled={readonly}
                                type="number"
                                onKeyDown={(evt) =>
                                  evt.key === "e" && evt.preventDefault()
                                }
                                className="inputbox-partnership"
                                value={
                                  partnershipDetailsModel.deliveryTypeSLA[0]
                                    .timeInSeconds
                                }
                                onChange={(e: any) => {
                                  setPartnershipDetailsModel((item: any) => {
                                    item.deliveryTypeSLA[0].timeInSeconds =
                                      e.target.value;
                                    return {
                                      ...item,
                                    };
                                  });
                                }}
                                style={{ width: "24%" }}
                              />
                              <AutoComplete
                                readOnly={readonly}
                                disabled={readonly}
                                tabIndex={5}
                                field="name"
                                dropdown
                                forceSelection
                                aria-label="Countries"
                                dropdownAriaLabel="Select"
                                className="dropdown-acc"
                                placeholder="Select type"
                                style={{ display: "revert" }}
                                suggestions={filteredslatype}
                                completeMethod={searchSLAType}
                                onChange={(e) => onRTPTypeChange(e)}
                                value={rtptypeAutoComplete}
                              />
                              {rtperrormessage !== null &&
                                rtperrormessage.length > 0 && (
                                  <span className="error-msg sla">
                                    {rtperrormessage}
                                  </span>
                                )}
                            </div>
                          </>
                        ) : null}
                        {partnershipDetailsModel.partnerDeliveryType !== null &&
                          partnershipDetailsModel.partnerDeliveryType.length >
                          2 &&
                          partnershipDetailsModel.partnerDeliveryType?.includes(
                            "MobileWallet"
                          ) === true ? (
                          <>
                            <div className="col-md-2">
                              <input
                                disabled={readonly}
                                type="number"
                                onKeyDown={(evt) =>
                                  evt.key === "e" && evt.preventDefault()
                                }
                                className="inputbox-partnership"
                                value={
                                  partnershipDetailsModel.deliveryTypeSLA[1]
                                    .timeInSeconds
                                }
                                onChange={(e: any) => {
                                  setPartnershipDetailsModel((item: any) => {
                                    item.deliveryTypeSLA[1].timeInSeconds =
                                      e.target.value;
                                    return {
                                      ...item,
                                    };
                                  });
                                }}
                                style={{ width: "24%" }}
                              />
                              <AutoComplete
                                readOnly={readonly}
                                disabled={readonly}
                                tabIndex={5}
                                field="name"
                                dropdown
                                forceSelection
                                aria-label="Countries"
                                dropdownAriaLabel="Select"
                                className="dropdown-acc"
                                placeholder="Select type"
                                style={{ display: "revert" }}
                                suggestions={filteredslatype}
                                completeMethod={searchSLAType}
                                onChange={(e) => onMobileWalletTypeChange(e)}
                                value={mobilewallettypeAutoComplete}
                              />
                              {mobilewalleterrormessage !== null &&
                                mobilewalleterrormessage.length > 0 && (
                                  <span className="error-msg sla">
                                    {mobilewalleterrormessage}
                                  </span>
                                )}
                            </div>
                          </>
                        ) : null}

                        {partnershipDetailsModel.partnerDeliveryType !== null &&
                          partnershipDetailsModel.partnerDeliveryType.length >
                          2 &&
                          partnershipDetailsModel.partnerDeliveryType?.includes(
                            "BankAccount"
                          ) === true ? (
                          <>
                            <div className="col-md-2">
                              <input
                                disabled={readonly}
                                type="number"
                                onKeyDown={(evt) =>
                                  evt.key === "e" && evt.preventDefault()
                                }
                                className="inputbox-partnership"
                                value={
                                  partnershipDetailsModel.deliveryTypeSLA[2]
                                    .timeInSeconds
                                }
                                onChange={(e: any) => {
                                  setPartnershipDetailsModel((item: any) => {
                                    item.deliveryTypeSLA[2].timeInSeconds =
                                      e.target.value;
                                    return {
                                      ...item,
                                    };
                                  });
                                }}
                                style={{ width: "24%" }}
                              />
                              <AutoComplete
                                readOnly={readonly}
                                disabled={readonly}
                                tabIndex={5}
                                field="name"
                                dropdown
                                forceSelection
                                aria-label="Countries"
                                dropdownAriaLabel="Select"
                                className="dropdown-acc"
                                placeholder="Select type"
                                style={{ display: "revert" }}
                                suggestions={filteredslatype}
                                completeMethod={searchSLAType}
                                onChange={(e) => onBankTypeChange(e)}
                                value={banktypeAutoComplete}
                              />
                              {bankerrormessage !== null &&
                                bankerrormessage.length > 0 && (
                                  <span className="error-msg sla">
                                    {bankerrormessage}
                                  </span>
                                )}
                            </div>
                          </>
                        ) : null}

                        {partnershipDetailsModel.partnerDeliveryType !== null &&
                          partnershipDetailsModel.partnerDeliveryType.length >
                          2 &&
                          partnershipDetailsModel.partnerDeliveryType?.includes(
                            "CashPickup"
                          ) === true ? (
                          <>
                            <div className="col-md-2">
                              <input
                                disabled={readonly}
                                type="number"
                                onKeyDown={(evt) =>
                                  evt.key === "e" && evt.preventDefault()
                                }
                                className="inputbox-partnership"
                                value={
                                  partnershipDetailsModel.deliveryTypeSLA[3]
                                    .timeInSeconds
                                }
                                onChange={(e: any) => {
                                  setPartnershipDetailsModel((item: any) => {
                                    item.deliveryTypeSLA[3].timeInSeconds =
                                      e.target.value;
                                    return {
                                      ...item,
                                    };
                                  });
                                }}
                                style={{ width: "24%" }}
                              />
                              <AutoComplete
                                readOnly={readonly}
                                disabled={readonly}
                                tabIndex={5}
                                field="name"
                                dropdown
                                forceSelection
                                aria-label="Countries"
                                dropdownAriaLabel="Select"
                                className="dropdown-acc"
                                placeholder="Select type"
                                style={{ display: "revert" }}
                                suggestions={filteredslatype}
                                completeMethod={searchSLAType}
                                onChange={(e) => onCashPickupTypeChange(e)}
                                value={cashpickuptypeAutoComplete}
                              />
                              {cashpickuperrormessage !== null &&
                                cashpickuperrormessage.length > 0 && (
                                  <span className="error-msg sla">
                                    {cashpickuperrormessage}
                                  </span>
                                )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  {null}
                  {/* {checkfacilitator === true ? (
                    <div className="row payment-section">
                      <TabView
                        activeIndex={index}
                        onTabChange={(e) => onTabChange(e)}
                      >
                        <TabPanel header="Facilitator Fees">
                          <Facilitator />
                        </TabPanel>

                        <TabPanel header="Add Partner" >
                          <AddFacilitator />
                        </TabPanel>
                      </TabView>
                    </div>
                  ) : null} */}
                </>
              )}
              {errormessage !== null && errormessage.length > 0 && (
                <span className="error-msg">{errormessage}</span>
              )}
              <div className="payment-button-section">
                <div className="bottom-btns">
                  {Number(status) === 8 ? (
                    <>
                      <Button
                        iconPos="left"
                        label="Back"
                        loading={buttonLoadingBack}
                        onClick={onBackClick}
                        className="btn btn-back second-btn"
                      />
                      <Button
                        iconPos="left"
                        label="Next"
                        loading={buttonLoadingSkip}
                        onClick={onNextClick}
                        className="btn btn-continue   btn-next second-btn"
                      />
                    </>
                  ) : (
                    <>

                      <button
                        type="button"
                        className="btn btn-back second-btn"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>

                      <Button
                        iconPos="left"
                        label=" Save and Continue"
                        className="btn btn-continue second-btn"
                        loading={buttonLoading}
                        onClick={
                          yesfacilitatorvalue === true
                            ? onFacilitatorSave
                            : onUpdateClick
                        }
                      />

                    </>
                  )}
                </div>
              </div>
            </div>
          </Scrollbars>
          {displaypopup ? (
            <>
              <div className="popup-body">
                <div className="Cancel-popup">
                  <div className="text-center ">
                    <div className="awesome-text">
                      <h4>Are you sure you want to cancel?</h4>
                      <p> All unsaved changes will be lost.</p>
                    </div>
                  </div>
                  <div className="payment-screen-btn">
                    <button
                      className="btn btn-cancel second-btn "
                      onClick={OnPopupClose}
                    >
                      {" "}
                      No
                    </button>
                    <button
                      className="btn btn-continue second-btn yes-btn-popup"
                      onClick={OnPopupOk}
                    >
                      {" "}
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {readyPopup ? (
            <div className="popup-body">
              <div className="regiter-popup confirm-popup " id="confirm-popup">
                <div className="popup-text">
                  <i className="pi pi-info-circle"></i>
                  <p>
                    Are you sure you want to submit? Once you submit, you will
                    not be able to undo your changes.
                  </p>
                </div>

                <div className="popup-btn">
                  <button
                    type="button"
                    onClick={cancelPopup}
                    className="btn btn-back second-btn"

                  >
                    No
                  </button>
                  <button
                    type="button"
                    onClick={accept}
                    className="btn btn-continue second-btn"
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default PartnershipDetails;
