import { TabPanel, TabView } from "primereact/tabview";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import { Logout } from "../../../utils/AccountUtils";
import Facilitator from "./Facilitator";
import AddFacilitator from "./AddFacilitator";

const FacilitatorTabContent: React.FC<any> = () => {
  const navigate = useNavigate();

  const [index, setIndex] = useState(0);

  const [status, setstatus] = useState(
    localStorage.getItem("OnboardingStatus")
  );

  const onTabChange = (e: any) => {
    setIndex(e.index);
  };

  useEffect(() => {
    const useroobj: any = localStorage.getItem("User");

    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    setstatus(localStorage.getItem("OnboardingStatus"));
  }, []);

  return (
    <>
     

            <div className="right-tab-section">
              <div className="container-fluid  facilitator-screen">
                <div className=" payment-section fees-next recevie-currency">
                <>
                  <TabView
                    activeIndex={index}
                    onTabChange={(e) => onTabChange(e)}
                  >
                    <TabPanel header="Facilitator Fees">
                      <Facilitator />
                    </TabPanel>

                    <TabPanel header="Add Partner">
                      <AddFacilitator />
                    </TabPanel>
                  </TabView>
                </>
              </div>
            </div>
            </div>


    </>
  );
};

export default FacilitatorTabContent;
