import { useContext, useEffect, useRef, useState } from "react";
import Currencies from "../components/Partner/Currencies/Currencies";
import Fees from "../components/Partner/Fees/Fees";
import PartnershipDetails from "../components/Partner/PartnershipDetails/PartnershipDetails";
import PaymentTabChange from "../components/Partner/PaymetTabs/PaymentTabChange";
import UserManagement from "../components/Partner/UserManagement/UserManagement";
import Wallet from "../components/Partner/Wallet/Wallet";
import { Logout } from "../utils/AccountUtils";
import { useNavigate } from "react-router-dom";
import "./Tabcontent.css";
import { PartnershipDetailsService } from "../services/Partner/PartnershipDetails/PartnershipDetailsService";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfigContext } from "antd/es/config-provider";
import LocalStorageContext from "../components/Context/LocalStorageContext";

const PaymentTabcontent: React.FC<any> = () => {
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const id = localStorage.getItem("PartnerId");
  const [partnerId, setpartnerId] = useState(id);
  const [partnerPaymentRole, setPartnerPaymentRole] = useState(0);
  const [loading, setLoading] = useState(true);
  const [stepFlag, setStepFlag] = useState<any>(
    localStorage.getItem("StepFlag")
  );
  const context = useContext(LocalStorageContext);
  const [status, setstatus] = useState(
    parseInt(localStorage.getItem("OnboardingStatus"))
  );

  const [buttonLoadingSkip, setButtonLoadingSkip] = useState(false);
  const [IsFacilitator, setIsFacilitator] = useState(localStorage.getItem("IsFacilitator"));

  const [buttonLoadingBack, setButtonLoadingBack] = useState(false);

  const [value, setValue] = useState(parseInt(stepFlag) + 1);

  const onSaveAndContinueClick = (flag: string) => {
    const number = 1;
    if (flag === "N") {
      setStepFlag(localStorage.getItem("StepFlag"));

      setValue(value + number);
    } else {
      setValue(value - number);
    }
  };

  const onNextClick = () => {
    setButtonLoadingSkip(true);
    setTimeout(() => {
      const number = 1;
      setValue(value + number);
    }, 1000);
  };
  const onStepClick = (val: any) => {
    setValue(val);
  };

  if (value == 12) {
    setValue(6);
  }

  const getPartnershipDetailsByPartnerId = (partnerid: number) => {

    PartnershipDetailsService.getPartnershipDetailsByPartnerId(
      Number(partnerid)
    )
      .then((response) => {
        const data = response.data;
        localStorage.setItem(
          "PartnerPaymentRole",
          data.partnerPaymentRole.toString()
        );
        context.updateRole(data.partnerPaymentRole);
        setPartnerPaymentRole(data.partnerPaymentRole);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting partnership details.",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    setstatus(parseInt(localStorage.getItem("OnboardingStatus")));

    getPartnershipDetailsByPartnerId(Number(partnerId));
  }, []);

  return (
    <>
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <div className="tab-content payment-tabcontent-main" id="pills-tabContent" >
            <ul className="tab-pane active" id="myTab" role="tablist">
              <div className="payment-steps">
                <Toast ref={toast}></Toast>
                <ul className="steps">
                  <li className="step">
                    <span
                      className={
                        value === 6 ? "payment-count active" : "payment-count"
                      }
                      onClick={() => onStepClick(6)}
                    >
                      6
                    </span>
                    <span className="pl-8 step-title text-blue">
                      Partnership Details
                    </span>


                    <span className="payment-line" />

                  </li>

                  <li className="step">
                    <span
                      className={
                        value === 7 ? "payment-count active" : "payment-count"
                      }
                      onClick={() =>
                        parseInt(stepFlag) === 5 ? "" : onStepClick(7)
                      }
                    >
                      7
                    </span>
                    <span className="pl-8 step-title text-blue">
                      Currencies
                    </span>
                    <span className="payment-line" />
                  </li>
                  <li className="step">
                    <span
                      className={
                        value === 8 ? "payment-count active" : "payment-count"
                      }
                      onClick={() =>
                        parseInt(stepFlag) === 6 || parseInt(stepFlag) === 5
                          ? ""
                          : onStepClick(8)
                      }
                    >
                      8
                    </span>
                    <span className="pl-8 step-title text-blue">Wallet</span>

                    <span className="payment-line" />

                  </li>

                  <li className="step">
                    <span
                      className={
                        value === 9 ? "payment-count active" : "payment-count"
                      }
                      onClick={() =>
                        parseInt(stepFlag) === 6 ||
                          parseInt(stepFlag) === 5 ||
                          parseInt(stepFlag) === 7
                          ? ""
                          : onStepClick(9)
                      }
                    >
                      9
                    </span>
                    <span className="pl-8 step-title text-blue">Fees</span>
                    {IsFacilitator === 'false' ? (<>
                      <span className="payment-line" />
                    </>) : null}
                  </li>
                  {IsFacilitator === 'false' ? (<>
                    {localStorage.getItem("PartnerPaymentRole") !== "1" && (
                      <li className="step">
                        <span
                          className={
                            value === 10
                              ? "payment-count active"
                              : "payment-count"
                          }
                          onClick={() =>
                            parseInt(stepFlag) === 6 ||
                              parseInt(stepFlag) === 5 ||
                              parseInt(stepFlag) === 7

                              ? ""
                              : onStepClick(10)
                          }
                        >
                          10
                        </span>
                        <span className="pl-8 step-title text-blue">
                          Payment Settings
                        </span>
                        <span className="payment-line" />
                      </li>
                    )}
                    <li className="step">
                      <span
                        className={
                          value ===
                            (localStorage.getItem("PartnerPaymentRole") !== "1"
                              ? 11
                              : 10)
                            ? "payment-count active"
                            : "payment-count"
                        }
                        onClick={() =>
                          parseInt(stepFlag) === 6 ||
                            parseInt(stepFlag) === 5 ||
                            parseInt(stepFlag) === 7
                            ? // || parseInt(stepFlag) === 8
                            // ||  parseInt(stepFlag) === 9

                            ""
                            : onStepClick(
                              localStorage.getItem("PartnerPaymentRole") !== "1"
                                ? 11
                                : 10
                            )
                        }
                      >
                        {localStorage.getItem("PartnerPaymentRole") !== "1"
                          ? 11
                          : 10}
                      </span>
                      <span className="pl-8 step-title text-blue">
                        User Management
                      </span>
                    </li>
                  </>) : (null)}

                </ul>
              </div>
            </ul>
          </div>

          {value === 6 && (
            <PartnershipDetails
              onSaveAndContinueClick={onSaveAndContinueClick}
              onNextClick={onNextClick}
              buttonLoadingBack={buttonLoadingBack}
              buttonLoadingSkip={buttonLoadingSkip}
              setButtonLoadingBack={setButtonLoadingBack}
              setButtonLoadingSkip={setButtonLoadingSkip}
              setIsFacilitator={setIsFacilitator}
            />
          )}
          {value === 7 && (
            <Currencies
              onSaveAndContinueClick={onSaveAndContinueClick}
              onNextClick={onNextClick}
              buttonLoadingBack={buttonLoadingBack}
              buttonLoadingSkip={buttonLoadingSkip}
              setButtonLoadingBack={setButtonLoadingBack}
              setButtonLoadingSkip={setButtonLoadingSkip}
              setIsFacilitator={setIsFacilitator}
            />
          )}
          {value === 8 && (
            <Wallet
              onSaveAndContinueClick={onSaveAndContinueClick}
              onNextClick={onNextClick}
              buttonLoadingBack={buttonLoadingBack}
              buttonLoadingSkip={buttonLoadingSkip}
              setButtonLoadingBack={setButtonLoadingBack}
              setButtonLoadingSkip={setButtonLoadingSkip}
              setIsFacilitator={setIsFacilitator}
            />
          )}
          {value === 9 && (
            <Fees
              onSaveAndContinueClick={onSaveAndContinueClick}
              onNextClick={onNextClick}
              buttonLoadingBack={buttonLoadingBack}
              buttonLoadingSkip={buttonLoadingSkip}
              setButtonLoadingBack={setButtonLoadingBack}
              setButtonLoadingSkip={setButtonLoadingSkip}
              setIsFacilitator={setIsFacilitator}
            />
          )}

          {localStorage.getItem("PartnerPaymentRole") !== "1" && (
            <>
              {value === 10 && (
                <PaymentTabChange
                  onSaveAndContinueClick={onSaveAndContinueClick}
                  onNextClick={onNextClick}
                  buttonLoadingBack={buttonLoadingBack}
                  buttonLoadingSkip={buttonLoadingSkip}
                  setButtonLoadingBack={setButtonLoadingBack}
                  setButtonLoadingSkip={setButtonLoadingSkip}
                />
              )}
            </>
          )}

          {value ===
            (localStorage.getItem("PartnerPaymentRole") !== "1" ? 11 : 10) && (
              <UserManagement
                onSaveAndContinueClick={onSaveAndContinueClick}
                source={"organization"}
                buttonLoadingBack={buttonLoadingBack}
                buttonLoadingSkip={buttonLoadingSkip}
                setButtonLoadingBack={setButtonLoadingBack}
                setButtonLoadingSkip={setButtonLoadingSkip}
              />
            )}
        </>
      )}
    </>
  );
};
export default PaymentTabcontent;
