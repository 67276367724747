import React, { useEffect, useRef, useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Logout } from "../../../utils/AccountUtils";
import { useNavigate } from "react-router";
import { Toast } from "primereact/toast";
import { CommonService } from "../../../services/Common/CommonService";
import { currenciesList } from "../../../utils/utils";
import RTPimg from "../../../assets/images/RTP-IMG.svg";
import Moneylogo from "../../../assets/images/cash-group.svg";
import mobilelogo from "../../../assets/images/Mobile-logo.svg";
import Banklogo from "../../../assets/images/Banklogo.svg";
import { CheckQuoteService } from "../../../services/CheckQuotes/CheckQuotesService";
import { ProgressSpinner } from "primereact/progressspinner";
import Scrollbars from "react-custom-scrollbars-2";
import { PartnershipDetailsService } from "../../../services/Partner/PartnershipDetails/PartnershipDetailsService";
import { Link } from "react-router-dom";
import { IsValidRoute } from "../../../utils/AccountUtils";
import moment from "moment-timezone";
const CheckQuotes: React.FC = () => {
  const navigate = useNavigate();

  const toast = useRef<Toast>(null);

  const id = localStorage.getItem("PartnerId");

  const [partnerId, setpartnerId] = useState(id);

  const [loading, setLoading] = useState(true);

  const [transactiontypelist, SetTransactionTypeList] = useState<any[]>([]);

  const [quotesData, setQuotesData]: any = useState([]);

  const [Message, setMessage] = React.useState("");

  const [filteredtransactiontype, setFilteredTransactionType] =
    useState<any>(null);

  const [transactiontypeAutoComplete, setTransactionTypeAutoComplete] =
    useState("");

  const [filteredcurrencieslist, setFilteredCurrencyList] = useState<any>(null);

  const [fromcurrencyAutoComplete, setFromCurrencyAutoComplete] = useState("");

  const [tocurrencyAutoComplete, setToCurrencyAutoComplete] = useState("");

  const [sendexactvalue, setSendExactValue] = useState(false);

  const [receiveexactvalue, setReceiveExactValue] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [transactiontypeerrorMessage, settransactiontypesErrorMessage] =
    React.useState("");

  const [fromcurrencyerrorMessage, setFromCurrencyErrorMessage] =
    React.useState("");

  const [tocurrencyerrorMessage, setToCurrencyErrorMessage] =
    React.useState("");

  const [roletypeerrorMessage, setRoleTypeErrorMessage] = useState("");

  const [sendamounterrorMessage, setsendamountErrorMessage] =
    React.useState("");

  const [receiveamounterrorMessage, setreceiveamountErrorMessage] =
    React.useState("");

  const [role, setRole] = useState();

  const [quoteModel, setQuoteModel] = React.useState({
    sendAmount: null,
    receiveAmount: null,
    sendCurrency: "",
    receiveCurrency: "",
    type: "",
    roletype: "",
  });

  // check null value
  const CheckNull = (value: any) => {
    if (
      value === "" ||
      value === undefined ||
      value === null ||
      value === "0"
    ) {
      return true;
    }
    return false;
  };

  // handle empty error message
  const ErrorMessageEmptyModel = () => {
    settransactiontypesErrorMessage("");
    setFromCurrencyErrorMessage("");
    setToCurrencyErrorMessage("");
    setsendamountErrorMessage("");
    setreceiveamountErrorMessage("");
    setRoleTypeErrorMessage("");
  };

  // handle validations
  const isValidate = (values: any) => {
    let formIsValid = true;
    ErrorMessageEmptyModel();

    if (CheckNull(values.type)) {
      settransactiontypesErrorMessage("Please select transaction type.");
      formIsValid = false;
    }

    if (CheckNull(values.sendCurrency)) {
      setFromCurrencyErrorMessage("Please select send from currency.");
      formIsValid = false;
    }
    if (CheckNull(values.roletype)) {
      setRoleTypeErrorMessage("Please select type is send or receive.");
      formIsValid = false;
    }
    if (CheckNull(values.receiveCurrency)) {
      setToCurrencyErrorMessage("Please select send to currency.");
      formIsValid = false;
    }
    if (sendexactvalue) {
      if (CheckNull(values.sendAmount)) {
        setsendamountErrorMessage("Please enter send amount.");
        formIsValid = false;
      }
      if (!CheckNull(values.sendAmount)) {
        if (values.sendAmount < 0) {
          setsendamountErrorMessage("Send amount can not be negative.");
          formIsValid = false;
        }
        if (!values.sendAmount.match(/^\d{1,18}(\.\d{0,2})?$/)) {
          setsendamountErrorMessage("Send amount valid only upto length(16,2)");
          formIsValid = false;
        }
      }
    }
    if (receiveexactvalue) {
      if (CheckNull(values.receiveAmount)) {
        setreceiveamountErrorMessage("Please enter receive amount.");
        formIsValid = false;
      }

      if (!CheckNull(values.receiveAmount)) {
        if (values.receiveAmount < 0) {
          setreceiveamountErrorMessage("Receive amount can not be negative.");
          formIsValid = false;
        }
        if (!values.receiveAmount.match(/^\d{1,18}(\.\d{0,2})?$/)) {
          setreceiveamountErrorMessage(
            "Receive amount valid only upto length(16,2)"
          );
          formIsValid = false;
        }
      }
    }
    if (!CheckNull(values.sendCurrency) && !CheckNull(values.receiveCurrency))
      if (values.sendCurrency == values.receiveCurrency) {
        setToCurrencyErrorMessage(
          "The source currency and the receive currency should not be the same."
        );
        formIsValid = false;
      }

    return formIsValid;
  };

  // partnership detail by id api call
  const partnershipDetailsByPartnerId = (id: number) => {

    PartnershipDetailsService.getPartnershipDetailsByPartnerId(id)
      .then((response) => {
        const data = response.data;
        setRole(data.partnerPaymentRole);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting partnership details.",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };

  // tranasaction type api call
  const getTransactionTypes = () => {
    setLoading(true);
    CommonService.getBusinessType()
      .then((response: any) => {
        SetTransactionTypeList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Oops, Something went wrong!",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops, Something went wrong!",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };

  // on change radio button
  const onRadioButtonChange = (e: any) => {
    if (e.target.value === "SendExact") {
      setQuotesData([]);
      quoteModel.receiveAmount = null;
      setSendExactValue(true);
      setReceiveExactValue(false);
      setQuoteModel({
        ...quoteModel,
        roletype: e.target.value,
      });
    } else if (e.target.value === "ReceiveExact") {
      setQuotesData([]);
      quoteModel.sendAmount = null;
      setSendExactValue(false);
      setReceiveExactValue(true);
      setQuoteModel({
        ...quoteModel,
        roletype: e.target.value,
      });
    }
  };

  //search input fiedl for transaction type
  const searchTransactionType = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < transactiontypelist.length; i++) {
      let item = transactiontypelist[i];

      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredTransactionType(_filteredItems);
  };

  // handle transaction type change
  const onTransactionTypeChange = (e: any) => {
    if (e.value !== null) {
      setTransactionTypeAutoComplete(e.value);
      setQuoteModel({
        ...quoteModel,
        type: e.value.name,
      });
    }
  };

  // search currency
  const searchCurrency = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < currenciesList.length; i++) {
      let item = currenciesList[i];
      if (item.Name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredCurrencyList(_filteredItems);
  };

  // on form currenct change
  const onFromCurrencyChange = (e: any) => {
    if (e.value !== null) {
      setFromCurrencyAutoComplete(e.value);
      setQuoteModel({
        ...quoteModel,
        sendCurrency: e.value.Name,
      });
    }
  };

  // to currency change
  const onToCurrencyChange = (e: any) => {
    if (e.value !== null) {
      setToCurrencyAutoComplete(e.value);
      setQuoteModel({
        ...quoteModel,
        receiveCurrency: e.value.Name,
      });
    }
  };

  // handle set quote
  const setQuotes = (responsedata: any, roletype: string) => {
    // SendExact ReceiveExact
    if (roletype === "SendExact") {
      if (
        responsedata.quoteOption === null ||
        responsedata.quoteOption?.length === 0
      ) {
        setQuotesData([]);
      } else {
        const deliveryTypeOptions = responsedata.quoteOption
          ?.map((quote: any) => quote)
          .map((option: any) =>
            option.deliveryTypeOptions.map((doption: any) => ({
              ...doption,
              fxRate: option.fxRate,
              fxRateCurrency: option.rateCurrency,
              deliveryFee: doption.deliveryFee,
              deliveryFeeCurrency: doption.deliveryFeeCurrency,
              partnerId: option.partnerId,
              partnerLogoURL: option.partnerLogoURL,
              estimatedDeliveryDateTime: doption.estimatedDeliveryDateTime,
              rateCurrency: option.rateCurrency,
              partnerName: option.partnerName,
              instarailsFee: responsedata.instarailsFee,
              senderFeeCurrency: responsedata.senderFeeCurrency,
              receiveCurrency: responsedata.receiveCurrency,
              sourceAmount: responsedata.sourceAmount,
              sourceCurrency: responsedata.sourceCurrency,
              instarailsFeeCurrency: responsedata.instarailsFeeCurrency,
              senderFee: responsedata.senderFee,
              facilitatorFee: responsedata.facilitatorFee,
              facilitatorFeeCurrency: responsedata.facilitatorFeeCurrency,
              receiverFacilitatorFee: doption.receiverFacilitatorFee,
              receiverFacilitatorFeeCurrency:
                doption.receiverFacilitatorFeeCurrency,
            }))
          );

        setQuotesData(deliveryTypeOptions.flat());
      }
    } else {
      if (
        responsedata.options === null ||
        responsedata.options?.length === 0
      ) {
        setQuotesData([]);
      } else {
        const deliveryTypeOptions = responsedata.options
          ?.map((quote: any) => quote)
          .map((option: any) =>
            option.deliveryTypeOptions.map((doption: any) => ({
              ...doption,
              receiveAmount: responsedata.receiveAmount,
              fxRate: option.fxRate,
              fxRateCurrency: option.rateCurrency,
              deliveryFee: doption.deliveryFee,
              deliveryFeeCurrency: doption.deliveryFeeCurrency,
              estimatedDeliveryDateTime: doption.estimatedDeliveryDateTime,
              partnerId: option.partnerId,
              partnerLogoURL: option.partnerLogoURL,
              rateCurrency: option.rateCurrency,
              partnerName: option.partnerName,
              instarailsFee: doption.instarailsFee,
              senderFeeCurrency: responsedata.sourceCurrency,
              receiveCurrency: responsedata.receiveCurrency,
              sourceAmount: doption.sendAmount,
              sourceCurrency: responsedata.sourceCurrency,
              instarailsFeeCurrency: doption.instarailsFeeCurrency,
              senderFee: doption.sendFee,
              facilitatorFee: doption.facilitatorFee,
              facilitatorFeeCurrency: responsedata.facilitatorFeeCurrency,
              receiverFacilitatorFee: doption.receiverFacilitatorFee,
              receiverFacilitatorFeeCurrency:
                doption.receiverFacilitatorFeeCurrency,
            }))
          );

        setQuotesData(deliveryTypeOptions.flat());
      }
    }
  };

  // handle Save button click
  const onAddClick = () => {
    setQuotesData([]);
    setMessage("");
    setButtonLoading(true);
    if (isValidate(quoteModel)) {
      CheckQuoteService.getApikeyByPartnerId(Number(partnerId), quoteModel)
        .then((response) => {
          setButtonLoading(false);
          setQuotes(response.data, quoteModel.roletype);

          if (
            response.data.quoteOption === null ||
            response.data.quoteOption?.length === 0 || response.data.options === null || response.data.options?.length === 0
          ) {
            setMessage("No Quotes Available.");
          }
        })

        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 500) {
            toast.current?.show({
              severity: "error",
              summary: "Oops, Something went wrong!",
              life: 3000,
            });
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data[0].errorMessage,
              life: 3000,
            });
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Oops! Something went wrong.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };

  // handle procced click
  const onProceedClick = (quote: any) => {
    localStorage.setItem("Quotedata", JSON.stringify(quote));
    localStorage.setItem("Type", JSON.stringify(quoteModel.type));
    localStorage.setItem("RoleType", quoteModel.roletype);
  };

  useEffect(() => {
    IsValidRoute(navigate);
    const useroobj = localStorage.getItem("User");

    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    getTransactionTypes();

    partnershipDetailsByPartnerId(Number(partnerId));
  }, []);

  return (
    <>
      {" "}
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <div className="right-tab-section">
            <Scrollbars
              className="contain-scroll"
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              autoHeight
              autoHeightMin={100}
              autoHeightMax={100}
              thumbMinSize={30}
              universal={true}
            >
              <div className="text-center">
                <Toast ref={toast}></Toast>
                <div className="heading-section">
                  <span className="text-header-purple">Check Quotes</span>
                </div>
                <div className="container-fluid acc-screen createpayment-screen chechquotes-screen">
                  <div className="row border-bottom-checkQuoet">
                    <div className="col-md-6 info-section">
                      <div className=" form-group ">
                        <span className="input-label">
                          Transaction Types
                          <span className="color-red">*</span>
                        </span>
                        <AutoComplete
                          field="name"
                          dropdown
                          forceSelection
                          aria-label="Countries"
                          dropdownAriaLabel="USD"
                          className="dropdown-acc"
                          placeholder="Select transaction types"
                          virtualScrollerOptions={{ itemSize: 38 }}
                          suggestions={filteredtransactiontype}
                          completeMethod={searchTransactionType}
                          onChange={(e) => onTransactionTypeChange(e)}
                          value={transactiontypeAutoComplete}
                        />
                        {transactiontypeerrorMessage !== null &&
                          transactiontypeerrorMessage.length > 0 ? (
                          <span className="error-msg">
                            {transactiontypeerrorMessage}
                          </span>
                        ) : null}
                      </div>

                      <div className=" form-group send-currency ">
                        <span className="input-label">
                          Send From Currency
                          <span className="color-red">*</span>
                        </span>
                        <AutoComplete
                          field="Name"
                          dropdown
                          forceSelection
                          aria-label="Countries"
                          dropdownAriaLabel="USD"
                          className="dropdown-acc"
                          placeholder="Select send from currency"
                          virtualScrollerOptions={{ itemSize: 38 }}
                          suggestions={filteredcurrencieslist}
                          completeMethod={searchCurrency}
                          onChange={(e) => onFromCurrencyChange(e)}
                          value={fromcurrencyAutoComplete}
                        />
                        {fromcurrencyerrorMessage !== null &&
                          fromcurrencyerrorMessage.length > 0 ? (
                          <span className="error-msg">
                            {fromcurrencyerrorMessage}
                          </span>
                        ) : null}
                      </div>

                      <div className=" form-group ">
                        <div className="">
                          <div className="form-check ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              checked={sendexactvalue}
                              value="SendExact"
                              onClick={(e) => onRadioButtonChange(e)}
                            />
                            <label className="form-check-label">
                              Send Exact
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className=" form-group ">
                        {sendexactvalue === true ? (
                          <>
                            <span className="input-label">
                              Send Amount
                              <span className="color-red">*</span>
                            </span>
                            <input
                              className="form-control "
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              placeholder="Enter send amount"
                              name="sendAmount"
                              value={quoteModel.sendAmount}
                              onChange={(e) =>
                                setQuoteModel({
                                  ...quoteModel,
                                  sendAmount: e.target.value,
                                })
                              }
                            />
                            {sendamounterrorMessage !== null &&
                              sendamounterrorMessage.length > 0 ? (
                              <span className="error-msg">
                                {sendamounterrorMessage}
                              </span>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {receiveexactvalue === true ? (
                              <>
                                <span className="input-label">
                                  Receive Amount
                                  <span className="color-red">*</span>
                                </span>
                                <input
                                  className="form-control "
                                  type="number"
                                  onKeyDown={(evt) =>
                                    evt.key === "e" && evt.preventDefault()
                                  }
                                  placeholder="Enter receive amount"
                                  name="receiveAmount"
                                  value={quoteModel.receiveAmount}
                                  onChange={(e) =>
                                    setQuoteModel({
                                      ...quoteModel,
                                      receiveAmount: e.target.value,
                                    })
                                  }
                                />
                                {receiveamounterrorMessage !== null &&
                                  receiveamounterrorMessage.length > 0 ? (
                                  <span className="error-msg">
                                    {receiveamounterrorMessage}
                                  </span>
                                ) : null}
                              </>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 info-section-second">
                      <div className=" col-md-12 form-group check-quets-visibility-none">
                        <div className="row">
                          <div className="col-md-6">
                            <span className="input-label">###</span>
                            <input className="form-control " type="text" />
                          </div>
                        </div>
                      </div>

                      <div className=" form-group send-currency ">
                        <span className="input-label">
                          Send To Currency
                          <span className="color-red">*</span>
                        </span>
                        <AutoComplete
                          field="Name"
                          dropdown
                          forceSelection
                          aria-label="Countries"
                          dropdownAriaLabel="USD"
                          className="dropdown-acc"
                          placeholder="Select send to currency"
                          virtualScrollerOptions={{ itemSize: 38 }}
                          suggestions={filteredcurrencieslist}
                          completeMethod={searchCurrency}
                          onChange={(e) => onToCurrencyChange(e)}
                          value={tocurrencyAutoComplete}
                        />
                        {tocurrencyerrorMessage !== null &&
                          tocurrencyerrorMessage.length > 0 ? (
                          <span className="error-msg">
                            {tocurrencyerrorMessage}
                          </span>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <span
                          className="input-label"
                          style={{ fontSize: "15px" }}
                        ></span>
                        <div className=" ">
                          <div className="form-check form-group">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              checked={receiveexactvalue}
                              value="ReceiveExact"
                              onClick={(e) => onRadioButtonChange(e)}
                            />
                            <label className="form-check-label">
                              Receive Exact
                            </label>
                            {roletypeerrorMessage !== null &&
                              roletypeerrorMessage.length > 0 ? (
                              <span className="error-msg">
                                {roletypeerrorMessage}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className=" getquote-btn">
                        <Button
                          iconPos="left"
                          label="Get Quotes"
                          className="btn btn-getquote"
                          loading={buttonLoading}
                          onClick={onAddClick}
                        />{" "}
                      </div>
                    </div>
                  </div>

                  <div className="check-quotes-section">
                    {quotesData.length > 0 ? (
                      <>
                        <div className="notes">
                          <p>
                            Note that additional fees might apply when you send the
                            transaction
                          </p>
                        </div>
                        <div className="checkquote-cards">
                          {quotesData?.map((quote: any, index: number) => {
                            return (
                              <div className="card">
                                <div className="card-body">
                                  <div className="row text-left card-first-row">
                                    <div className="col-md-6 form-group hdfc-logo-img-text">
                                      {/* <img
                                        src={quote.partnerLogoURL}
                                        alt="img"
                                        className="bankImg-size"
                                      />*/}
                                      <span className="hdfc-text">
                                        {quote.partnerName}
                                      </span>
                                    </div>
                                    <div className="col-md-6 form-group rtp-img-text">
                                      {" "}
                                      {(() => {
                                        if (quote.deliveryType === "RTP") {
                                          return (
                                            <img
                                              src={RTPimg}
                                              alt="img"
                                              className="bankImg-size"
                                            />
                                          );
                                        } else if (
                                          quote.deliveryType === "MobileWallet"
                                        ) {
                                          return (
                                            <img
                                              src={mobilelogo}
                                              alt="img"
                                              className="bankImg-size"
                                            />
                                          );
                                        } else if (
                                          quote.deliveryType === "CashPickup"
                                        ) {
                                          return (
                                            <img
                                              src={Moneylogo}
                                              alt="img"
                                              className="bankImg-size"
                                            />
                                          );
                                        }
                                        if (
                                          quote.deliveryType === "BankAccount"
                                        ) {
                                          return (
                                            <img
                                              src={Banklogo}
                                              alt="img"
                                              className="bankImg-size"
                                            />
                                          );
                                        } else {
                                          return (
                                            <img
                                              src={Banklogo}
                                              alt="img"
                                              className="bankImg-size"
                                            />
                                          );
                                        }
                                      })()}
                                    </div>
                                  </div>
                                  <div className="row  text-left text-color-checkquoet Recipient-amt ">
                                    <div className="col-md-8 form-group ">
                                      <span className="recipient-amt">
                                        Final Recipient Amount
                                      </span>
                                    </div>
                                    <div className="col-md-4 form-group amount-left ">
                                      <h4>
                                        {quote.receiveAmount}
                                        <span>{quote.receiveCurrency}</span>
                                      </h4>
                                    </div>
                                  </div>

                                  <div className="row  text-left text-color-checkquoet">
                                    <div className="col form-group ">
                                      <span>Send Amount</span>
                                    </div>
                                    <div className="col form-group amount-left">
                                      <p>
                                        {quote.sourceAmount}
                                        <span>{quote.sourceCurrency}</span>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row  text-left text-color-checkquoet">
                                    <div className="col form-group ">
                                      <span>Instarails Fees</span>
                                    </div>
                                    <div className="col form-group amount-left ">
                                      <p>
                                        {quote.instarailsFee}{" "}
                                        <span>
                                          {quote.instarailsFeeCurrency}
                                        </span>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row  text-left text-color-checkquoet">
                                    <div className="col form-group ">
                                      <span>Fx Rate</span>
                                    </div>
                                    <div className="col form-group amount-left ">
                                      <p>
                                        {quote.fxRate}
                                        <span> </span>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row  text-left text-color-checkquoet">
                                    <div className="col-md-8 form-group ">
                                      <span>
                                        Delivery Fees for {quote.deliveryType}
                                      </span>
                                    </div>
                                    <div className="col-md-4 form-group amount-left ">
                                      <p>
                                        {quote.deliveryFee}{" "}
                                        <span>{quote.deliveryFeeCurrency}</span>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row  text-left text-color-checkquoet">
                                    <div className="col form-group ">
                                      <span>Sender Fees</span>
                                    </div>
                                    <div className="col form-group amount-left ">
                                      <p>
                                        {quote.senderFee}{" "}
                                        <span>{quote.senderFeeCurrency}</span>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row  text-left text-color-checkquoet">
                                    <div className="col-md-8 form-group ">
                                      <span>Sender Facilitator Fees</span>
                                    </div>
                                    <div className="col-md-4 form-group amount-left ">
                                      <p>
                                        {quote.facilitatorFee}{" "}
                                        <span>
                                          {quote.facilitatorFeeCurrency}
                                        </span>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row  text-left text-color-checkquoet">
                                    <div className="col-md-8 form-group ">
                                      <span>Fx Provider Commision</span>
                                    </div>
                                    <div className="col-md-4 form-group amount-left ">
                                      <p>
                                        0<span>{quote.fxRateCurrency}</span>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row  text-left text-color-checkquoet">
                                    <div className="col-md-8 form-group ">
                                      <span>Receiver Facilitator Fees</span>
                                    </div>
                                    <div className="col-md-4 form-group amount-left ">
                                      <p>
                                        {quote.receiverFacilitatorFee}
                                        <span>
                                          {quote.receiverFacilitatorFeeCurrency}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row  text-left text-color-checkquoet">
                                    <div className="col-md-6 form-group ">
                                      <span>Estimated Delivery Date</span>
                                    </div>
                                    <div className="col-md-6 form-group amount-left ">
                                      <p>
                                        <span>
                                          <span>
                                            {moment
                                              .utc(
                                                quote.estimatedDeliveryDateTime
                                              )
                                              .tz("America/New_York")
                                              .format("MM/DD/YY HH:mm:ss")}{" "}
                                          </span>
                                        </span>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="procced-btn">
                                    {role === 1 || role === 3 ? (
                                      <Link
                                        target="_blank"
                                        to={"/createpayment"}
                                      >
                                        <Button
                                          iconPos="left"
                                          label="Proceed"
                                          className="btn btn-procced"
                                          loading={buttonLoading}
                                          onClick={() => onProceedClick(quote)}
                                        />
                                      </Link>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <>{Message}</>
                    )}
                  </div>
                </div>
              </div>
            </Scrollbars>
          </div>
        </>
      )}
    </>
  );
};

export default CheckQuotes;
