const BasicInfoToolTipData= [

    {
        "id": 1,
        "Info":`<h4>Entity name is how the state identifies your organization</h4> `
    },
    {
        "id": 2,
        "name":"Website",
        "Info":`<h4>Organization’s website URL </h4>`
    },
    {
        "id": 3,
        "Info":`<h4>Taxpayer Identification Numbers</h4> <ul><li>Social Security number “SSN”</li> <li>Employer Identification Number “EIN”</li> <li>Individual Taxpayer Identification Number “ITIN”</li> <li>Global Intermediary Identification Number (GIIN)</li> <li>Legal Entity Identifier (LEI)</li> <li>Global Legal Entity Identifier (GLEI)</li> </ul>`,
    },
    {
        "id": 4,
        "name":"Tax Currency",
        "Info":`<h4>The currency code in which your organization pays taxes.</h4>`
    },
    {
        "id": 5,
        "Info":`<h4>The approximate number of employees in your organization</h4>`
    },
    {
        "id": 6,
        "Info":`<h4>Specific information related to the business operations</h4>`
    },
    {
        "id": 7,
        "Info":`<h4>If the name under which you operate the business differs from its legal registered name</h4>`
    },
    {
        "id": 8,
        "Info":`<h4>A unique identification number assigned to your organization by your national registration office</h4>`
    },
    {
        "id": 9,
        "Info":`<h4>A tax registration ID for organization, used to identify the tax account of a business entity.</h4>`
    },
    {
        "id": 10,
        "Info":`<h4>The legal structure of your organization</h4> <ul><li>Sole Proprietorship: Has one sole owner and operator</li> <li>LLC: A legal business entity that provides limited liability protection</li> <li>Private Company: Owned by the company's founders, management, or a group of private investors</li> <li>Public Company: Has sold all or a portion of itself to the public via an initial public offering</li> <li>Corporation: An incorporated association is a registered legal entity usually for recreational, cultural or charitable purposes with at least five members.</li> <li>Partnerships: General or limited partnerships</li> <li>Governmental Organization: A government appointed group, that can be permanent or semi-permanent organization and is funded by the government.</li> <li>Non Profit: Organized for purposes other than generating profit</li> </ul>`,
    },
    {
        "id": 11,
        "Info":`<h4>The entity type describes the type of services your organization engages in</h4> <ul> <li>Bank</li> <li>Central bank</li> <li>Facilitator or service bureau: Organization that offers business services to other companies for a fee</li> <li>Enterprise business: A for-profit business started and run by an entrepreneur.</li> <li>FX provider: Sets the exchange FX rate at which they are willing swap the Source Currency for the Destination Currency</li> <li>Liquidity provider: Provides capital to finance transactions in the foreign exchange market.</li> <li>Merchant: Sells directly to the public either from a store or through the internet</li> <li>Mobile App: An organization that primarily uses a mobile app to communicate with its users</li> <li>Payment company: A payment processing company</li></ul>`,
    },
    {
        "id": 12,
        "Info":`<h4>Your official organization logo in svg, jpg, jpeg, or png format</h4>`
    }

]


 export default BasicInfoToolTipData;