import axios from "axios";
import { environment } from "../../environments/environment";
import { ILogin } from "../../models/ILogin";

export class LoginService {
  private static URL: any = environment.authApiUrl;

  public static login(data: ILogin) {
    let UserURL: any = `${this.URL}/Auth/Login`;
    return axios.post<any>(UserURL, data);
  }

  public static updatetoken() {
    let UserURL: any = `${this.URL}/Auth/updatetoken`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
    };
    return axios.get<any>(UserURL, config);
  }
}
