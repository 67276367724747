import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useRef, useState } from "react";
import { PaymentSettingsServices } from "../../../services/Partner/PaymentSettings/PaymentSettings";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../../utils/AccountUtils";

const Paymentsettingread: React.FC<any> = ({
  onNextClick,
  onBackClick,
}) => {


  const id = localStorage.getItem("PartnerId");

  const [status, setstatus] = useState(
    localStorage.getItem("OnboardingStatus")
  );

  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);

  const [showResults, setShowResults] = React.useState(false);

  const [paymentList, setpaymentList] = useState([]);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [buttonLoadingBack, setButtonLoadingBack] = useState(false);

  const [dataLength, setDataLength] = useState(0);

  const [stepFlag, setstepFlag] = useState(localStorage.getItem("StepFlag"));

  const [partnerid, SetPartnerId] = React.useState(id);

  const [PaymentSettings, setPaymentSettings] = React.useState({
    id: 0,
    partnerId: Number(partnerid),
    Name: "",
    Contact: "",
    Verification: "",
  });

  const toast = useRef<Toast>(null);

  //Get payment Api call 

  const getPayment = () => {
    setLoading(true);

    PaymentSettingsServices.getPayment()
      .then((data: any) => {
        const responsedata = data.data;
        setpaymentList(responsedata);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        }
        setLoading(false);
      });
  };

  // on next click 
  const onChildNextClick = () => {
    setButtonLoading(true);

    setTimeout(() => {
         onNextClick();
    }, 1000);
    setButtonLoading(true);
  };

  // on back click 
  const onChildBack = () => {

    setButtonLoadingBack(true);


    setTimeout(() => {
      onBackClick();
    }, 1000);
   
    setButtonLoadingBack(true);
    
  };



  useEffect(() => {

    getPayment();

  }, []);


  return (
    <>
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <div>
          <>
            <div className="row ">
              <div className="col-md-12 text-center">
                <h2 className="read-only-heading">
                  Here are the default fields in a payment:
                </h2>
              </div>
            </div>
            <table className="table read-only-table">
              <tbody>
                {paymentList
                  .sort((a: any, b: any) =>
                    a.PaymentReadOnly > b.PaymentReadOnly ? -1 : 1
                  )
                  .map((PaymentReadOnly: any, index) => (
                    <tr key={index} className="read-only-row">
                      <td className="read-only">
                        <span> {PaymentReadOnly.name}</span>
                      </td>
                      <td className="read-text">
                        {PaymentReadOnly.description}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="button-section readonly-btn">
              <div className="bottom-btns">
              
                <Button
                    iconPos="left"
                    label="Back"
                    loading={buttonLoadingBack}
                    onClick={onChildBack}
                    className="btn btn-back second-btn"
                  />
                <>
                  <Button
                    iconPos="left"
                    label="Next"
                    loading={buttonLoading}
                    onClick={onChildNextClick}
                    className="btn btn-continue   btn-next second-btn"
                  />
                </>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default Paymentsettingread;
