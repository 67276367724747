
import React from "react";
import { useNavigate } from "react-router";
import IRLOGO from "../../../assets/images/icon/logo-icon.png";
import "../../Account/Login/Login.css";

const Errorpage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="errormsg-page Sigin-page">
 
        <div className="bg-height errorpage-main">
          <img src={IRLOGO} alt="img" className="logo-white errormsglogo" />
          <div className="errorLogin-form">
            <div className="signin-form-display">
              <div className="login-form-display">
                <div className="text-center">
                  <h1>Oops!</h1>
                    <h2> Page not found</h2>
                  <div className="forgot-password">
                    <h3><a href="/">Goto HomePage</a>
                    </h3></div>
                </div>
              </div>
            </div>
            </div>
      </div>
    </div>
  );
};

export default Errorpage;
