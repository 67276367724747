import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useNavigate } from "react-router-dom";
import BRLimg from "../../../../assets/images/BRLimg.svg";
import CANADAimg from "../../../../assets/images/canada-flag.svg";
import INDimg from "../../../../assets/images/india.svg";
import USDlogo from "../../../../assets/images/USDImg.svg";
import { CurrenciesService } from "../../../../services/Partner/Currencies/Currencies";
import { PartnerFeesDetailsService } from "../../../../services/Partner/Fees/Fees";
import { PartnershipDetailsService } from "../../../../services/Partner/PartnershipDetails/PartnershipDetailsService";
import { Logout } from "../../../../utils/AccountUtils";

function IsValidateObject(formIsValid: boolean, finalData: any) {
  return { formIsValid, finalData };
}

const C2CReceiveFees: React.FC<any> = ({
  onSaveAndContinueClick,
  partnerType,
  partnerDeliveryType,
  loadingChildComponent,
  setActiveIndex,
  partnerPaymentRole,
  setShowB2B,
  setShowC2C,
  // setLoading
}) => {
  const [currency, setCurrency] = useState(null);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [loadingC2C, setLoadingC2C] = useState(true);
  const [readonly, setReadOnly] = useState(false);
  const [backbuttonLoading, setBackButtonLoading] = useState(false);
  const id = localStorage.getItem("PartnerId");
  const [partnerid, setpartnerId] = useState(id);

  const [showBelowGrid, setShowBelowGrid] = useState(false);

  const [displaypopup, setDisplayPopup] = useState(false);
  const [dirtyfield, setDirtyField] = useState(false);

  const [feesAmount, setFeesAmount] = useState("");
  const [updated, setUpdated] = useState(feesAmount);

  const [receivefixedfeeserrorMessage, setreceivefixedfeeserrorMessage] =
    useState("");
  const [receivevariablefeeserrorMessage, setreceivevariablefeeserrorMessage] =
    useState("");

  const [firstModelCurrency, setfirstModelCurrency] = useState("");

  const [sendCurrencyFees, setSendCurrencyFees] = useState("");
  const [status, setstatus] = useState(
    localStorage.getItem("OnboardingStatus")
  );
  const toast = useRef<Toast>(null);

  const navigate = useNavigate();

  const [latestreceivefeeNewModel, setlatestreceivefeeNewModel]: any = useState(
    []
  );
  const [latestReceiveFeeModel, setlatestReceiveFeeModel]: any = useState([]);
  const [deliveyTypes, setdeliveyTypes]: any = useState([]);
  const [receivefeeNewModel, setReceivefeeNewModel]: any = useState([
    {
      INR: [
        {
          id: 0,
          typeId: 0,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "INR",
          receiveFixedFees: null,
          dt: "RTP",
          partnerDeliveryType: 1,
          deliveryType: "RTP",
          countryCode: "IND",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: INDimg,
        },
        {
          id: 1,
          typeId: 1,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "INR",
          receiveFixedFees: null,
          dt: "MobileWallet",
          partnerDeliveryType: 4,
          deliveryType: "MobileWallet",
          countryCode: "IND",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: INDimg,
        },
        {
          id: 2,
          typeId: 2,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "INR",
          receiveFixedFees: null,
          dt: "CashPickup",
          partnerDeliveryType: 5,
          deliveryType: "CashPickup",
          countryCode: "IND",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: INDimg,
        },
        {
          id: 3,
          typeId: 3,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "INR",
          receiveFixedFees: null,
          dt: "BankAccount",
          partnerDeliveryType: 2,
          deliveryType: "BankAccount",
          countryCode: "IND",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: INDimg,
        },
      ],
    },

    //// USD Currency
    {
      USD: [
        {
          id: 0,
          typeId: 0,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "USD",
          receiveFixedFees: null,
          dt: "RTP",
          partnerDeliveryType: 1,
          deliveryType: "RTP",
          countryCode: "USA",
          receiveVariableFees: null,
          yescheckedFees: true,
          nocheckedFees: true,
          flag: USDlogo,
        },
        {
          id: 1,
          typeId: 1,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "USD",
          partnerDeliveryType: 4,
          countryCode: "USA",
          receiveFixedFees: null,
          dt: "MobileWallet",
          deliveryType: "MobileWallet",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: USDlogo,
        },
        {
          id: 2,
          typeId: 2,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "USD",
          receiveFixedFees: null,
          dt: "CashPickup",
          partnerDeliveryType: 5,
          deliveryType: "CashPickup",
          countryCode: "USA",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: USDlogo,
        },
        {
          id: 3,
          typeId: 3,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "USD",
          receiveFixedFees: null,
          dt: "BankAccount",
          partnerDeliveryType: 2,
          deliveryType: "BankAccount",
          countryCode: "USA",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: USDlogo,
        },
      ],
    },

    //// CAD Currency
    {
      CAD: [
        {
          id: 0,
          typeId: 0,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "CAD",
          receiveFixedFees: null,
          dt: "RTP",
          partnerDeliveryType: 1,
          deliveryType: "RTP",
          countryCode: "CAN",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: CANADAimg,
        },
        {
          id: 1,
          typeId: 1,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "CAD",
          receiveFixedFees: null,
          dt: "MobileWallet",
          partnerDeliveryType: 4,
          deliveryType: "MobileWallet",
          countryCode: "CAN",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: CANADAimg,
        },
        {
          id: 2,
          typeId: 2,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "CAD",
          receiveFixedFees: null,
          dt: "CashPickup",
          partnerDeliveryType: 5,
          deliveryType: "CashPickup",
          countryCode: "CAN",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: CANADAimg,
        },
        {
          id: 3,
          typeId: 3,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "CAD",
          receiveFixedFees: null,
          dt: "BankAccount",
          partnerDeliveryType: 2,
          deliveryType: "BankAccount",
          countryCode: "CAN",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: CANADAimg,
        },
      ],
    },

    //// BRL Currency
    {
      BRL: [
        {
          id: 0,
          typeId: 0,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "BRL",
          receiveFixedFees: null,
          dt: "RTP",
          partnerDeliveryType: 1,
          deliveryType: "RTP",
          countryCode: "BRL",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: BRLimg,
        },
        {
          id: 1,
          typeId: 1,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "BRL",
          receiveFixedFees: null,
          dt: "MobileWallet",
          partnerDeliveryType: 4,
          deliveryType: "MobileWallet",
          countryCode: "BRL",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: BRLimg,
        },
        {
          id: 2,
          typeId: 2,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "BRL",
          receiveFixedFees: null,
          dt: "CashPickup",
          partnerDeliveryType: 5,
          deliveryType: "CashPickup",
          countryCode: "BRL",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: BRLimg,
        },
        {
          id: 3,
          typeId: 3,
          partnerId: id,
          partnerReceiveFeeType: 3,
          partnerReceiveFeeTier: null,
          receiveFeeCurrency: "BRL",
          receiveFixedFees: null,
          dt: "BankAccount",
          partnerDeliveryType: 2,
          deliveryType: "BankAccount",
          countryCode: "BRL",
          receiveVariableFees: null,
          yescheckedFees: false,
          nocheckedFees: true,
          flag: BRLimg,
        },
      ],
    },
  ]);

  const onVariableFeeChange = (
    e: any,
    index: number,
    typeId: number,
    receiveFeeCurrency: string,
    modelDt: string,
    state: string
  ) => {
    if (state === "Primary") {
      let newArr: any = [...latestreceivefeeNewModel];
      let newReceivefeeNewModel: any = [...receivefeeNewModel];
      newArr.forEach((x: any) => {
        // if(x.id == index && x.typeId == typeId && x.typeId == receiveFeeCurrency && x.typeId == dt && x.typeId == modelDt)
        if (
          x.id == index &&
          x.typeId == typeId &&
          x.receiveFeeCurrency == receiveFeeCurrency &&
          x.dt == modelDt
        ) {
          x.receiveVariableFees = e.target.value;
        }
      });

      const inrData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "INR"
      );
      const usdData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "USD"
      );
      const cadData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "CAD"
      );
      const brlData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "BRL"
      );

      if (firstModelCurrency === "INR") {
        newReceivefeeNewModel[0].INR = inrData;
      }
      if (firstModelCurrency === "USD") {
        newReceivefeeNewModel[1].USD = usdData;
      }
      if (firstModelCurrency === "CAD") {
        newReceivefeeNewModel[2].CAD = cadData;
      }
      if (firstModelCurrency === "BRL") {
        newReceivefeeNewModel[3].BRL = brlData;
      }

      setlatestreceivefeeNewModel(newArr);
      setReceivefeeNewModel(newReceivefeeNewModel);
      setFeesAmount(e.target.value);
    } else {
      let newArr: any = [...latestReceiveFeeModel];
      let newReceivefeeNewModel: any = [...receivefeeNewModel];
      newArr.forEach((x: any) => {
        // if(x.id == index && x.typeId == typeId && x.typeId == receiveFeeCurrency && x.typeId == dt && x.typeId == modelDt)
        if (
          x.id == index &&
          x.typeId == typeId &&
          x.receiveFeeCurrency == receiveFeeCurrency &&
          x.dt == modelDt
        ) {
          x.receiveVariableFees = e.target.value;
        }
      });

      const inrData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "INR"
      );
      const usdData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "USD"
      );
      const cadData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "CAD"
      );
      const brlData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "BRL"
      );

      if (firstModelCurrency === "INR") {
        newReceivefeeNewModel[1].USD = usdData;
        newReceivefeeNewModel[2].CAD = cadData;
        newReceivefeeNewModel[3].BRL = brlData;
      }
      if (firstModelCurrency === "USD") {
        newReceivefeeNewModel[0].INR = inrData;
        newReceivefeeNewModel[2].CAD = cadData;
        newReceivefeeNewModel[3].BRL = brlData;
      }
      if (firstModelCurrency === "CAD") {
        newReceivefeeNewModel[0].INR = inrData;
        newReceivefeeNewModel[1].USD = usdData;
        newReceivefeeNewModel[3].BRL = brlData;
      }
      if (firstModelCurrency === "BRL") {
        newReceivefeeNewModel[0].INR = inrData;
        newReceivefeeNewModel[1].USD = usdData;
        newReceivefeeNewModel[2].CAD = cadData;
      }

      setlatestReceiveFeeModel(newArr);
      setReceivefeeNewModel(newReceivefeeNewModel);
      setFeesAmount(e.target.value);
    }
  };

  const onfixedFeeChange = (
    e: any,
    index: number,
    typeId: number,
    receiveFeeCurrency: string,
    modelDt: string,
    state: string
  ) => {
    if (state === "Primary") {
      let newArr: any = [...latestreceivefeeNewModel];
      let newReceivefeeNewModel: any = [...receivefeeNewModel];

      newArr.forEach((x: any) => {
        // if(x.id == index && x.typeId == typeId && x.typeId == receiveFeeCurrency && x.typeId == dt && x.typeId == modelDt)
        if (
          x.id == index &&
          x.typeId == typeId &&
          x.receiveFeeCurrency == receiveFeeCurrency &&
          x.dt == modelDt
        ) {
          x.receiveFixedFees = e.target.value;
        }
      });
      const inrData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "INR"
      );
      const usdData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "USD"
      );
      const cadData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "CAD"
      );
      const brlData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "BRL"
      );

      if (firstModelCurrency === "INR") {
        newReceivefeeNewModel[0].INR = inrData;
      }
      if (firstModelCurrency === "USD") {
        newReceivefeeNewModel[1].USD = usdData;
      }
      if (firstModelCurrency === "CAD") {
        newReceivefeeNewModel[2].CAD = cadData;
      }
      if (firstModelCurrency === "BRL") {
        newReceivefeeNewModel[3].BRL = brlData;
      }

      setlatestreceivefeeNewModel(newArr);
      setReceivefeeNewModel(newReceivefeeNewModel);
      setFeesAmount(e.target.value);
    } else {
      let newArr: any = [...latestReceiveFeeModel];
      let newReceivefeeNewModel: any = [...receivefeeNewModel];

      newArr.forEach((x: any) => {
        // if(x.id == index && x.typeId == typeId && x.typeId == receiveFeeCurrency && x.typeId == dt && x.typeId == modelDt)
        if (
          x.id == index &&
          x.typeId == typeId &&
          x.receiveFeeCurrency == receiveFeeCurrency &&
          x.dt == modelDt
        ) {
          x.receiveFixedFees = e.target.value;
        }
      });
      const inrData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "INR"
      );
      const usdData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "USD"
      );
      const cadData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "CAD"
      );
      const brlData = newArr.filter(
        (data: any) => data.receiveFeeCurrency === "BRL"
      );

      if (firstModelCurrency === "INR") {
        newReceivefeeNewModel[1].USD = usdData;
        newReceivefeeNewModel[2].CAD = cadData;
        newReceivefeeNewModel[3].BRL = brlData;
      }
      if (firstModelCurrency === "USD") {
        newReceivefeeNewModel[0].INR = inrData;
        newReceivefeeNewModel[2].CAD = cadData;
        newReceivefeeNewModel[3].BRL = brlData;
      }
      if (firstModelCurrency === "CAD") {
        newReceivefeeNewModel[0].INR = inrData;
        newReceivefeeNewModel[1].USD = usdData;
        newReceivefeeNewModel[3].BRL = brlData;
      }
      if (firstModelCurrency === "BRL") {
        newReceivefeeNewModel[0].INR = inrData;
        newReceivefeeNewModel[1].USD = usdData;
        newReceivefeeNewModel[2].CAD = cadData;
      }
      setlatestReceiveFeeModel(newArr);
      setReceivefeeNewModel(newReceivefeeNewModel);
      setFeesAmount(e.target.value);
    }
  };

  const SendFeesClick = (
    e: any,
    id: any,
    typeId: any,
    receiveFeeCurrency: string,
    modelDt: string
  ) => {
    let newArr: any = [...latestReceiveFeeModel];

    var valueToAssignObject: any;

    newArr.forEach((x: any) => {
      if (
        x.id == id &&
        x.typeId == typeId &&
        x.receiveFeeCurrency == latestReceiveFeeModel[0].receiveFeeCurrency &&
        x.dt == modelDt
      ) {
        valueToAssignObject = x;
        return;
      }
    });

    newArr.forEach((x: any, index: any) => {
      if (
        x.id == id &&
        x.typeId == typeId &&
        x.receiveFeeCurrency == receiveFeeCurrency &&
        x.dt == modelDt
      ) {
        x.receiveFixedFees = valueToAssignObject.receiveFixedFees;
        x.receiveVariableFees = valueToAssignObject.receiveVariableFees;
      }
    });

    setlatestreceivefeeNewModel(newArr);
  };
  const RemoveSendFeesClick = (
    e: any,
    id: any,
    typeId: any,
    receiveFeeCurrency: string,
    modelDt: string
  ) => {
    let newArr: any = [...latestreceivefeeNewModel];

    newArr.forEach((x: any, index: any) => {
      if (
        x.id == id &&
        x.typeId == typeId &&
        x.receiveFeeCurrency == receiveFeeCurrency &&
        x.dt == modelDt
      ) {
        x.receiveFixedFees = "";
        x.receiveVariableFees = "";
      }
    });

    setlatestreceivefeeNewModel(newArr);
  };

  const ErrorMessageEmptyModel = () => {
    setreceivefixedfeeserrorMessage("");
    setreceivevariablefeeserrorMessage("");
  };

  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null) {
      return true;
    }
    return false;
  };

  const isValidate = () => {
    let formIsValid = true;
    let finalData: any[];

    if (firstModelCurrency === "INR") {
      const topModel = latestreceivefeeNewModel.filter(
        (data: any) => data.receiveFeeCurrency === "INR"
      );
      const bottomModel = latestReceiveFeeModel.filter(
        (data: any) => data.receiveFeeCurrency !== "INR"
      );

      finalData = [...topModel, ...bottomModel];

      finalData
        .slice()
        .reverse()
        .forEach((receiveFees: any) => {
          
          if (
            !(Number(receiveFees.receiveFixedFees) < 0) &&
            !CheckNull(receiveFees.receiveFixedFees)
          ) {
            if (
              !receiveFees.receiveFixedFees
                .toString()
                .match(/^\d{1,16}(\.\d{0,2})?$/)
            ) {
              setreceivefixedfeeserrorMessage(
                `Receive fixed fees valid only upto length(16,2) for ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
              );
              formIsValid = false;
            }
          }
          if (
            !(Number(receiveFees.receiveVariableFees) < 0) &&
            !CheckNull(receiveFees.receiveVariableFees)
          ) {
            if (
              !receiveFees.receiveVariableFees
                .toString()
                .match(/^\d{1,16}(\.\d{0,2})?$/)
            ) {
              setreceivevariablefeeserrorMessage(
                `Receive variable fees valid only upto length(16,2) for ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
              );
              formIsValid = false;
            }
          }
          if (!(Number(receiveFees.receiveFixedFees) >= 0) || Object.is(Number(receiveFees.receiveFixedFees),-0)) {
            setreceivefixedfeeserrorMessage(
              `Receive fixed fees cannot be negative number for  ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
            );
            formIsValid = false;
          }
          if (!(Number(receiveFees.receiveVariableFees) >= 0) || Object.is(Number(receiveFees.receiveVariableFees),-0)) {
            setreceivevariablefeeserrorMessage(
              `Receive variable fees cannot be negative number for  ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
            );
            formIsValid = false;
          }
        });
    }

    if (firstModelCurrency === "USD") {
      const topModel = latestreceivefeeNewModel.filter(
        (data: any) => data.receiveFeeCurrency === "USD"
      );
      const bottomModel = latestReceiveFeeModel.filter(
        (data: any) => data.receiveFeeCurrency !== "USD"
      );

      finalData = [...topModel, ...bottomModel];

      finalData
        .slice()
        .reverse()
        .forEach((receiveFees: any) => {
          if (
            !(Number(receiveFees.receiveFixedFees) < 0) &&
            !CheckNull(receiveFees.receiveFixedFees)
          ) {
            if (
              !receiveFees.receiveFixedFees
                .toString()
                .match(/^\d{1,16}(\.\d{0,2})?$/)
            ) {
              setreceivefixedfeeserrorMessage(
                `Receive fixed fees valid only upto length(16,2) for ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
              );
              formIsValid = false;
            }
          }
          if (
            !(Number(receiveFees.receiveVariableFees) < 0) &&
            !CheckNull(receiveFees.receiveVariableFees)
          ) {
            if (
              !receiveFees.receiveVariableFees
                .toString()
                .match(/^\d{1,16}(\.\d{0,2})?$/)
            ) {
              setreceivevariablefeeserrorMessage(
                `Receive variable fees valid only upto length(16,2) for ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
              );
              formIsValid = false;
            }
          }
          if (!(Number(receiveFees.receiveFixedFees) >= 0) || Object.is(Number(receiveFees.receiveFixedFees),-0)) {
            setreceivefixedfeeserrorMessage(
              `Receive fixed fees cannot be negative number for  ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
            );
            formIsValid = false;
          }
          if (!(Number(receiveFees.receiveVariableFees) >= 0) || Object.is(Number(receiveFees.receiveVariableFees),-0)) {
            setreceivevariablefeeserrorMessage(
              `Receive variable fees cannot be negative number for  ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
            );
            formIsValid = false;
          }
        });
    }

    if (firstModelCurrency === "CAD") {
      const topModel = latestreceivefeeNewModel.filter(
        (data: any) => data.receiveFeeCurrency === "CAD"
      );
      const bottomModel = latestReceiveFeeModel.filter(
        (data: any) => data.receiveFeeCurrency !== "CAD"
      );

      finalData = [...topModel, ...bottomModel];

      finalData
        .slice()
        .reverse()
        .forEach((receiveFees: any) => {
          if (
            !(Number(receiveFees.receiveFixedFees) < 0) &&
            !CheckNull(receiveFees.receiveFixedFees)
          ) {
            if (
              !receiveFees.receiveFixedFees
                .toString()
                .match(/^\d{1,16}(\.\d{0,2})?$/)
            ) {
              setreceivefixedfeeserrorMessage(
                `Receive fixed fees valid only upto length(16,2) for ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
              );
              formIsValid = false;
            }
          }
          if (
            !(Number(receiveFees.receiveVariableFees) < 0) &&
            !CheckNull(receiveFees.receiveVariableFees)
          ) {
            if (
              !receiveFees.receiveVariableFees
                .toString()
                .match(/^\d{1,16}(\.\d{0,2})?$/)
            ) {
              setreceivevariablefeeserrorMessage(
                `Receive variable fees valid only upto length(16,2) for ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
              );
              formIsValid = false;
            }
          }
          if (!(Number(receiveFees.receiveFixedFees) >= 0) || Object.is(Number(receiveFees.receiveFixedFees),-0)) {
            setreceivefixedfeeserrorMessage(
              `Receive fixed fees cannot be negative number for  ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
            );
            formIsValid = false;
          }
      
        });
    }

    if (firstModelCurrency === "BRL") {
      const topModel = latestreceivefeeNewModel.filter(
        (data: any) => data.receiveFeeCurrency === "BRL"
      );
      const bottomModel = latestReceiveFeeModel.filter(
        (data: any) => data.receiveFeeCurrency !== "BRL"
      );

      finalData = [...topModel, ...bottomModel];

      finalData
        .slice()
        .reverse()
        .forEach((receiveFees: any) => {
          if (
            !(Number(receiveFees.receiveFixedFees) < 0) &&
            !CheckNull(receiveFees.receiveFixedFees)
          ) {
            if (
              !receiveFees.receiveFixedFees
                .toString()
                .match(/^\d{1,16}(\.\d{0,2})?$/)
            ) {
              setreceivefixedfeeserrorMessage(
                `Receive fixed fees valid only upto length(16,2) for ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
              );
              formIsValid = false;
            }
          }
          if (
            !(Number(receiveFees.receiveVariableFees) < 0) &&
            !CheckNull(receiveFees.receiveVariableFees)
          ) {
            if (
              !receiveFees.receiveVariableFees
                .toString()
                .match(/^\d{1,16}(\.\d{0,2})?$/)
            ) {
              setreceivevariablefeeserrorMessage(
                `Receive variable fees valid only upto length(16,2) for ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
              );
              formIsValid = false;
            }
          }
          if (!(Number(receiveFees.receiveFixedFees) >= 0) || Object.is(Number(receiveFees.receiveFixedFees),-0)) {
            setreceivefixedfeeserrorMessage(
              `Receive fixed fees cannot be negative number for  ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
            );
            formIsValid = false;
          }
          if (!(Number(receiveFees.receiveVariableFees) >= 0) || Object.is(Number(receiveFees.receiveVariableFees),-0)) {
            setreceivevariablefeeserrorMessage(
              `Receive variable fees cannot be negative number for  ${receiveFees.receiveFeeCurrency} with ${receiveFees.deliveryType}.`
            );
            formIsValid = false;
          }
        });
    }

    return IsValidateObject(formIsValid, finalData);
  };

  const onNextClick = () => {
    onSaveAndContinueClick("N");
  };

  const onAddClick = () => {

    latestreceivefeeNewModel.forEach((element:any)=> {

      if(element.receiveFixedFees === null ||  element.receiveFixedFees == "")
      {
       element.receiveFixedFees = 0;
      }
      if(element.receiveVariableFees === null ||  element.receiveVariableFees == "")
      {
        element.receiveVariableFees = 0;
      }
    });

    ErrorMessageEmptyModel();
    const receiveFeesData = isValidate();
    
    if (receiveFeesData.formIsValid) {
      const receiveFeesData = isValidate();
      setButtonLoading(true);
      PartnerFeesDetailsService.addPartnerReceiveFees(receiveFeesData.finalData)
        .then((data) => {
          toast.current?.show({
            severity: "success",
            summary: "Send fees added successfully",
            life: 3000,
          });

          getReceiveFees();
          setButtonLoading(false);

          onSaveAndContinueClick("N");
        })
        .catch((error) => {
          if (error.response.status === 500) {
            toast.current?.show({
              severity: "error",
              summary: "Something went wrong",
              life: 3000,
            });
          } else if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Error while adding partner send fees.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };

  const getCurrenciesByPartnerId = (partnerid: any) => {
    setLoadingC2C(true)
    CurrenciesService.getCurrenciesByPartnerId(partnerid)

      .then((data: any) => {
        const responsedata = data.data;

        const currencies = responsedata.map(
          (currency: any, index: any) => currency.currency
        );

        let textArray = partnerDeliveryType
          .replace("[", "")
          .replace("]", "")
          .replace(/["]+/g, "")
          .split(",");

        setdeliveyTypes(textArray);

        var feesCurrencyModel: any = [];

        for (var feed in receivefeeNewModel) {
          for (var feedmodel in receivefeeNewModel[feed]) {
            for (var fes in receivefeeNewModel[feed][feedmodel]) {
              for (let dts in textArray) {
                for (var curr in responsedata) {
                  if (
                    receivefeeNewModel[feed][feedmodel][fes]
                      .receiveFeeCurrency == responsedata[curr].currency &&
                    receivefeeNewModel[feed][feedmodel][fes].dt ==
                      textArray[dts] &&
                    (responsedata[curr].partnerPaymentRole === 2 ||
                      responsedata[curr].partnerPaymentRole === 3)
                  ) {
                    feesCurrencyModel.push(
                      receivefeeNewModel[feed][feedmodel][fes]
                    );
                  }
                }
              }
            }
          }
        }

        currencies.length >= 2
          ? setShowBelowGrid(true)
          : setShowBelowGrid(false);
        setlatestreceivefeeNewModel(feesCurrencyModel);
        setfirstModelCurrency(feesCurrencyModel[0].receiveFeeCurrency);
        setSendCurrencyFees(feesCurrencyModel[0].receiveFeeCurrency);
        setlatestReceiveFeeModel(feesCurrencyModel);
        setCurrency(currencies);
        setLoadingC2C(false)
        getReceiveFees(feesCurrencyModel[0].receiveFeeCurrency);

        const uniqueCurrency = [
          new Set(
            feesCurrencyModel.map((item: any) => item.receiveFeeCurrency)
          ),
        ];

        if (uniqueCurrency[0].size <= 1) {
          setShowBelowGrid(false);
        } else {
          setShowBelowGrid(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting partnership details.",
            life: 3000,
          });
        }
        setLoadingC2C(false);
      });
  };

  const getReceiveFees = (currency?: string) => {

    PartnerFeesDetailsService.getPartnerReceiveFeesByPartnerId(
      Number(partnerid),
      "C2C"
    )
      .then((data) => {
        const feesData = data.data;
        if (feesData.length === 0) {
          setReceivefeeNewModel(receivefeeNewModel);
        } else {
          const INRRecieveData = feesData.filter(
            (fees: any) => fees.receiveFeeCurrency === "INR"
          );
          const USDRecieveData = feesData.filter(
            (fees: any) => fees.receiveFeeCurrency === "USD"
          );
          const CADRecieveData = feesData.filter(
            (fees: any) => fees.receiveFeeCurrency === "CAD"
          );
          const BRLRecieveData = feesData.filter(
            (fees: any) => fees.receiveFeeCurrency === "BRL"
          );
          if (currency === "INR") {
            INRRecieveData.forEach((inrdata: any) => {
              if (inrdata.deliveryType === "RTP") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (inrdata.deliveryType === "MobileWallet") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (inrdata.deliveryType === "CashPickup") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (inrdata.deliveryType === "BankAccount") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }
            });

            USDRecieveData.forEach((usddata: any) => {
              if (usddata.deliveryType === "RTP") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (usddata.deliveryType === "MobileWallet") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (usddata.deliveryType === "CashPickup") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (usddata.deliveryType === "BankAccount") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }
                    return item;
                  });
                });
              }
            });

            CADRecieveData.forEach((caddata: any) => {
              if (caddata.deliveryType === "RTP") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (caddata.deliveryType === "MobileWallet") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (caddata.deliveryType === "CashPickup") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (caddata.deliveryType === "BankAccount") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }
                    return item;
                  });
                });
              }
            });

            BRLRecieveData.forEach((brldata: any) => {
              if (brldata.deliveryType === "RTP") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (brldata.deliveryType === "MobileWallet") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (brldata.deliveryType === "CashPickup") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (brldata.deliveryType === "BankAccount") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }
                    return item;
                  });
                });
              }
            });
          }

          if (currency === "USD") {
            INRRecieveData.forEach((inrdata: any) => {
              if (inrdata.deliveryType === "RTP") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (inrdata.deliveryType === "MobileWallet") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (inrdata.deliveryType === "CashPickup") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (inrdata.deliveryType === "BankAccount") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }
            });

            USDRecieveData.forEach((usddata: any) => {
              if (usddata.deliveryType === "RTP") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (usddata.deliveryType === "MobileWallet") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (usddata.deliveryType === "CashPickup") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (usddata.deliveryType === "BankAccount") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }
                    return item;
                  });
                });
              }
            });

            CADRecieveData.forEach((caddata: any) => {
              if (caddata.deliveryType === "RTP") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (caddata.deliveryType === "MobileWallet") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (caddata.deliveryType === "CashPickup") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (caddata.deliveryType === "BankAccount") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }
                    return item;
                  });
                });
              }
            });

            BRLRecieveData.forEach((brldata: any) => {
              if (brldata.deliveryType === "RTP") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (brldata.deliveryType === "MobileWallet") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (brldata.deliveryType === "CashPickup") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (brldata.deliveryType === "BankAccount") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }
                    return item;
                  });
                });
              }
            });
          }

          if (currency === "CAD") {
            INRRecieveData.forEach((inrdata: any) => {
              if (inrdata.deliveryType === "RTP") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (inrdata.deliveryType === "MobileWallet") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (inrdata.deliveryType === "CashPickup") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (inrdata.deliveryType === "BankAccount") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }
            });

            USDRecieveData.forEach((usddata: any) => {
              if (usddata.deliveryType === "RTP") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (usddata.deliveryType === "MobileWallet") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (usddata.deliveryType === "CashPickup") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (usddata.deliveryType === "BankAccount") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }
                    return item;
                  });
                });
              }
            });

            CADRecieveData.forEach((caddata: any) => {
              if (caddata.deliveryType === "RTP") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (caddata.deliveryType === "MobileWallet") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (caddata.deliveryType === "CashPickup") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (caddata.deliveryType === "BankAccount") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }
                    return item;
                  });
                });
              }
            });

            BRLRecieveData.forEach((brldata: any) => {
              if (brldata.deliveryType === "RTP") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (brldata.deliveryType === "MobileWallet") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (brldata.deliveryType === "CashPickup") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (brldata.deliveryType === "BankAccount") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }
                    return item;
                  });
                });
              }
            });
          }

          if (currency === "BRL") {
            INRRecieveData.forEach((inrdata: any) => {
              if (inrdata.deliveryType === "RTP") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (inrdata.deliveryType === "MobileWallet") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (inrdata.deliveryType === "CashPickup") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (inrdata.deliveryType === "BankAccount") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "INR"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: inrdata.receiveFixedFees,
                        receiveVariableFees: inrdata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }
            });

            USDRecieveData.forEach((usddata: any) => {
              if (usddata.deliveryType === "RTP") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (usddata.deliveryType === "MobileWallet") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (usddata.deliveryType === "CashPickup") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (usddata.deliveryType === "BankAccount") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "USD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: usddata.receiveFixedFees,
                        receiveVariableFees: usddata.receiveVariableFees,
                      };
                    }
                    return item;
                  });
                });
              }
            });

            CADRecieveData.forEach((caddata: any) => {
              if (caddata.deliveryType === "RTP") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (caddata.deliveryType === "MobileWallet") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (caddata.deliveryType === "CashPickup") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (caddata.deliveryType === "BankAccount") {
                setlatestReceiveFeeModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "CAD"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: caddata.receiveFixedFees,
                        receiveVariableFees: caddata.receiveVariableFees,
                      };
                    }
                    return item;
                  });
                });
              }
            });

            BRLRecieveData.forEach((brldata: any) => {
              if (brldata.deliveryType === "RTP") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 1 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (brldata.deliveryType === "MobileWallet") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 4 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (brldata.deliveryType === "CashPickup") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 5 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }

                    return item;
                  });
                });
              }

              if (brldata.deliveryType === "BankAccount") {
                setlatestreceivefeeNewModel((prevState: any) => {
                  return prevState.map((item: any) => {
                    if (
                      item.partnerDeliveryType === 2 &&
                      item.receiveFeeCurrency === "BRL"
                    ) {
                      return {
                        ...item,
                        receiveFixedFees: brldata.receiveFixedFees,
                        receiveVariableFees: brldata.receiveVariableFees,
                      };
                    }
                    return item;
                  });
                });
              }
            });
          }
        }

        setLoadingC2C(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting partner send fees details.",
            life: 3000,
          });
        }
        setLoadingC2C(false);
      });
  };

  const onBackOkClick = () => {

    if (partnerPaymentRole === 3) {
      setBackButtonLoading(true);
      if (partnerType?.includes("B2C") || partnerType?.includes("B2B")) {
        setTimeout(() => {
          setShowB2B(true);
          setShowC2C(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setActiveIndex(0);
        }, 1000);
      }
    } else if (partnerPaymentRole === 2) {
      setBackButtonLoading(true);
      if (partnerType?.includes("B2C") || partnerType?.includes("B2B")) {
        setTimeout(() => {
          setShowB2B(true);
          setShowC2C(false);
        }, 1000);
      } else {
        setTimeout(() => {
          onSaveAndContinueClick("B");
        }, 1000);
      }
    }
  };

  // const onPartnerDeliveryChange = () => {
  //   if (partnerType?.includes("C2C")) {
  //     setShowC2C(true);
  //     setShowB2B(false);
  //   }
  //   if (partnerType?.includes("B2B")) {
  //     setShowB2B(true);
  //     setShowC2C(false);
  //   }
  //   if (partnerType?.includes("B2C")) {
  //     setShowB2B(true);
  //     setShowC2C(false);
  //   }
  //   if (partnerType?.includes("B2B") && partnerType?.includes("C2C")) {
  //     setShowB2B(true);
  //     setShowC2C(false);
  //   }
  //   if (partnerType?.includes("B2B") && partnerType?.includes("B2B")) {
  //     setShowB2B(true);
  //     setShowC2C(false);
  //   }
  //   if (partnerType?.includes("B2C") && partnerType?.includes("C2C")) {
  //     setShowB2B(true);
  //     setShowC2C(false);
  //   }
  // };

  const handleClose = () => {
    if (dirtyfield === true) {
      setDisplayPopup(true);
    }
  };

  const OnPopupClose = () => {
    setDisplayPopup(false);
  };
  const OnPopupOk = () => {
    latestreceivefeeNewModel.map((model: any, index: any) => {
      model.receiveFixedFees = null;
      model.receiveVariableFees = null;
    });
    getCurrenciesByPartnerId(Number(partnerid));
    setDisplayPopup(false);
    setDirtyField(false);

    ErrorMessageEmptyModel();
  };

  useEffect(() => {
    Number(status) === 8 ? setReadOnly(true) : setReadOnly(false);
    // onPartnerDeliveryChange();
    getCurrenciesByPartnerId(Number(partnerid));
  }, []);

  return (
    <>
      {loadingC2C ? (
        <div className="spinner-class" style={{ display: "flex" }}>
          <ProgressSpinner />
        </div>
      ) : (
        <Scrollbars
          className="contain-scroll"
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={500}
          autoHeightMax={1000}
          thumbMinSize={30}
          universal={true}
        >
          <div className="Fees-container">
            <p className="fees-discription">
              Configure any fees that you want to charge consumers for
              receiving payments
            </p>

            <div className="Fees-heading">
              <h2>Consumer Receive Fees </h2>
            </div>

            <div className="Fees-sending-payment">
              <h4>
                What do you want to charge your consumers for receiving
                payments?
              </h4>
              <div className="Fees-details-card Fees-details-card-3">
                {latestreceivefeeNewModel.map((model: any, index: any) => {
                  if (firstModelCurrency == model.receiveFeeCurrency) {
                    return (
                      <div className="inner-card-wrapper" key={model.id}>
                        <div className="fees-card country ">
                          <p>Country </p>
                          {(() => {
                            if (model.receiveFeeCurrency === "INR") {
                              return (
                                <img
                                  src={INDimg}
                                  alt="img"
                                  className="currency-img"
                                />
                              );
                            } else if (model.receiveFeeCurrency === "BRL") {
                              return (
                                <img
                                  src={BRLimg}
                                  alt="img"
                                  className="currency-img"
                                />
                              );
                            } else if (model.receiveFeeCurrency === "CAD") {
                              return (
                                <img
                                  src={CANADAimg}
                                  alt="img"
                                  className="currency-img"
                                />
                              );
                            } else {
                              return (
                                <img
                                  src={USDlogo}
                                  alt="img"
                                  className="currency-img"
                                />
                              );
                            }
                          })()}
                        </div>
                        <div className="fees-card country">
                          <p>Currency</p>
                          <h3>{model.receiveFeeCurrency}</h3>
                        </div>
                        <div className="fees-card country">
                          <p>Delivery Type </p>
                          <h3>{model.deliveryType}</h3>
                        </div>
                        <div className="fees-card fixed-fee">
                          <p>Fixed Fees</p>
                          <div className="fees-card-inupt">
                            <input
                              readOnly={readonly}
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              name="receiveFixedFees"
                              id="feesAmount"
                              className="form-control"
                              aria-describedby="text"
                              value={model.receiveFixedFees}
                              onChange={(e) => {
                                onfixedFeeChange(
                                  e,
                                  model.id,
                                  model.typeId,
                                  model.receiveFeeCurrency,
                                  model.dt,
                                  "Primary"
                                );
                                setDirtyField(true);
                              }}
                            />
                          </div>
                        </div>

                        <div className="fees-card fixed-fee">
                          <p>Variable Fees</p>
                          <div className="percent-wrapper">
                            <input
                              readOnly={readonly}
                              type="number"
                              id="variablefees"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              className="form-control"
                              name="receiveVariableFees"
                              aria-describedby="text"
                              value={model.receiveVariableFees}
                              onChange={(e: any) => {
                                onVariableFeeChange(
                                  e,
                                  model.id,
                                  model.typeId,
                                  model.receiveFeeCurrency,
                                  model.dt,
                                  "Primary"
                                );
                                setDirtyField(true);
                              }}
                            />
                            <span className="feespercent">%</span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
                {showBelowGrid ? null : (
                  <div className="error-card-3 ">
                    <div className="error-message-col">
                      {receivefixedfeeserrorMessage !== null &&
                        receivefixedfeeserrorMessage.length > 0 && (
                          <span className="error-msg">
                            {receivefixedfeeserrorMessage}
                          </span>
                        )}
                    </div>
                    <div className="error-message-col">
                      {receivevariablefeeserrorMessage !== null &&
                        receivevariablefeeserrorMessage.length > 0 && (
                          <span className="error-msg">
                            {receivevariablefeeserrorMessage}
                          </span>
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <hr></hr>
            <>
              {showBelowGrid && (
                <div className="Fees-sending-payment Country-select">
                  <h4>
                    Will the delivery fees be the same for the
                    following countries and delivery type?
                  </h4>
                  <>
                  <div className="Fees-details-card-4 Fees-details-card">
                    {latestReceiveFeeModel.map((model: any, index: any) => {
                      if (sendCurrencyFees !== model.receiveFeeCurrency) {
                        return (
                          <div className="fees-screen-below-section">
                            <div className="inner-card-wrapper" key={index}>
                              <div className="fees-card country">
                                <p>Country </p>
                                <img
                                  src={model.flag}
                                  alt="img"
                                  className="currency-img"
                                />
                              </div>
                              <div className="fees-card country">
                                <p>Currency</p>
                                <h3>{model.receiveFeeCurrency}</h3>
                              </div>
                              <div className="fees-card country">
                                <p>Delivery Type</p>
                                <h3>{model.dt}</h3>
                              </div>

                              <div className="toggle-country ">
                                <div className="Toggle-btn">
                                  <div className="switch-field">
                                    <input
                                      type="radio"
                                      readOnly={readonly}
                                      disabled={readonly}
                                      id={`radio-one-${index}`}
                                      name={`switch-one-${index}`}
                                      onClick={(e: any) =>
                                        SendFeesClick(
                                          e,
                                          model.id,
                                          model.typeId,
                                          model.receiveFeeCurrency,
                                          model.dt
                                        )
                                      }
                                    />

                                    <label
                                      className="default-check"
                                      htmlFor={`radio-one-${index}`}
                                    >
                                      Yes
                                    </label>
                                    <input
                                      type="radio"
                                      readOnly={readonly}
                                      disabled={readonly}
                                      id={`radio-two-${index}`}
                                      name={`switch-one-${index}`}
                                      defaultChecked={true}
                                      onClick={(e: any) =>
                                        RemoveSendFeesClick(
                                          e,
                                          model.id,
                                          model.typeId,
                                          model.receiveFeeCurrency,
                                          model.dt
                                        )
                                      }
                                    />

                                    <label
                                      className="default-check "
                                      htmlFor={`radio-two-${index}`}
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="fees-card fixed-fee">
                                <p>Fixed Fees</p>
                                <div className="fees-card-inupt">
                                  <input
                                    readOnly={readonly}
                                    type="number"
                                    onKeyDown={(evt) =>
                                      evt.key === "e" && evt.preventDefault()
                                    }
                                    name="receiveFixedFees"
                                    id="feesAmount"
                                    className="form-control"
                                    aria-describedby="text"
                                    value={model.receiveFixedFees}
                                    onChange={(e) => {
                                      onfixedFeeChange(
                                        e,
                                        model.id,
                                        model.typeId,
                                        model.receiveFeeCurrency,
                                        model.dt,
                                        "Secondary"
                                      );
                                      setDirtyField(true);
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="fees-card fixed-fee">
                                <p>Variable Fees</p>
                                <div className="percent-wrapper">
                                  <input
                                    readOnly={readonly}
                                    type="number"
                                    id="variablefees"
                                    onKeyDown={(evt) =>
                                      evt.key === "e" && evt.preventDefault()
                                    }
                                    className="form-control"
                                    name="receiveVariableFees"
                                    aria-describedby="text"
                                    value={model.receiveVariableFees}
                                    onChange={(e) => {
                                      onVariableFeeChange(
                                        e,
                                        model.id,
                                        model.typeId,
                                        model.receiveFeeCurrency,
                                        model.dt,
                                        "Secondary"
                                      );
                                      setDirtyField(true);
                                    }}
                                  />
                                  <span className="feespercent">%</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}

<div className="error-card-3 ">
                          <div className="error-message-col">
                            {receivefixedfeeserrorMessage !== null &&
                              receivefixedfeeserrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {receivefixedfeeserrorMessage}
                                </span>
                              )}
                          </div>
                          <div className="error-message-col">
                            {receivevariablefeeserrorMessage !== null &&
                              receivevariablefeeserrorMessage.length > 0 && (
                                <span className="error-msg">
                                  {receivevariablefeeserrorMessage}
                                </span>
                              )}
                          </div>
                        </div>
</div>
                  </>
                </div>
              )}
              {displaypopup ? (
                <>
                  <div className="popup-body">
                    <div className="Cancel-popup">
                      <div className="text-center ">
                        <div className="awesome-text">
                          <h4>Are you sure you want to cancel?</h4>
                          <p> All unsaved changes will be lost.</p>
                        </div>
                      </div>
                      <div className="payment-screen-btn">
                        <button
                          className="btn btn-cancel second-btn "
                          onClick={OnPopupClose}
                        >
                          {" "}
                          No
                        </button>
                        <button
                          className="btn btn-continue second-btn yes-btn-popup"
                          onClick={OnPopupOk}
                        >
                          {" "}
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </>

     
            <div className="button-section">
              <div className="bottom-btns">
                <Button
                  label="Back"
                  loading={backbuttonLoading}
                  className="btn btn-back second-btn"
                  onClick={onBackOkClick}
                />
                {Number(status) === 8 ? (
                  <>
                    <button
                      type="button"
                      onClick={onNextClick}
                      className="btn btn-next second-btn "
                    >
                      Next
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-back second-btn"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>

                    <Button
                      iconPos="left"
                      label=" Save and Continue"
                      loading={buttonLoading}
                      className="btn btn-continue second-btn"
                      onClick={() => onAddClick()}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Scrollbars>
      )}
    </>
  );
};

export default C2CReceiveFees;
