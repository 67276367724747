import { TabPanel, TabView } from "primereact/tabview";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import { Logout } from "../../../utils/AccountUtils";
import ChangePassword from "./ChangePassword";
import ProfileDetails from "./ProfileDetails";

const Profile: React.FC<any> = () => {
  const navigate = useNavigate();

  const [index, setIndex] = useState(0);

  const [status, setstatus] = useState(
    localStorage.getItem("OnboardingStatus")
  );

  const parentFunction = (num: number) => {
    setIndex(num);
  };

  const onTabChange = (e: any) => {
    setIndex(e.index);
  };

  useEffect(() => {

    const useroobj: any = localStorage.getItem("User");

    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }

    setstatus(localStorage.getItem("OnboardingStatus"));
  }, []);

  return (
    <>

      <div className="right-tab-section">
        <div className="container-fluid profile-content">
          {Number(status) <= 2 ? (
            <>
              <TabView>
                <TabPanel header="Account Details">
                  <ChangePassword />
                </TabPanel>
              </TabView>
            </>
          ) : (
            <>
              <TabView
                activeIndex={index}
                onTabChange={(e) => onTabChange(e)}
              >
                <TabPanel header="Profile Details">
                  <ProfileDetails parentFunction={parentFunction} />
                </TabPanel>

                <TabPanel header="Account Details">
                  <ChangePassword />
                </TabPanel>
              </TabView>
            </>
          )}
        </div>
      </div>

    </>
  );
};

export default Profile;
