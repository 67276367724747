import { Button } from "primereact/button";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import CloseIcon from "../../../assets/images/icon/close-icon.png";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import { AutoComplete } from "primereact/autocomplete";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { FxRateService } from "../../../services/Partner/FxRate/FxRateService";
import { Logout } from "../../../utils/AccountUtils";
import { currenciesList } from "../../../utils/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import Scrollbars from "react-custom-scrollbars-2";

const FxRate: React.FC = () => {

  const partnerid = localStorage.getItem("PartnerId");
  const toast = useRef<Toast>(null);

  const navigate = useNavigate();

  const [filteredcurrencylist, setFilteredCurrencyList] = React.useState<any[]>(
    []
  );

  const [filteredrecievecurrencylist, setFilteredRecieveCurrencyList] =
    React.useState<any[]>([]);

  const [, setTypeAutoComplete] = useState("");

  const [sourcecurrencyAutoComplete, setSourceCurrencyAutoComplete] =
    useState("");

  const [targetcurrencyAutoComplete, setTargetCurrencyAutoComplete] =
    useState("");

  const [loading, setLoading] = useState(false);

  const [pageloading, setPageLoading] = useState(true);


  const [displayBasic, setDisplayBasic] = useState(false);

  const [fxrateList, setFxRateList] = React.useState([]);

  const [recievecurrenciesList, setRecieveCurrenciesList] = React.useState([]);

  const [fxrateHistoryList, setFxRateHistoryList] = React.useState([]);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [sourceCurrencyMessage, setsourceCurrencyMessage] = React.useState("");

  const [sourceAmountMessage, setsourceAmountMessage] = React.useState("");

  const [targetCurrencyMessage, settargetCurrencyMessage] = React.useState("");

  const [targetAmountMessage, settargetAmountMessage] = React.useState("");

  const [, setMessage] = React.useState("");

  const [, setAmountMessage] = React.useState("");

  const [rateMessage, setRateMessage] = React.useState("");

  const [visible, setVisible] = useState(false);

  //fx rate model
  const [FxRateModel, setFxRateModel] = React.useState({
    id: 0,
    partnerId: partnerid,
    sourceCurrency: "",
    sourceAmount: "1",
    targetCurrency: "",
    targetAmount: null,
    amount: null,
    rate: "",
    lastUpdatedTime: new Date(),
  });

  //set filter
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    sourceAmount: { value: null, matchMode: FilterMatchMode.EQUALS },
    sourceCurrency: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    targetCurrency: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    rate: { value: null, matchMode: FilterMatchMode.EQUALS },
    targetAmount: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  //set model empty
  const setModelEmpty = () => {
    setFxRateModel({
      id: 0,
      partnerId: partnerid,
      sourceCurrency: "",
      sourceAmount: "1",
      targetCurrency: "",
      targetAmount: null,
      amount: null,
      rate: "",
      lastUpdatedTime: new Date(),
    });
    setTypeAutoComplete("");
    setSourceCurrencyAutoComplete("");
    setTargetCurrencyAutoComplete("");
  };

  //check null
  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null) {
      return true;
    }
    return false;
  };

  //error message empty
  const ErrorMessageEmptyModel = () => {
    setsourceCurrencyMessage("");
    setsourceAmountMessage("");
    settargetCurrencyMessage("");
    settargetAmountMessage("");
    setAmountMessage("");
    setRateMessage("");
    setMessage("");
  };

  //validation check
  const isValidate = (values: any) => {
    let formIsValid = true;
    ErrorMessageEmptyModel();
    if (CheckNull(values.sourceCurrency)) {
      setsourceCurrencyMessage("Please select the send currency.");
      formIsValid = false;
    }
    if (
      !CheckNull(values.sourceCurrency) &&
      !CheckNull(values.targetCurrency)
    ) {
      if (values.sourceCurrency === values.targetCurrency) {
        settargetCurrencyMessage(
          "Receive currency and send currency is not allow to same."
        );
        formIsValid = false;
      }
    }
    if (CheckNull(values.sourceAmount)) {
      setsourceAmountMessage("Please enter the send amount.");
      formIsValid = false;
    }
    if (!CheckNull(values.sourceAmount)) {
      if (values.sourceAmount <= 0) {
        setsourceAmountMessage("Sendamount is not zero or negative.");
        formIsValid = false;
      }
      if (!(values.sourceAmount <= 0)) {
        if (!(values.sourceAmount.toString().match(/^\d{1,16}(\.\d{0,2})?$/))) {
          setsourceAmountMessage('Sendamount valid only upto length(16,2)');
          formIsValid = false;
        }
      }

    }
    if (CheckNull(values.targetCurrency)) {
      settargetCurrencyMessage("Please select the receive fixed currency.");
      formIsValid = false;
    }
    if (CheckNull(values.targetAmount)) {
      settargetAmountMessage("Please enter target amount.");
      formIsValid = false;
    }
    if (!CheckNull(values.targetAmount)) {
      if (values.targetAmount <= 0) {
        settargetAmountMessage("Targetamount is not zero or negative.");
        formIsValid = false;
      }
      if (!(values.targetAmount <= 0)) {
        if (!(values.targetAmount.toString().match(/^\d{1,16}(\.\d{0,5})?$/))) {
          settargetAmountMessage('Targetamount valid only upto length(16,5)');
          formIsValid = false;
        }
      }
    }

    if (CheckNull(values.rate)) {
      setRateMessage("Please enter rate.");
      return false;
    }
    if (!CheckNull(values.rate)) {
      if (values.rate <= 0) {
        setRateMessage("Rate is not zero or negative.");
        formIsValid = false;
      }
      if (!(values.rate <= 0)) {
        if (!(values.rate.toString().match(/^\d{1,16}(\.\d{0,5})?$/))) {
          setRateMessage('Rate valid only upto length(16,5)');
          formIsValid = false;
        }
      }
    }
    return formIsValid;
  };

  //refresh page
  const refreshPage = () => {
    setLoading(true);
    getRecieveCurrencies(partnerid);
    getFxRatePartnerId(partnerid);
    getFxRateHistoryId(partnerid);
  };

  //get fx rate history by id
  const getFxRateHistoryId = (val: any) => {
    setLoading(true);
    FxRateService.getFxRateHistoryByPartnerId(val)
      .then((data) => {

        setFxRateHistoryList(data.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! this Something went wrong.",
            life: 3000,
          });
        }

        setFxRateHistoryList([]);
        setLoading(false);
      });
  };

  //get fx rate by partner id
  const getFxRatePartnerId = (val: any) => {
    setLoading(true);
    FxRateService.getFxRateByPartnerId(val)
      .then((data) => {

        setFxRateList(data.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! this Something went wrong.",
            life: 3000,
          });
        }

        setFxRateList([]);
        setLoading(false);
      });
  };

  //get receive currencies
  const getRecieveCurrencies = (val: any) => {
    setLoading(true);
    setPageLoading(false)

    FxRateService.getRecieveCurrenciesPartnerId(val)
      .then((data) => {
        setLoading(true);
        setRecieveCurrenciesList(data.data);
        setLoading(false);

      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! this Something went wrong.",
            life: 3000,
          });
        }

        setRecieveCurrenciesList([]);
        setLoading(false);
      });
  };

  //on cancel click
  const onCancleClick = () => {
    setModelEmpty();
    setVisible(false);
    FxRateModel.id === 0
      ? setModelEmpty()
      : getFxRatePartnerId(Number(partnerid));
    setDisplayBasic(false);
    ErrorMessageEmptyModel();
  };

  //on add click
  const onAddClick = () => {
    ErrorMessageEmptyModel();
    FxRateModel.partnerId = partnerid;
    FxRateModel.amount = FxRateModel.targetAmount;

    if (isValidate(FxRateModel)) {
      setButtonLoading(true);
      FxRateService.addFxRate(FxRateModel)
        .then((data) => {
          setModelEmpty();
          setButtonLoading(false);
          getFxRatePartnerId(FxRateModel.partnerId);
          onHideClick();
        })
        .catch((error) => {
          if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 400) {
            setMessage(error.response.data[0].errorMessage);
          } else if (error.response.status === 409) {
            setVisible(true);
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Oops! Something went wrong.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };

  //confirm click
  const onConfirmClick = () => {
    setVisible(false);
    onUpdateClick();
  };

  //on update click
  const onUpdateClick = () => {
    FxRateModel.amount = FxRateModel.targetAmount;
    ErrorMessageEmptyModel();
    setButtonLoading(true);
    if (isValidate(FxRateModel)) {
      FxRateService.updateFxRate(FxRateModel)
        .then((data) => {
          setModelEmpty();
          onHideClick();
          getFxRatePartnerId(FxRateModel.partnerId);
          getFxRateHistoryId(FxRateModel.partnerId);
          setButtonLoading(false);
        })

        .catch((error) => {
          if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 400) {
            setMessage(error.response.data[0].errorMessage);
          } else if (error.response.status === 409) {
            setMessage(
              "FxRate with same send currency and receive currency already exists."
            );
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Oops! Something went wrong.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };

  //form date field
  const formatDateField = (rowData: any) => {
    return (
      <React.Fragment>
        <span>
          {moment(rowData.lastUpdatedTime).format("MM/DD/YY H:mm:ss")}{" "}
        </span>
      </React.Fragment>
    );
  };

  //search currency
  const searchCurrency = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < currenciesList.length; i++) {
      let item = currenciesList[i];
      if (item.Name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredCurrencyList(_filteredItems);
  };

  //search recieve currency
  const searchRecieveCurrency = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < recievecurrenciesList.length; i++) {
      let item = recievecurrenciesList[i];
      if (item.currency.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredRecieveCurrencyList(_filteredItems);
  };

  //on source currency change
  const onSourceCurrencyChange = (e: any) => {
    if (e.value !== null) {
      setSourceCurrencyAutoComplete(e.value);
      setFxRateModel({
        ...FxRateModel,
        sourceCurrency: e.value.Name,
      });
    }
  };

  //target currency change
  const onTargetCurrencyChange = (e: any) => {
    if (e.value !== null) {
      setTargetCurrencyAutoComplete(e.value);
      setFxRateModel({
        ...FxRateModel,
        targetCurrency: e.value.currency,
      });
    }
  };

  //edit fx rate row data
  const actionBodyTemplate = (rowData: any) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="editbtn"
          onClick={() => EditFxRate(rowData)}
          title="Edit"
        />
      </>
    );
  };

  //show hide fx model
  const ShowHideDialog = () => {
    setButtonLoading(false);
    setFxRateModel({
      ...FxRateModel,
      sourceAmount: "1",
      targetAmount: null,
      rate: "",
      sourceCurrency: "",
      targetCurrency: "",
    });

    ErrorMessageEmptyModel();
    setDisplayBasic(true);
  };

  //on hide click
  const onHideClick = () => {
    setModelEmpty();
    setDisplayBasic(false);
  };

  //edit fx rate
  const EditFxRate = (val: any) => {
    ShowHideDialog();
    setFxRateModel(val);
    setTypeAutoComplete(val.typeName);
    setSourceCurrencyAutoComplete(val.sourceCurrency);
    setTargetCurrencyAutoComplete(val.targetCurrency);
    ErrorMessageEmptyModel();
  };

  //on rate change
  const onRateChange = (e: any) => {
    FxRateModel.targetAmount =
      Number(e.target.value) * Number(FxRateModel.sourceAmount);
    setFxRateModel({
      ...FxRateModel,
      rate: e.target.value,
    });
  };

  //source amount
  const onSourceAmountChange = (e: any) => {
    FxRateModel.targetAmount =
      Number(e.target.value) * Number(FxRateModel.rate);
    setFxRateModel({
      ...FxRateModel,
      sourceAmount: e.target.value,
    });
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Fx Rate History</span>
    </div>
  );

  const footerContent = (
    <>
      <Button
        label="No"
        onClick={() => setVisible(false)}
        className=" btn btn btn-back second-btn"
      />
      <Button
        label="Yes"
        onClick={() => onConfirmClick()}
        autoFocus
        className="btn btn-continue second-btn"
      />
    </>
  );

  useEffect(() => {
    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      navigate("/");
    }
    // setTimeout(() => {
    getRecieveCurrencies(partnerid);
    getFxRatePartnerId(partnerid);
    getFxRateHistoryId(partnerid);
    //  }, 1000);
  }, []);

  return (
    <>
      <div className="fxratemain">
        {pageloading ? (
          <div className="spinner-class">
            <ProgressSpinner />
          </div>) : (<div className="right-tab-section">
            <Scrollbars
              className="contain-scroll"
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              autoHeight
              autoHeightMin={100}
              autoHeightMax={100}
              thumbMinSize={30}
              universal={true}
            >
              <div className="heading-section transaction">
                <span className="text-header-purple">FxRate</span>
              </div>

              <div className="user-heading ">
                <div className="col-md-6">

                </div>
                <div className="col-md-6 fxrate-btn">
                  <Button
                    iconPos="left"
                    label="Provide Fx Rate"
                    className="btn btn-continue send-btn second-btn"
                    onClick={ShowHideDialog}
                  />

                  <Button
                    iconPos="left"
                    icon="pi pi-refresh"
                    label="Refresh"
                    className="btn btn-continue second-btn"
                    onClick={() => refreshPage()}
                  />{" "}

                </div>
              </div>



              {loading ? (
                <div className="spinner-class">
                  <ProgressSpinner />
                </div>
              ) : (
                <div className=" facilitator-main ">
                  <div className="datatable-doc-demo adm-insta-fees-table">
                    <div className="user-tab ">
                      <DataTable
                        value={fxrateList}
                        paginator
                        className=""
                        rows={5}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}
                        dataKey="id"
                        rowHover
                        filterDisplay="menu"
                        responsiveLayout="scroll"
                        globalFilterFields={[
                          "name",
                          "country.name",
                          "representative.name",
                          "balance",
                          "status",
                        ]}
                        emptyMessage="No FxRate found."
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                      >
                        <Column
                          style={{ width: "15%" }}
                          field="lastUpdatedTime"
                          header="Date Time"
                          body={formatDateField}
                        />
                        <Column field="sourceAmount" header="Send Amount" />
                        <Column field="sourceCurrency" header="Send Currency" />
                        <Column field="targetCurrency" header="Receive Currency" />
                        <Column field="targetAmount" header="Receive Amount" />
                        <Column field="rate" header="Rate" />
                        <Column field="updatedBy" header="Updated By" />
                        <Column header="Action" body={actionBodyTemplate} />
                      </DataTable>
                    </div>
                  </div>

                  <div className="datatable-doc-demo adm-insta-fees-table">
                    <div className="user-tab last-fixrate-table">
                      <DataTable
                        value={fxrateHistoryList}
                        header={header}
                        filters={filters}
                        onFilter={(e) => setFilters(e.filters)}
                        paginator
                        className="filter-table"
                        rows={100}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}
                        dataKey="id"
                        rowHover
                        filterDisplay="row"
                        responsiveLayout="scroll"
                        globalFilterFields={[
                          "sourceCurrency",
                          "targetCurrency",
                          "representative.name",
                          "balance",
                          "status",
                        ]}
                        emptyMessage="No FxRate history found."
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                      >
                        <Column
                          style={{ width: "15%" }}
                          className="fxHistory"
                          field="lastUpdatedTime"
                          header="Date Time"
                          sortable
                          body={formatDateField}
                        />
                        <Column
                          field="sourceAmount"
                          header="Send Amount"
                          filterField="sourceAmount"
                          filter
                          showFilterMenu={false}
                          filterPlaceholder="Filter By Amount"
                          style={{ minWidth: "10%" }}
                        />

                        <Column
                          field="sourceCurrency"
                          header="Send Currency"
                          filterField="sourceCurrency"
                          filter
                          showFilterMenu={false}
                          filterPlaceholder="Filter By Currency"
                          style={{ minWidth: "10%" }}
                        />

                        <Column
                          field="targetCurrency"
                          header="Receive Currency"
                          filter
                          filterField="targetCurrency"
                          filterPlaceholder="Filter By Currency"
                          showFilterMenu={false}
                          style={{ minWidth: "10%" }}
                        />



                        <Column
                          field="targetAmount"
                          header="Receive Amount"
                          filter
                          filterField="targetAmount"
                          filterPlaceholder="Filter By Amount"
                          showFilterMenu={false}
                          style={{ minWidth: "10%" }}
                        />

                        <Column
                          field="rate"
                          header="Rate"
                          filterField="rate"
                          filter
                          showFilterMenu={false}
                          filterPlaceholder="Filter By Rate"
                          style={{ minWidth: "10%" }}
                        />


                      </DataTable>
                    </div>
                  </div>
                </div>
              )}
            </Scrollbars>
          </div>)}

        <Dialog
          header="Header"
          visible={displayBasic}
          onHide={() => onHideClick()}
          className="user-dialog"
        >
          <div className="FFpopup">
            <button className="close-btn" onClick={onHideClick}>
              <img src={CloseIcon} />
            </button>
            <div className="row popup-inner-content ">
              <div className="col-md-6 form-group">
                <span className="input-label">
                  Send currency <span className="color-red">*</span>
                </span>
                <AutoComplete
                  dropdown
                  forceSelection
                  className="dropdown-acc"
                  name="sourcecurrency"
                  field="Name"
                  suggestions={filteredcurrencylist}
                  completeMethod={searchCurrency}
                  placeholder="Select send currency"
                  value={sourcecurrencyAutoComplete}
                  onChange={(e) => onSourceCurrencyChange(e)}
                />
                {sourceCurrencyMessage !== null &&
                  sourceCurrencyMessage.length > 0 ? (
                  <span className="login-error-msg">{sourceCurrencyMessage}</span>
                ) : null}
              </div>
              <div className="col-md-6 form-group ">
                <span className="input-label">
                  Send Amount<span className="color-red">*</span>
                </span>
                <input
                  className="form-control "
                  type="number"
                  name="sourceamount"
                  placeholder="Enter send amount"
                  value={FxRateModel.sourceAmount}
                  onChange={(e) => onSourceAmountChange(e)}
                />
                {sourceAmountMessage !== null &&
                  sourceAmountMessage.length > 0 ? (
                  <span className="login-error-msg">{sourceAmountMessage}</span>
                ) : null}
              </div>
              <div className="col-md-6 form-group ">
                <span className="input-label">
                  Receive Currency <span className="color-red">*</span>
                </span>
                <AutoComplete
                  dropdown
                  forceSelection
                  className="dropdown-acc"
                  name="sourcecurrency"
                  field="currency"
                  suggestions={filteredrecievecurrencylist}
                  completeMethod={searchRecieveCurrency}
                  placeholder="Select receive currency"
                  value={targetcurrencyAutoComplete}
                  onChange={(e) => onTargetCurrencyChange(e)}
                />
                {targetCurrencyMessage !== null &&
                  targetCurrencyMessage.length > 0 ? (
                  <span className="login-error-msg">{targetCurrencyMessage}</span>
                ) : null}
              </div>

              <div className="col-md-6 form-group ">
                <span className="input-label">
                  Rate
                  <span className="color-red">*</span>
                </span>
                <input
                  className="form-control "
                  type="number"
                  placeholder="Enter rate"
                  name="rate"
                  value={FxRateModel.rate}
                  onChange={(e) => onRateChange(e)}
                />
                {rateMessage !== null && rateMessage.length > 0 ? (
                  <span className="login-error-msg">{rateMessage}</span>
                ) : null}
              </div>
              <div className="col-md-6 form-group ">
                <span className="input-label">
                  Receive Amount <span className="color-red">*</span>
                </span>
                <input
                  className="form-control "
                  type="number"
                  name="targetamount"
                  placeholder="Enter receive amount"
                  value={FxRateModel.targetAmount}
                />
                {targetAmountMessage !== null &&
                  targetAmountMessage.length > 0 ? (
                  <span className="login-error-msg">{targetAmountMessage}</span>
                ) : null}
              </div>
              <div className="payment-btn-dialog">
                <button
                  type="button"
                  onClick={onCancleClick}
                  className="btn btn-cancel second-btn"
                >
                  Cancel
                </button>
                <Button
                  iconPos="left"
                  className="btn btn-continue second-btn save-btn"
                  label={"Save"}
                  loading={buttonLoading}
                  onClick={FxRateModel.id === 0 ? onAddClick : onUpdateClick}
                />
              </div>
            </div>
            <div>

            </div>
          </div>
        </Dialog>
        <div className=" ">
          <Dialog
          className="Fxrate-popup"
            header="Confirm dialog box user-dialog"
            visible={visible}
      
            onHide={() => setVisible(false)}
            footer={footerContent}
          >
            <p >
              FxRate with same send currency and receive currency already
              exists. Do you wish to save new record ?
            </p>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default FxRate;
