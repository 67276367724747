import { ProgressSpinner } from "primereact/progressspinner";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PartnershipDetailsService } from "../../../services/Partner/PartnershipDetails/PartnershipDetailsService";
import { Logout } from "../../../utils/AccountUtils";

import ReceiveFees from "./FeesTabs/ReceiveFees";
import SendFees from "./FeesTabs/SendFees";

import { getTabActiveIndex } from "../../../utils/utils";
import Facilitator from "../../PartnerOnboardReady/Facilitator/Facilitator";
import AddFacilitator from "../../PartnerOnboardReady/Facilitator/AddFacilitator";
import { Button } from "primereact/button";
import { UserManagementService } from "../../../services/Partner/UserManagement/UserManagementService";
import { CommonService } from "../../../services/Common/CommonService";
const Fees: React.FC<any> = ({ onSaveAndContinueClick, setIsFacilitator, setButtonLoadingSkip, buttonLoadingSkip }) => {
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const [partnerPaymentRole, setPartnerPaymentRole] = useState(0);
  const [loading, setLoading] = useState(true);
  const id = localStorage.getItem("PartnerId");
  const [partnerid, setpartnerId] = useState(id);
  const [backbuttonloading, setBackButtonLoading] = useState(false);
  const [partnerDeliveryType, setPartnerDeliveryType] = useState([]);
  const [isfacilitator, setFacilitator] = useState(localStorage.getItem('IsFacilitator'));
  const [activeIndex, setActiveIndex] = useState(0);
  const [partnerType, setPartnerType] = useState([]);
  const [receiverBackButtonValue, setReceiverBackButtonValue] = useState(false);
  const [readonly, setReadOnly] = useState(false);
  const [readyPopup, setReadyPopup] = useState(false);
  const [checkFeesPopup, setCheckFeesPopup] = useState(false);
  const [status, setstatus] = useState(
    localStorage.getItem("OnboardingStatus")
  );

  const [index, setIndex] = useState(0);

  const getPartnershipDetailsByPartnerId = () => {
    setLoading(true);
    PartnershipDetailsService.getPartnershipDetailsByPartnerId(
      Number(partnerid)
    )
      .then((response) => {
        const data = response.data;
        const index = getTabActiveIndex(
          data.partnerPaymentRole,
          data.partnerTypes
        );
        if (data.partnerPaymentRole === 2) {
          setActiveIndex(1);
        } else {
          setActiveIndex(0);
        }
        setPartnerPaymentRole(data.partnerPaymentRole);
        setPartnerDeliveryType(data.partnerDeliveryType);
        setPartnerType(data.partnerTypes);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting partnership details.",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };
  const cancelFeesPopup = () => {
    setCheckFeesPopup(false);
  }

  useEffect(() => {
    Number(status) === 8 ? setReadOnly(true) : setReadOnly(false);
    // Update the document title using the browser API
    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    setIsFacilitator(localStorage.getItem('IsFacilitator'));
    setFacilitator(localStorage.getItem('IsFacilitator'));
    getPartnershipDetailsByPartnerId();
  }, []);

  const onTabChange = (partnerPaymentRole: any) => {
    setActiveIndex(partnerPaymentRole.index);
  };
  const onFacilitatorTabChange = (e: any) => {
    setIndex(e.index);
  };
  const onReadyClick = () => {
    setButtonLoadingSkip(true);
    checkFeesExistance(Number(partnerid));
  };
  const cancelPopup = () => {
    setReadyPopup(false);
  };

  const onFeesBack = () => {
    setBackButtonLoading(true);

    setTimeout(() => {
      onSaveAndContinueClick('B')
    }, 500)


  }

  const checkFeesExistance = (partnerid: any) => {
    CommonService.checkFeesExistance(partnerid)

      .then((response: any) => {
        if (response.data === false) {
          setCheckFeesPopup(true);
          setButtonLoadingSkip(false);
        } else {
          setReadyPopup(true);
          setButtonLoadingSkip(false);
        }
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }

      });
  };

  const receiverBackButton=(value:boolean)=>{
    setReceiverBackButtonValue(value);
  }
  
  useEffect(()=>{
  },[receiverBackButtonValue]);

  const accept = () => {
    setReadyPopup(false);
    setCheckFeesPopup(false);
    setButtonLoadingSkip(true);
    UserManagementService.nextUser()

      .then((response: any) => {
        localStorage.setItem("StepFlag", "11");
        localStorage.setItem("OnboardingStatus", "7");
        onSaveAndContinueClick("N");
        setButtonLoadingSkip(false);
        navigate("/onboarding");
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setButtonLoadingSkip(false);
      });
  };


  return (
    <>
      {isfacilitator === 'false' ? (<>
        {loading ? (
          <div className="spinner-class">
            <ProgressSpinner />
          </div>
        ) : (
          <div className="container-fluid payment-screen fees-screen">
            <div className="tabview-demo">
              <TabView
                onTabChange={(e) => onTabChange(e)}
                activeIndex={activeIndex}
                key={new Date().toString()}
              >
                {partnerPaymentRole === 1 || partnerPaymentRole === 3 ? (
                  <TabPanel header="Send">
                    <SendFees partnerType={partnerType} partnerPaymentRole={partnerPaymentRole} onSaveAndContinueClick={onSaveAndContinueClick} setActiveIndex={setActiveIndex}
                         receiverBackButtonValue={receiverBackButtonValue} receiverBackButton={receiverBackButton} key={new Date().setDate(new Date().getDate() + 1).toString()}/>
                  </TabPanel>
                ) : (
                  <TabPanel header="Send" disabled>
                    <SendFees onSaveAndContinueClick={onSaveAndContinueClick} partnerPaymentRole={partnerPaymentRole} setActiveIndex={setActiveIndex} receiverBackButtonValue={receiverBackButtonValue} receiverBackButton={receiverBackButton} key={new Date().setDate(new Date().getDate() + 2).toString()}/>
                  </TabPanel>
                )}
                {partnerPaymentRole === 2 || partnerPaymentRole === 3 ? (
                  <TabPanel header="Receive">
                    <ReceiveFees
                      partnerDeliveryType={partnerDeliveryType} partnerPaymentRole={partnerPaymentRole}
                      partnerType={partnerType} onSaveAndContinueClick={onSaveAndContinueClick}
                      setActiveIndex={setActiveIndex}
                      receiverBackButtonValue={receiverBackButtonValue}
                      receiverBackButton={receiverBackButton}
                      key={new Date().setDate(new Date().getDate() + 3).toString()}
                    />
                  </TabPanel>
                ) : (
                  <TabPanel header="Receive" disabled>
                    <ReceiveFees
                      partnerDeliveryType={partnerDeliveryType} partnerPaymentRole={partnerPaymentRole}
                      partnerType={partnerType} onSaveAndContinueClick={onSaveAndContinueClick}
                      setActiveIndex={setActiveIndex}
                      receiverBackButtonValue={receiverBackButtonValue}
                      receiverBackButton={receiverBackButton}
                      key={new Date().setDate(new Date().getDate() + 4).toString()}
                    />
                  </TabPanel>
                )}


              </TabView>

            </div>
          </div>
        )}
      </>) :
        <>

          <div className="row payment-section fees-next recevie-currency">

            <div className="user-heading fees" >

              {Number(status) === 8 ? null : (
                isfacilitator === 'true' ? (
                  <>
                    <Button
                      loading={backbuttonloading}
                      label="Back"
                      className="btn btn-back hover-remove"
                      onClick={onFeesBack}
                    />
                    &nbsp;&nbsp; &nbsp;&nbsp;
                    <Button
                      loading={buttonLoadingSkip}
                      label="Ready"
                      className="btn btn-continue"
                      onClick={onReadyClick}
                    />
                  </>
                ) : null
              )}


            </div>


            <TabView
              activeIndex={index}
              onTabChange={(e) => onFacilitatorTabChange(e)}
            >
              <TabPanel header="Facilitator Fees">
                <Facilitator />
              </TabPanel>

              <TabPanel header="Add Partner" >
                <AddFacilitator />
              </TabPanel>
            </TabView>
          </div>
          {readyPopup ? (
            <div className="popup-body">
              <div className="regiter-popup confirm-popup " id="confirm-popup">
                <div className="popup-text">
                  <i className="pi pi-info-circle"></i>
                  <p>
                    Are you sure you want to submit? Once you submit, you will not be
                    able to undo your changes.
                  </p>
                </div>

                <div className="popup-btn">
                  <button
                    type="button"
                    onClick={cancelPopup}
                    className="btn btn-back second-btn"
                  >
                    No
                  </button>
                  <button

                    type="button"
                    onClick={accept}
                    className="btn btn-continue second-btn"
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </>
      }
      {checkFeesPopup ? (
        <div className="popup-body feespopup">
          <div className="regiter-popup confirm-popup " id="confirm-popup">
          <div className="popup-text">
          <i className="pi pi-info-circle"></i>
                  <p>
                  Are you sure you want to submit without fees?
                  </p>
            </div>

            <div className="popup-btn">
              <button
                type="button"
                onClick={cancelFeesPopup}
                className="btn btn-back second-btn"
              >
                No
              </button>
              <button
                type="button"
                onClick={accept}
                className="btn btn-continue second-btn"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      ) : null}

    </>
  );
};

export default Fees;
