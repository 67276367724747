import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import IRLOGO from "../../../assets/images/icon/logo-icon.png";
import passImg from "../../../assets/images/pass-img.svg";
import { ISetPassword } from "../../../models/ISetPassword";
import { LoginService } from "../../../services/Account/LoginService";
import { SetPasswordService } from "../../../services/Account/SetPasswordService";
import { Logindata } from "../../../utils/AccountUtils";
import LocalStorageContext from "../../Context/LocalStorageContext";

interface IState {
  user: ISetPassword;
}

const SetPassword: React.FC = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const context = useContext(LocalStorageContext);
  const [state, setState] = useState<IState>({
    user: {
      setPasswordToken: token,
      newPassword: "",
      confirmPassword: "",
      isActive: false,
      userName: "",
      firstName: "",
      lastName: "",
    },
  });

  const [isUserValid, setIsUserValid] = useState("");

  const [loginstate, setLoginState] = useState({
    userName: state.user.userName,
    password: "",
  });

  const toTitleCase = (phrase: string) => {
    return phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const [buttonloading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmpasswordType, setConfirmPasswordType] = useState("password");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");

  //   handle change function

  const ErrorMessageEmptyModel = () => {
    setNewPasswordErrorMessage("");
    setConfirmPasswordErrorMessage("");
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfirmPassword = () => {
    if (confirmpasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };
  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null) {
      return true;
    }
    return false;
  };

  const isValidate = (values: any) => {
    let formIsValid = true;
    ErrorMessageEmptyModel();
    if (CheckNull(values.newPassword)) {
      setNewPasswordErrorMessage("Please enter new password.");
      formIsValid = false;
    }
    const regix = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );

    if (!CheckNull(values.newPassword)) {
      if (values.newPassword.trim().length === 0) {
        setNewPasswordErrorMessage("Empty password not allowed.");
        formIsValid = false;
      }
      if (regix.test(values.newPassword) === false) {
        setNewPasswordErrorMessage(
          "8 characters,1 special character,1 capital letter,1 lowercase letter,1 number"
        );
        formIsValid = false;
      }
    }
    if (CheckNull(values.confirmPassword)) {
      setConfirmPasswordErrorMessage("Please enter confirm password.");
      formIsValid = false;
    }
    if (!CheckNull(values.confirmPassword)) {
      if (values.confirmPassword.trim().length === 0) {
        setConfirmPasswordErrorMessage("Empty password not allowed.");
        formIsValid = false;
      }
      if (values.newPassword !== values.confirmPassword) {
        setConfirmPasswordErrorMessage("Confirm password does not match");
        formIsValid = false;
      }
    }

    return formIsValid;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({
      user: {
        ...state.user,
        [event.target.name]: event.target.value,
      },
    });
    setLoginState({
      ...loginstate,
      password: event.target.value,
      userName: state.user.userName,
    });
  };

  // form handling
  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setButtonLoading(true);
    if (isValidate(state.user)) {
      SetPasswordService.setUserPassword(state.user)
        .then((data) => {
          LoginService.login(loginstate)
            .then((response: any) => {
              Logindata(response, navigate);
              context.updateLogoSrc(response.data.logoURL);
              context.updateStatus(response.data.onboardingStatus);
              context.updateStepFlag(response.data.stepFlag);
              context.updateFacilitator(response.data.isfacilitator);
              context.updateRole(response.data.partnerRole);
              setButtonLoading(false);
            })
            .catch((error) => {
              setButtonLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);

          if (error.response.status == 400) {
            setIsUserValid("false");
          }

          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    getUser(token!);

    localStorage.clear();
  }, []);

  const getUser = (token: string) => {
    setLoading(true);
    SetPasswordService.getUserByToken(token)
      .then((response: any) => {
        setState({
          user: {
            ...state.user,
            isActive: response.data.isActive,
            userName: response.data.userName,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
          },
        });

        setIsUserValid("true");
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })

      .catch((error) => {
        console.log(error);
        if (error.response.status == 400) {
          setIsUserValid("false");
        } else {
          setIsUserValid("false");
        }
        setLoading(false);
      });
  };

  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <>
   
        <>
         
            <div className="Sigin-page bg-height">
            {loading ? (
        <div className="spinner-class white-spinner">
          <ProgressSpinner />
        </div>
      ) : isUserValid === "false" ? (
              <div className="SetPassword-form">
                <div className="login-page">
                  <img src={IRLOGO} alt="img" className="logo-white" />
                </div>
                <div className="link-expired-message">
                  <p className="text-white-pass link-expire">
                    This link is expired or invalid, please contact to service
                    provider.
                  </p>
                </div>
              </div>
                    ) : isUserValid === "true" ? (
                      <>
                        <div>
                          <div className="Sigin-page bg-height">
                            <div className="SetPassword-form">
                              <div className="login-page">
                                <img src={IRLOGO} alt="img" className="logo-white" />
                              </div>
                              <div className="signin-form-display">
                                <div className="login-form-display">
                                  <div className="text-center">
                                    <h2>
                                      Hello {toTitleCase(state.user.firstName)}{" "}
                                      {toTitleCase(state.user.lastName)}!
                                    </h2>
              
                                    <h4>Please set up your password.</h4>
              
                                    <h4 className="second-title">
                                      Access the instant payment network
                                    </h4>
                                  </div>
              
                                  <div className="username-form">
                                    <form >
                                      <div className="form-group login-form-input ">
                                        <p>
                                          {" "}
                                          <img
                                            src={passImg}
                                            alt="img"
                                            style={{ width: "14px" }}
                                          />
                                          New Password
                                        </p>
                                        <div className="password-input-icon">
                                          <input
                                            type={passwordType}
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            placeholder="New password"
                                            name="newPassword"
                                            onChange={handleChange}
                                            value={state.user.newPassword}
                                            onKeyPress={handleKeypress}
                                          />
                                          <span className="eyeicon">
                                            {passwordType === "password" ? (
                                              <i
                                                className="pi pi-eye-slash"
                                                onClick={togglePassword}
                                              ></i>
                                            ) : (
                                              <i
                                                className="pi pi-eye"
                                                onClick={togglePassword}
                                              ></i>
                                            )}
                                          </span>
                                          {newPasswordErrorMessage !== null &&
                                            newPasswordErrorMessage.length > 0 ? (
                                            <span className="login-error-msg-password">
                                              {newPasswordErrorMessage}
                                            </span>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="form-group login-form-input confirm-pass">
                                        <p>
                                          {" "}
                                          <img
                                            src={passImg}
                                            alt="img"
                                            style={{ width: "14px" }}
                                          />
                                          Confirm Password
                                        </p>
                                        <div className="password-input-icon">
                                          <input
                                            type={confirmpasswordType}
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            placeholder="Confirm password"
                                            name="confirmPassword"
                                            value={state.user.confirmPassword}
                                            onChange={handleChange}
                                            onKeyPress={handleKeypress}
                                          />
                                          <span className="eyeicon">
                                            {confirmpasswordType === "password" ? (
                                              <i
                                                className="pi pi-eye-slash"
                                                onClick={toggleConfirmPassword}
                                              ></i>
                                            ) : (
                                              <i
                                                className="pi pi-eye"
                                                onClick={toggleConfirmPassword}
                                              ></i>
                                            )}
                                          </span>
                                          {confirmPasswordErrorMessage !== null &&
                                            confirmPasswordErrorMessage.length > 0 ? (
                                            <span className="login-error-msg">
                                              {confirmPasswordErrorMessage}
                                            </span>
                                          ) : null}
                                        </div>
                                      </div>
                                      <Button
                                        label="Set Password"
                                        className="btn btn-login save-btn reset-btn"
                                        loading={buttonloading}
                                        onClick={handleSubmit}
                                      />
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </>
                    ) : null}
            </div>
          
        </>

    </>
  );
};

export default SetPassword;
