import { Navigate, Route, Routes } from "react-router";
import Login from "./components/Account/Login/Login";
import Register from "./components/Account/Register/Register";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "primeicons/primeicons.css";
// import "@fontsource/space-grotesk";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import SetPassword from "./components/Account/SetPassword/SetPassword";
import { BrowserRouter } from "react-router-dom";
import Details from "./Layout/Details";
import OnboardingDialogue from "./components/Partner/OnbordingDialogue";
import ForgotPassword from "./components/Account/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/Account/ResetPassword/ResetPassword";
import Payment from "./Layout/Payment";
import Paymentsettingread from "./components/Partner/PaymentReadonly/Paymentsettingread";
import Dashboard from "./Layout/Dashboard";
import CreatePayment from "./components/PartnerOnboardReady/Payment/CreatePayment";
import Transactions from "./components/PartnerOnboardReady/Transaction/Transactions";
import Users from "./components/PartnerOnboardReady/Users/Users";
import Profile from "./components/Account/Profile/Profile";
import Errorpage from "./components/Partner/ErrorPage/Errorpage";
import CheckQuotes from "./components/PartnerOnboardReady/CheckQuotes/CheckQuotes";
import DeveloperResources from "./components/PartnerOnboardReady/DeveloperResources/DeveloperResources";
import FxRate from "./components/PartnerOnboardReady/FxRate/FxRate";
import Reports from "./components/PartnerOnboardReady/Reports/Reports";
import FacilitatorTabContent from "./components/PartnerOnboardReady/Facilitator/FacilitatorTabcontent";
import SubmitPayment from "./components/PartnerOnboardReady/Payment/SubmitPayment";
import AddPaymentFields from "./components/PartnerOnboardReady/Payment/AddPaymentFields";
import TransactionsPaymentDetails from "./components/PartnerOnboardReady/Transaction/TransactionPaymentDetails";
import DashboardLayout from "./Layout/DashboardLayout";
import Ledger from "./components/PartnerOnboardReady/Ledger/Ledger";
import LocalStorageProvider from "./components/Provider/LocalStorageProvider";

//import React from 'react'

export default function App() {
  return (
    <LocalStorageProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="login" element={<Navigate to="/" />} />
       
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="setpassword/:token" element={<SetPassword />} />
        <Route path="resetpassword/:token" element={<ResetPassword />} />

        <Route path="/" element={<DashboardLayout />}>


        <Route path="/dashboard" element={<Dashboard />} />
        
          <Route index path="/details" element={<Details />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/onboarding" element={<OnboardingDialogue />} />
          <Route path="/Paymentsettingread" element={<Paymentsettingread />} />
          <Route path="/createpayment" element={<CreatePayment />} />
          <Route path="/addpaymentfields" element={<AddPaymentFields />} />
          <Route path="/submitpayment" element={<SubmitPayment />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/users" element={<Users />} />
          <Route path="/checkquotes"  element={<CheckQuotes />} />
          <Route path="/facilitator" element={<FacilitatorTabContent />} />
          <Route path="/developerresources" element={<DeveloperResources />} />
          <Route path="/fxrate" element={<FxRate />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/ledger" element={<Ledger/>}/>
          <Route
          path="/transaction-details/:paymentId"
          element={<TransactionsPaymentDetails />}
        >

        </Route>
        </Route>
        <Route path="*" element={<Errorpage />}></Route>
     
      </Routes>
    </BrowserRouter>
    </LocalStorageProvider>
  );
}

function PageNotFound() {
  return (
    <div>
      <h2>404 Page not found</h2>
    </div>
  );
}
