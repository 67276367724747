const DocumentToolTips = [

  {
    "id":0,
     "Info":`<h4>Summary of what your organization does </h4>`
},
{
    "id":1,
     "Info":`<h4>Provide the reason why you want to join the Instarails network. The reason could be as follows:</h4> <ul> <li>Instant global cross-border payments</li>   <li>Referred by an individual or company</li>  <li>Global business expansion</li>  <li>Easier and more efficient global cross-border payments</li>  <li>Get more payment volume from the Instarails network in my country</li> </ul>`
},
{
    "id":2,
     "Info":`<h4>Type of the document</h4>`
},
]

export default DocumentToolTips;