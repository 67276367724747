import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useNavigate, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { TransactionDetailsService } from "../../../services/Partner/TransactionDetails/TransactionDetails";
import { Logout } from "../../../utils/AccountUtils";
import moment from "moment";
import { Button } from "primereact/button";
import { IsValidRoute } from "../../../utils/AccountUtils";

const Transactions: React.FC = () => {

  const dt = useRef(null);

  const toast = useRef<Toast>(null);

  const [loading, setLoading] = useState(false);

  const [pageLoading, setPageLoading] = useState(true);

  const navigate = useNavigate();

  const [globalFilterValue, setGlobalFilterValue] = useState(null);

  const [transactionDetails, setTransactionDetails] = useState<any[]>([]);

  const flag = parseInt(localStorage.getItem("PartnerPaymentRole"));
  const isFacilitator = (localStorage.getItem("IsFacilitator"));





  useEffect(() => {
    IsValidRoute(navigate);
    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    transactionDetailsPartnerById();
  }, []);

  // transaction details by partner id

  const transactionDetailsPartnerById = () => {
    setLoading(true);
    TransactionDetailsService.getTransactionDetailsByPartnerId()
      .then((data) => {
        setTransactionDetails(data.data);
        setLoading(false);
        setPageLoading(false)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setTransactionDetails([]);
        setLoading(false);
      });
  };


  const checkreceiverName = (rowData: any) => {

    if (rowData.receiverName === '' || rowData.receiverName === null || rowData.receiverName === undefined) {
      return (
        <React.Fragment>
          <span>{(rowData.receiveAmount = '')} </span>
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <span>{(rowData.receiveAmount)} </span>
        </React.Fragment>
      );
    }
  }


  // date field as per m/d/y 
  const formatDateField = (rowData: any) => {
    return (
      <React.Fragment>
        <span>{moment(rowData.creationDate).format("MM/DD/YY H:mm.ss")} </span>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData: any) => {
    const onPaymentId = ({ }) => {
      if (rowData.paymentId) {
        navigate(`/transaction-details/${rowData.paymentId}`);
      }
    };
    return (
      <>
        <a className="payment-id-link" onClick={onPaymentId}>
          {rowData.paymentId}
        </a>
      </>
    );
  };



  return (
    <>
      {pageLoading ? (<div className="spinner-class">
        <ProgressSpinner />
      </div>) : (
      <div className=" dashboard transaction-screen transaction-screen-3">
        <div className="heading-section transaction">
          <span className="text-header-purple">Transactions</span>
        </div>
        <div className="user-heading">
          <div className="col-md-6 data-search-input">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                placeholder="Search..."
                onInput={(e: any) =>
                  setGlobalFilterValue(e.target.value)
                }
              />
            </span>
          </div>
          <Button
            iconPos="left"
            className="refresh-btn"
            icon="pi pi-refresh"
            label={"Refresh"}
            onClick={() =>
              transactionDetailsPartnerById()
            }
          ></Button>
        </div>
        {loading ? (
          <div className="spinner-class">
            <ProgressSpinner />
          </div>
        ) : (
          <div className="transactionmain">
            <div className=" right-tab-section transaction-tab ">
              <Scrollbars
                className="contain-scroll"
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                autoHeight
                autoHeightMin={100}
                autoHeightMax={100}
                thumbMinSize={30}
                universal={true}
              >

                <div className="transaction-table-scroll ">

                  <div className="datatable-doc-demo">
                    <div className="">
                      <DataTable
                        ref={dt}
                        value={transactionDetails}
                        paginator
                        className="transactionDetails-table"
                        rows={50}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[50, 50, 50]}
                        dataKey="id"
                        loading={loading}
                        scrollDirection="both"
                        filterDisplay="menu"
                        globalFilter={globalFilterValue}
                        filters={globalFilterValue}
                        emptyMessage="No transactions found."
                        responsiveLayout="scroll"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        sortField="creationDate"
                        sortOrder={-1}
                      >
                        <Column
                          field="creationDate"
                          header="Dates Time"
                          sortable
                          body={formatDateField}
                          style={{ width: "6%" }}
                        />

                        <Column
                          className="message-id "
                          field="paymentId"
                          body={actionBodyTemplate}
                          header="Payment Id"
                          sortable
                          style={{ width: "15%" }}
                        />

                
                        <Column
                          className="small-col"
                          field="paymentType"
                          header="Payment Type"
                          sortable
                          style={{ width: "6%" }}
                        />

                        <Column
                          className="small-col"
                          field="sendAmount"
                          header="Send Amount"
                          sortable
                          style={{ width: "6%" }}
                        />
                        <Column
                          className="small-col"
                          field="sendCurrency"
                          header="Send Currency"
                          sortable
                          style={{ width: "6%" }}
                        />
                        <Column
                          className="small-col receive-currency"
                          field="targetCurrency"
                          header="Receive Currency"
                          sortable
                          style={{ width: "6%" }}
                        />
                        <Column

                          className="small-col"
                          field="receiveAmount"
                          header="Receive Amount"
                          style={{ width: '8%' }}
                          sortable
                          //body={checkreceiverName}
                        />
                        <Column
                          field="description"
                          header="Status"
                          style={{ width: "9%" }}
                          sortable
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              </Scrollbars>
            </div>
          </div>
        )}
      </div>)}
    </>
  );
};

export default Transactions;
