import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useNavigate } from "react-router-dom";
import BRLimg from "../../../assets/images/BRLimg.svg";
import CANADAimg from "../../../assets/images/canada-flag.svg";
import INDimg from "../../../assets/images/india.svg";
import USDlogo from "../../../assets/images/USDImg.svg";
import { CurrenciesService } from "../../../services/Partner/Currencies/Currencies";
import { UserManagementService } from "../../../services/Partner/UserManagement/UserManagementService";
import { WalletService } from "../../../services/Partner/Wallet/WalletService";
import { Logout } from "../../../utils/AccountUtils";

const Wallet: React.FC<any> = ({ onSaveAndContinueClick, onNextClick, buttonLoadingSkip,
  buttonLoadingBack,
  setButtonLoadingBack,
  setButtonLoadingSkip, setIsFacilitator }) => {
  const id = localStorage.getItem("PartnerId");
  const [partnerid, setpartnerId] = useState(id);
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [walletList, setWalletList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [readonly, setReadOnly] = useState(false);
  const [status, setstatus] = useState(
    localStorage.getItem("OnboardingStatus")
  );


  const [buttonLoading, setButtonLoading] = useState(false);
  const [readybuttonenable, setReadyButtonEnable] = useState(true);
  const getCurrenciesByPartnerId = (partnerid: any) => {
    setLoading(true);
    CurrenciesService.getCurrenciesByPartnerId(partnerid)
      .then((data: any) => {
        const responsedata = data.data;
        setWalletList(responsedata);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };


  const onBackClick = () => {
    setButtonLoadingBack(true);
    setTimeout(() => {
      onSaveAndContinueClick("B");
    }, 1000);
  };


  const onAddClick = () => {
    setButtonLoading(true);
    WalletService.addWallet()
      .then((response: any) => {
        setButtonLoading(false);
        localStorage.setItem("StepFlag", "8");
        onSaveAndContinueClick("N");

      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while adding wallet info details.",
            life: 3000,
          });
        }
        setButtonLoading(false);
      });
  };




  useEffect(() => {
    setButtonLoadingBack(false);
    setButtonLoadingSkip(false);
    {
      Number(status) === 8 ? setReadOnly(true) : setReadOnly(false);
    }
    setIsFacilitator(localStorage.getItem('IsFacilitator'));

    getCurrenciesByPartnerId(Number(partnerid));
  }, []);

  return (
    <>
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <Scrollbars
          className="contain-scroll"
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={100}
          autoHeightMax={100}
          thumbMinSize={30}
          universal={true}
        >
          <div className="container-fluid acc-screen currency-screen wallet-main">
            <Toast ref={toast}></Toast>
            <div className="recevie-currency">
              <h3>We have created the following sub-account for you:</h3>
              <div className="currency-details">
                {walletList.length === 0 ? (
                  <p className="wallet-empty-message">
                    You must first create a wallet.
                  </p>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Currency</th>
                        <th>Sub-account ID</th>
                        <th>Wallet ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {walletList
                        .sort((a: any, b: any) =>
                          a.currency > b.currency ? -1 : 1
                        )
                        .map((currency: any, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="border-right country-flag">
                              {(() => {
                                if (currency.currency === "INR") {
                                  return (
                                    <img
                                      src={INDimg}
                                      alt="img"
                                      className="currency-img"
                                    />
                                  );
                                } else if (currency.currency === "BRL") {
                                  return (
                                    <img
                                      src={BRLimg}
                                      alt="img"
                                      className="currency-img"
                                    />
                                  );
                                } else if (currency.currency === "CAD") {
                                  return (
                                    <img
                                      src={CANADAimg}
                                      alt="img"
                                      className="currency-img"
                                    />
                                  );
                                } else {
                                  return (
                                    <img
                                      src={USDlogo}
                                      alt="img"
                                      className="currency-img"
                                    />
                                  );
                                }
                              })()}
                              {currency.currency}
                            </td>
                            <td>{currency.code}</td>
                            <td>{currency.walletId}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
                <div className="button-section">
                  <div className="bottom-btns">
                    <Button
                      iconPos="left"
                      label="Back"
                      loading={buttonLoadingBack}
                      onClick={onBackClick}
                      className="btn btn-back second-btn"
                    />
                    {Number(status) === 8 ? (
                      <>

                        <Button
                          iconPos="left"
                          label="Next"
                          loading={buttonLoadingSkip}
                          onClick={onNextClick}
                          className="btn btn-continue   btn-next second-btn"
                        />

                      </>
                    ) : (
                      <>

                        <Button
                          iconPos="left"
                          label="Next"
                          loading={buttonLoading}
                          className="btn btn-continue   btn-next second-btn"
                          onClick={onAddClick}
                        />
                        {/*                          
                          <Button
                            loading={buttonLoadingSkip}
                            label="Ready"
                            className="btn btn-continue"
                            onClick={onReadyClick}
                          /> */}

                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      )}

    </>
  );
};

export default Wallet;
