import axios from "axios";
import { environment } from "../../environments/environment";

export class DashboardService{
    private static URL: any = environment.partnerApiUrl;
    public static getDashboardData = (partnerid: any) => {
        let PartnerURL: string = `${this.URL}/dashboard?partnerid=${partnerid}`;    
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
        };
        return axios.get<any>(PartnerURL, config);
      }

}
