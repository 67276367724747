import React from "react"
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Logout } from "../../src/utils/AccountUtils";
import { DashboardService } from "../services/Dashboard/DashboardService";
import { ProgressSpinner } from "primereact/progressspinner";

const Dashboard: React.FC = () => {
  const [dashboarddata, setdashboarddata] = React.useState([]);
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const PartnerId = localStorage.getItem("PartnerId");
  const [noOfTransactions, setNoOfTransactions] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [showsend, setshowsend] = React.useState(false);
  const [showrecieve, setshowrecieve] = React.useState(false);

  // const [currencywisetransactionmodel, setCurrencyWiseTransactionModel] = React.useState([]);
  const [partnertransactionfeemodels, setPartnerTransactionFeeModels] = React.useState([]);
  const [responsepartnerfeesAmounts, setPartnerFeesAmount] = React.useState([]);

  useEffect(() => {
    const useroobj = localStorage.getItem("User");
    const paymentrole = localStorage.getItem("PartnerPaymentRole");
    const isFacilitator = localStorage.getItem("IsFacilitator");
    setshowrecieve(false);
    setshowsend(false);
    if (isFacilitator == 'true') {
      setshowrecieve(true);
      setshowsend(true);
    }
    else {
      if (paymentrole == '1' || paymentrole == '3') {
        setshowsend(true);
      }

      if (paymentrole == '2' || paymentrole == '3') {
        setshowrecieve(true);
      }
    }

    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    getDashboardData(PartnerId);
  }, []);

  const getDashboardData = (partnerid: any) => {
    setLoading(true);
    DashboardService.getDashboardData(partnerid)
      .then((dashboarddatalist: any) => {
        setdashboarddata(dashboarddatalist);

        setNoOfTransactions(dashboarddatalist.data.noOfTransactions);
        // setCurrencyWiseTransactionModel(dashboarddatalist.data.transcationModels);
        setPartnerTransactionFeeModels(dashboarddatalist.data.partnerFeemodels);

        setPartnerFeesAmount(dashboarddatalist.data.responsePartnerFeesAmounts);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };
  return (
    <>
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <div className="dashboardmain">

          <span className="text-header-purple">Dashboard</span>

          <div className="row dashboardmain">
            <div className="col-md-4  dashboard-card">
              <div className="row">
                <span className="text-black-address header">
                  Total Number of transaction
                </span>
                <div className="dashboardvalue">{noOfTransactions}</div>
              </div>
            </div>
            {
              partnertransactionfeemodels?.length > 0 && partnertransactionfeemodels != null ?
                <div className="col-md-4  dashboard-card dashboardcardsecond">
                  {
                    partnertransactionfeemodels.map((model: any, index: any) => {
                      return (
                        <>
                          <span className="text-black-address mt-2">
                            {model.feeType}
                          </span>
                          <div className="dashboardvalue">
                            {model.totalFee} {model.currency}
                          </div>
                        </>
                      );
                    })
                  }
                </div>
                :
                <div className="col-md-4  dashboard-card dashboardcardsecond">
                  {

                    <>
                      <span className="text-black-address mt-2">
                        {showsend ? <>
                          <span className="text-black-address mt-2"> Send Fee</span>
                          <div className="dashboardvalue"> 0 </div>
                        </> : null}
                        {showrecieve ? <>
                          <span className="text-black-address mt-2"> Receive Fee </span>
                          <div className="dashboardvalue"> 0 </div>
                        </> : null}
                      </span>
                    </>
                  }
                </div>
            }
            {responsepartnerfeesAmounts?.length > 0 && responsepartnerfeesAmounts != null ?
              <div className="col-md-4  dashboard-card dashboardcardsecond">
                {responsepartnerfeesAmounts.map((model: any, index: any) => {
                  return (
                    <>
                      <span className="text-black-address mt-2">
                        {model.feetype}
                      </span>
                      <div className="dashboardvalue">
                        {model.total} {model.currency}
                      </div>
                    </>
                  );
                })}
              </div>
              : <div className="col-md-4  dashboard-card dashboardcardsecond">
                {

                  <>

                    {
                      showsend ? <>
                        <span className="text-black-address mt-2"> Send Amount </span>
                        <div className="dashboardvalue">  0 </div>
                      </> :
                        null}
                    {showrecieve ? <>
                      <span className="text-black-address mt-2"> Receive Amount</span>
                      <div className="dashboardvalue">0</div>  </> : null}

                  </>
                }
              </div>
            }
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
