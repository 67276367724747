
const AddressoolTipData = [
{
    "id":1,
     "Info":`<h4>Your street address and unit number</h4>`
},
{
    "id":2,
     "Info":`<h4>Optional address line</h4>`
},
{
    "id":3,
     "Info":`<h4>Optional building number for address</h4>`
},
{
    "id":4,
     "Info":`<h4>Town or city of address</h4>`
},
{
    "id":5,
     "Info":`<h4>Province or state of address</h4>`
},
{
    "id":6,
     "Info":`<h4>Country</h4>`
},
{
    "id":7,
     "Info":`<h4>Postal Code or zip code </h4>`
}

]


export default AddressoolTipData;