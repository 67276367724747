import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { Logout } from "../../../utils/AccountUtils";
import { PaymentSettingsServices } from "../../../services/Partner/PaymentSettings/PaymentSettings";
import { useNavigate } from "react-router-dom";


function AddReceiverInformationManadatoryFields(
  PartnerId: any,
  Type: any,
  FieldId: any,
  SubCategoryId: any
) {
  return { PartnerId, Type, FieldId, SubCategoryId };
}

const B2BorC2BTransactionLimits: React.FC<any> = ({
  onB2BorC2BTransactionLimitsAddClick,
  buttonLoadingSkip,
  buttonLoadingBack,
  setButtonLoadingBack,
  setButtonLoadingSkip,
  B2BorC2BTransactionLimitsBackClick,
}) => {
  const id = localStorage.getItem("PartnerId");

  const [loading, setLoading] = useState(true);

  const [readonly, setReadOnly] = useState(false);

  const [status, setstatus] = useState(
    localStorage.getItem("OnboardingStatus")
  );

  const [namefield, setNameField] = useState([]);

  const [nameIntegerfield, setNameIntegerfield] = useState([]);

  const navigate = useNavigate();
  
  const [contactFields, setcontactFields] = useState([]);

  const [contactIntegerFields, setContactIntegerFields] = useState([]);

  const [verificationFields, setverificationFields] = useState([]);

  const [verificationIntergerFields, setVerificationIntegerFields] = useState(
    []
  );

  const toast = useRef<Toast>(null);

  const [dataLength, setDataLength] = useState(0);

  const [partnerid, SetPartnerId] = React.useState(id);

  const [popupSkip, setPopupSkip] = useState(false);

  const [dirtyfield, setDirtyField] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [checkedAll, setCheckedAll] = useState(false);

  const [checkAllContactField, setCheckAllContactField] = useState(false);

  const [checkAllVerificationField, setCheckAllVerificationField] = useState(false);

  /////// Name Fields Checked

  const setnamefield = (data: any) => {
    const state = [...namefield];
    const selectedData = state.map((obj) => {
      if (obj.id === data.id) {
        return { ...obj, IsChecked: !data.IsChecked };
      }
      return obj;
    });

    const trueSelectedData = selectedData
      .filter((data) => data.IsChecked === true)
      .map((x) => x.id);
    setNameIntegerfield(trueSelectedData);
    setNameField(selectedData);
    setDirtyField(true);

    if (checkedAll === true) {
      {
        namefield.map((nameFieldsData: any, index) => (nameFieldsData = false));
        setCheckedAll(false);
      }
    }
  };

  /////// Contcat Fields Checked

  const setContactfield = (data: any) => {
    const state = [...contactFields];
    const selectContactData = state.map((obj2) => {
      if (obj2.id === data.id) {
        return { ...obj2, IsChecked: !data.IsChecked };
      }
      return obj2;
    });
    const trueContactData = selectContactData
      .filter((data) => data.IsChecked === true)
      .map((y) => y.id);
    setContactIntegerFields(trueContactData);
    setcontactFields(selectContactData);
    setDirtyField(true);

    if (checkAllContactField === true) {
    
      {
        contactFields.map((contactIntegerFields: any, index) => (
          contactIntegerFields = false
        ))
      }
      setCheckAllContactField(false);
    }
  };

  /////// verification Fields Checked
  const setVerificationfield = (data: any) => {
    const state = [...verificationFields];
    const selectVerifiacationData = state.map((obj3) => {
      if (obj3.id === data.id) {
        return { ...obj3, IsChecked: !data.IsChecked };
      }
      return obj3;
    });
    const trueVerificationata = selectVerifiacationData
      .filter((data) => data.IsChecked === true)
      .map((z) => z.id);
    setVerificationIntegerFields(trueVerificationata);
    setverificationFields(selectVerifiacationData);
    setDirtyField(true);

    if (checkAllVerificationField === true) {
    
      {
        verificationFields.map((verificationIntergerFields: any, index) => (
          verificationIntergerFields = false
        ))
        setCheckAllVerificationField(false);
      }
    
    }
  };

  // receiver payment fiedls
  const receiverPaymentFields = () => {
    const ids = [1, 2, 3, 4];
    PaymentSettingsServices.ReceivePaymentField(ids)
      .then((data: any) => {
        const responsedata = data.data;
        const nameFieldsData = responsedata

          .filter((name: any) => name.categoryName === "Name Field")
          .map((namefield: any) => ({ ...namefield, IsChecked: false }));

        const contactFieldsData = responsedata
          .filter((name: any) => name.categoryName === "Contact Fields")
          .map((contactfield: any) => ({ ...contactfield, IsChecked: false }));

        const verificationFieldsData = responsedata
          .filter((name: any) => name.categoryName === "Verification Fields")
          .map((verificationfields: any) => ({
            ...verificationfields,
            IsChecked: false,
          }));

        setNameField(nameFieldsData);
        setcontactFields(contactFieldsData);
        setverificationFields(verificationFieldsData);
        GetReceiverInfo(
          nameFieldsData,
          contactFieldsData,
          verificationFieldsData
        );
        // setLoading(false);
      })

      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        }
       
      });
  };

  // get receiver info
  const GetReceiverInfo = (
    nameFieldsData: any,
    contactFieldsData: any,
    verificationFieldsData: any
  ) => {
    setLoading(true);
    PaymentSettingsServices.getReceiverInformation(Number(partnerid), "B2B", 1)
      .then((res) => {
        const responseData = res.data;
        const newData: any = [];
        const contactData: any = [];
        const verificationData: any = [];
        setDataLength(responseData.length);

        
        nameFieldsData.slice(6, 9).forEach((element: any) => {
          if (responseData.some((x: any) => x.fieldId == element.id)) {
            newData.push({ ...element, IsChecked: true });
            setCheckedAll(true);
          } else {
            newData.push({ ...element, IsChecked: false });
            setCheckedAll(false);
          }
          newData.forEach((element:any) => {
            if(element.IsChecked === false){
            setCheckedAll(false);
            }
          });
     
        });


        contactFieldsData.forEach((element: any) => {
          if (responseData.some((x: any) => x.fieldId == element.id)) {
            contactData.push({ ...element, IsChecked: true });
            setCheckAllContactField(true);
          } else {
            contactData.push({ ...element, IsChecked: false });
            setCheckAllContactField(false)
          }
          contactData.forEach((element:any) => {
            if(element.IsChecked === false){
              setCheckAllContactField(false);
            }
          });
        });

        verificationFieldsData.forEach((element: any) => {
          if (responseData.some((x: any) => x.fieldId == element.id)) {
            verificationData.push({ ...element, IsChecked: true });
            setCheckAllVerificationField(true);

          } else {
            verificationData.push({ ...element, IsChecked: false });
            setCheckAllVerificationField(false);
          }
          verificationData.forEach((element:any) => {
            if(element.IsChecked === false){
            setCheckAllVerificationField(false);
            }
          });
        });

        setNameField(newData);
        setcontactFields(contactData);
        setverificationFields(verificationData);
        setLoading(false);


      })

      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        }
  
      });
  };

  //on save click
  const onAddClick = () => {
    const fieldIds = [...namefield, ...contactFields, ...verificationFields]
      .filter((data: any) => data.IsChecked === true)
      .map((res: any) => res.id);

    const apidata = AddReceiverInformationManadatoryFields(
      partnerid,
      "B2B",
      fieldIds.toString(),
      1
    );

    setButtonLoading(true);

    PaymentSettingsServices.getReceiverInformationFields(apidata)
      .then((res) => receiverPaymentFields())
      .then((receiverPaymentFields) => {
        onB2BorC2BTransactionLimitsAddClick();
        setButtonLoading(false);
      })

      .catch((error) => {
        console.log(error);

        setButtonLoading(true);
      });
  };

  //on back click
  const onChildBack = () => {
    B2BorC2BTransactionLimitsBackClick();
  };

  // on next click
  const onNextClick = () => {
    setButtonLoadingSkip(true)
    if (dirtyfield === true) {
      setPopupSkip(true);
    } else if (dirtyfield === false) {
      setPopupSkip(false);
      onB2BorC2BTransactionLimitsAddClick();
    }
  };

  const OnPopupClose = () => {
    setPopupSkip(false);
  };

  const OnPopupOk = () => {
    onB2BorC2BTransactionLimitsAddClick();
  };


  //select all name field
  const onSelectall = () => {
    if (checkedAll === false) {
      setCheckedAll(true);
      {
        namefield.map((NameReceive: any, index) => (
          NameReceive.IsChecked = true
        ))
      }
    } else {
      setCheckedAll(false);

      {
        namefield.map((NameReceive: any, index) => (

          NameReceive.IsChecked = false

        )
        )
      }
    }

  };

  //select all contact field
  const onSelectallContactField = () => {
    if (checkAllContactField === false) {
      setCheckAllContactField(true);
      {
        contactFields.map((ContactReceive: any, index) => (
          ContactReceive.IsChecked = true
        ))
      }
    } else {
      setCheckAllContactField(false);

      {
        contactFields.map((ContactReceive: any, index) => (

          ContactReceive.IsChecked = false

        ))
      }
    }
  }


  const onSelectallVerifiactionField = () => {
    if (checkAllVerificationField === false) {
      setCheckAllVerificationField(true);
      {
        verificationFields.map((verificationReceive: any) => (
          verificationReceive.IsChecked = true
        ))
      }
    } else {
      setCheckAllVerificationField(false);

      {
        verificationFields.map((verificationReceive: any) => (

          verificationReceive.IsChecked = false

        ))
      }
    }
  }


  useEffect(() => {
    {
      Number(status) === 8 ? setReadOnly(true) : setReadOnly(false);
    }
    receiverPaymentFields();
    setButtonLoadingBack(false);
    setButtonLoadingSkip(false);
  }, []);

  return (
    <>
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <div>
          <div className="row ">
            <div className="col-md-12">
              <h2>
             Choose all the information that you require
                about the business “receiving” the payment
              </h2>
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>Name Fields</th>
                <th>Contact Fields</th>
                <th> Verification Fields</th>
              </tr>
            </thead>
            <tbody>
              <tr className="read-only-row">
                <td>
                  <div className="select-all-field">
                    <div className="switch-style">
                      <InputSwitch
                        disabled={readonly}
                        className="status-check"
                        name="Send"
                        checked={checkedAll}
                        onChange={onSelectall}
                      />
                    </div>
                    <div>
                      <p>Select All</p>
                    </div>
                  </div>
                  <div className="form-check yes-check">
                    {namefield.map((NameReceive: any, index) => (
                      <div key={index} className="namefield-border">
                        <input
                          readOnly={readonly}
                          disabled={readonly}
                          className="form-check-input"
                          type="checkbox"
                          name="name"
                          id="name"
                          value={NameReceive.id}
                          checked={NameReceive.IsChecked}
                          onChange={(e) => setnamefield(NameReceive)}
                        />
                        <label className="form-check-label">
                          {NameReceive.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </td>

                <td>
                  <div className="select-all-field">
                    <div className="switch-style">
                      <InputSwitch
                        disabled={readonly}
                        className="status-check"
                        name="Send"
                        checked={checkAllContactField}
                        onChange={(e) => onSelectallContactField()}
                      />
                    </div>
                    <div>
                      <p>Select All</p>
                    </div>
                  </div>
                  <div className="form-check yes-check">
                    {contactFields.map((ContactReceive: any, index) => (
                      <div key={index} className="namefield-border">
                        <input
                          readOnly={readonly}
                          disabled={readonly}
                          className="form-check-input"
                          type="checkbox"
                          name="name"
                          id="name"
                          value={ContactReceive.id}
                          checked={ContactReceive.IsChecked}
                          onChange={(e) => setContactfield(ContactReceive)}
                        />
                        <label className="form-check-label">
                          {ContactReceive.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </td>
                <td>
                  <div className="select-all-field">
                    <div className="switch-style">
                      <InputSwitch
                        disabled={readonly}
                        className="status-check"
                        name="Send"
                        checked={checkAllVerificationField}
                        onChange={(e) => onSelectallVerifiactionField()}
                      />
                    </div>
                    <div>
                      <p>Select All</p>
                    </div>
                  </div>
                  <div className="form-check yes-check">
                    {verificationFields.map(
                      (verificationReceive: any, index) => (
                        <div key={index} className="namefield-border">
                          <input
                            readOnly={readonly}
                            disabled={readonly}
                            className="form-check-input"
                            type="checkbox"
                            name="name"
                            id="name"
                            checked={verificationReceive.IsChecked}
                            onChange={(e) =>
                              setVerificationfield(verificationReceive)
                            }
                          />
                          <label className="form-check-label">
                            {verificationReceive.name}
                          </label>
                        </div>
                      )
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="button-section">
            <div className="bottom-btns">
              <>
                <Button
                  iconPos="left"
                  label="Back"
                  loading={buttonLoadingBack}
                  onClick={onChildBack}
                  className="btn btn-back second-btn"
                />
                {Number(status) === 8 ? (
                  <>
                    <button
                      type="button"
                      onClick={onNextClick}
                      className="btn btn-next second-btn "
                    >
                      Next
                    </button>
                  </>
                ) : (
                  <>
                    <Button
                      iconPos="left"
                      label=" Save and Continue"
                      loading={buttonLoading}
                      className="btn btn-continue second-btn"
                      onClick={onAddClick}
                    />

                    <Button
                      iconPos="left"
                      label="Skip"
                      loading={buttonLoadingSkip}
                      onClick={onNextClick}
                      className="btn btn-continue   btn-next second-btn"
                    />
                  </>
                )}
              </>
            </div>
          </div>
          {popupSkip ? (
            <div className="popup-body">
              <div className="regiter-popup Payment-screen">
                <div className="text-center ">
                  <div className="awesome-text">
                    <h4>Are you sure you want to continue?</h4>
                    <p> All unsaved changes will be lost.</p>
                  </div>
                </div>
                <div className="payment-screen-btn">
                  <button
                    className="btn btn-cancel second-btn "
                    onClick={OnPopupClose}
                  >
                    {" "}
                    No
                  </button>

                  <button
                    className="btn btn-continue second-btn yes-btn-popup"
                    onClick={OnPopupOk}
                  >
                    {" "}
                    Yes
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default B2BorC2BTransactionLimits;
