export const Logindata = (response, navigate) => {
  localStorage.setItem("User", JSON.stringify(response.data));
  localStorage.setItem("PartnerId", JSON.stringify(response.data.partnerId));

  localStorage.setItem("Token", JSON.stringify(response.data.jwtToken));

  localStorage.setItem("StepFlag", JSON.stringify(response.data.stepFlag));
  localStorage.setItem("PartnerLogo", response.data.logoURL);
  localStorage.setItem(
    "PartnerPaymentRole",
    JSON.stringify(response.data.partnerRole)
  );
  localStorage.setItem(
    "OnboardingStatus",
    JSON.stringify(response.data.onboardingStatus)
  );
  localStorage.setItem("IsFacilitator", response.data.isfacilitator);
  if (response.data.partnerId === null || response.data.partnerId === 0) {
    localStorage.setItem("StepFlag", "0");
    navigate(`/details`);
  } else if (
    (response.data.stepFlag === 5 && response.data.onboardingStatus === 2) ||
    (response.data.stepFlag === 5 && response.data.onboardingStatus === 5) ||
    (response.data.stepFlag === 5 && response.data.onboardingStatus === 4) ||
    (response.data.stepFlag === 11 && response.data.onboardingStatus === 7) ||
    (response.data.stepFlag === 4 && response.data.onboardingStatus === 3)
  ) {
    navigate(`/onboarding`);
  } else if (
    response.data.stepFlag === 11 &&
    response.data.onboardingStatus === 8
  ) {
    navigate(`/dashboard`);
  } else if (
    response.data.stepFlag <= 5 &&
    (response.data.onboardingStatus === 0 ||
      response.data.onboardingStatus === 1)
  ) {
    navigate(`/details`);
  } else if (
    response.data.stepFlag >= 6 &&
    response.data.onboardingStatus === 6
  ) {
    navigate(`/payment`);
  }
};

export const LogoClick = (partnerId, stepflag, status, navigate) => {
  if (partnerId === null || partnerId === 0) {
    localStorage.setItem("StepFlag", "0");
    navigate(`/details`);
  } else if (
    (stepflag === 5 && status === 2) ||
    (stepflag === 5 && status === 5) ||
    (stepflag === 5 && status === 4) ||
    (stepflag === 11 && status === 7)
  ) {
    navigate(`/onboarding`);
  } else if (stepflag === 11 && status === 8) {
    navigate(`/dashboard`);
  } else if (stepflag <= 5 && (status === 0 || status === 1 || status === 3)) {
    navigate(`/details`);
  } else if (stepflag >= 6 && status === 6) {
    navigate(`/payment`);
  }
};

export const Logout = (navigate) => {
  localStorage.clear();
  localStorage.clear();
  localStorage.removeItem("PartnerLogo");
  localStorage.removeItem("StepFlag");
  localStorage.removeItem("OnboardingStatus");
  localStorage.removeItem("PartnerPaymentRole");
  localStorage.removeItem("IsFacilitator");
  navigate("/");
};

export const Errordata = (error, toast, navigate) => {
  if (error.response === null || error.response === undefined) {
    toast.current?.show({
      severity: "error",
      summary: "Oops! Something went wrong.",
      life: 3000,
    });
  } else if (error.response.status === 400) {
    toast.current?.show({
      severity: "error",
      summary: error.response.data[0].errorMessage,
      life: 3000,
    });
  } else if (error.response.status === 401) {
    toast.current?.show({
      severity: "error",
      summary: "Unauthorized",
      life: 3000,
    });
    Logout(navigate);
  } else {
    toast.current?.show({
      severity: "error",
      summary: "Oops! Something went wrong.",
      life: 3000,
    });
  }
};

export const IsValidRoute = (navigate) => {
  const stepFlag = parseInt(localStorage.getItem("StepFlag"));
  const onboardingStatus = parseInt(localStorage.getItem("OnboardingStatus"));

  if (
    (stepFlag === 1 || stepFlag === 2 || stepFlag === 3 || stepFlag === 4) &&
    onboardingStatus === 1
  ) {
    navigate("/details");
  }
  if (stepFlag === 5 && (onboardingStatus === 2 || onboardingStatus === 5)) {
    navigate("/onboarding");
  }
  if (
    (stepFlag === 5 && onboardingStatus === 4) ||
    ((stepFlag === 6 ||
      stepFlag === 7 ||
      stepFlag === 8 ||
      stepFlag === 9 ||
      stepFlag === 10) &&
      onboardingStatus === 6)
  ) {
    navigate("/payment");
  }
};
