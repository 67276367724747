import React, { useEffect, useRef, useState } from "react";
import Copy_icon from "../../../assets/images/copy.svg";
import { useNavigate } from "react-router-dom";
import { ResourceService } from "../../../services/Partner/DevResources/ResourceService";
import { Logout } from "../../../utils/AccountUtils";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import Scrollbars from "react-custom-scrollbars-2";
import { IsValidRoute } from "../../../utils/AccountUtils";

const DeveloperResources: React.FC = () => {

  const navigate = useNavigate();

  const id = localStorage.getItem("PartnerId");

  const [loading, setLoading] = React.useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const [generateloading, setgenerateloading] = React.useState(false);

  const [webhookloading, setwebhookloading] = React.useState(false);

  const [receiveloading, setreceiveloading] = React.useState(false);

  const [keyList, setKeyList] = React.useState([]);

  const [webhookList, setWebhookList] = React.useState([]);

  const [devkey, setdevkey] = React.useState("");

  const [stagekey, setstagekey] = React.useState("");

  const [prodkey, setprodkey] = React.useState("");

  const [webhookdevkey, setwebhookdevkey] = React.useState("");

  const [webhookstagekey, setwebhookstagekey] = React.useState("");

  const [webhookprodkey, setwebhookprodkey] = React.useState("");

  const [webhookdevkeyErrorMessage, setwebhookdevkeyErrorMessage] = React.useState("");
  const [apikeymessage, setAPIKeyMessage] = React.useState("");
  const [webhookmessage, setWebhookMessage] = React.useState("");
  const [apireceivemessage, setAPIReceiveMessage] = React.useState("");
  const [webhookstagekeyErrorMessage, setwebhookstagekeyErrorMessage] = React.useState("");

  const [webhookprodkeyErrorMessage, setwebhookprodkeyErrorMessage] = React.useState("");

  const [devReceiveUrl, setdevReceiveUrl] = React.useState("");

  const [stageReceiveUrl, setstageReceiveUrl] = React.useState("");

  const [prodReceiveUrl, setprodReceiveUrl] = React.useState("");

  const [devReceiveUrlErrorMessage, setdevReceiveUrlErrorMessage] = React.useState("");

  const [stageReceiveUrlErrorMessage, setstageReceiveUrlErrorMessage] = React.useState("");

  const [prodReceiveUrlErrorMessage, setprodReceiveUrlErrorMessage] = React.useState("");

  const [devSendUrl, setdevSendUrl] = React.useState("");

  const [stageSendUrl, setstageSendUrl] = React.useState("");

  const [prodSendUrl, setprodSendUrl] = React.useState("");

  const toast = useRef<Toast>(null);

  const [SecurityKeyModel, setSecurityKeyModel] = React.useState({
    Id: 0,
    PartnerId: id,
    Environment: "",
    CreatedBy: 0,
    Flag: 0,
  });

  const isValidUrl = (val: string) => {

    var res = val.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  };


  // handle and get sercurity key 
  const getAllSecuritKeys = (val: any, flag: boolean = false) => {
    if (flag) setgenerateloading(true);
    else

      setLoading(true);

    ResourceService.getResourcesByPartnerId(val)
      .then((data) => {
        setKeyList(data.data);

        if (data.data.length > 0) {
          let keys = data.data;
          keys.forEach((k: any) => {
            if (k.environment == "Dev") {
              setdevkey(k.apI_Key);
            }
            if (k.environment == "Stage") {
              setstagekey(k.apI_Key);
            }
            if (k.environment == "Prod") {
              setprodkey(k.apI_Key);
            }
          });
        }
        if (flag) setgenerateloading(false);
        else
          setPageLoading(false);
        setLoading(false);

      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setKeyList([]);
        if (flag) setgenerateloading(false);
        else setLoading(false);

      });
  };

  // generate key  for SecurityKeyModel
  const generateKeys = () => {
    setgenerateloading(true);
    ResourceService.RegenerateResources(SecurityKeyModel)
      .then((data: any) => {
        getAllSecuritKeys(id, true);
        setgenerateloading(false);
        setAPIKeyMessage("API keys regenerated successfully!")
        setTimeout(() => {
          setAPIKeyMessage("")
        }, 3000);



      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else if (error.response.status === 409) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setgenerateloading(false);
      });
  };
  // get all web hooks by partner id
  const getAllWebhookKeys = (val: any) => {
    // setwebhookloading(true);
    ResourceService.getWebhooksByPartnerId(val)
      .then((data) => {
        if (data.data != null) {
          let keys = data.data;
          keys.forEach((k: any) => {
            if (k.environment == "Dev") {
              setwebhookdevkey(k.webhookURL);
            }
            if (k.environment == "Stage") {
              setwebhookstagekey(k.webhookURL);
            }
            if (k.environment == "Prod") {
              setwebhookprodkey(k.webhookURL);
            }
          });
        }
        setwebhookloading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setWebhookList([]);
        setwebhookloading(false);
      });
  };

  // get all receive end points by partner id for values
  const getAllReceiveEndPoints = (val: any) => {
    // setwebhookloading(true);
    ResourceService.getReceiveEndPointsByPartnerId(val)
      .then((data) => {
        if (data.data != null) {
          let keys = data.data;
          keys.forEach((k: any) => {
            if (k.environment == "Dev") {
              setdevReceiveUrl(k.endpointURL);
            }
            if (k.environment == "Stage") {
              setstageReceiveUrl(k.endpointURL);
            }
            if (k.environment == "Prod") {
              setprodReceiveUrl(k.endpointURL);
            }
          });
        }
        setwebhookloading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setWebhookList([]);
        setwebhookloading(false);
      });
  };

  // get all send end points by partner id for values
  const getAllSendEndPoints = (val: any) => {
    // setwebhookloading(true);
    ResourceService.getSendEndPointsByPartnerId(val)
      .then((data) => {
        if (data.data != null) {
          let keys = data.data;
          keys.forEach((k: any) => {
            if (k.environment == "Dev") {
              setdevSendUrl(k.endpointURL);
            }
            if (k.environment == "Stage") {
              setstageSendUrl(k.endpointURL);
            }
            if (k.environment == "Prod") {
              setprodSendUrl(k.endpointURL);
            }
          });
        }
        setwebhookloading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setWebhookList([]);
        setwebhookloading(false);
      });
  };
  //validate web hook url
  const isValidWebhookUrls = () => {

    let formIsValid = true;
    if (webhookdevkey != "") {
      if (!isValidUrl(webhookdevkey)) {
        setwebhookdevkeyErrorMessage("Please enter valid dev webhook url.");
        formIsValid = false;
      }
    }
    if (webhookstagekey != "") {
      if (!isValidUrl(webhookstagekey)) {
        setwebhookstagekeyErrorMessage(
          "Please enter valid sandbox webhook url."
        );
        formIsValid = false;
      }
    }
    if (webhookprodkey != "") {
      if (!isValidUrl(webhookprodkey)) {
        setwebhookprodkeyErrorMessage(
          "Please enter valid production webhook url."
        );
        formIsValid = false;
      }
    }

    return formIsValid;
  };

  // handle copy inputes from clip board
  const copyToClipBoardClicked = (input: any, resource: any) => {
    if (input != "" && input != null && input != undefined) {
      if (resource === 3) {
        if (isValidUrl(input)) {
          navigator.clipboard.writeText(input);
          showClipboard();
        }
      } else {
        navigator.clipboard.writeText(input);
        showClipboard();
      }
    }
  };

  // form validate for receive url
  const isValidReceiveUrls = () => {
    let formIsValid = true;
    if (devReceiveUrl != "") {
      if (!isValidUrl(devReceiveUrl)) {
        setdevReceiveUrlErrorMessage("Please enter valid dev receive url.");
        formIsValid = false;
      }
    }

    if (stageReceiveUrl != "") {
      if (!isValidUrl(stageReceiveUrl)) {
        setstageReceiveUrlErrorMessage(
          "Please enter valid sandbox receive url."
        );
        formIsValid = false;
      }
    }

    if (prodReceiveUrl != "") {
      if (!isValidUrl(prodReceiveUrl)) {
        setprodReceiveUrlErrorMessage(
          "Please enter valid production receive url."
        );
        formIsValid = false;
      }
    }

    return formIsValid;
  };

  // add web hooks keys for web hook model
  const addWebhookKeys = () => {
    setwebhookloading(true);
    if (isValidWebhookUrls()) {
      let webhookModel = {
        Id: 0,
        PartnerId: id,
        DevWebhookURL: webhookdevkey,
        StageWebhookURL: webhookstagekey,
        ProdWebhookURL: webhookprodkey,
        CreatedBy: 0,
      };
      ResourceService.saveWebhookURLs(webhookModel)
        .then((data: any) => {
          setwebhookloading(false);

          getAllWebhookKeys(id);
          setWebhookMessage("Saved successfully!");
          setTimeout(() => {
            setWebhookMessage("")
          }, 3000);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data[0].errorMessage,
              life: 3000,
            });
          } else if (error.response.status === 409) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data,
              life: 3000,
            });
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Oops! Something went wrong.",
              life: 3000,
            });
          }
          setwebhookloading(false);
        });
    } else {
      setwebhookloading(false);
    }
  };

  // save receive points 
  const savereceivepoints = () => {
    setreceiveloading(true);
    if (isValidReceiveUrls()) {
      let webhookModel = {
        Id: 0,
        PartnerId: id,
        DevEndpointURL: devReceiveUrl,
        StageEndpointURL: stageReceiveUrl,
        ProdEndpointURL: prodReceiveUrl,
        CreatedBy: 0,
      };

      ResourceService.saveReceiveURLs(webhookModel)
        .then((data: any) => {
          setreceiveloading(false);

          getAllReceiveEndPoints(id);
          setAPIReceiveMessage("Saved successfully!");
          setTimeout(() => {
            setAPIReceiveMessage("")
          }, 3000);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data[0].errorMessage,
              life: 3000,
            });
          } else if (error.response.status === 409) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data,
              life: 3000,
            });
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Oops! Something went wrong.",
              life: 3000,
            });
          }
          setreceiveloading(false);
        });
    } else {
      setreceiveloading(false);
    }
  };

  //show clip board
  const showClipboard = () => {
    toast.current?.show({
      severity: "success",
      summary: "Success message",
      detail: "Copied to clipboard.",
      life: 3000,
    });
  };

  useEffect(() => {

    IsValidRoute(navigate);
    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      navigate("/");
    }
    setwebhookdevkeyErrorMessage("");
    setwebhookstagekeyErrorMessage("");
    setwebhookprodkeyErrorMessage("");
    setdevReceiveUrlErrorMessage("");
    setstageReceiveUrlErrorMessage("");
    setprodReceiveUrlErrorMessage("");
    getAllSecuritKeys(id);
    getAllWebhookKeys(id);
    getAllReceiveEndPoints(id);
    getAllSendEndPoints(id);

  }, []);


  return (
    <>

      {pageLoading ? (<div className="row loading-icon">
        <div className="spinner-class-security">
          <ProgressSpinner />
        </div>
      </div>) : (
        <div className="developer-resorce-Screen">

          <Toast ref={toast}></Toast>


          <div className="heading-section">
            <span className="text-header-purple">Developer Support Hub</span>
          </div>
          <div className=" container-fluid acc-screen  right-tab-section developer-resorce-Screen">
            <div className="row hub-section">

              <span className="developer-header-color">
                Welcome to the developer support hub, where you can access
                necessary information for integrating with us.
              </span>
            </div>

            {loading ? (

              <div className="row loading-icon">
                <div className="spinner-class-security">
                  <ProgressSpinner />
                </div>
              </div>

            ) : (
              <Scrollbars
                className="contain-scroll"
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                autoHeight
                autoHeightMin={100}
                autoHeightMax={100}
                thumbMinSize={30}
                universal={true}
              >
                <div className="row hub-cards">
                  <div className="col-md-6  developer-screen-section info-section-border">
                    <div className="card">
                      <div className="card-body">
                        <h1>API Keys</h1>
                        <p className="text-center">
                          We provide a unique API key for each partner, for
                          authentication.
                        </p>
                        <p className="text-center">
                          It will also be used for AES 256 GCM encryption of
                          request messages.
                        </p>

                        <div className="row">
                          <div className=" col-md-12 form-group ">
                            <span className="input-label">Dev </span>
                            <input
                              readOnly
                              className="form-control "
                              type="text"
                              value={devkey}
                            />
                            <img
                              src={Copy_icon}
                              alt=""
                              className="copyicon-size"
                              onClick={() =>
                                copyToClipBoardClicked(devkey, 1)
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "38px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                          <div className=" col-md-12 form-group ">
                            <span className="input-label">SandBox </span>
                            <input
                              readOnly
                              className="form-control "
                              type="text"
                              value={stagekey}
                            />
                            <img
                              src={Copy_icon}
                              alt=""
                              className="copyicon-size"
                              onClick={() =>
                                copyToClipBoardClicked(stagekey, 1)
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "38px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                          <div className=" col-md-12 form-group ">
                            <span className="input-label">Production </span>
                            <input
                              readOnly
                              className="form-control "
                              type="text"
                              value={prodkey}
                            />
                            <img
                              src={Copy_icon}
                              alt=""
                              className="copyicon-size"
                              onClick={() =>
                                copyToClipBoardClicked(prodkey, 1)
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "38px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>

                        {apikeymessage !== null &&
                          apikeymessage.length > 0 ? (
                          <span className="resource-success-msg">
                            {apikeymessage}
                          </span>
                        ) : null}
                      </div>

                      <div className="" style={{ textAlign: "end" }}>
                        <Button
                          iconPos="left"
                          label="Regenerate"
                          className="btn btn-continue developer-save-btn"
                          onClick={generateKeys}
                          style={{
                            marginBottom: "10px",
                            marginTop: "-15px",
                          }}
                          loading={generateloading}
                        />
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-body">
                        <h1>Webhook Callback URL</h1>
                        <p className="text-center">
                          These are the endpoints where we will submit any
                          API responses.
                        </p>

                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className=" col-md-12 form-group ">
                            <span className="input-label">Dev </span>
                            <input
                              className="form-control "
                              type="text"
                              value={webhookdevkey}
                              onChange={(e) => {
                                setwebhookdevkey(e.target.value);
                                setwebhookdevkeyErrorMessage("");

                              }}
                            />
                            <img
                              src={Copy_icon}
                              alt=""
                              className="copyicon-size"
                              onClick={() =>
                                copyToClipBoardClicked(webhookdevkey, 3)
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "38px",
                                cursor: "pointer",
                              }}
                            />
                            {webhookdevkeyErrorMessage !== null &&
                              webhookdevkeyErrorMessage.length > 0 ? (
                              <span className="login-error-msg">
                                {webhookdevkeyErrorMessage}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className=" col-md-12 form-group ">
                            <span className="input-label">SandBox </span>
                            <input
                              className="form-control "
                              type="text"
                              onChange={(e) => {
                                setwebhookstagekey(e.target.value);
                                setwebhookstagekeyErrorMessage("");

                              }}
                              value={webhookstagekey}
                            />
                            <img
                              src={Copy_icon}
                              alt=""
                              className="copyicon-size"
                              onClick={() =>
                                copyToClipBoardClicked(webhookstagekey, 3)
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "38px",
                                cursor: "pointer",
                              }}
                            />
                            {webhookstagekeyErrorMessage !== null &&
                              webhookstagekeyErrorMessage.length > 0 ? (
                              <span className="login-error-msg">
                                {webhookstagekeyErrorMessage}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className=" col-md-12 form-group ">
                            <span className="input-label">Production </span>
                            <input
                              className="form-control "
                              type="text"
                              value={webhookprodkey}
                              onChange={(e) => {
                                setwebhookprodkey(e.target.value);
                                setwebhookprodkeyErrorMessage("");
                              }}
                            />
                            <img
                              src={Copy_icon}
                              alt=""
                              className="copyicon-size"
                              onClick={() =>
                                copyToClipBoardClicked(webhookprodkey, 3)
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "38px",
                                cursor: "pointer",
                              }}
                            />
                            {webhookprodkeyErrorMessage !== null &&
                              webhookprodkeyErrorMessage.length > 0 ? (
                              <span className="login-error-msg">
                                {webhookprodkeyErrorMessage}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        {webhookmessage !== null &&
                          webhookmessage.length > 0 ? (
                          <span className="resource-success-msg">
                            {webhookmessage}
                          </span>
                        ) : null}
                      </div>
                      <div className="" style={{ textAlign: "end" }}>
                        <Button
                          iconPos="left"
                          label="Save"
                          className="btn btn-continue developer-save-btn"
                          onClick={addWebhookKeys}
                          style={{
                            marginBottom: "10px",
                            marginTop: "-15px",
                          }}
                          loading={webhookloading}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 developer-screen-section">
                    <div className="card">
                      <div className="card-body">
                        <h1>API Send Endpoints</h1>
                        <p className="text-center">
                          These are the endpoints where you may submit any
                          API requests.
                        </p>
                        <p className="text-center">
                          Instarails platform will process your request in
                          real-time.
                        </p>
                        <div className="row">
                          <div className=" col-md-12 form-group ">
                            <span className="input-label">Dev </span>
                            <input
                              readOnly
                              className="form-control "
                              type="text"
                              value={devSendUrl}
                            />
                            <img
                              src={Copy_icon}
                              alt=""
                              className="copyicon-size"
                              onClick={() =>
                                copyToClipBoardClicked(devSendUrl, 3)
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "38px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className=" col-md-12 form-group ">
                            <span className="input-label">Sandbox </span>
                            <input
                              readOnly
                              className="form-control "
                              type="text"
                              value={stageSendUrl}
                            />
                            <img
                              src={Copy_icon}
                              alt=""
                              className="copyicon-size"
                              onClick={() =>
                                copyToClipBoardClicked(stageSendUrl, 3)
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "38px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className=" col-md-12 form-group ">
                            <span className="input-label">Production </span>
                            <input
                              readOnly
                              className="form-control "
                              type="text"
                              value={prodSendUrl}
                            />
                            <img
                              src={Copy_icon}
                              alt=""
                              className="copyicon-size"
                              onClick={() =>
                                copyToClipBoardClicked(prodSendUrl, 3)
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "38px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <h1>API Receive Endpoints</h1>
                        <p className="text-center">
                          These are the endpoints where we will submit any
                          Payment API requests.
                        </p>
                        <p className="text-center">
                          Our request will contain the callback URL for
                          receiving the API response.
                        </p>
                        <div className="row">
                          <div className=" col-md-12 form-group ">
                            <span className="input-label">Dev </span>
                            <input
                              className="form-control "
                              type="text"
                              value={devReceiveUrl}
                              onChange={(e) => {
                                setdevReceiveUrl(e.target.value);
                                setdevReceiveUrlErrorMessage("");
                              }}
                            />
                            <img
                              src={Copy_icon}
                              alt=""
                              className="copyicon-size"
                              onClick={() =>
                                copyToClipBoardClicked(devReceiveUrl, 3)
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "38px",
                                cursor: "pointer",
                              }}
                            />
                            {devReceiveUrlErrorMessage !== null &&
                              devReceiveUrlErrorMessage.length > 0 ? (
                              <span className="login-error-msg">
                                {devReceiveUrlErrorMessage}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className=" col-md-12 form-group ">
                            <span className="input-label">Sandbox </span>
                            <input
                              className="form-control "
                              type="text"
                              value={stageReceiveUrl}
                              onChange={(e) => {
                                setstageReceiveUrl(e.target.value);
                                setstageReceiveUrlErrorMessage("");
                              }}
                            />
                            <img
                              src={Copy_icon}
                              alt=""
                              className="copyicon-size"
                              onClick={() =>
                                copyToClipBoardClicked(stageReceiveUrl, 3)
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "38px",
                                cursor: "pointer",
                              }}
                            />
                            {stageReceiveUrlErrorMessage !== null &&
                              stageReceiveUrlErrorMessage.length > 0 ? (
                              <span className="login-error-msg">
                                {stageReceiveUrlErrorMessage}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className=" col-md-12 form-group ">
                            <span className="input-label">Production </span>
                            <input
                              className="form-control "
                              type="text"
                              value={prodReceiveUrl}
                              onChange={(e) => {
                                setprodReceiveUrl(e.target.value);
                                setprodReceiveUrlErrorMessage("");
                              }}
                            />
                            <img
                              src={Copy_icon}
                              alt=""
                              className="copyicon-size"
                              onClick={() =>
                                copyToClipBoardClicked(prodReceiveUrl, 3)
                              }
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "38px",
                                cursor: "pointer",
                              }}
                            />
                            {prodReceiveUrlErrorMessage !== null &&
                              prodReceiveUrlErrorMessage.length > 0 ? (
                              <span className="login-error-msg">
                                {prodReceiveUrlErrorMessage}
                              </span>
                            ) : null}
                          </div>

                        </div>
                        {apireceivemessage !== null &&
                          apireceivemessage.length > 0 ? (
                          <span className="resource-success-msg">
                            {apireceivemessage}
                          </span>
                        ) : null}
                      </div>
                      <div className="" style={{ textAlign: "end" }}>
                        <Button
                          iconPos="left"
                          label="Save"
                          className="btn btn-continue developer-save-btn"
                          onClick={savereceivepoints}
                          style={{
                            marginBottom: "10px",
                            marginTop: "-15px",
                          }}
                          loading={receiveloading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Scrollbars>
            )}
          </div>
        </div>
      )}


    </>
  );
};

export default DeveloperResources;
