import React, {useEffect } from "react";

import UserManagement from "../../Partner/UserManagement/UserManagement";

import { IsValidRoute } from "../../../utils/AccountUtils";
import { Link, useNavigate } from "react-router-dom";

const Users: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    IsValidRoute(navigate);
  });

  function setGlobalFilterValue(value: any): void {
    throw new Error("Function not implemented.");
  }

  return (
    <>
      {" "}
      <div className="heading-section">
        <span className="text-header-purple">Users</span>
        <div>
          <UserManagement source={"sidebar"} />
        </div>
      </div>
    </>
  );
};

export default Users;
