import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { IBasicInfo } from "../../../models/IBasicInfo";
import { CommonService } from "../../../services/Common/CommonService";
import ToolTip from "../ToolTipsData/ToolTip";
import { Button } from "primereact/button";
import { LoginService } from "../../../services/Account/LoginService";
import { BasicInfoService } from "../../../services/Partner/BasicInfo/BasicInfoService";
import {
  checkEIN,
  checkITIN,
  checkLEI,
  checkSSN,
  checkTIN,
  currenciesList,
  industryList,
  setHeaderImage,
  validWebsite,
} from "../../../utils/utils";
import "./BasicInfo.css";
import BasicInfoToolTipData from "../../../services/Partner/BasicInfo/BasicInfoToolTips";
import { Logout } from "../../../utils/AccountUtils";
import Scrollbars from "react-custom-scrollbars-2";
import LocalStorageContext from "../../Context/LocalStorageContext";

interface IState {
  user: IBasicInfo;
}

const BasicInfo: React.FC<any> = ({
  onSaveAndContinueClick,
  onNextClick,
  buttonLoadingSkip,
  setButtonLoadingSkip,
  updateLogoSrc
}) => {
  const id = localStorage.getItem("PartnerId");
  const context = useContext(LocalStorageContext);
  const [, setPartnerTypesMultiSelectList] = useState(null);

  const navigate = useNavigate();

  const toast = useRef<Toast>(null);

  const [basicInfoToolTipdata] = useState<any>(BasicInfoToolTipData);

  const [partnerid] = React.useState(id);

  const [filteredtaxidtype, setFilteredTaxIdType] = useState<any>(null);

  const [filteredcompanytype, setFilteredCompanyType] = useState<any>(null);

  const [filteredentitytype, setFilteredEntityType] = useState<any>(null);

  const [, SetPaymentrole] = useState<any[]>([]);

  const [taxidtypelist, SetTaxIdTypeList] = useState<any[]>([]);

  const [documenttypelist, setFilteredDocumentType] = useState<any[]>([]);

  const [filteredcurrencieslist, setFilteredCurrencyList] = useState<any[]>([]);

  const [filteredindustrieslist, setFilteredIndustryList] = useState<any[]>([]);

  const [companytype, SetCompanyType] = useState<any[]>([]);

  const [entitytype, SetEntityType] = useState<any[]>([]);

  const [documenttype, setCompanyDocumentType] = useState<any[]>([]);

  const [legalnameerrorMessage, setlegalnameErrorMessage] = React.useState("");

  const [dbaerrorMessage, setDBAErrorMessage] = React.useState("");

  const [regnoerrorMessage, setregnoErrorMessage] = React.useState("");

  const [websiteerrorMessage, setwebsiteErrorMessage] = React.useState("");

  const [taxnoerrorMessage, settaxnoErrorMessage] = React.useState("");

  const [taxidtypeerrorMessage, settaxidtypeErrorMessage] = React.useState("");

  const [companytypeerrorMessage, setcompanytypeErrorMessage] =
    React.useState("");

  const [entitytypeerrorMessage, setentitytypeErrorMessage] =
    React.useState("");

  const [sizeofbusinesserrorMessage, setsizeofbusinessErrorMessage] =
    React.useState("");

  const [otherindustryerrorMessage, setotherindustryerrorMessage] = React.useState("");

  const [natureofbusinesserrorMessage, setnatureofbusinessErrorMessage] =
    React.useState("");

  const [logoErrorMessage, setlogoErrorMessage] = React.useState("");
  const [logoErrorMessage1, setlogoErrorMessage1] = React.useState("");
  const [, setpartperTypeName] = React.useState([]);

  const [taxidtypeAutoComplete, setTaxIdTypeAutoComplete] = useState("");

  const [, setRoleAutoComplete] = useState("");

  const [companytypeAutoComplete, setCompanyTypeAutoComplete] = useState("");

  const [entitytypeAutoComplete, setEntityTypeAutoComplete] = useState("");

  const [sizeofbusinessAutoComplete, setSizeOfBusinessAutoComplete] =
    useState("");

  const [taxCurrencyAutoComplete, setTaxCurrencyAutoComplete] = useState("");

  const [industryAutoComplete, setIndustryAutoComplete] = useState("");

  const [loading, setLoading] = useState(true);

  const [continuebuttonloading, setContinueButtonLoading] = useState(false);

  const [uploadButtonloading, setuploadButtonloading] = useState(false);

  const [readonly, setReadOnly] = useState(false);

  const [status] = useState(localStorage.getItem("OnboardingStatus"));

  const [logoUpload, setLogoUpload] = useState(false);

  const [displaypopup, setDisplayPopup] = useState(false);

  const [dirtyfield, setDirtyField] = useState(false);

  const [logoUrl, setlogoUrl] = useState("");

  // basic info model

  const [basicInfoModel, setBasicInfoModel] = useState({
    id: 0,
    partnerId: Number(partnerid),
    legalName: "",
    roleName: "",
    dba: "",
    registrationNumber: "",
    website: "",
    taxIdType: 0,
    taxCurrency: "",
    taxNumber: "",
    industry: "",
    companyTypeId: 0,
    sizeOfBusinessId: 0,
    otherIndustry: "",
    entityTypeId: 0,
    facilitatorId: 1,
    logoUrl: logoUrl,
  });

  // set model empty
  const setModelEmpty = () => {
    setBasicInfoModel({
      id: 0,
      partnerId: Number(partnerid),
      legalName: "",
      roleName: "",
      dba: "",
      registrationNumber: "",
      website: "",
      taxIdType: 0,
      taxCurrency: "",
      taxNumber: "",
      industry: "",
      companyTypeId: 0,
      sizeOfBusinessId: 0,
      otherIndustry: "",
      entityTypeId: 0,
      facilitatorId: 1,
      logoUrl: "",
    });
    setTaxIdTypeAutoComplete("");
    setTaxCurrencyAutoComplete("");
    setCompanyTypeAutoComplete("");
    setSizeOfBusinessAutoComplete("");
    setEntityTypeAutoComplete("");
    setIndustryAutoComplete("");
    setlogoUrl(null);
  };

  //get basic info by partner id
  const getBasicInfoByPartnerId = (id: number) => {
    setLoading(true);
    CommonService.basicinfoDropdown(Number(partnerid))
      .then((response: any) => {
        SetPaymentrole(response.data.paymentrole);
        SetTaxIdTypeList(response.data.taxidtype);
        SetCompanyType(response.data.businesstype);
        SetEntityType(response.data.companytype);
        setCompanyDocumentType(response.data.DocumentType);
        //setfacilitatorList(response.data.facilitator);

        BasicInfoService.getBasicInfoByPartnerId(Number(partnerid))
          .then((response: any) => {

            setBasicInfoModel(response.data);
            const typesdata = JSON.parse(response.data.partnerTypes) || [];
            var finaltypesdata = typesdata.map((type: any) => ({
              name: type,
              code: type,
            }));

            setPartnerTypesMultiSelectList(finaltypesdata);
            setRoleAutoComplete(response.data.roleName);
            setTaxIdTypeAutoComplete(response.data.taxType);
            setCompanyTypeAutoComplete(response.data.companyType);
            setEntityTypeAutoComplete(response.data.entityType);
            setSizeOfBusinessAutoComplete(response.data.sizeOfBusiness);
            setTaxCurrencyAutoComplete(response.data.taxCurrency);
            setIndustryAutoComplete(response.data.industry);
            if (response.data.partnerTypes !== null) {
              setpartperTypeName(JSON.parse(response.data.partnerTypes));
            }
            setlogoUrl(response.data.logoUrl);

            setLoading(false);
          })
          .catch((error) => {
            if (error.response.status === 500) {
              toast.current?.show({
                severity: "error",
                summary: "Something went wrong",
                life: 3000,
              });
            } else if (error.response.status === 401) {
              toast.current?.show({
                severity: "error",
                summary: "Unauthorized",
                life: 3000,
              });
              Logout(navigate);
            } else {
              toast.current?.show({
                severity: "error",
                summary: "Something went wrong",
                life: 3000,
              });
            }
          });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        }
      });
  };

  //search tax id
  const searchTaxIDType = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < taxidtypelist.length; i++) {
      let item = taxidtypelist[i];

      if (item.description.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredTaxIdType(_filteredItems);
  };

  //search company type
  const searchCompanyType = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < companytype.length; i++) {
      let item = companytype[i];

      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredCompanyType(_filteredItems);
  };

  //search entity type
  const searchEntityType = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < entitytype.length; i++) {
      let item = entitytype[i];

      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredEntityType(_filteredItems);
  };

  // search document type
  const searchDocumentType = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < documenttype.length; i++) {
      let item = documenttype[i];

      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredDocumentType(_filteredItems);
  };
  // search tax currency
  const searchTaxCurrency = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < currenciesList.length; i++) {
      let item = currenciesList[i];

      if (item.Name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredCurrencyList(_filteredItems);
  };

  // search indusrty
  const searchIndustry = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < industryList.length; i++) {
      let item = industryList[i];

      if (item.Name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredIndustryList(_filteredItems);
  };

  // error message empty model handle
  const ErrorMessageEmptyModel = () => {
    setlegalnameErrorMessage("");
    setDBAErrorMessage("");
    setregnoErrorMessage("");
    setwebsiteErrorMessage("");
    settaxnoErrorMessage("");
    settaxidtypeErrorMessage("");
    setcompanytypeErrorMessage("");
    setentitytypeErrorMessage("");
    setsizeofbusinessErrorMessage("");
    setnatureofbusinessErrorMessage("");
    setotherindustryerrorMessage("");
    setlogoErrorMessage("");
    setlogoErrorMessage1("");
  };

  // check null values
  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null || value === 0) {
      return true;
    }
    return false;
  };

  // validation check
  const isValidate = (values: any) => {
    let formIsValid = true;
    ErrorMessageEmptyModel();

    if (CheckNull(values.legalName)) {
      setlegalnameErrorMessage("Please enter legal name.");
      formIsValid = false;
    }
    if (!CheckNull(values.legalName)) {
      if (values.legalName.trim().length === 0) {
        setlegalnameErrorMessage("Please enter legal name.");
        formIsValid = false;
      }
      if (values.legalName.length > 100) {
        setlegalnameErrorMessage("Please enter valid legal name.");
        formIsValid = false;
      }
    }

    if (!CheckNull(values.dba)) {
      if (values.dba.trim().length === 0) {
        setDBAErrorMessage("Please enter valid DBA name or remove unwanted spaces.");
        formIsValid = false;
      }

      if (values.dba.length > 25) {
        setDBAErrorMessage("Please enter valid DBA name.");
        formIsValid = false;
      }
    }

    if (CheckNull(values.registrationNumber)) {
      setregnoErrorMessage("Please enter registration number.");
      formIsValid = false;
    }
    if (!CheckNull(values.registrationNumber)) {
      if (values.registrationNumber.trim().length === 0) {
        setregnoErrorMessage("Please enter registration number.");
        formIsValid = false;
      }
      if (values.registrationNumber.length > 50) {
        setregnoErrorMessage("Please enter valid registration number.");
        formIsValid = false;
      }
    }
    if (!CheckNull(values.website)) {
      if (values.website.trim().length === 0) {
        setwebsiteErrorMessage("Please enter website.");
        formIsValid = false;
      }
      if (!validWebsite.test(values.website)) {
        setwebsiteErrorMessage("Please enter valid website.");
        formIsValid = false;
      }
      if (values.website.length > 100) {
        setwebsiteErrorMessage("Please enter valid website.");
        formIsValid = false;
      }
    }
    if (CheckNull(values.website)) {
      setwebsiteErrorMessage("Please enter website.");
      formIsValid = false;
    }
    if (CheckNull(values.taxIdType) || values.taxIdType === 0) {
      settaxidtypeErrorMessage("Please select Tax ID type.");
      formIsValid = false;
    }
    if (CheckNull(values.taxNumber)) {
      settaxnoErrorMessage("Please enter Tax ID number.");
      formIsValid = false;
    }

    if (!CheckNull(values.taxNumber)) {
      if (values.taxNumber.trim().length === 0) {
        settaxnoErrorMessage("Please enter Tax ID number.");
        formIsValid = false;
      }
    }

    if (formIsValid) {
      const taxType = taxidtypelist.filter((t) => t.id === values.taxIdType)[0]
        .type;

      switch (taxType) {
        case "LEI":
          if (checkLEI(values.taxNumber) === false) {
            formIsValid = false;
            settaxnoErrorMessage(
              "Please provide a valid LEI that is 20 characters long"
            );
          }
          break;
        case "EIN":
          if (checkEIN(values.taxNumber) === false) {
            formIsValid = false;
            settaxnoErrorMessage(
              "EIN is a nine-digit number in the given format: XX-XXXXXXX"
            );
          }

          break;
        case "TIN":
          if (
            checkTIN(values.taxNumber) === false ||
            values.taxNumber === 111111111 ||
            values.taxNumber === 222222222 ||
            values.taxNumber === 333333333 ||
            values.taxNumber === 666666666 ||
            values.taxNumber === 123456789
          ) {
            formIsValid = false;
            settaxnoErrorMessage(
              "Please provide valid TIN in given format: XXX-XX-XXXX"
            );
          }
          break;
        case "ITIN":
          if (checkITIN(values.taxNumber) === false) {
            formIsValid = false;
            settaxnoErrorMessage(
              "ITIN is a nine-digit number in the given format: 9XX-XX-XXXX"
            );
          }
          break;
        case "SSN":
          if (checkSSN(values.taxNumber) === false) {
            formIsValid = false;
            settaxnoErrorMessage(
              "SSN is a nine-digit number in the given format: XXX-XX-XXXX"
            );
          }
          break;
        case "GIIN":
          if (
            values.taxNumber.toString() === "0" ||
            CheckNull(values.taxNumber)
          ) {
            settaxnoErrorMessage("Please provide GIIN");
            formIsValid = false;
          }
          break;
        case "GLEI":
          if (
            values.taxNumber.toString() === "0" ||
            CheckNull(values.taxNumber) ||
            values.taxNumber === 111111111 ||
            values.taxNumber === 222222222 ||
            values.taxNumber === 333333333 ||
            values.taxNumber === 666666666 ||
            values.taxNumber === 123456789
          ) {
            formIsValid = false;
            settaxnoErrorMessage("Please provide valid GLEI");
          }
          break;
        case "LEI":
          if (
            values.taxNumber.toString() === "0" ||
            CheckNull(values.taxNumber) ||
            values.taxNumber === 111111111 ||
            values.taxNumber === 222222222 ||
            values.taxNumber === 333333333 ||
            values.taxNumber === 666666666 ||
            values.taxNumber === 123456789
          ) {
            formIsValid = false;
            settaxnoErrorMessage("Please provide valid LEI");
          }
          break;
        default:
        // code block
      }
    }

    if (CheckNull(values.companyTypeId)) {
      setcompanytypeErrorMessage("Please select company type.");
      formIsValid = false;
    }
    if (CheckNull(values.entityTypeId) || values.entityTypeId === 0) {
      setentitytypeErrorMessage("Please select entity type.");
      formIsValid = false;
    }
    if (CheckNull(values.sizeOfBusinessId)) {
      setsizeofbusinessErrorMessage("Please select size of business.");
      formIsValid = false;
    }
    if (CheckNull(values.industry)) {
      setnatureofbusinessErrorMessage("Please select nature of business.");
      formIsValid = false;
    }

    if (values.industry === "Other") {
      if (CheckNull(values.otherIndustry)) {
        setotherindustryerrorMessage("Please enter other industry.");
        formIsValid = false;
      }
    }
    if (CheckNull(values.logoUrl)) {
      setlogoErrorMessage("Please upload logo.");
      formIsValid = false;
    }
    return formIsValid;
  };

  // token update
  const updatetoken = () => {

    LoginService.updatetoken()
      .then((response) => {
        localStorage.setItem("User", JSON.stringify(response.data));

        localStorage.setItem(
          "PartnerId",
          JSON.stringify(response.data.partnerId)
        );
        localStorage.setItem("Token", JSON.stringify(response.data.jwtToken));
        localStorage.setItem("StepFlag", "1");
        localStorage.setItem("OnboardingStatus", "1");
        onSaveAndContinueClick("N");
        setContinueButtonLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting updated token.",
            life: 3000,
          });
        }
      });
  };


  // save button click
  const onAddClick = (event: React.FormEvent<HTMLButtonElement>): void => {
    ErrorMessageEmptyModel();
    setContinueButtonLoading(true);

    basicInfoModel.logoUrl = logoUrl;
    if (!CheckNull(basicInfoModel.website)) {
      if ((!(basicInfoModel.website.startsWith("https://"))) && (!(basicInfoModel.website.startsWith("http://")))) {
        basicInfoModel.website = 'https://' + basicInfoModel.website;
      }
    }



    if (isValidate(basicInfoModel)) {
      basicInfoModel.legalName = basicInfoModel.legalName.trim();
      BasicInfoService.addBasicInfo(basicInfoModel)
        .then((response: any) => {

          context.updateLogoSrc(logoUrl);

          updatetoken();

        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 500) {
            toast.current?.show({
              severity: "error",
              summary: "Something went wrong",
              life: 3000,
            });
          } else if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data[0]?.errorMessage,
              life: 3000,
            });
          } else if (error.response.status === 409) {
            setlegalnameErrorMessage(error.response.data);
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Error while saving basic info details.",
              life: 3000,
            });
          }
          setContinueButtonLoading(false);
        });
    } else {
      setContinueButtonLoading(false);
    }

  };

  // update fields
  const onUpdateClick = () => {
    ErrorMessageEmptyModel();


    basicInfoModel.partnerId = Number(partnerid);
    if (!CheckNull(basicInfoModel.website)) {
      if ((!(basicInfoModel.website.startsWith("https://"))) && (!(basicInfoModel.website.startsWith("http://")))) {
        basicInfoModel.website = 'https://' + basicInfoModel.website;
      }
    }
    setContinueButtonLoading(true);
    basicInfoModel.logoUrl = logoUrl;

    if (isValidate(basicInfoModel)) {
      basicInfoModel.legalName = basicInfoModel.legalName.trim();
      BasicInfoService.updateBasicInfo(basicInfoModel)
        .then((response) => {
          setContinueButtonLoading(false);
          context.updateLogoSrc(logoUrl);
          onSaveAndContinueClick("N");

        })
        .catch((error) => {
          if (error.response.status === 500) {
            toast.current?.show({
              severity: "error",
              summary: "Something went wrong",
              life: 3000,
            });
          } else if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data[0].errorMessage,
              life: 3000,
            });
          } else if (error.response.status === 409) {
            setlegalnameErrorMessage(error.response.data);
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Error while saving basic info details.",
              life: 3000,
            });
          }
          setContinueButtonLoading(false);
        });
    } else {
      setContinueButtonLoading(false);
    }
  };

  // on tax id type change
  const onTaxIDTypeChange = (e: any) => {
    if (e.value !== null) {
      setTaxIdTypeAutoComplete(e.value);
      setBasicInfoModel({
        ...basicInfoModel,
        taxIdType: e.value.id,
      });
      setDirtyField(true);
    }
  };

  // on company type
  const onCompanyTypeChange = (e: any) => {
    if (e.value !== null) {
      setCompanyTypeAutoComplete(e.value);
      setBasicInfoModel({
        ...basicInfoModel,
        companyTypeId: e.value.id,
      });
      setDirtyField(true);
    }
  };

  // on enityt type change
  const onEntityTypeChange = (e: any) => {
    if (e.value !== null) {
      setEntityTypeAutoComplete(e.value);
      setBasicInfoModel({
        ...basicInfoModel,
        entityTypeId: e.value.id,
      });
      setDirtyField(true);
    }
  };

  // on business change
  const onSizeOfBusinessChange = (e: any) => {
    if (e.value !== null) {
      setSizeOfBusinessAutoComplete(e.value);
      setBasicInfoModel({
        ...basicInfoModel,
        sizeOfBusinessId: e.value.id,
      });
      setDirtyField(true);
    }
  };

  // on tax currency changes
  const onTaxCurrencyChange = (e: any) => {
    if (e.value !== null) {
      setTaxCurrencyAutoComplete(e.value);
      setBasicInfoModel({
        ...basicInfoModel,
        taxCurrency: e.value.Name,
      });
      setDirtyField(true);
    }
  };

  // on industry change
  const onIndustryChange = (e: any) => {
    if (e.value !== null) {
      setIndustryAutoComplete(e.value);
      setBasicInfoModel({
        ...basicInfoModel,
        industry: e.value.Name,
      });
      setDirtyField(true);
    }
  };

  //on file change
  const onFileChange = (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setuploadButtonloading(true);
    if ((fileObj.type === "image/jpeg" || fileObj.type === "image/jpg" || fileObj.type === "image/png" || fileObj.type === "image/svg+xml") && fileObj.size <= 4 * 1024 * 1024) {
      const imagemodel = event.target.files[0];
      const formData = new FormData();
      formData.append("PartnerId", Number(partnerid).toString());
      formData.append("Logo", imagemodel);

      BasicInfoService.updateLogoUrl(formData).then((res: any) => {
        setlogoUrl(res.data.logoUrl);


        setuploadButtonloading(false);
        setlogoErrorMessage1("");
      });
    } else {
      setlogoErrorMessage("")
      setuploadButtonloading(false);

      setlogoErrorMessage1("Only JPG/JPEG/PNG/SVG images are allowed upto size 4 MB.")
    }

    setDirtyField(true);
  };

  // handle close
  const handleClose = () => {

    if (dirtyfield === true) {
      setDisplayPopup(true);
    } else {
      ErrorMessageEmptyModel();
    }

  };

  //on popup close
  const OnPopupClose = () => {
    setDisplayPopup(false);
  };

  // on popup ok
  const OnPopupOk = () => {

    basicInfoModel.partnerId === 0
      ? setModelEmpty()
      : getBasicInfoByPartnerId(Number(partnerid));
    setDisplayPopup(false);
    setDirtyField(false);
    ErrorMessageEmptyModel();
  };

  useEffect(() => {
    setButtonLoadingSkip(false);

    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }

    getBasicInfoByPartnerId(Number(partnerid));

    {
      Number(status) === 8 ? setReadOnly(true) : setReadOnly(false);
    }
  }, []);


  return (
    <>
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          {" "}
          <Scrollbars
            className="contain-scroll"
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={100}
            autoHeightMax={100}
            thumbMinSize={30}
            universal={true}
          >
            <div className="container-fluid acc-screen basicinfomain">
              {/* <Toast ref={toast}></Toast> */}
              <div className="row basic-info-row">
                <div className="col-md-6 info-section info-section-border">
                  <div className="col-md-12 form-group ">
                    <span className="input-label">
                      Entity Legal Name <span className="color-red">*</span>
                      <ToolTip props={basicInfoToolTipdata[0]}></ToolTip>
                    </span>
                    <input
                      readOnly={readonly}
                      tabIndex={1}
                      className="form-control"
                      type="text"
                      name="legalName"
                      autoComplete="nope"
                      placeholder="Enter legal name"
                      value={basicInfoModel.legalName}
                      onChange={(e) => {
                        setBasicInfoModel({
                          ...basicInfoModel,
                          legalName: e.target.value,
                        });
                        setDirtyField(true);
                      }}
                    />
                    {legalnameerrorMessage !== null &&
                      legalnameerrorMessage.length > 0 ? (
                      <span className="error-msg">{legalnameerrorMessage}</span>
                    ) : null}
                  </div>
                  <div className="col-md-12 form-group ">
                    <span className="input-label">
                      Website <span className="color-red">*</span>
                      <ToolTip props={basicInfoToolTipdata[1]}></ToolTip>
                    </span>
                    <input
                      readOnly={readonly}
                      tabIndex={3}
                      className="form-control"
                      type="text"
                      placeholder="Enter website"
                      name="businessWebsite"
                      autoComplete="nope"
                      value={basicInfoModel.website}
                      onChange={(e) => {
                        setBasicInfoModel({
                          ...basicInfoModel,
                          website: e.target.value,
                        });
                        setDirtyField(true);
                      }}
                    />
                    {websiteerrorMessage !== null &&
                      websiteerrorMessage.length > 0 ? (
                      <span className="error-msg">{websiteerrorMessage}</span>
                    ) : null}
                  </div>{" "}
                  <div className="col-md-12 form-group  ">
                    <span className="input-label">
                      Tax ID Type <span className="color-red">*</span>{" "}
                      <ToolTip props={basicInfoToolTipdata[2]}></ToolTip>
                    </span>
                    <AutoComplete
                      readOnly={readonly}
                      disabled={readonly}
                      tabIndex={5}
                      field="description"
                      dropdown
                      aria-label="roles"
                      dropdownAriaLabel="Select tax id type"
                      className="dropdown-acc"
                      placeholder="Select Tax ID type"
                      virtualScrollerOptions={{ itemSize: 38 }}
                      suggestions={filteredtaxidtype}
                      completeMethod={searchTaxIDType}
                      onChange={(e) => onTaxIDTypeChange(e)}
                      value={taxidtypeAutoComplete}
                      forceSelection
                    />
                    {taxidtypeerrorMessage !== null &&
                      taxidtypeerrorMessage.length > 0 ? (
                      <span className="error-msg">{taxidtypeerrorMessage}</span>
                    ) : null}
                  </div>
                  {/* <div className="col-md-12 form-group  form-group ">
                    <span className="input-label">
                      Tax Currency{" "}
                      <ToolTip props={basicInfoToolTipdata[3]}></ToolTip>
                    </span>
                    <AutoComplete
                      readOnly={readonly}
                      disabled={readonly}
                      tabIndex={7}
                      field="Name"
                      dropdown
                      forceSelection
                      aria-label="Countries"
                      dropdownAriaLabel="Select tax currency"
                      className="dropdown-acc"
                      placeholder="Select tax currency"
                      suggestions={filteredcurrencieslist}
                      completeMethod={searchTaxCurrency}
                      onChange={(e) => onTaxCurrencyChange(e)}
                      value={taxCurrencyAutoComplete}

                    />
                  </div>{" "} */}
                  <div className="col-md-12 form-group  form-group ">
                    <span className="input-label">
                      Size of Business <span className="color-red">*</span>
                      <ToolTip props={basicInfoToolTipdata[4]}></ToolTip>
                    </span>
                    <AutoComplete
                      readOnly={readonly}
                      disabled={readonly}
                      tabIndex={9}
                      field="name"
                      dropdown
                      aria-label="roles"
                      dropdownAriaLabel="Select size of business"
                      className="dropdown-acc"
                      placeholder="Select size of business"
                      virtualScrollerOptions={{ itemSize: 38 }}
                      suggestions={documenttypelist}
                      completeMethod={searchDocumentType}
                      onChange={(e) => onSizeOfBusinessChange(e)}
                      value={sizeofbusinessAutoComplete}
                      forceSelection
                    />
                    {sizeofbusinesserrorMessage !== null &&
                      sizeofbusinesserrorMessage.length > 0 ? (
                      <span className="error-msg">
                        {sizeofbusinesserrorMessage}
                      </span>
                    ) : null}
                  </div>{" "}
                  <div className="col-md-12 form-group ">
                    <span className="input-label">
                      Nature of Business <span className="color-red">*</span>{" "}
                      <ToolTip props={basicInfoToolTipdata[5]}></ToolTip>
                    </span>
                    <AutoComplete
                      readOnly={readonly}
                      disabled={readonly}
                      tabIndex={11}
                      field="Name"
                      dropdown
                      aria-label="roles"
                      dropdownAriaLabel="Select nature of business"
                      className="dropdown-acc"
                      placeholder="Select nature of business"
                      virtualScrollerOptions={{ itemSize: 38 }}
                      suggestions={filteredindustrieslist}
                      completeMethod={searchIndustry}
                      onChange={(e) => onIndustryChange(e)}
                      value={industryAutoComplete}
                      forceSelection
                    />
                    {natureofbusinesserrorMessage !== null &&
                      natureofbusinesserrorMessage.length > 0 ? (
                      <span className="error-msg">
                        {natureofbusinesserrorMessage}
                      </span>
                    ) : null}
                  </div>{" "}
                  {basicInfoModel.industry === "Other" ?
                    <div className="col-md-12 form-group ">
                      <span className="input-label">
                        Other Industry <span className="color-red">*</span>
                        <ToolTip props={basicInfoToolTipdata[5]}></ToolTip>
                      </span>
                      <input
                        readOnly={readonly}
                        tabIndex={12}
                        className="form-control"
                        type="text"
                        placeholder="Enter other industry"
                        value={basicInfoModel.otherIndustry}
                        onChange={(e) => {
                          setBasicInfoModel({
                            ...basicInfoModel,
                            otherIndustry: e.target.value,
                          });
                          setDirtyField(true);
                        }}
                      />
                      {otherindustryerrorMessage !== null &&
                        otherindustryerrorMessage.length > 0 ? (
                        <span className="error-msg">{otherindustryerrorMessage}</span>
                      ) : null}
                    </div>
                    : null}

                </div>
                <div className="col-md-6 info-section-second">
                  <div className="col-md-12 form-group ">
                    <span className="input-label">
                      Doing Business As{" "}
                      <ToolTip props={basicInfoToolTipdata[6]}></ToolTip>
                    </span>{" "}
                    <input
                      readOnly={readonly}
                      tabIndex={2}
                      className="form-control"
                      name="businessAs"
                      autoComplete="nope"
                      type="text"
                      placeholder="Enter doing business as"
                      value={basicInfoModel.dba}
                      onChange={(e) => {
                        setBasicInfoModel({
                          ...basicInfoModel,
                          dba: e.target.value,
                        });
                        setDirtyField(true);
                      }}
                    />
                    {dbaerrorMessage !== null && dbaerrorMessage.length > 0 ? (
                      <span className="error-msg">{dbaerrorMessage}</span>
                    ) : null}
                  </div>
                  <div className="col-md-12 form-group ">
                    <span className="input-label">
                      Registration Number <span className="color-red">*</span>{" "}
                      <ToolTip props={basicInfoToolTipdata[7]}></ToolTip>
                    </span>
                    <input
                      readOnly={readonly}
                      tabIndex={4}
                      className="form-control"
                      type="text"
                      name="registrationNumber"
                      autoComplete="nope"
                      placeholder="Enter registration number"
                      value={basicInfoModel.registrationNumber}
                      onChange={(e) => {
                        setBasicInfoModel({
                          ...basicInfoModel,
                          registrationNumber: e.target.value,
                        });
                        setDirtyField(true);
                      }}
                    />

                    {regnoerrorMessage !== null &&
                      regnoerrorMessage.length > 0 ? (
                      <span className="error-msg">{regnoerrorMessage}</span>
                    ) : null}
                  </div>
                  <div className="col-md-12 form-group  ">
                    <span className="input-label">
                      Tax ID Number <span className="color-red">*</span>
                      <ToolTip props={basicInfoToolTipdata[8]}></ToolTip>
                    </span>
                    <input
                      readOnly={readonly}
                      tabIndex={6}
                      className="form-control"
                      name="TaxIdNumber"
                      autoComplete="nope"
                      type="text"
                      placeholder="Enter Tax ID number"
                      value={basicInfoModel.taxNumber}
                      onChange={(e) => {
                        setBasicInfoModel({
                          ...basicInfoModel,
                          taxNumber: e.target.value,
                        });
                        setDirtyField(true);
                      }}
                    />
                    {taxnoerrorMessage !== null &&
                      taxnoerrorMessage.length > 0 ? (
                      <span className="error-msg error-msg-small">
                        {taxnoerrorMessage}
                      </span>
                    ) : null}
                  </div>{" "}
                  <div className="col-md-12 form-group  ">
                    <span className="input-label">
                      Company Type <span className="color-red">*</span>
                      <ToolTip props={basicInfoToolTipdata[9]}></ToolTip>
                    </span>
                    <AutoComplete
                      readOnly={readonly}
                      disabled={readonly}
                      tabIndex={8}
                      field="name"
                      dropdown
                      aria-label="roles"
                      dropdownAriaLabel="Select company type"
                      className="dropdown-acc"
                      placeholder="Select company type"
                      virtualScrollerOptions={{ itemSize: 38 }}
                      suggestions={filteredcompanytype}
                      completeMethod={searchCompanyType}
                      onChange={(e) => onCompanyTypeChange(e)}
                      value={companytypeAutoComplete}
                      forceSelection
                    />
                    {companytypeerrorMessage !== null &&
                      companytypeerrorMessage.length > 0 ? (
                      <span className="error-msg">
                        {companytypeerrorMessage}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-md-12 form-group  ">
                    <span className="input-label">
                      Entity Type <span className="color-red">*</span>{" "}
                      <ToolTip props={basicInfoToolTipdata[10]}></ToolTip>
                    </span>
                    <AutoComplete
                      readOnly={readonly}
                      disabled={readonly}
                      tabIndex={10}
                      field="name"
                      dropdown
                      aria-label="roles"
                      dropdownAriaLabel="Select entity type"
                      className="dropdown-acc"
                      placeholder="Select entity type"
                      virtualScrollerOptions={{ itemSize: 38 }}
                      suggestions={filteredentitytype}
                      completeMethod={searchEntityType}
                      onChange={(e) => onEntityTypeChange(e)}
                      value={entitytypeAutoComplete}
                      forceSelection
                    />
                    {entitytypeerrorMessage !== null &&
                      entitytypeerrorMessage.length > 0 ? (
                      <span className="error-msg">
                        {entitytypeerrorMessage}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-md-12 form-group ">
                    <span className="input-label">
                      Upload Logo <span className="color-red">*</span>{" "}
                      <ToolTip props={basicInfoToolTipdata[11]}></ToolTip>
                    </span>
                    <div className="Imgupload-buttonbar myfileupload-buttonbar ">
                      <div className="col-md-6">
                        <Button
                          className="document-upload "
                          loading={uploadButtonloading}
                        >
                          Upload
                          <input
                            readOnly={readonly}
                            hidden={readonly}
                            name="Name"
                            accept=".jpg,.png,.jpeg,.svg"
                            type="file"
                            multiple
                            onChange={(e) => onFileChange(e)}
                            className="inputdata "
                          />
                        </Button>

                        {logoUrl !== null ? (<div className="Logo-wrappper">
                          <img
                            src={logoUrl}
                            alt="PartnerImage"
                            id="PartnerImage"
                            className="uploadimg"
                          />
                        </div>
                        ) : null}

                        {logoErrorMessage !== null &&
                          logoErrorMessage.length > 0 ? (
                          <span className={logoUrl !== null ? "error-msg  " : "error-msg error-msg-upload "}>
                            {logoErrorMessage}
                          </span>
                        ) : null}
                        {logoErrorMessage1 !== null &&
                          logoErrorMessage1.length > 0 ? (
                          <span className={logoUrl !== null ? "error-msg  error-msg errormsg-limit " : "error-msg error-msg-limit "}>
                            {logoErrorMessage1}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="button-section">
                <div className=" bottom-btns ">
                  {Number(status) === 8 ? (
                    <>
                      <Button
                        iconPos="left"
                        label="Next"
                        loading={buttonLoadingSkip}
                        onClick={onNextClick}
                        className="btn btn-continue   btn-next second-btn"
                      />
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={handleClose}
                        className="btn btn-cancel second-btn "
                      >
                        Cancel
                      </button>

                      <Button
                        disabled={uploadButtonloading ? true : false}
                        iconPos="left"
                        label=" Save and Continue"
                        className="btn btn-continue second-btn"
                        loading={continuebuttonloading}
                        onClick={
                          basicInfoModel.partnerId === 0
                            ? onAddClick
                            : onUpdateClick
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </Scrollbars>
          {displaypopup ? (
            <>
              <div className="popup-body">
                <div className="Cancel-popup">
                  <div className="text-center ">
                    <div className="awesome-text">
                      <h4>Are you sure you want to cancel?</h4>
                      <p> All unsaved changes will be lost.</p>
                    </div>
                  </div>
                  <div className="payment-screen-btn">
                    <button
                      className="btn btn-cancel second-btn"
                      onClick={OnPopupClose}
                    >
                      {" "}
                      No
                    </button>
                    <button
                      className="btn btn-continue second-btn yes-btn-popup"
                      onClick={OnPopupOk}
                    >
                      {" "}
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default BasicInfo;
