import { Button } from "primereact/button";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import CloseIcon from "../../../assets/images/icon/close-icon.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AutoComplete } from "primereact/autocomplete";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { FacilitatorService } from "../../../services/Partner/Facilitator/FacilitatorService";
import { CommonService } from "../../../services/Common/CommonService";
import { Logout } from "../../../utils/AccountUtils";
import { currenciesList } from "../../../utils/utils";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { PartnerTypeList } from "../../../utils/utils";

const Facilitator: React.FC = () => {
  const id = localStorage.getItem("PartnerId");
  const [partnerid, setpartnerId] = React.useState(id);
  const [facilitatorList, setFacilitatorList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [facilatorfeepopup, setfacilatorfeepopup] = useState(false);
  const [typeerrorMessage, settypeErrorMessage] = React.useState("");
  const [typeAutoComplete, setTypeAutoComplete] = useState("");
  const [fixedcommisionerrorMessage, setfixedcommisionErrorMessage] =
    React.useState("");
  const [variablecommisionerrorMessage, setvariablecommissionErrorMessage] =
    React.useState("");
  const [currencyerrorMessage, setcurrencyErrorMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [filteredcurrencylist, setFilteredCurrencyList] = React.useState<any[]>(
    []
  );
  const [filteredtypelist, setFilteredTypeList] = React.useState<any[]>([]);
  const [typeList, setTypeList] = React.useState([]);
  const [currencyAutoComplete, setCurrencyAutoComplete] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [readOnly, setReadOnly] = React.useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [FacilitatorFeeModel, setFacilitatorFeeModel] = React.useState({
    id: 0,
    type: "",
    partnerId: Number(partnerid),
    facilitatorFixedCommission: "",
    facilitatorVariableCommission: "",
    facilitatorCommissionCurrency: "",
  });
  const setModelEmpty = () => {
    setFacilitatorFeeModel({
      id: 0,
      type: "",
      partnerId: Number(partnerid),
      facilitatorFixedCommission: "",
      facilitatorVariableCommission: "",
      facilitatorCommissionCurrency: "",
    });
    setTypeAutoComplete("");
    setCurrencyAutoComplete("");
  };
  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null) {
      return true;
    }
    return false;
  };
  const ErrorMessageEmptyModel = () => {
    settypeErrorMessage("");
    setfixedcommisionErrorMessage("");
    setvariablecommissionErrorMessage("");
    setcurrencyErrorMessage("");
    setErrorMessage("");
  };

  const isValidate = (values: any) => {
    let formIsValid = true;
    ErrorMessageEmptyModel();
    if (CheckNull(values.type)) {
      settypeErrorMessage("Please select type.");
      formIsValid = false;
    }
    if (CheckNull(values.facilitatorFixedCommission)) {
      setfixedcommisionErrorMessage("Please enter fixed fee.");
      formIsValid = false;
    }

    
    if (!CheckNull(values.facilitatorFixedCommission)) {
      if (values.facilitatorFixedCommission < 0) {
        setfixedcommisionErrorMessage('Fixed fee can not be negative.');
        formIsValid = false;
      }
      if (!(values.facilitatorFixedCommission < 0)) {
        if (!(values.facilitatorFixedCommission.toString().match(/^\d{1,16}(\.\d{0,2})?$/))) {
          setfixedcommisionErrorMessage('Fixed fee valid only upto length(16,2)');
          formIsValid = false;
        }
      }
    }

    if (!(Number(values.facilitatorFixedCommission) >= 0) || Object.is(Number(values.facilitatorFixedCommission),-0)) {
      setfixedcommisionErrorMessage(
        `Facilitator fixed fees cannot be negative number for  ${values.receiveFeeCurrency} with ${values.deliveryType}.`
      );
      formIsValid = false;
    }
 

    if (CheckNull(values.facilitatorVariableCommission)) {
      setvariablecommissionErrorMessage("Please enter variable fee.");
      formIsValid = false;
    }
    if (!CheckNull(values.facilitatorVariableCommission)) {
      if (values.facilitatorVariableCommission < 0) {
        setvariablecommissionErrorMessage('Variable fee can not be negative.');
        formIsValid = false;
      }
      if (!(values.facilitatorVariableCommission < 0)) {
        if (!(values.facilitatorVariableCommission.toString().match(/^\d{1,16}(\.\d{0,2})?$/))) {
          setvariablecommissionErrorMessage('Variable fee valid only upto length(16,2)');
          formIsValid = false;
        }
      }
      if (!(Number(values.facilitatorVariableCommission) >= 0) || Object.is(Number(values.facilitatorVariableCommission),-0)) {
        setvariablecommissionErrorMessage(
          `Facilitator variable fees cannot be negative number for  ${values.facilitatorVariableCommission} with ${values.deliveryType}.`
        );
        formIsValid = false;
      }
      
    }

    if (CheckNull(values.facilitatorCommissionCurrency)) {
      setcurrencyErrorMessage("Please select currency.");
      return false;
    }
    return formIsValid;
  };

  useEffect(() => {
    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      navigate("/");
    }
    getBusinessTypes();
    getFacilitatorByPartnerId(partnerid);
  }, []);

  const getBusinessTypes = () => {
    CommonService.getBusinessType()
      .then((data) => {
        setTypeList(data.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setTypeList([]);
      });
  };

  const getFacilitatorByPartnerId = (val: any) => {
    // setLoading(true);
    FacilitatorService.getFacilitatorFeeByPartnerId(val)
      .then((data) => {
        setFacilitatorList(data.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setFacilitatorList([]);
        // setLoading(false);
      });
  };

  const ShowHideDialogfee = () => {
    setfacilatorfeepopup(true);
  };
  const onHideClickfee = () => {
    setfacilatorfeepopup(false);
    setReadOnly(false);
  };
  const onAddClick = () => {
    ErrorMessageEmptyModel();
    FacilitatorFeeModel.partnerId = Number(partnerid);
    setButtonLoading(true);
    if (isValidate(FacilitatorFeeModel)) {
      FacilitatorService.addFacilitatorFee(FacilitatorFeeModel)
        .then((data) => {
          setModelEmpty();
          setButtonLoading(false);
          getFacilitatorByPartnerId(FacilitatorFeeModel.partnerId);
          setfacilatorfeepopup(false);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data[0].errorMessage,
              life: 3000,
            });
          } else if (error.response.status === 409) {
            setErrorMessage(error.response.data);
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Oops! Something went wrong.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };
  const onUpdateClick = () => {
    ErrorMessageEmptyModel();
    setButtonLoading(true);
    if (isValidate(FacilitatorFeeModel)) {
      FacilitatorService.updateFacilitatorFee(FacilitatorFeeModel)
        .then((data) => {
          setModelEmpty();
          setButtonLoading(false);
          getFacilitatorByPartnerId(FacilitatorFeeModel.partnerId);
          setfacilatorfeepopup(false);
          setReadOnly(false);
        })

        .catch((error) => {
          if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data[0].errorMessage,
              life: 3000,
            });
          } else if (error.response.status === 409) {
            setErrorMessage(error.response.data);
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Oops! Something went wrong.",
              life: 3000,
            });
          }
          setButtonLoading(false);
        });
    } else {
      setButtonLoading(false);
    }
  };
  const deleteFacilitator = () => {
    setLoading(true);
    FacilitatorService.deleteFacilitatorFee(recordToDelete.id)
      .then((data) => {
        setLoading(false);
        setShowDialog(false);
        setRecordToDelete(null);
        getFacilitatorByPartnerId(partnerid);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };
  const onHide = () => {
    setShowDialog(false);
    setRecordToDelete(null);
  };
  const onCancleClick = () => {
    FacilitatorFeeModel.id === 0
      ? setModelEmpty()
      : getFacilitatorByPartnerId(Number(partnerid));
    setfacilatorfeepopup(false);
    ErrorMessageEmptyModel();
    setReadOnly(false);
  };
  const searchCurrency = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < currenciesList.length; i++) {
      let item = currenciesList[i];
      if (item.Name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredCurrencyList(_filteredItems);
  };
  const searchType = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < PartnerTypeList.length; i++) {
      let item = PartnerTypeList[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredTypeList(_filteredItems);
  };
  const onTypeChange = (e: any) => {
    if (e.value !== null) {
      setTypeAutoComplete(e.value);
      setFacilitatorFeeModel({
        ...FacilitatorFeeModel,
        type: e.value.id,
      });
    }
  };
  const confirmDelete = (record: any) => {
    setShowDialog(true);
    setRecordToDelete(record);
  };
  const actionBodyTemplate = (rowData: any) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="editbtn"
          onClick={() => EditFacilitatorFee(rowData)}
          title="Edit"
        />
        <Button
          icon="pi pi-trash"
          className="editbtn"
          onClick={() => confirmDelete(rowData)}
          title="Delete"
        />
      </>
    );
  };


  const onFeeAddClick = () => {
    setModelEmpty();
    ErrorMessageEmptyModel();
    ShowHideDialogfee();
  };
  const EditFacilitatorFee = (val: any) => {
    setReadOnly(true);
    setfacilatorfeepopup(true);
    setFacilitatorFeeModel(val);

    const type = PartnerTypeList
      .filter((data) => data.id === val.type)[0].name;
    setTypeAutoComplete(type);

    setCurrencyAutoComplete(val.facilitatorCommissionCurrency);
    ErrorMessageEmptyModel();
  };
  const onCurrencyChange = (e: any) => {
    if (e.value !== null) {
      setCurrencyAutoComplete(e.value);
      setFacilitatorFeeModel({
        ...FacilitatorFeeModel,
        facilitatorCommissionCurrency: e.value.Name,
      })
    }
  };
  const TypeActionBodyTemplate = (rowData: any) => {
    const typename = PartnerTypeList
      .filter((data) => data.id === rowData.type)
      .map((x) => x.name);
    return (
      <>
        {typename}
      </>
    )

  }

  const variablefeeactionBodyTemplate = (rowData: any) => {
    return (
      <>
        {rowData.facilitatorVariableCommission} {"%"}
      </>
    )
  }

  return (
    <>
      {" "}
      <div className="F-Fee-main">
        <Toast ref={toast}></Toast>


        <ConfirmDialog
          id="confirm-popup"
          visible={showDialog}
          onHide={onHide}
          message={`Are you sure you want to delete?`}
          header="Confirm Delete"
          icon="pi pi-info-circle"
          acceptClassName="p-button-danger"
          acceptLabel="Yes"
          rejectLabel="No"
          accept={deleteFacilitator}
        />

        <div className=" facilitator-tabs">

          <div className="button-section">
            <Button
              iconPos="left"
              label="Add Fees"
              icon="pi pi-plus"
              className="btn btn-continue"
              onClick={onFeeAddClick}
            />
          </div>
        </div>


        <div className="facilitator-main">
          {loading ? (
            <div className="spinner-class">
              <ProgressSpinner />
            </div>
          ) : (
            <div className="datatable-doc-demo">
              <div className="">
                <DataTable
                  value={facilitatorList}
                  paginator
                  className="p-datatable-customers"
                  rows={10}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  rowsPerPageOptions={[10, 25, 50]}
                  dataKey="id"
                  rowHover
                  filterDisplay="menu"
                  responsiveLayout="scroll"
                  globalFilterFields={[
                    "name",
                    "country.name",
                    "representative.name",
                    "balance",
                    "status",
                  ]}
                  emptyMessage="No facilitator fees found."
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                >
                  <Column header="Type" sortable body={TypeActionBodyTemplate} />
                  <Column
                    field="facilitatorCommissionCurrency"
                    header="Currency"
                  />
                  <Column
                    field="facilitatorFixedCommission"
                    header="Fixed Fee"
                  />
                  <Column
                    body={variablefeeactionBodyTemplate}
                    header="Variable Fee"
                  />
                  <Column header="Action" body={actionBodyTemplate} />
                </DataTable>
              </div>
            </div>
          )}
        </div>
      </div>{" "}
      <Dialog
        header="Header"
        visible={facilatorfeepopup}
        onHide={() => onHideClickfee()}
        className="user-dialog"
      >
        <div className="FFpopup">
          <button className="close-btn" onClick={onHideClickfee}>
            <img src={CloseIcon} />
          </button>
          <div className="row user-row">
            <div className="col-md-6 form-group ">
              <span className="input-label">
                Type <span className="color-red">*</span>
              </span>
              <AutoComplete
                readOnly={readOnly}
                disabled={readOnly}
                field="name"
                dropdown
                forceSelection
                className="dropdown-acc"
                placeholder="Select type"
                suggestions={filteredtypelist}
                completeMethod={searchType}
                value={typeAutoComplete}
                onChange={(e) => onTypeChange(e)}
              />
              {typeerrorMessage !== null && typeerrorMessage.length > 0 ? (
                <span className="error-msg">{typeerrorMessage}</span>
              ) : null}
            </div>
            <div className="col-md-6 form-group ">
              <span className="input-label">
                Currency <span className="color-red">*</span>
              </span>
              <AutoComplete
                readOnly={readOnly}
                disabled={readOnly}
                field="Name"
                dropdown
                forceSelection
                className="dropdown-acc"
                placeholder="Select currency"
                suggestions={filteredcurrencylist}
                completeMethod={searchCurrency}
                value={currencyAutoComplete}
                onChange={(e) => onCurrencyChange(e)}
              />
              {currencyerrorMessage !== null &&
                currencyerrorMessage.length > 0 ? (
                <span className="error-msg">{currencyerrorMessage}</span>
              ) : null}
            </div>
            <div className="col-md-6 form-group ">
              <span className="input-label">
                Facilitator fixed fee <span className="color-red">*</span>
              </span>
              <input
                className="form-control "
                type="number"
                onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                name="fixed fee"
                placeholder="Enter facilitator fixed fee"
                value={FacilitatorFeeModel.facilitatorFixedCommission}
                onChange={(e) =>
                  setFacilitatorFeeModel({
                    ...FacilitatorFeeModel,
                    facilitatorFixedCommission: e.target.value,
                  })
                }
              />
              {fixedcommisionerrorMessage !== null &&
                fixedcommisionerrorMessage.length > 0 ? (
                <span className="error-msg">{fixedcommisionerrorMessage}</span>
              ) : null}
            </div>
            <div className="col-md-6 form-group ">
              <span className="input-label">
                Facilitator variable fee
                <span className="color-red">*</span>
              </span>
              <div className="percent-wrapper">
                <input
                  className="form-control "
                  type="number"
                  onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                  placeholder="Enter facilitator variable fee"
                  name="variable fee"
                  value={FacilitatorFeeModel.facilitatorVariableCommission}
                  onChange={(e) =>
                    setFacilitatorFeeModel({
                      ...FacilitatorFeeModel,
                      facilitatorVariableCommission: e.target.value,
                    })
                  }
                />
                <span className="feespercent">%</span>
              </div>
              {variablecommisionerrorMessage !== null &&
                variablecommisionerrorMessage.length > 0 ? (
                <span className="error-msg">
                  {variablecommisionerrorMessage}
                </span>
              ) : null}
            </div>
          </div>
          <div className="text-center">
            {errorMessage !== null &&
              errorMessage.length > 0 ? (
              <span className="error-msg">{errorMessage}</span>
            ) : null}
            <div className="FFpopup-btn">
              <button
                type="button"
                onClick={onCancleClick}
                className="btn btn-cancel second-btn"
              >
                Cancel
              </button>
              <Button
                iconPos="left"
                className="btn btn-continue second-btn"
                label={"Save"}
                loading={buttonLoading}
                onClick={
                  FacilitatorFeeModel.id === 0 ? onAddClick : onUpdateClick
                }
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Facilitator;
