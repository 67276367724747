import React from "react";
import { CurrenciesService } from "../../../services/Partner/Currencies/Currencies";
import { PartnerDepositService } from "../../../services/Ledger/LedgerService";
import { ProgressSpinner } from "primereact/progressspinner";
import { useState, useRef, useEffect } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { useNavigate, useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Logout } from "../../../utils/AccountUtils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { IsValidRoute } from "../../../utils/AccountUtils";
import Scrollbars from "react-custom-scrollbars-2";
import { Button } from "primereact/button";

const Ledger: React.FC = () => {
  const PartnerId = localStorage.getItem("PartnerId");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [partnerloading, setpartnerloading] = useState(false);
  const [loadingTransactions, setloadingTransactions] = useState(false);
  const [filteredWalletlist, setFilteredWalletlist] = React.useState<any[]>([]);
  const [walletValue, setWalletValue] = useState("");
  const [errorMessageWalletValue, setErrorMessageWalletValue] = useState("");
  const [currencyValue, setCurrencyValue] = useState("");
  const [headerValue, setHeaderValue] = useState("Amount");
  const [totalbalance, settotalbalance] = useState(0);
  const [show, setshow] = useState(false);
  const [showbalance, setBalance] = useState(false);
  const [showwallet, setWallet] = useState(true);
  const [walletid, setWalletid] = useState(0);
  const [errorMessageRechargeAmountValue, setErrorMessageRechargeAmountValue] =
    useState("");
  const toast = useRef<Toast>(null);
  const [walletList, setwalletList] = React.useState([]);
  const [BalanceList, setBalanceList] = React.useState([]);
  const [transactionHistoryModel, setTransactionHistoryModel] = React.useState(
    []
  );
  const [activeCard, setActiveCard] = useState(null);
  const [balanceloading, setBalanceLoading] = React.useState(false);
  const [depositModel, setDepositModel] = React.useState({
    id: 0,
    AccountId: 0,
    PartnerId: 0,
    Amount: 0.0,
    PaymentOption: 0,
    TransactionId: "",
    TransactionType: "",
    RecordType: 0,
    Notes: "",
  });

  const [acbalancemodel, setAcbalanceModel] = React.useState({
    AvailableBalance: 0.0,
    CurrentBalance: 0.0,
  });
  const setModelEmpty = () => {
    setAcbalanceModel({
      AvailableBalance: 0,
      CurrentBalance: 0,
    });
  };

  useEffect(() => {
    IsValidRoute(navigate);
    // Update the document title using the browser API
    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    setDepositModel({
      ...depositModel,
      PartnerId: Number(PartnerId),
    });
    getCurrenciesByPartnerId(PartnerId);
  }, []);

  const getAllTransactionsByPartner = (partnerid: any, accountId: any) => {

    PartnerDepositService.getDepositeTransactions(partnerid, accountId)
      .then((transcationdetails: any) => {
        setTransactionHistoryModel(transcationdetails.data);
        gettotalbalance(partnerid, accountId);
      setpartnerloading(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
      });
  };

  const gettotalbalance = (partnerid: any, accountId: any) => {
    setBalanceLoading(true);
    setpartnerloading(true);
    //  setModelEmpty();
    PartnerDepositService.getTotalBalance(partnerid, accountId)
      .then((totalbalancebypartnerid: any) => {
       
        setBalanceList(totalbalancebypartnerid.data);
       
        setBalanceLoading(false);
        setpartnerloading(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else if (error.response.status === 404) {
          toast.current?.show({
            severity: "error",
            summary: "Data Not Found",
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setBalanceLoading(false);
      });
  };

  const getCurrenciesByPartnerId = (partnerid: any) => {
    setLoading(true);
    CurrenciesService.getCurrenciesByPartnerId(partnerid)
      .then((currencies: any) => {
        setwalletList(currencies.data);
      
        {
          currencies.data?.map((wallet: any, index: number) => {
            gettotalbalance(wallet.partnerId, wallet.id);
          })
        }
       
        getAllTransactionsByPartner(partnerid, 0);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Something went wrong",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Error while getting partnership details.",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };

  const resetPage = () => {
    setTransactionHistoryModel([]);
    setloadingTransactions(false);
    setWalletValue("");
    setCurrencyValue("");
    setHeaderValue("Amount");
    setDepositModel({ ...depositModel, AccountId: 0 });
    setErrorMessageRechargeAmountValue("");
    setErrorMessageWalletValue("");
    setWalletid(0);
    setshow(false);
    setBalance(false);
    setWallet(true);
    getCurrenciesByPartnerId(PartnerId);
    setActiveCard(null)
  }

  const searchWallet = (event: any) => {
    let query = event.query;
    let _filteredItems: any = [];
    for (let i = 0; i < walletList.length; i++) {
      let item = walletList[i];

      if (item.code.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }
    setFilteredWalletlist(_filteredItems);
  };

  const onWalletChange = (e: any, index: any) => {
    setpartnerloading(true);
    const selectedCode = e.code;
    const transactionHistoryList = [...transactionHistoryModel];
    const newTransactionHistoryList = transactionHistoryList.filter(
      (transaction: any) => transaction.code === selectedCode
    );
    setTransactionHistoryModel(newTransactionHistoryList);
    setloadingTransactions(true);
    setWalletValue(e);
    setCurrencyValue(e.currency);
    setHeaderValue("Amount (" + e.currency + ")");
    setDepositModel({ ...depositModel, AccountId: e.id });
    getAllTransactionsByPartner(PartnerId, e.id);

    setErrorMessageRechargeAmountValue("");
    setErrorMessageWalletValue("");
    setWalletid(e.id);
    setshow(true);
    setBalance(true);
    setWallet(false);
    setActiveCard(index);
  };

  const formatDateField = (rowData: any) => {
    return (
      <React.Fragment>
        <span>{moment(rowData.date).format("MM/DD/YY H:mm:ss")} </span>
      </React.Fragment>
    );
  };

  return (
    <>
      {loading ? (
        <div className="spinner-class">
          <ProgressSpinner />
        </div>
      ) : (
        <div className="table-screen add-user-screen wrapper ledgermain">
          {/* <Toast ref={toast}></Toast> */}
          <div className="heading-section">
            <span className="text-header-purple">Wallet</span>
            <div className="refresh walletrefresh" style={{ marginRight: "45px" }}>
              <Button
                iconPos="left"
                icon="pi pi-refresh"
                label={"Refresh"}
                className="btn btn-continue"
                onClick={() => {
                  resetPage();
                }}
              ></Button>
            </div>
          </div>
          <div className=" ledger-screen">
            <div className="row walletrow">
              {walletList?.map((wallet: any, index: number) => {
                return (
                  <div className="col-lg-3 desktopcard "  >
                    <div key={index} className={`wallet ${activeCard === index ? 'wallet-card active' : 'wallet-card'}`} onClick={(e) => { onWalletChange(wallet, index) }} >
                      <div className="walletname">
                        <span className="text-blue-address">
                          {wallet.code}
                        </span>

                        <div className="availblebalance-div">
                          <span className="input-label">
                            Available Balance :{" "}
                            {wallet.availableBalance}
                          </span>
                          <span className="input-label">
                            {" "}
                            Current Balance : {wallet.currentBalance}
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>
                );
              })}

            </div>

            {partnerloading ? (
              <div className="spinner-class">
                <ProgressSpinner />
              </div>
            ) : (
              <>
                <div
                  className="datatable-doc-demo"
                  style={{ marginTop: "25px" }}
                >
                  {" "}
                  <span className="text-blue-address">Transactions</span>
                  <div className="ledger-table">
                    <DataTable
                      value={transactionHistoryModel}
                      paginator
                      className="p-datatable-customers"
                      rows={10}
                      readOnly={true}
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      rowsPerPageOptions={[10, 25, 50]}
                      dataKey="id"
                      rowHover
                      filterDisplay="menu"
                      responsiveLayout="scroll"
                      globalFilterFields={[
                        "name",
                        "country.name",
                        "representative.name",
                        "balance",
                        "status",
                      ]}
                      emptyMessage="No data found."
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    >
                      <Column
                        field="date"
                        header="Date Time"
                        body={formatDateField}
                      />
                      {showwallet ? (
                        <Column field="code" header="WalletId" />
                      ) : (
                        <></>
                      )}

                      <Column
                        field="amount"
                        header={headerValue}
                        bodyClassName={(rowData) =>
                          rowData.paymentOption.includes("Credit")
                            ? "text-green"
                            : "text-danger"
                        }
                      />

                      <Column field="paymentOption" header="Payment Option" />
                      <Column field="notes" header="Note" />
                      <Column field="currency" header="Currency"></Column>
                      <Column field="createdBy" header="Created By" />
                    </DataTable>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Ledger;
