
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../../assets/images/icon/close-icon.png";
import { FacilitatorService } from "../../../services/Partner/Facilitator/FacilitatorService";
import { Logout } from "../../../utils/AccountUtils";
import { ProgressSpinner } from "primereact/progressspinner";
import { validEmail } from "../../../utils/utils";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";

const AddFacilitator: React.FC = () => {
    const partnerId = localStorage.getItem("PartnerId");
    const [facilitatorList, setFacilitatorList] = React.useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [firstNameErrorMessage, setFirstNameErrorMessage] = React.useState("");
    const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState("");
    const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
    const [legalNameErrorMessage, setLegalNameErrorMessage] = React.useState("");
    const [facilatorpartnerpopup, setfacilatorpartnerpopup] = useState(false);
    const navigate = useNavigate();
    const toast = useRef<Toast>(null);
    const [displayBasic, setDisplayBasic] = useState(false);  
    const [activeStatus, setActiveStatus] = useState(null);
    const [changeStatus, setChangeStatus] = useState(false);
    const [inactivePartner, setInactivePartner] = useState(null);
    const [displaydeletepopup, setDisplayDeletePopup] = useState(false);
    


    useEffect(() => {
        const useroobj = localStorage.getItem("User");
        if (useroobj === null || useroobj === undefined) {
            navigate("/");
        }
        getAllPartners(partnerId);
    }, []);

    const getAllPartners = (val: any) => {
        setLoading(true);
        FacilitatorService.getFacilitatorPartners(val)
            .then((data) => {
                setFacilitatorList(data.data);
                setLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    toast.current?.show({
                        severity: "error",
                        summary: "Unauthorized",
                        life: 3000,
                    });
                    Logout(navigate);
                } else if (error.response.status === 400) {
                    toast.current?.show({
                        severity: "error",
                        summary: error.response.data[0].errorMessage,
                        life: 3000,
                    });
                } else {
                    toast.current?.show({
                        severity: "error",
                        summary: "Oops! Something went wrong.",
                        life: 3000,
                    });
                }
                setFacilitatorList([]);
                setLoading(false);
            });
    };

    const [FacilitatorPartnerModel, setFacilitatorPartnerModel] = React.useState({
        firstName: "",
        lastName: "",
        facilitatorId: Number(partnerId),
        email: "",
        legalName: "",
        setPasswordToken: ""
    });


    const [FacilitatorPartnerResponseModel, setFacilitatorPartnerResposeModel] = React.useState({
        PartnerId: 0,
        UserId: 0,
        BasicDetailId: 0
    });


    const setModelEmpty = () => {
        setFacilitatorPartnerModel({
            firstName: "",
            lastName: "",
            facilitatorId: Number(partnerId),
            email: "",
            legalName: "",
            setPasswordToken: ""
        });
    };


    const ShowHideDialog = () => {
        ErrorMessageEmptyModel();
        setModelEmpty();
        setDisplayBasic(true);
    };
    const onHideClick = () => {
        setModelEmpty();
        setDisplayBasic(false);
    };

    const ErrorMessageEmptyModel = () => {
        setFirstNameErrorMessage("");
        setLastNameErrorMessage("");
        setEmailErrorMessage("");
        setLegalNameErrorMessage("");
    };

    const CheckNull = (value: any) => {
        if (value === "" || value === undefined || value === null) {
            return true;
        }
        return false;
    };

    const actionBodyTemplate = (rowData: any) => {
        return (
          <>
            <span className="switch-style ">
              <InputSwitch
                className="status-check activeinput"
                name="Send"
                checked={Boolean(rowData.isActive)}
                onChange={(e) => InactivePartnerDetails(rowData, e)}
              />
            </span>
          </>
        );
      };

      const InactivePartnerDetails = (rowData: any, e: any) => {
        setActiveStatus(Boolean(rowData.isActive));
        setChangeStatus(e.target.value);
        setInactivePartner(rowData);
        setDisplayDeletePopup(true);
      };

      const reject = () => {
        setDisplayDeletePopup(false);
      };


      const onDeleteClickUser = () => {
        onDeleteClick(inactivePartner.id);
        setDisplayDeletePopup(false);
      };

      const onDeleteClick=(id:any) =>{
        setLoading(true);
        FacilitatorService.inactivePartnerID(id, changeStatus)
            .then((data) => {
                setFacilitatorList(data.data);
                getAllPartners(partnerId)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    toast.current?.show({
                        severity: "error",
                        summary: "Unauthorized",
                        life: 3000,
                    });
                    Logout(navigate);
                } else if (error.response.status === 400) {
                    toast.current?.show({
                        severity: "error",
                        summary: error.response.data[0].errorMessage,
                        life: 3000,
                    });
                } else {
                    toast.current?.show({
                        severity: "error",
                        summary: "Oops! Something went wrong.",
                        life: 3000,
                    });
                }
                setFacilitatorList([]);
                setLoading(false);
            });
      }

    const isValidate = (values: any) => {
        let formIsValid = true;
        ErrorMessageEmptyModel();
        if (CheckNull(values.firstName)) {
            setFirstNameErrorMessage("Please enter first name.");
            formIsValid = false;
        }
        if (CheckNull(values.lastName)) {
            setLastNameErrorMessage("Please enter last name.");
            formIsValid = false;
        }
        if (!validEmail.test(values.email)) {
            setEmailErrorMessage("Please enter valid email.");
            formIsValid = false;
        }
        if (CheckNull(values.email)) {
            setEmailErrorMessage("Please enter email.");
            formIsValid = false;
        }
        if (CheckNull(values.legalName)) {
            setLegalNameErrorMessage("Please enter legal name.");
            return false;
        }
        if (!CheckNull(values.legalName)) {
            if (values.legalName.trim().length === 0) {
                setLegalNameErrorMessage("Please enter legal name.");
                formIsValid = false;
            }
            if (values.legalName.length > 100) {
                setLegalNameErrorMessage("Please enter valid legal name.");
                formIsValid = false;
            }
        }



        return formIsValid;
    };

    const formatDateField = (rowData: any) => {
        return (
          <React.Fragment>
            <span>{moment(rowData.createdDate).format("MM/DD/YYYY H:mm.ss")} </span>
          </React.Fragment>
        );
      };

    const onAddOPartnerClick = () => {
        ErrorMessageEmptyModel();
        FacilitatorPartnerModel.facilitatorId = Number(partnerId);
        setButtonLoading(true);
        if (isValidate(FacilitatorPartnerModel)) {
            FacilitatorService.addFacilitatorPartner(FacilitatorPartnerModel)
                .then((data: any) => {
                    setModelEmpty();
                    setButtonLoading(false);
                    setFacilitatorPartnerResposeModel(data);
                    setfacilatorpartnerpopup(false);
                    onHideClick();
                    getAllPartners(partnerId);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        toast.current?.show({
                            severity: "error",
                            summary: "Unauthorized",
                            life: 3000,
                        });
                        Logout(navigate);
                    } else if (error.response.status === 400) {
                        toast.current?.show({
                            severity: "error",
                            summary: error.response.data[0].errorMessage,
                            life: 3000,
                        });
                    } else if (error.response.status === 409) {
                        setEmailErrorMessage("Business email already exist.")
                    } else if (error.response.status === 410) {
                        setLegalNameErrorMessage("Legal name already exist.")
                    }
                    else {
                        toast.current?.show({
                            severity: "error",
                            summary: "Oops! Something went wrong.",
                            life: 3000,
                        });
                    }
                    setButtonLoading(false);
                });
        } else {
            setButtonLoading(false);
        }
    };
    const onCancleClick = () => {
        setModelEmpty();
        ErrorMessageEmptyModel();
        setDisplayBasic(false);
    };

    return (
        <>
            {" "}
            <div className="AddFacilitator-div">
                <Toast ref={toast}></Toast>
                <ConfirmDialog id="confirm-popup" />
                <div className="facilitator-tabs">

                    <div className="button-section">
                        <Button
                            iconPos="left"
                            label="Add Partner"
                            icon="pi pi-plus"
                            className="btn btn-continue"
                            onClick={ShowHideDialog}
                        />

                    </div>
                </div>

                <div className="facilitator-main">
                    {loading ? (
                        <div className="spinner-class">
                            <ProgressSpinner />
                        </div>
                    ) : (
                        <div className="datatable-doc-demo">
                            <div className="">
                                <DataTable
                                    value={facilitatorList}
                                    paginator
                                    className="p-datatable-customers"
                                    rows={10}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    rowsPerPageOptions={[10, 25, 50]}
                                    dataKey="id"
                                    rowHover
                                    filterDisplay="menu"
                                    responsiveLayout="scroll"
                                    globalFilterFields={[
                                        "name",
                                        "country.name",
                                        "representative.name",
                                        "balance",
                                        "status",
                                    ]}
                                    emptyMessage="No partners found."
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                >
                                    <Column field="legalName" header="Partner" sortable />

                                    <Column field="userName" header="Email" />

                                    <Column field="createdDate"  body={formatDateField} header="Invited Date" sortable />

                                    <Column field="createdBy" header="Invited By" sortable />

                                    <Column field="onboardingStatus" header="Onboarding Status" sortable />

                                    <Column
                          field="isActive"
                          header="Action"
                          body={actionBodyTemplate}
                          style={{ width: "25%" }}
                        />

                                </DataTable>
                            </div>
                        </div>
                    )}
                </div>
            </div>{" "}

            <Dialog
                header="Header"
                visible={displayBasic}
                onHide={() => onHideClick()}
                className="user-dialog"
            >
                <div className="FFpopup">
                    <button className="close-btn" onClick={onHideClick}>
                        <img src={CloseIcon} />
                    </button>

                    <div className="row user-row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <span>
                                    First Name <span className="color-red">*</span>
                                </span>
                                <input
                                    type="text"
                                    name="firstName"
                                    className="form-control"
                                    value={FacilitatorPartnerModel.firstName}
                                    onChange={(e) =>
                                        setFacilitatorPartnerModel({
                                            ...FacilitatorPartnerModel,
                                            firstName: e.target.value,
                                        })}
                                    placeholder="Enter first name"
                                />
                                {firstNameErrorMessage !== null &&
                                    firstNameErrorMessage.length > 0 ? (
                                    <span className="error-msg">{firstNameErrorMessage}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <span>
                                    Last Name <span className="color-red">*</span>
                                </span>
                                <input
                                    type="text"
                                    name="lastName"
                                    className="form-control"
                                    value={FacilitatorPartnerModel.lastName}
                                    onChange={(e) =>
                                        setFacilitatorPartnerModel({
                                            ...FacilitatorPartnerModel,
                                            lastName: e.target.value,
                                        })}
                                    placeholder="Enter last name"
                                />
                                {lastNameErrorMessage !== null &&
                                    lastNameErrorMessage.length > 0 ? (
                                    <span className="error-msg">{lastNameErrorMessage}</span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="row user-row last-row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <span>
                                    Business Email <span className="color-red">*</span>
                                </span>
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    value={FacilitatorPartnerModel.email}
                                    onChange={(e) =>
                                        setFacilitatorPartnerModel({
                                            ...FacilitatorPartnerModel,
                                            email: e.target.value,
                                        })}
                                    placeholder="Enter business email"
                                />
                                {emailErrorMessage !== null &&
                                    emailErrorMessage.length > 0 ? (
                                    <span className="error-msg">{emailErrorMessage}</span>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <span>
                                    Legal Name <span className="color-red">*</span>
                                </span>
                                <input
                                    type="legalName"
                                    className="form-control"
                                    value={FacilitatorPartnerModel.legalName}
                                    onChange={(e) =>
                                        setFacilitatorPartnerModel({
                                            ...FacilitatorPartnerModel,
                                            legalName: e.target.value,
                                        })}
                                    placeholder="Enter legal name"
                                />
                                {legalNameErrorMessage !== null &&
                                    legalNameErrorMessage.length > 0 ? (
                                    <span className="error-msg">{legalNameErrorMessage}</span>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="payment-btn-dialog">
                        <button
                            type="button"
                            onClick={onCancleClick}
                            className="btn btn-cancel second-btn"
                        >
                            Cancel
                        </button>
                        <Button
                            iconPos="left"

                            label={"Save"}
                            className="btn btn-continue second-btn"
                            loading={buttonLoading}
                            onClick={onAddOPartnerClick}
                        />
                    </div>
                </div>
            </Dialog>


        {displaydeletepopup ? (
        <div className="popup-body">
          <div className="regiter-popup">
            <div className="text-center ">
              <div className="awesome-text">
                {activeStatus ? (
                  <h4>
                    {" "}
                    <i className="pi pi-info-circle"></i> Are you sure you want
                    to inactive partner?
                  </h4>
                ) : (
                  <h4>
                    {" "}
                    <i className="pi pi-info-circle"></i> Are you sure you want
                    to active partner?
                  </h4>
                )}
              </div>
            </div>
            <div className="payment-screen-btn">
              <button className="btn btn-cancel second-btn " onClick={reject}>
                {" "}
                No
              </button>
              <button
                className="btn btn-continue second-btn yes-btn-popup"
                onClick={onDeleteClickUser}
              >
                {" "}
                Yes
              </button>
            </div>
          </div>
        </div>
         ) : null}
        </>
    );
};

export default AddFacilitator;
