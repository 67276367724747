import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router";
import IRLOGO from "../../../assets/images/icon/logo-icon.png";
import passImg from "../../../assets/images/pass-img.svg";
import { ISetPassword } from "../../../models/ISetPassword";
import { LoginService } from "../../../services/Account/LoginService";
import { SetPasswordService } from "../../../services/Account/SetPasswordService";
import { Logindata } from "../../../utils/AccountUtils";
import { ProgressSpinner } from "primereact/progressspinner";
import LocalStorageContext from "../../Context/LocalStorageContext";

interface IState {
  user: ISetPassword;
}


const ResetPassword: React.FC = () => {
  const { token } = useParams();
  const navigate = useNavigate();


  const context = useContext(LocalStorageContext);
  const [state, setState] = useState<IState>({
    user: {
      setPasswordToken: token,
      newPassword: "",
      confirmPassword: "",
      isActive: false,
      userName: "",
      firstName: "",
      lastName: "",
    },
  });
  const [loginstate, setLoginState] = useState({
    userName: state.user.userName,
    password: "",
  });

  const toTitleCase = (phrase: string) => {
    return phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const [isUserValid, setIsUserValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingtoken, setLoadingToken] = useState(false);
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState("");
  const [passwordmatchErrorMessage, setpasswordmatchPasswordErrorMessage] =
    useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmpasswordType, setConfirmPasswordType] = useState("password");
  //   handle change function

  const ErrorMessageEmptyModel = () => {
    setNewPasswordErrorMessage("");
    setConfirmPasswordErrorMessage("");
    setpasswordmatchPasswordErrorMessage("");
  };

  const CheckNull = (value: any) => {
    if (value === "" || value === undefined || value === null) {
      return true;
    }
    return false;
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  const toggleConfirmPassword = () => {
    if (confirmpasswordType === "password") {
      setConfirmPasswordType("text")
      return;
    }
    setConfirmPasswordType("password")
  }
  const isValidate = (values: any) => {
    let formIsValid = true;
    ErrorMessageEmptyModel();
    if (CheckNull(values.newPassword)) {
      setNewPasswordErrorMessage("Please enter new password");
      formIsValid = false;
    }

    if (!CheckNull(values.newPassword)) {
      if (values.newPassword.trim().length === 0) {
        setNewPasswordErrorMessage(
          "Empty password not allowed."
        );
        formIsValid = false;
      }
    }
    const regix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    if (regix.test(values.newPassword) === false) {
      setNewPasswordErrorMessage("8 characters,1 special character,1 capital letter,1 lowercase letter,1 number");
      formIsValid = false;
    }
    if (CheckNull(values.confirmPassword)) {
      setConfirmPasswordErrorMessage("Please enter confirm password");
      formIsValid = false;
    }
    if (!CheckNull(values.confirmPassword)) {
      if (values.confirmPassword.trim().length === 0) {
        setConfirmPasswordErrorMessage(
          "Empty password not allowed."
        );
        formIsValid = false;
      }
      if (values.newPassword !== values.confirmPassword) {
        setConfirmPasswordErrorMessage("Confirm password does not match");
        formIsValid = false;
      }
    }

    return formIsValid;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({
      user: {
        ...state.user,
        [event.target.name]: event.target.value,
      },
    });
    setLoginState({
      ...loginstate,
      password: state.user.newPassword,
      userName: state.user.userName,
    });
  };

  // form handling
  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoading(true);
    if (isValidate(state.user)) {
      SetPasswordService.resetUserPassword(state.user)
        .then((data) => {
          LoginService.login(loginstate)
            .then((response: any) => {
              Logindata(response, navigate);
              context.updateLogoSrc(response.data.logoURL);
              context.updateStatus(response.data.onboardingStatus);
              context.updateStepFlag(response.data.stepFlag);
              context.updateFacilitator(response.data.isfacilitator);
              context.updateRole(response.data.partnerRole);
              setLoading(false);

            })
            .catch((error) => {
              setLoading(false);
            });
        })
        .catch((error) => {
          setLoading(false);

        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser(token!);
    localStorage.clear();
  }, []);

  const getUser = (token: string) => {
    setLoadingToken(true);
    SetPasswordService.getUserByForgotPasswordToken(token)
      .then((response: any) => {
        setState({
          user: {
            ...state.user,
            isActive: response.data.isActive,
            userName: response.data.userName,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
          },
        });
        setLoadingToken(false);
        setIsUserValid(true);
      })
      .catch((error) => {
        console.log(error);
        setLoadingToken(false);
        if (error.response.status == 400) {
          setIsUserValid(false);
        }
      });
  };
  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };
  return (
    <>
      
       
            <div className="Sigin-page bg-height">
            {
        loadingtoken ? (
          <div className="spinner-class white-spinner">
            <ProgressSpinner />
          </div>
        ) : (
              <div className="SetPassword-form">
                <div className="login-page">
                  <img src={IRLOGO} alt="img" className="logo-white" />
                </div>
                {isUserValid == false ? (
                  <div className="link-expired-message">
                    <p className="text-white-pass link-expire">
                      This link is expired or invalid, please contact to service
                      provider.
                    </p>
                  </div>
                ) : (
                  <div className="signin-form-display">
                    <div className="login-form-display">
                      <div className="text-center">
                        <h2>
                          Hello {toTitleCase(state.user.firstName)}{" "}
                          {toTitleCase(state.user.lastName)}!
                        </h2>

                        <h4>Please reset your password.</h4>

                        <h4 className="second-title">
                          Access the instant payment network
                        </h4>
                      </div>

                      <div className="username-form">
                        <form >
                          <div className="form-group login-form-input ">
                            <p>
                              {" "}
                              <img
                                src={passImg}
                                alt="img"
                                style={{ width: "14px" }}
                              />
                              New Password
                            </p>
                            <div className="password-input-icon">
                              <input
                                type={passwordType}
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="New password"
                                name="newPassword"
                                onChange={handleChange}
                                value={state.user.newPassword}
                                onKeyPress={handleKeypress}
                              />
                              <span className="eyeicon">
                                {passwordType === "password" ? <i className="pi pi-eye-slash" onClick={togglePassword}></i> : <i className="pi pi-eye" onClick={togglePassword}></i>}
                              </span>
                              {newPasswordErrorMessage !== null &&
                                newPasswordErrorMessage.length > 0 ? (
                                <span className=" login-error-msg-password">
                                  {newPasswordErrorMessage}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group login-form-input confirm-pass">
                            <p>
                              {" "}
                              <img
                                src={passImg}
                                alt="img"
                                style={{ width: "14px" }}
                              />
                              Confirm Password
                            </p>
                            <div className="password-input-icon">
                              <input
                                type={confirmpasswordType}
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="Confirm password"
                                name="confirmPassword"
                                value={state.user.confirmPassword}
                                onChange={handleChange}
                                onKeyPress={handleKeypress}
                              />    <span className="eyeicon">
                                {confirmpasswordType === "password" ? <i className="pi pi-eye-slash" onClick={toggleConfirmPassword}></i> : <i className="pi pi-eye" onClick={toggleConfirmPassword}></i>}
                              </span>

                              {confirmPasswordErrorMessage !== null &&
                                confirmPasswordErrorMessage.length > 0 ? (
                                <span className="login-error-msg">
                                  {confirmPasswordErrorMessage}
                                </span>
                              ) : null}
                              {passwordmatchErrorMessage !== null &&
                                passwordmatchErrorMessage.length > 0 ? (
                                <span className="login-error-msg">
                                  {passwordmatchErrorMessage}
                                </span>
                              ) : null}

                            </div>
                          </div>


                          <Button
                            label="Reset Password"
                            className="btn btn-login save-btn reset-btn"
                            loading={loading}
                            onClick={handleSubmit}
                          />

                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              
        )
      }

            </div>
         

    </>
  );
};

export default ResetPassword;
