import React, { useEffect, useRef, useState } from "react";

import Header from "../../../Layout/Header";
import Sidebar from "../../../Layout/Sidebar";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Logout } from "../../../utils/AccountUtils";
import { useNavigate } from "react-router-dom";
import { PaymentService } from "../../../services/Payment/PaymentService";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import Scrollbars from "react-custom-scrollbars-2";
import moment from "moment";

const SubmitPayment: React.FC = () => {
  const paymentDataModel = localStorage.getItem("PaymentModel");

  const [paymentModel, setPaymentModel] = useState(
    JSON.parse(paymentDataModel)
  );

  const [paymentId, setPaymentId] = useState("");

  const id = localStorage.getItem("PartnerId");

  const [partnerId, setPartnerId] = useState(id);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [buttonloading, setButtonLoading] = useState(false);

  const [show, setShow] = useState(false);

  const [paymentloglist, setPaymentLogList] = useState<any[]>([]);

  const toast = useRef<Toast>(null);

  const [valuesArray, SetvaluesArray] = useState<any[]>([]);

  const [readyPopup, setReadyPopup] = useState(false);

  let intervalId: number;
  //get log by partner id
  const getLogs = (paymentid: any) => {
    setButtonLoading(true);

    PaymentService.getPaymentLogs(paymentid)
      .then((response) => {
        setPaymentLogList(response.data);

        PaymentService.getPaymentStatus(paymentid)
          .then((response) => {
            if (response.data != 0) {
              clearInterval(intervalId);
              setLoading(false);
              setButtonLoading(false);
              return;
            }
          })
          .catch((error) => {
            if (error.response.status === 500) {
              toast.current?.show({
                severity: "error",
                summary: "Oops, Something went wrong!",
                life: 3000,
              });
            }
             else if (error.response.status === 401) {
              toast.current?.show({
                severity: "error",
                summary: "Unauthorized",
                life: 3000,
              });
              clearInterval(intervalId);
              setLoading(false);
              setButtonLoading(false);
              Logout(navigate);
            }
            else {
              toast.current?.show({
                severity: "error",
                summary: "Oops, Something went wrong!",
                life: 3000,
              });
            }
            setLoading(false);
            setButtonLoading(false);
          });
        setLoading(false);
        setButtonLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Oops, Something went wrong!",
            life: 3000,
          });
        }
        else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          clearInterval(intervalId);
          setLoading(false);
          setButtonLoading(false);
          Logout(navigate);
        }
        else {
          toast.current?.show({
            severity: "error",
            summary: "Oops, Something went wrong!",
            life: 3000,
          });
        }
        setLoading(false);
        setButtonLoading(false);
      });
  };

  // handle save button click
  const onAddClick = () => {
    setPaymentId("");
    setLoading(true);
    PaymentService.getPayment(Number(partnerId), paymentModel)
      .then((response: any) => {
        setPaymentId(response.data);
        intervalId = window.setInterval(function () {
          getLogs(response.data);
        }, 2000);
        localStorage.removeItem("PaymentModel");
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.current?.show({
            severity: "error",
            summary: "Oops, Something went wrong!",
            life: 3000,
          });
        } else if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data.errors,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops!, Something went wrong.",
            life: 3000,
          });
        }
        setLoading(false);
      });
  };

  // handle referesh click
  const onRefreshClick = () => {
    getLogs(paymentId);
  };

  // date handle
  const formatDateField = (rowData: any) => {
    return (
      <React.Fragment>
        <span>{moment(rowData.date).format("MM/DD/YY H:mm:ss")} </span>
      </React.Fragment>
    );
  };

  // on show hide
  const OnShowHideClick = () => {
    if (show === true) {
      setShow(false);
    } else if (show === false) {
      setShow(true);
    }
  };

  const actionBodyTemplate = (rowData: any) => {
    if (!(rowData.stackTrace === "" || rowData.stackTrace === null)) {
      return (
        <>
          <Button
            type="button"
            className="btn btn-details  "
            onClick={() => ShowDetails(rowData)}
          >
            {" "}
            Details{" "}
          </Button>
        </>
      );
    }
  };

  const ShowDetails = (rowData: any) => {
    SetvaluesArray(JSON.parse(rowData.stackTrace));

    setReadyPopup(true);
  };

  const cancelPopup = () => {
    setReadyPopup(false);
  };

  useEffect(() => {
    if (paymentDataModel === null) {
      navigate("/createpayment");
    } else {
      const useroobj = localStorage.getItem("User");
      if (useroobj === null || useroobj === undefined) {
        Logout(navigate);
      }

      localStorage.removeItem("PaymentModel");

      return () => {
        clearInterval(intervalId);
        setButtonLoading(false);
        // return;
      };
    }
   
  }, []);
  return (
    <>
      {" "}
      <div>
        <Toast ref={toast}></Toast>

        <Scrollbars
          className="creatpayment-scroll"
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={100}
          autoHeightMax={100}
          thumbMinSize={30}
          universal={true}
        >
          <div className="text-center">
            <>
              {" "}
              <div className="heading-section">
                <span className="text-header-purple">Submit Payment</span>
              </div>
              <div className="container-fluid acc-screen createpayment-screen SubmitpaymentScreen">
                <div className="transaction-screen create-payment-table">
                  <div className="transaction-details">
                    <h2>Payment Information</h2>
                    <div className="d-flex">
                      <div className="detials-col">
                        <h4>Sending From:</h4>
                        <p>
                          {" "}
                          {
                            paymentModel?.paymentDataModel[0].paymentInformation
                              .sendCurrency
                          }
                        </p>
                      </div>
                      <div className="detials-col">
                        <h4>Sending To:</h4>
                        <p>
                          {
                            paymentModel?.paymentDataModel[0].paymentInformation
                              .receiveCurrency
                          }
                        </p>
                      </div>
                      <div className="detials-col">
                        <h4>Transaction Type :</h4>
                        <p>
                          {
                            paymentModel?.paymentDataModel[0].paymentInformation
                              .transactionType
                          }
                        </p>
                      </div>
                      <div className="detials-col">
                        <h4>Payment Type:</h4>
                        <p>
                          {" "}
                          {
                            paymentModel?.paymentDataModel[0].paymentInformation
                              .paymentType
                          }
                        </p>
                      </div>
                      <div className="detials-col">
                        <h4>Routing Preference:</h4>
                        <p>
                          {" "}
                          {
                            paymentModel?.paymentDataModel[0].paymentInformation
                              .routingPreferences.preferredType
                          }
                        </p>
                      </div>
                    </div>
                    <div className="d-flex">
                      {paymentModel?.paymentDataModel[0].paymentInformation
                        .routingPreferences.preferredType ===
                        "PreferredPaymentProvider" ||
                      paymentModel?.paymentDataModel[0].paymentInformation
                        .routingPreferences.preferredType ===
                        "PreferredQuoteID" ? (
                        <div className="detials-col">
                          <h4>Preferred Value :</h4>
                          <p>
                            {" "}
                            {
                              paymentModel?.paymentDataModel[0]
                                .paymentInformation.routingPreferences
                                .preferredValue
                            }
                          </p>
                        </div>
                      ) : paymentModel?.paymentDataModel[0].paymentInformation
                          .routingPreferences.preferredType ===
                        "SmartPreferredDelivery" ? (
                        <div className="detials-col">
                          <h4> Preferred Delivery Type:</h4>
                          <p>
                            {" "}
                            {
                              paymentModel?.paymentDataModel[0]
                                .paymentInformation.routingPreferences
                                .preferredValue
                            }
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}

{
                          (paymentModel?.paymentDataModel[0].paymentInformation
                            .sendAmount===0||paymentModel?.paymentDataModel[0].paymentInformation.sendAmount===null) ?  <div className="detials-col">
                          <h4>Receive Amount :</h4>
                          <p>
                            {paymentModel?.paymentDataModel[0].paymentInformation
                              .receiveAmount}
                          </p>
                        </div>: <div className="detials-col">
                          <h4>Send Amount :</h4>
                          <p>
                            {paymentModel?.paymentDataModel[0].paymentInformation
                              .sendAmount}
                          </p>
                        </div>
                        }
                      {/* <div className="detials-col">
                        <h4>Send Amount :</h4>
                        <p>
                          {
                            paymentModel?.paymentDataModel[0].paymentInformation
                              .sendAmount
                          }
                        </p>
                      </div> */}
                      <div className="detials-col">
                        <h4>Source Of Fund :</h4>
                        <p>
                          {" "}
                          {
                            paymentModel?.paymentDataModel[0].paymentInformation
                              .sourceOfFunds
                          }
                        </p>
                      </div>
                      <div className="detials-col">
                        <h4>Purpose Of Transaction:</h4>
                        <p>
                          {" "}
                          {
                            paymentModel?.paymentDataModel[0].paymentInformation
                              .purposeOfTransaction
                          }
                        </p>
                      </div>
                      <div className="detials-col">
                        <h4>Relationship:</h4>
                        <p>
                          {" "}
                          {
                            paymentModel?.paymentDataModel[0].paymentInformation
                              .relationship
                          }
                        </p>
                      </div>
                    </div>

                    {show ? (
                      <>
                        <div className="d-flex">
                          <div className="detials-col">
                            <h4>Payment Notes:</h4>
                            <p>
                              {" "}
                              {
                                paymentModel?.paymentDataModel[0]
                                  .paymentInformation.paymentNotes
                              }
                            </p>
                          </div>
                        </div>
                        <h2>Sender Information</h2>
                        <div className="d-flex">
                          {paymentModel?.paymentDataModel[0].paymentInformation
                            .paymentType === "C2C" ||
                          paymentModel?.paymentDataModel[0].paymentInformation
                            .paymentType === "C2B" ? (
                            <>
                              <div className="detials-col">
                                <h4>First Name:</h4>
                                <p>
                                  {
                                    paymentModel?.paymentDataModel[0]
                                      .senderInformation.firstName
                                  }
                                </p>
                              </div>

                              <div className="detials-col">
                                <h4> Last Name :</h4>
                                <p>
                                  {
                                    paymentModel?.paymentDataModel[0]
                                      .senderInformation.lastName
                                  }
                                </p>
                              </div>
                              <div className="detials-col">
                                <h4>Country :</h4>
                                <p>
                                  {
                                    paymentModel?.paymentDataModel[0]
                                      .senderInformation.country
                                  }
                                </p>
                              </div>
                              <div className="detials-col">
                                <h4>Phone Number :</h4>
                                <p>
                                  {
                                    paymentModel?.paymentDataModel[0]
                                      .senderInformation.phoneNumber
                                  }
                                </p>
                              </div>
                              <div className="detials-col">
                                <h4> Email Address :</h4>
                                <p>
                                  {
                                    paymentModel?.paymentDataModel[0]
                                      .senderInformation.emailAddress
                                  }
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              {paymentModel?.paymentDataModel[0]
                                .paymentInformation.paymentType === "B2B" ||
                              paymentModel?.paymentDataModel[0]
                                .paymentInformation.paymentType === "B2C" ? (
                                <>
                                  <div className="detials-col">
                                    <h4> Business Name :</h4>
                                    <p>
                                      {
                                        paymentModel?.paymentDataModel[0]
                                          .senderInformation.businessName
                                      }
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </div>
                        <h2>Recipient Information</h2>

                        {paymentModel?.paymentDataModel[0].paymentInformation
                          .paymentType === "C2C" ||
                        paymentModel?.paymentDataModel[0].paymentInformation
                          .paymentType === "B2C" ? (
                          <>
                            <div className="d-flex">
                              <div className="detials-col">
                                <h4>First Name:</h4>
                                <p>
                                  {
                                    paymentModel?.paymentDataModel[0]
                                      .receiverInformation.firstName
                                  }
                                </p>
                              </div>
                              <div className="detials-col">
                                <h4>Last Name :</h4>
                                <p>
                                  {
                                    paymentModel?.paymentDataModel[0]
                                      .receiverInformation.lastName
                                  }
                                </p>
                              </div>

                              <div className="detials-col">
                                <h4>Phone Number :</h4>
                                <p>
                                  {
                                    paymentModel?.paymentDataModel[0]
                                      .receiverInformation.phoneNumber
                                  }
                                </p>
                              </div>
                              <div className="detials-col">
                                <h4>Email Address :</h4>
                                <p>
                                  {
                                    paymentModel?.paymentDataModel[0]
                                      .receiverInformation.emailAddress
                                  }
                                </p>
                              </div>
                              <div className="detials-col">
                                <h4>Date of Birth :</h4>
                                <p>
                                  {moment(
                                    paymentModel?.paymentDataModel[0]
                                      .receiverInformation.dateOfBirth
                                  ).format("MM/DD/YYYY")}
                                </p>
                              </div>
                            </div>
                            {/* <div className="d-flex">
                              <div className="detials-col">
                                <h4>RTPID :</h4>
                                <p>
                                  {
                                    paymentModel?.paymentDataModel[0]
                                      .receiverInformation.rtpid
                                  }
                                </p>
                              </div>

                              <div className="detials-col">
                                <h4> RTP Name :</h4>
                                <p>
                                  {
                                    paymentModel?.paymentDataModel[0]
                                      .receiverInformation.rtpName
                                  }
                                </p>
                              </div>
                            </div> */}
                          </>
                        ) : (
                          <>
                            {paymentModel?.paymentDataModel[0]
                              .paymentInformation.paymentType === "C2B" ||
                            paymentModel?.paymentDataModel[0].paymentInformation
                              .paymentType === "B2B" ? (
                              <>
                                <div className="d-flex">
                                  <div className="detials-col">
                                    <h4> Business Name </h4>
                                    <p>
                                      {
                                        paymentModel?.paymentDataModel[0]
                                          .receiverInformation.businessName
                                      }
                                    </p>
                                  </div>

                                  <div className="detials-col">
                                    <h4> Business ID </h4>
                                    <p>
                                      {
                                        paymentModel?.paymentDataModel[0]
                                          .receiverInformation.businessID
                                      }
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="show-more">
                      <button onClick={OnShowHideClick}>
                        {show === true ? "Show-less" : "Show-more"}
                        {show === true ? (
                          <i className="pi pi-angle-up"> </i>
                        ) : (
                          <i className="pi pi-angle-down"> </i>
                        )}{" "}
                      </button>
                    </div>
                  </div>

                 
                  <div className="button-section">
                    <div className="bottom-btns">
                      {paymentId === "" ||
                      paymentId === undefined ||
                      paymentId === null ? (
                        <Button
                          iconPos="left"
                          loading={loading}
                          label="Submit Payment"
                          className="btn btn-continue second-btn"
                          onClick={onAddClick}
                        />
                      ) : (
                        <Button
                          iconPos="left"
                          icon="pi pi-refresh"
                          loading={buttonloading}
                          label="Refresh"
                          className="btn btn-continue second-btn"
                          onClick={onRefreshClick}
                        />
                      )}
                    </div>
                  </div>
                  <div className="datatable-doc-demo">
                    <div className="">
                      <DataTable
                        value={paymentloglist}
                        paginator
                        className="p-datatable-customers"
                        rows={50}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}
                        dataKey="id"
                        rowHover
                        filterDisplay="menu"
                        responsiveLayout="scroll"
                        globalFilterFields={[
                          "name",
                          "country.name",
                          "representative.name",
                          "balance",
                          "status",
                        ]}
                        emptyMessage="No logs found."
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                      >
                        <Column
                          field="date"
                          header="DateTime"
                          body={formatDateField}

                          
                          style={{ width: "10%" }}
                        />
                        <Column field="logMessage" header="Message" />

                        <Column
                          className="message-id transaction-link "
                          field="paymentId"
                          body={actionBodyTemplate}
                          header="Details"
                          style={{ width: "10%" }}
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </Scrollbars>

        {readyPopup ? (
          <div className="popup-body">
            <div
              className="regiter-popup confirm-popup "
              id="confirm-popup"
              style={{ width: 800 }}
            >
              <div className="popup-text">
                <div className="Details-Table" style={{ width: 800 }}>
                  <DataTable
                    value={valuesArray}
                    paginator
                    className="transactionDetails-table"
                    rows={10}
                    dataKey="id"
                    filterDisplay="menu"
                    loading={loading}
                    responsiveLayout="scroll"
                    cellSelection
                    emptyMessage="No records found  found."
                  >
                    <Column
                      className="message-id"
                      field="StatusCode"
                      header="Status Code "
                    />
                    <Column
                      className="message-id"
                      field="Message"
                      header="Message "
                    />
                  </DataTable>
                </div>
              </div>

              <div className="popup-btn">
                <button
                  type="button"
                  onClick={cancelPopup}
                  className="btn btn-continue second-btn"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SubmitPayment;
