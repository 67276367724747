import React, { useState, useEffect, useRef } from "react";
import { IsValidRoute } from "../../../utils/AccountUtils";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ReportsService } from "../../../services/Reports/Reports";
import { Toast } from "primereact/toast";
import { Logout } from "../../../utils/AccountUtils";
import { dateFormat } from "../../../utils/utils";
import Scrollbars from "react-custom-scrollbars-2";

const Reports: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [exportReport, setExportReport] = useState(false);

  const [partnerID, setPartnerId] = useState(localStorage.getItem("PartnerId"));

  const [startDate, setStartDate]: any = useState(
    new Date(new Date().setDate(new Date().getDate() - 29))
  );
  const [endDate, setEndDate]: any = useState(new Date());
  const maxDate: Date = new Date();

  const [endDateErrorMessage, setEndDateErrorMessage] = React.useState("");

  const [startDateErrorMessage, setStartDateErrorMessage] = React.useState("");

  const [pageloading, setPageLoading] = useState(true)
  const dt = useRef(null);
  const [reportData, setReportData] = useState<any[]>([]);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    IsValidRoute(navigate);
    const useroobj = localStorage.getItem("User");
    if (useroobj === null || useroobj === undefined) {
      Logout(navigate);
    }
    onSubmitClick();
  }, []);

  const getReportData = () => {
    if (isValidate()) {
      setLoading(true);
      ReportsService.getReportsDetails(
        dateFormat(startDate.setHours(0, 0, 0)),
        dateFormat(endDate.setHours(23, 59, 59)),
        partnerID
      )
        .then((data) => {
          setReportData(data.data);

          if (!(data.data.length === 0)) {
            setExportReport(true);
          }
          setLoading(false);
          setPageLoading(false)
        })
        .catch((error) => {
          if (error.response.status === 401) {
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              life: 3000,
            });
            Logout(navigate);
          } else if (error.response.status === 400) {
            toast.current?.show({
              severity: "error",
              summary: error.response.data[0].errorMessage,
              life: 3000,
            });
          } else {
            toast.current?.show({
              severity: "error",
              summary: "Oops! Something went wrong.",
              life: 3000,
            });
          }
          setReportData([]);
          setLoading(false);
          setPageLoading(false)
        });
    }
  };

  const onSubmitClick = () => {

    setReportData(null);
    setExportReport(false);
    getReportData();
  };

  const downloadCSVFile = () => {
    setLoading(true);
    ReportsService.downloadReportsDetails(
      dateFormat(startDate.setHours(0, 0, 0)),
      dateFormat(endDate.setHours(23, 59, 59)),
      partnerID
    )
      .then((response) => {
        setLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "IR_Reports.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.current?.show({
            severity: "error",
            summary: "Unauthorized",
            life: 3000,
          });
          Logout(navigate);
        } else if (error.response.status === 400) {
          toast.current?.show({
            severity: "error",
            summary: error.response.data[0].errorMessage,
            life: 3000,
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Oops! Something went wrong.",
            life: 3000,
          });
        }
        setReportData([]);
        setLoading(false);
      });
  };

  const isValidate = () => {
    let validData = true;
    setStartDateErrorMessage(null);
    setEndDateErrorMessage(null);

    if (checkNull(startDate)) {
      setStartDateErrorMessage("Please enter start date.");
      validData = false;
    }

    if (checkNull(endDate)) {
      setEndDateErrorMessage("Please enter end date.");
      validData = false;
    }

    if (moment(endDate).diff(moment(startDate), "days") < 0) {
      setEndDateErrorMessage("End Date should be greater than start date.");
      validData = false;
    }

    return validData;
  };

  const checkNull = (value: any) => {
    if (value === "" || value === undefined || value === null) {
      return true;
    }
    return false;
  };

  return (
    <>
  {pageloading ? (
              <div className="spinner-class">
                <ProgressSpinner />
              </div>
            ) : (
              <div className=" right-tab-section transaction-tab ">
              <Scrollbars
                className="contain-scroll"
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                autoHeight
                autoHeightMin={100}
                autoHeightMax={100}
                thumbMinSize={30}
                universal={true}
              >
                <div className="heading-section">
                  <span className="text-header-purple">Reports</span>
                </div>
                <div className="container-fluid acc-screen info-section reportpage">
                  <div className="row">
                    <div className=" col-md-4 form-group ">
                      <span className="input-label">
                        Start Date<span className="color-red"></span>
                      </span>
                      <div className="calendar-style">
                        <Calendar
                          id="icon"
                          showIcon
                          className="calendar-style"
                          placeholder="mm/dd/yyyy"
                          dateFormat="mm/dd/yy"
                          name="startDate"
                          maxDate={endDate}
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(e.target.value);
                            setStartDateErrorMessage(null);
                            setExportReport(false);
                          }}
                        />
                        {startDateErrorMessage !== null &&
                        startDateErrorMessage.length > 0 ? (
                          <span className="error-msg report-error">
                            {startDateErrorMessage}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className=" col-md-4 form-group ">
                      <span className="input-label">
                        End Date<span className="color-red"></span>
                      </span>
                      <div className="calendar-style">
                        <Calendar
                          id="icon"
                          showIcon
                          className="calendar-style"
                          placeholder="mm/dd/yyyy"
                          dateFormat="mm/dd/yy"
                          name="endDate"
                          maxDate={maxDate}
                          value={endDate}
                          onChange={(e) => {
                            setEndDate(e.target.value);
                            setEndDateErrorMessage(null);
                            setExportReport(false);
                          }}
                        />
                        {endDateErrorMessage !== null &&
                        endDateErrorMessage.length > 0 ? (
                          <span className="error-msg report-error">
                            {endDateErrorMessage}
                          </span>
                        ) : null}
                      </div>
                    </div>
      
                    <div className=" col-md-2 " style={{ marginTop: "6px" }}>
                      <Button
                        id="downloadBtn"
                        className="btn btn-continue second-btn"
                        label={"Submit"}
                        onClick={() => onSubmitClick()}
                      />
                    </div>
                    <div className=" col-md-2 " style={{ marginTop: "6px" }}>
                      {exportReport ? (
                        <Button
                          id="downloadBtn"
                          className="btn btn-continue second-btn"
                          label={"Export"}
                          onClick={() => downloadCSVFile()}
                        />
                      ) : null}
                    </div>
                  </div>
      
                  {loading ? (
                    <div className="spinner-class">
                      <ProgressSpinner />
                    </div>
                  ) : (
                    <div className="datatable-doc-demo">
                      <div className="">
                        <DataTable
                          ref={dt}
                          value={reportData}
                          paginator
                          className="transactionDetails-table"
                          rows={50}
                          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                          rowsPerPageOptions={[50, 50, 50]}
                          dataKey="id"
                          loading={loading}
                          rowHover
                          filterDisplay="menu"
                          emptyMessage="No record found."
                          responsiveLayout="scroll"
                          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                          sortField="creationDate"
                          sortOrder={-1}
                        >
                          <Column
                            className="small-col"
                            field="paymentID"
                            header="Payment ID"
                            sortable
                          />
      
                          <Column field="sendAmount" header="Send Amount" sortable />                 
      
                          <Column
                            className="small-col"
                            field="sendCurrency"
                            header="Send Currency"
                            sortable
                          />
      
                            <Column
                            className="small-col"
                            field="receiveAmount"
                            header="Receive Amount"
                            sortable
                          />
      
                          <Column
                            className="small-col"
                            field="receiveCurrency"
                            header="Receive Currency"
                            sortable
                          />
                        
                          <Column
                            field="paymentType"
                            header="Payment Type"
                            sortable
                          />               
      
                        </DataTable>
                      </div>
                    </div>
                  )}
                </div>
              </Scrollbars>
            </div>
            ) }

   
    </>
  );
};

export default Reports;
