import React, { useRef, useState, useEffect, useContext } from "react";
import profileimg from "../assets/images/profile-img.png";
import notification from "../assets/images/notification-img.png";
import { Menu } from "primereact/menu";
import "./Header.css";
import { useNavigate, useParams } from "react-router";
import Logouticon from "../assets/images/logoutwhite.svg";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Tooltip } from "primereact/tooltip";
import LocalStorageContext from "../components/Context/LocalStorageContext";
import { Empty } from "antd";

const Header: React.FC<any> = ({
  pageName,
  partnerNameDisplay,
  updateLogoSrc,
  }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const menu = useRef<Menu>(null);
  const navigate = useNavigate();
  const [status, setstatus] = useState(
    localStorage.getItem("OnboardingStatus")
  );
  const context = useContext(LocalStorageContext);
  const [partnerlogo, setlogo] = useState(context.logoSrc);

  const [showlogo, setshowLogo] = useState(false);

  const [pageName1, setPageName1] = useState("");

  const currentuser = localStorage.getItem("User");

  const [currentuserobj, setuserobj]: any = useState("");

  const [partnerName1, setPartnerName1] = useState("");
  const [notificationPopup, setNotificationPopup] = React.useState(false);

  function OnNotificationClick() {
    setNotificationPopup((notificationPopup) => !notificationPopup);
  }


  const accept = () => {
    navigate("/");
  };



  const setLogo = () => {


    if (context.logoSrc === "null") {

      setshowLogo(false);

    }
    else {
      setshowLogo(true);

    }

  };
  const reject = () => { };

  const confirm1 = () => {
    confirmDialog({
      message: "Are you sure you want to logout?",
      header: "Logout",
      icon: "pi pi-info-circle",
      accept,
      reject,
    });
  };
  const items = [
    {
      items: [
        {
          label: "Profile",
          icon: "pi pi-fw pi-user",
          command: () => {
            navigate("/profile");
          },
        },

        {
          label: "Organization",

          icon: "pi pi-fw pi-building",
          command: () => {
            navigate("/details");
          },
        },

        {
          label: "Logout",

          icon: "pi pi-lock",
          command: () => {
            confirm1();
          },
        },
      ],
    },
  ];
  const items1 = [
    {
      items: [
        {
          label: "Profile",
          icon: "pi pi-fw pi-user",
          command: () => {
            navigate("/profile");
          },
        },
        {
          label: "Logout",

          icon: "pi pi-lock",
          command: () => {
            confirm1();
          },
        },
      ],
    },
  ];

  useEffect(() => {

        
    setuserobj(JSON.parse(currentuser));
    setPartnerName1(partnerNameDisplay);
    setPageName1(pageName);
    // OnLogoUpdate();
    setPartnerName1(partnerNameDisplay);
    setPageName1(pageName);

  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="header-section">
        {context.logoSrc === null  ? <div className=""></div> : 
        (
          <div className="">
            {context.logoSrc === "null" || context.logoSrc == undefined || context.logoSrc === Empty ? (
             null
            ) :
            <div className="logo-section">
            <div className="partner-logo-bg">
                <img
            src={context.logoSrc}
            className="partner-logo"
      
          /> </div>
          </div>}
          </div>
        )}

        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ConfirmDialog id="confirm-popup" />
          <div className="has-search ">
            <span className="fa fa-search form-control-feedback" />
          </div>

          <p className="partner-name">
            Hi {currentuserobj.firstName} {currentuserobj.lastName}
          </p>
          <span className="notification-bar">
            {/* <p className="notification-count"> 1</p> */}
            <Button
              className="notification-icon"
              icon="pi pi-bell"
              onClick={OnNotificationClick}
            />
          </span>

          <div className="profileimg-mr">
            <Menu
              model={Number(context.status) === 8 ? items : items1}
              popup
              ref={menu}
              id="popup_menu"
            />
            {Number(context.status) === 5 ? null : (
              <>
                <button
                  title="Profile"
                  onClick={(event) => menu.current.toggle(event)}
                >
                  <img
                    src={profileimg}
                    aria-controls="popup_menu"
                    aria-haspopup
                  />
                </button>
              </>
            )}
          </div>
        </div>
       
      </div>
    </nav>
  );
};

export default Header;
